'use strict';
var progress = require('../progress'),
    util = require('../util'),
    ajax = require('../ajax');


// google address API place.address_components types
var componentForm = {
        street_number: 'short_name',  // street number
        route: 'long_name',   // street name
        locality: 'long_name'   // comuna
}
function searchForStores($value) {
	var $submitButton = $('.js-submit-store-search');
	var $target = $('.container-store-result');
	var selectedStore = $('.js-select-store').attr('name');
	var submitBTN = $submitButton.attr('name');
	var regionName = $('#dwfrm_storelocator_state option:selected').text()
	var params = {};
	params[selectedStore] = $value.val();
	params[submitBTN] = $submitButton.val();
	params.regionName = regionName;
	params.isStoreLocator = true;
	
	var url = util.appendParamsToUrl(Urls.findStores, params);
	var options = {
		url : url,
		type : 'POST',
		target : $target,
		callback: function () {
			progress.hide();
			initializeEvents();
		}
	}

	progress.show($target);

	ajax.load(options);
}


function displayAddress () {
    //make a new search to obtain current possition address
    var google_map_pos = new google.maps.LatLng(  $('.js-location-latitude').val(), $('.js-location-longitude').val());
    var google_maps_geocoder = new google.maps.Geocoder();
    google_maps_geocoder.geocode(
        { 'latLng': google_map_pos },
        function( results, status ) {
        	if (status == 'OK') {
        		var street = '';
        		var streetNumber = '';
        		var comuna = '';
  
        		for (var i = 0; i < results[0].address_components.length; i++) {
        			var addressType = results[0].address_components[i].types[0];
        			if (componentForm[addressType]) {
        				var value = results[0].address_components[i][componentForm[addressType]];
        				if (addressType === 'locality') {  
        					// fill Comuna select
        					comuna = value;
        				} else if (addressType === 'route') {
        					street = value;
        				} else if (addressType === 'street_number') {
        					streetNumber = value;
        				}
        			}
        		}
        		if (comuna && street && streetNumber) {
        			$('.js-user-location').html(street + ' ' + streetNumber +', '+comuna);
        			$('.js-location-address').removeClass('hidden');
        		} else {
        			$('.js-not-found-store-geolocation').hide();
        			$('.js-no-found-geolocation').show();
        		}
        	} else {
        		// trigger error msg
        		// $('.js-no-location-error').removeClass('hidden');
        	}
			$('.js-select-store').prop("selectedIndex", 0);
			
			if ($('.container-ubication.hidden').length <= 0) {
				$('.user-location').hide();
				$('.store-address-search-panel').addClass('current-address');
			}
        }

    );
}

function getLocation() {
	var $target = $('.js-store-pickup-list');
    progress.show($target);
    function showPosition(position) {
        $('.js-location-latitude').val(position.coords.latitude);
        $('.js-location-longitude').val(position.coords.longitude);
    	var $submitButton = $('.js-check-location');
    	var $target = $('.js-storelocator-container');
    	var lat = [$('.js-location-latitude').attr('name')];
    	var lon = [$('.js-location-longitude').attr('name')];
    	var submitName = [$submitButton.attr('name')];
    	var params = {};
    		params[lat] = $('.js-location-latitude').val();
    		params[lon] =  $('.js-location-longitude').val();
    		params[submitName] =  $submitButton.val();
    		params.isStoreLocator = true;
    	
    	var url = util.appendParamsToUrl(Urls.findStores, params);
    	var options = {
    		url : url,
    		type : 'POST',
    		target : $target,
    		callback: function () {
    			progress.hide();
    			initializeEvents();
    			displayAddress();
    		}
    	}

    	ajax.load(options);
        
    }
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, geoError);
    } else { 
        // show the error MSG
    }
    
    // IE hack:
    if (util.isIE() != false) {
        progress.hide();
    }
}

function geoError(err) {
	progress.hide();
}

function initializeEvents() {
	$('.js-select-store').on('change', function(e) {
		
		if ($(this).val() == '') {
			e.preventDefault();
			return;
		}
		searchForStores($(this));
	});
	
	$('.js-check-location').on('click', function(e) {
		getLocation();
		
	});
	var maxStoresToScrollBar = 6;
	if ($('.table-store-body .row-location').length > maxStoresToScrollBar) {
    	changeMaxHeight();
		$(window).resize(function () {
			changeMaxHeight();
        });    		    
    }
    
    function changeMaxHeight() {
    	var maxHeight = 0;
		$('.table-store-body .row-location:lt(' + maxStoresToScrollBar + ')').each(function () {
			maxHeight += $(this).outerHeight();
	    });
		$('.table-store-body').height(maxHeight);
    }
	
	
}
exports.init = function () {
	initializeEvents();
};
exports.displayAddress = displayAddress;
