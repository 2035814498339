'use strict';

var regions = require('../../regions');
var dialog = require('../../dialog');
var personalDetails = require('./personalDetails');
var formPrepare = require('./formPrepare');
var formatRut = require('./../../components/formatRut');

exports.initFormPrepare = function () {
    formPrepare.init({
        continueSelector: '.js-facturasave',
        formSelector: '.js-facturaform',
        enabledByDefault: true,
    });

    $(document).bind('factura-form-show', function (e, status) {
        formPrepare.validateForm({
            continueSelector: '.js-facturasave',
            formSelector: '.js-facturaform',
            enabledByDefault: true,
        });
    });
};

exports.init = function () {
    regions.initializeRegionsComunaFiltering();
    formatRut.initFormatRutInstance('#js-factura-rut-container');

    $('.remove-factura').on('click', function (e) {
        e.preventDefault();
        var deleteUrl = this.href;
        dialog.open({
            html: Resources.ABOUT_TO_DELETE,
            options: {
                title: Resources.DELETE_ADDRESS,
                titleClass: 'text-uppercase',
                width: 600,
                //height: '483',
                responsive: true,
                collision: 'none',
                modal: true,
                dialogClass: 'alert-modal remove-factura-dialog',
                close: function () {
                    $(this).dialog('close');
                },
                buttons: [
                    {
                        text: Resources.CANCEL,
                        class: 'btn btn-secondary btn-forms text-uppercase',
                        click: function () {
                            $(this).dialog('close');
                        },
                    },
                    {
                        text: Resources.DELETE,
                        class: 'btn btn-primary btn-forms btn-remove-factura text-uppercase',
                        click: function () {
                            if (window.location.search) {
                                window.location.href = `${deleteUrl}${window.location.search}`;
                            } else {
                                window.location.href = deleteUrl;
                            }
                        },
                    },
                ],
            },
        });
    });
};
