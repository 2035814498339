const collapse = {
  className: 'collapser',

  init() {
    $(`.${this.className}`).each((i, el) => {
      const $el = $(el);
      const $title = $el.find(`[data-collapse="title"]`);
      const $content = $el.find(`[data-collapse="content"]`);

      $el.show();

      const toggle = (init = false) => {
        if (!init) {
          const $otherEls = $(`.${this.className}--group`).not($el);
          $otherEls.removeClass(`${this.className}--open`);
          $otherEls.find(`[data-collapse="content"]`).hide();
        }

        if (init) {
          $content.hide();
          return;
        }

        if ($el.hasClass(`${this.className}--open`)) {
          this.close($el, $content);
        } else {
          this.open($el, $content);
        }
      };

      toggle(true);
      $title.on('click', (e) => {
        toggle();
      });
    });
  },

  close($el, $content) {
    $el.removeClass(`${this.className}--open`);
    $content.hide();
  },

  open($el, $content) {
    $el.addClass(`${this.className}--open`);
    $content.show();
  },
};

module.exports = collapse;
