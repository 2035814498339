'use strict';

function unavailableProducts(element, reason, shippingMethod) {
	if ($(element).length > 0) {
		var skuArr = []
		var priceSum = 0;

		$(element).find('.js-product-sku').each(function (index, el) {
			skuArr.push($(el).text());
			priceSum = priceSum + Number($(el).data('price'));
		});

		if (skuArr.length > 0) {
			window.dataLayer.push({
				'event-interactive-category': 'error',
				'event-interactive-accion': 'unavailable product - ' + shippingMethod + ' - ' + reason + ' - ' + skuArr.join('/'),
				'event-interactive-tag': 'checkout step 2',
				'event-interactive-value': priceSum,
				'event': 'event-interactive'
			});
		}
	}
}

function unavailableClickAndCollectMethod(element, reason, shippingMethod) {
	var isClickAndCollectAvailable = $('[data-delivery-type="CLICK_COLLECT"]').length > 0;
	if (!isClickAndCollectAvailable && $(element).length > 0) {
		var skuArr = [];
		var priceSum = 0;
		$(element).each(function (index, el) {
			skuArr.push($(el).data('minilineitem').id);
			priceSum = priceSum + Number($(el).data('minilineitem').price);
		})

		if (skuArr.length > 0) {
			window.dataLayer.push({
				'event-interactive-category': 'error',
				'event-interactive-accion': 'unavailable product - ' + shippingMethod + '- ' + reason + ' - ' + skuArr.join('/'),
				'event-interactive-tag': 'checkout step 2',
				'event-interactive-value': priceSum,
				'event': 'event-interactive'
			});
		}
	}
}

exports.unavailableProducts = unavailableProducts;
exports.unavailableClickAndCollectMethod = unavailableClickAndCollectMethod;