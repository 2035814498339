'use strict';
var CatalogProductGuide = {
    currentStep: 0,

    init: function() {
        $(() => {
            const $form = $('#catalog-product-guide-search');
            if ($form.length > 0) {
                $('[data-content="catalog-product-guide-attributefilter"]').hide();
                $('input[type=radio][name=catalog-product-guide-categoryfilter]').change(function() {
                    categorySelectedChange();
                });
                $(function() {
                    categorySelectedChange();
                    searchProduct();
                    $('#catalog-product-guide-search input').change(function() { 
                        searchProduct();
                   }); 
                });
                this.initStepper($form);
            }
        });
    },

    initStepper($form) {
        $form.find('.catalog-product-guide__step').hide();
        this.initButtonStepper($form);
        this.refreshStepState($form);
    },

    initButtonStepper($container) {
        this.refreshButtonsState($container);
        $container.find('.catalog-product-guide__step-button[data-action="prev"]').on('click', this.handleClickPrevStep.bind(this));
        $container.find('.catalog-product-guide__step-button[data-action="next"]').on('click', this.handleClickNextStep.bind(this));
    },

    handleClickPrevStep(e) {
        const $container = $(e.target).closest('.catalog-product-guide__form');
        this.currentStep = this.currentStep - 1;
        this.refreshButtonsState($container);
        this.refreshStepState($container);
    },

    handleClickNextStep(e) {
        const $container = $(e.target).closest('.catalog-product-guide__form');
        this.currentStep = this.currentStep + 1;
        this.refreshButtonsState($container);
        this.refreshStepState($container);
    },

    refreshStepState($container) {
        $container.find('.catalog-product-guide__step').hide().each((i, stepElement) => {
            const $stepElement = $(stepElement);
            if (i === this.currentStep) {
                $stepElement.show();
            }
        });
    },

    refreshButtonsState($container) {
        const $step = $container.find('.catalog-product-guide__step');
        const hideClass = 'catalog-product-guide__step-button-wrap--hide';

        $container.find('.catalog-product-guide__step-button-wrap').addClass(hideClass);
        $container.find('.catalog-product-guide__step-button').prop('disabled', true).each((i, button) => {
            const $button = $(button);
            const action = $button.data('action');

            if (action === 'prev') {
                if (this.currentStep > 0) $button.prop('disabled', false).parent().removeClass(hideClass);
            }
            if (action === 'next') {
                if (this.currentStep < $step.length - 1) $button.prop('disabled', false).parent().removeClass(hideClass);
            }
        });
    }
}

function categorySelectedChange(){
    $('[data-content="catalog-product-guide-attributefilter"]').hide();
    //$('[data-content="catalog-product-guide-li-categoryfilter"]').hide();
    $(".js-catalog-product-guide-next-step-button").prop("disabled", true);
    $('.js-checkbox-catalog-product-guide').prop('checked',false);
    $('.js-radio-price-catalog-product-guide').prop('checked',false);
    
    if($("#catalog-product-guide-uniqueCategory").length > 0){
        let category = $("#catalog-product-guide-uniqueCategory").val();
        $(".js-catalog-product-guide-next-step-button").prop("disabled", false);
        $('[data-content="catalog-product-guide-attributefilter"]').each(function(element){
            $(this).show();
        });
    }else{
        $(':input[type="radio"][name="catalog-product-guide-categoryfilter"]:checked').each(function(radio){
            let category = $(this).data('value');
            let step = $(this).data('step');
            $(".js-catalog-product-guide-next-step-button").prop("disabled", false);
            $('[data-content="catalog-product-guide-attributefilter"]').each(function(element){
                if($(this).data('category') == category){
                    $(this).show();
                }
            });
            $('[data-content="catalog-product-guide-li-categoryfilter"]').each(function(element){
                if(($(this).data('parent') != category && $(this).data('step') > step)){
                    $(this).hide();
                }
                if(($(this).data('parent') == category && $(this).data('step') > step)){
                    $(this).show();
                }
            });
        });
    }
    if($(':input[type="radio"][name="catalog-product-guide-categoryfilter"]:checked').length == 0){
        $('[data-content="catalog-product-guide-li-categoryfilter"]').show();
    }
    $(':input[type="radio"][name="catalog-product-guide-categoryfilter"]:checked').show();
}

function searchProduct(){
    var form =  $("#catalog-product-guide-search");
    var filters = {};
    //var type_collection = [];
    if ($('#catalog-product-guide-search').length > 0) {
        if($("#catalog-product-guide-uniqueCategory").length > 0){
            filters['category'] = $("#catalog-product-guide-uniqueCategory").val();
        } else {
            if($(':input[type="radio"][name="catalog-product-guide-categoryfilter"]:checked').length > 0) {
                filters['category'] = $(':input[type="radio"][name="catalog-product-guide-categoryfilter"]:checked').val();
            }
        }
    }
        
    
    if ($('#catalog-product-guide-search').length > 0) {
        if($("#catalog-product-guide-pmid").length > 0){
            filters['promotionId'] = $("#catalog-product-guide-pmid").val();
        }    
    }
    filters['filters'] = [];
    $(form).find(':input').each(function() {
        if($(this).is(':checked')){
            switch ($(this).data('type')) {
                case 'fixed':
                    var filter = {
                        "type":$(this).data('type'),
                        "values":$(this).data('value'),
                        "name":$(this).data('name')
                    };
                    filters.filters.push(filter);
                    break;
                case 'price':
                    var filter = {
                        "type":$(this).data('type'),
                        "maxPrice":$(this).data('maxprice'),
                        "minPrice":$(this).data('minprice')
                    };
                    filters.filters.push(filter);
                    break;
                case 'collection':
                    var exist = 0;
                    for (i = 0; i < filters.filters.length; i++) {
                        if(filters.filters[i].name == $(this).data('name')){
                            exist = 1;	
                            filters.filters[i].values.push($(this).data('value'));
                        }
                    }
                    if(exist == 0){
                        var filter = {
                            "type":$(this).data('type'),
                            "name":$(this).data('name'),
                            "values":[
                                $(this).data('value')
                            ]
                        };
                        filters.filters.push(filter);
                    }
                    break;
                case 'range':
                    var filter = {
                        "type":$(this).data('type'),
                        "maxValue":$(this).data('maxvalue'),
                        "minValue":$(this).data('minvalue'),
                        "name":$(this).data('name')
                    };
                    filters.filters.push(filter);
                    break;
            }
        }
    });

    let str = [];
    for (let p in filters){
        if (filters.hasOwnProperty(p)) {
            if(p !== "filters") str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filters[p]));
            else{
                filters[p].forEach(function (filter, index) {
                    for (let attr in filter){
                        if (filter.hasOwnProperty(attr)) {
                            let value = Array.isArray(filter[attr]) ? filter[attr].join('|') : filter[attr];
                            str.push(encodeURIComponent(attr) + (index+1) + "=" + encodeURIComponent(value));
                        }
                    }
                });
            }
        }
    }

    var data = $.ajax({
        contentType: 'application/json',
        type: "GET",
        data: decodeURIComponent(str.join("&")),
        dataType: "json",
        url: Urls.getQtyFilteredProduct,
        success: function(data){
            $('#catalog-product-guide-counter-text').each(function () {
                $(this).prop('Counter',parseInt($("#catalog-product-guide-counter-text").text())).animate({
                    Counter: data.data.count
                }, {
                    duration: 500,
                    easing: 'swing',
                    step: function (now) {
                        $(this).text(Math.ceil(now));
                    }
                });
            });
            if(data.data.count == '0'){
                $("#catalog-product-guide-link-result").attr("disabled", true);
                $("#catalog-product-guide-link-result").removeAttr("href");
            }else{
                $("#catalog-product-guide-link-result").attr("disabled", false);
                $("#catalog-product-guide-link-result").attr("href", data.data.url);
            }
        }
    });
}
module.exports = CatalogProductGuide;