Number.prototype.isBetween = function(min, max) {
  return this > min && this < max;
};

const ProductsMarking = require('./../../utils/ProductsMarking');
const image = require('./../../pages/product/image');

const sliderAdjustable = {
  currentWith: 0,
  afterChange: null,
  resizeTimer: null,
  initialized: false,

  initAfterChangeEvent() {
    if (this.afterChange && typeof this.afterChange === 'function') {
      this.afterChange();
    }
  },

  init() {
    $(() => {
      $('.slider-adjustable').each((i, el) => {
        if ($(el).hasClass('slick-initialized')) {
          return;
        }

        this.initialized = true
        const widthContainer = Math.round($(el).width());
        const listMarkingEnabled = $(el).data('listMarking') || 0;
        const listMarkingDescription = $(el).data('listMarkingDescription');
        const listMarkingSlot = $(el).data('listMarkingSlot');
				const isHomeCarousel = $(el).data('listMarkingIsInHome');
				const homeOrder = $(el).data('listMarkingHomeOrder');

        const Marking = listMarkingEnabled ? new ProductsMarking() : null;

        if (listMarkingEnabled) {
					let listName = '';
					if(isHomeCarousel){
						const homeVitrina = window.Resources.GTM_HOME_CAROUSEL_LIST_PARAMETER;
						listName = `${homeVitrina} ${homeOrder}: ${listMarkingDescription}`
					}else{ 
						listName = listMarkingSlot !== null && listMarkingSlot !== undefined ? `${listMarkingDescription} - ${listMarkingSlot}` : `${listMarkingDescription}`;
					}
					Marking.defineListName(listName);
					Marking.defineIsInHome(isHomeCarousel);
        }

        $(el).on('init', (e, slick) => {
          if (!!Marking) {
            Marking.handleChangeSlide(slick);
          }
        });

        $(el).slick(this.getOptions(widthContainer));

        $(el).on('afterChange', (e, slick, currentSlide) => {
          if (!!Marking) {
            Marking.handleChangeSlide(slick, currentSlide);
          }

          image.lazyLoad();
        });

        if ($(el).hasClass('slider-adjustable--debugger')) {
          this.setWidthInfo(el, widthContainer);
        }
      });

        if (!this.initialized) {
            $(window).on('resize', this.handleResize.bind(this));
        }
    });
  },

  handleResize(e) {
    this.getCurrentWidth();
    
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.restart();
    }, 200);
  },

  getCurrentWidth() {
    this.currentWith = $(window).width();
  },

  restart() {
    $('.slider-adjustable').each((i, el) => {
      if (!$(el).hasClass('slick-initialized')) {
        return;
      }

      $(el).find('.slider-adjustable-box-info').remove();
      $(el).slick('unslick');

      const widthContainer = Math.round($(el).width());
      $(el).slick(this.getOptions(widthContainer)).on('afterChange', () => {
        setTimeout(() => {
          image.lazyLoad();
        }, 100);
      });

      if ($(el).hasClass('slider-adjustable--debugger')) {
        this.setWidthInfo(el, widthContainer);
      }
    });
  },

  setWidthInfo(el, width) {
    let tag = $(el).find('.slider-adjustable-box-info');

    if (!tag.length) {
      tag = $('<div class="slider-adjustable-box-info"></div>');
      $(el).append(tag);
    }

    $(el).css('position', 'relative');
    $(el).css('border', '1px solid red');

    tag.css({
      position: 'absolute',
      top: '4px',
      left: '4px',
      backgroundColor: 'red',
      color: 'white',
      border: '1px solid black',
      padding: '0px 2px',
      fontSize: '10px',
      display: 'inline-block',
    });

    // tag.text(JSON.stringify(this.getOptions(width)) + ' - - - ' + width);
    tag.text(width);
  },

  getOptions(widthContainer) {
    const options = {
      centerMode: false,
      dots: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    };

    if (widthContainer.isBetween(0, 599)) {
      options.slidesToShow = 2;
      options.slidesToScroll = 2;
    }

    if (widthContainer.isBetween(600, 799)) {
      options.slidesToShow = 3;
      options.slidesToScroll = 3;
    }

    if (widthContainer.isBetween(800, 1004)) {
      options.slidesToShow = 4;
      options.slidesToScroll = 4;
    }

    if (widthContainer.isBetween(1005, Infinity)) {
      options.slidesToShow = 5;
      options.slidesToScroll = 5;
    }

    return options;
  },
};

module.exports = sliderAdjustable;
