"use strict";

var utils = require("./../util");

var mobileSearch = {
    /* variables que se iniciaran en la funcion initDomVariables */

    containerSearch: null,
    formSearch: null,
    inputSearch: null,
    iconSearch: null,
    resetSearch: null,
    containerSuggestions: null,
    hideSearch: null,

    /* fin de variables a inicializar */

    requestTimeoutInstance: null,
    requestTimeout: 500,
    prevSearchUrl: "",

    init() {
        $(document).ready(() => {
            this.initDomVariables();
            this.initEvents();
            if (!this.inputSearch.val()) {
                this.resetSearch.hide();
            }
        });
    },

    initDomVariables() {
        this.containerSearch = $("#mobile-fixed-search");
        this.formSearch = $("#mobile-fixed-search__form");
        this.inputSearch = $("#mobile-fixed-search__form-input");
        this.resetSearch = $("#mobile-fixed-search__form-reset");
        this.containerSuggestions = $("#mobile-fixed-search__inner");
        this.overlay = $("#overlay-content");
        this.iconSearch = $("#icon-search--input");
        this.hideSearch = $("#mobile-fixed-search__form-back");
    },

    initEvents() {
        this.resetSearch.bind("click", this.handleResetSearch.bind(this));
        this.formSearch.bind("submit", this.handleSubmitForm.bind(this));
        this.inputSearch.bind("focus", this.handleFocusSearch.bind(this));
        this.inputSearch.bind("keyup", this.handleKeyUpSearch.bind(this));
        this.iconSearch.bind("click", this.handlerOpenSearch.bind(this));
        this.hideSearch.bind("click", this.handleBlurSearch.bind(this));
        
        // hide component
        // this.inputSearch.bind('blur', this.handleBlurSearch.bind(this));
        // $(document).bind("click", this.handleBlurSearch.bind(this));
        this.containerSearch.bind("click", (e) => {
            e.stopPropagation();
        });
    },

    handlerOpenSearch(e){
        e.preventDefault();
        this.containerSearch.removeClass('hidden');
        this.inputSearch.focus();
    },

    handleResetSearch(e) {
        this.getData("");
        this.resetSearch.hide();
        this.inputSearch.val("");
        this.inputSearch.attr("value", "");
        this.inputSearch.focus();
    },

    handleSubmitForm(e) {
        if (!this.inputSearch.val()) {
            e.preventDefault();
        } else {
            localStorage.setItem("search-text", this.inputSearch.val());
        }
    },

    handleFocusSearch(e) {
        // hide mobile-fixed menu if it´s open
        $('.navbarToggle input[type="checkbox"]:checked').click();

        // hide customer popup if it´s open
        if (
            !!window.headerCustomer.$popup &&
            window.headerCustomer.$popup.hasClass("header-popup--active")
        ) {
            window.headerCustomer.handleClick();
        }

        var currentSearchValue = e.target.value;
        this.getData(currentSearchValue);
        this.containerSuggestions.addClass("mobile-fixed-search__inner--open");
        this.containerSuggestions.addClass(
            "mobile-fixed-search__inner--loading"
        );
        this.overlay.addClass("js-mobile-search-overlay--show");
        $("html, body").addClass("disabled-scroll");
    },

    handleBlurSearch(e) {
        this.prevSearchUrl = "";
        this.containerSuggestions.removeClass(
            "mobile-fixed-search__inner--open"
        );
        this.overlay.removeClass("js-mobile-search-overlay--show");
        this.containerSearch.addClass('hidden');
        $("html, body").removeClass("disabled-scroll");
    },

    handleKeyUpSearch(e) {
        var currentSearchValue = this.inputSearch.val();
        if (currentSearchValue.length) {
            this.resetSearch.show();
        } else {
            this.resetSearch.hide();
        }
        this.getSuggestions(currentSearchValue);
    },

    getSuggestions(value) {
        this.containerSuggestions.addClass(
            "mobile-fixed-search__inner--loading"
        );
        clearInterval(this.requestTimeoutInstance);
        this.requestTimeoutInstance = window.setTimeout(() => {
            this.getData(value);
        }, this.requestTimeout);
    },

    getData(value) {
        var minLengthToSearch = SitePreferences.SIMPLE_SEARCH_FIELD_MIN_LENGTH;

        var requestUrl =
            value.length >= minLengthToSearch
                ? utils.appendParamToURL(Urls.searchsuggestions, "q", value)
                : Urls.einsteinsuggestions;

        if (requestUrl === this.prevSearchUrl) {
            if (
                this.containerSuggestions.hasClass(
                    "mobile-fixed-search__inner--loading"
                )
            ) {
                this.containerSuggestions.removeClass(
                    "mobile-fixed-search__inner--loading"
                );
            }
            return;
        }

        this.prevSearchUrl = requestUrl;
        $.get(requestUrl, (data) => {
            if (
                this.containerSuggestions.hasClass(
                    "mobile-fixed-search__inner--loading"
                )
            ) {
                this.containerSuggestions.removeClass(
                    "mobile-fixed-search__inner--loading"
                );
            }
        const containerSuggestions = this.containerSuggestions.html(data);
           
        });
    }
};

module.exports = mobileSearch;
