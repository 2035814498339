'use strict';

var ajax = require('../../ajax'),
	formPrepare = require('./formPrepare'),
	progress = require('../../progress'),
	util = require('../../util'),
	validator = require('../../validator');

function initializeDeliveryCalendar() {
	if (!SitePreferences.ENABLE_NEW_HOME_DELIVERY_UI) {
		$('.delivery-carousel:visible').slick({
			slidesToScroll: 1,
			slidesToShow: 5,
			infinite: false,
			dots: false,
			prevArrow: $('#delivery-prev'),
			nextArrow: $('#delivery-next'),
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 3
				}
			}, {
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			}]
		});
		selectDefaultTimeSlots();
	}
	initializeEvents();
}

function updateLineItemDates() {
	var lineItemDates = {};
	$('.js-line-item-selected-dates').val('');
	if (!SitePreferences.ENABLE_NEW_HOME_DELIVERY_UI) {
		$('.js-delivery-date-list input:checked').each(function (index, elem) {
			lineItemDates[$(elem).data('sku')]= {
				"dispatchDate" : $(elem).data('date'),
				"arrivalDate"  : $(elem).data('delivery-date'),
				"facility"     : $(elem).data('facility') ? $(elem).data('facility').toString() : ''
			}
		});
	} else {
		$('select.js-select-delivert-date option:selected').each(function (index, elem) {
			lineItemDates[$(elem).data('sku')]= {
				"dispatchDate" : $(elem).data('date'),
				"arrivalDate"  : $(elem).data('delivery-date'),
				"facility"     : $(elem).data('facility') ? $(elem).data('facility').toString() : ''
			}
		});
	}
	$('.js-line-item-selected-dates').val(JSON.stringify(lineItemDates));
}

function selectDefaultTimeSlots() {
	var selected = false;
	var ind = 0;
	var productsDateSeleted = new Array();
	$('.js-delivery-date-list:first > li').each(function (item){
		productsDateSeleted.push(false);
	});
	$('.js-delivery-date-list').each(function (index, elem) {
		if (selected) {
			return;
		}

		for (var i=1; i< $(elem).find('li').length; i++) {
			var entry = $(elem).find('li')[i];
			if (!productsDateSeleted[i] && $(entry).hasClass('js-date-entry-available')) {
				productsDateSeleted[i] = true;
				$(entry).find('input.js-date-select-input').trigger('click');
				updateLineItemDates();
			}
		}
	});
	$('.delivery-carousel:visible').slick('slickGoTo', 0);

}

/** @function
 * @name updateSelectedDateWithDeliveryWindowHour
 * set hour range by sku
 * */
function updateSelectedDateWithDeliveryWindowHour(sku, window) {
	var selectedDates = JSON.parse($('.js-line-item-selected-dates').val());
	if (selectedDates[sku]) {
		var windowHour = window.substring(0, 5).concat(':00');
		selectedDates[sku]["dispatchDate"] = selectedDates[sku]["dispatchDate"].substring(0, 11).concat(windowHour);
		selectedDates[sku]["arrivalDate"] = selectedDates[sku]["arrivalDate"].substring(0, 11).concat(windowHour);
	}
	$('.js-line-item-selected-dates').val(JSON.stringify(selectedDates));
}

/** @function
 * @name getAllDeliveryWindowsData
 * Iterate all select delivery windows and update references values
 * */
function getAllDeliveryWindowsData() {
	var lineItemWindows = {};
	$('select.js-select-delivert-window').each(function (index, elem) {
		var optionSelect = $(elem).find(':selected:not([disabled])').val();
		if (optionSelect) {
			var optionSelecObj = JSON.parse(optionSelect);
			lineItemWindows[$(elem).data('sku')] = optionSelecObj;
			updateSelectedDateWithDeliveryWindowHour($(elem).data('sku'), optionSelecObj.deliveryTime);
		}
	});
	$('.js-line-item-selected-windows').val(JSON.stringify(lineItemWindows));
}

/** @function
 * @name getDeliveryWindowsByDeliveryDates
 * Get select input by change event and build delivery windows select
 * */
function getDeliveryWindowsByDeliveryDates(_this) {
	// get select selected
	var optionSelect = $(_this).find(':selected');
	// get delivery windows select by sku
	var deliveryWindowsSelect = $('select.js-select-delivert-window[data-sku="' + optionSelect.data('sku') + '"]')[0];
	if (deliveryWindowsSelect)
		setDeliveryWindowOptionsInSelect(deliveryWindowsSelect, optionSelect.data('delivery-windows'), optionSelect.data('delivery-windows-costs')); // fill delivery windows select with options windows
}

/** @function
 * @name setDeliveryWindowOptionsInSelect
 * Append delivery windows to select
 * */
function setDeliveryWindowOptionsInSelect(deliveryWindowsSelect, deliveryWindows, deliveryCost) {
	// remove all options except first
	$(deliveryWindowsSelect).empty();
	// add options to select by delivery window
	deliveryWindows.forEach(function (deliveryWindow) {
		var window = deliveryWindow.deliveryTimeStart.concat(' a ').concat(deliveryWindow.deliveryTimeEnd);
		// this hidden cost price label in front
		//var price = deliveryCost[deliveryWindow.codigo] ? ' (' + deliveryCost[deliveryWindow.codigo] + ')' : (deliveryCost.TH ? ' (' + deliveryCost.TH + ')' : '');
		// var option = new Option(window+price, JSON.stringify({
		// 	codigo: deliveryWindow.codigo,
		// 	deliveryTime: window,
		// 	deliveryCost: price
		// }));
		var option = new Option(window, JSON.stringify({
			codigo: deliveryWindow.codigo,
			deliveryTime: window
		}));
		$(deliveryWindowsSelect).append(option);
	});
	// active input and select first option by default
	$(deliveryWindowsSelect).prop({
		'disabled': false,
		'selectedIndex': 0
	});
	getAllDeliveryWindowsData();
}

function initializeEvents() {
	if ($('.js-shipping-method-option-container').find('div.field-wrapper').length > 1) {
		$('.js-shipping-method-option-container').find('div.field-wrapper').first().hide();
//		if ($('.js-selected-address').length > 0) {
//			 $('.js-selected-address').hide();
//		}
	}
	if (!SitePreferences.ENABLE_NEW_HOME_DELIVERY_UI) {
		$('li.js-date-entry-available input').off('click');
		$('li.js-date-entry-available input').on('click', function () {

			updateLineItemDates();
		});
	} else {
		$('select.js-select-delivert-date').off('change');
		$('select.js-select-delivert-date').change( function () {
			updateLineItemDates();
		});
		if ($('select.js-select-delivert-date option:selected').length > 0) {
			updateLineItemDates();
		}

	}

	$('.js-click-on-click-and-collect').on('click', function() {
		$("div.js-choose-shippingmethod").find("[data-delivery-type=CLICK_COLLECT]").click();
	});

	// add event change to select window input
	$('select.js-select-delivert-window').on('change', function () {
		getAllDeliveryWindowsData();
	});
	// fill delivery window by delibery date
	$('select.js-select-delivert-date').each(function () {
		getDeliveryWindowsByDeliveryDates(this);
	});
	// add event change to select date input
	$('select.js-select-delivert-date').on('change', function () {
		getDeliveryWindowsByDeliveryDates(this);
	});
	if ($('.productos').length) {
		if ($('#dwfrm_singleshipping_shippingAddress select.js-select-delivert-date').length || $('#dwfrm_singleshipping_shippingAddress div.service-product')) {
			$('#dwfrm_singleshipping_shippingAddress select.js-select-delivert-date').first().focus();
			var continueBillingToPay = $('.js-submit-shipping').data('continuebillingtopay');
			$('.js-submit-shipping').val(continueBillingToPay);
			$('.js-submit-span-shipping').text(continueBillingToPay);
		} else {
			$('.js-submit-shipping').attr('disabled', 'disabled');
		}
	}
	//TODO disable the form button
	//only enable the button if all radio buttons are selected, for each product item
}

exports.init = function () {
	initializeEvents();
};

exports.initializeDeliveryCalendar = initializeDeliveryCalendar;
