'use strict';
const util = require('./util');
const page = require('./page');
window.formNotification = require('./components/commons/form-notification');
const createPassword = {
  init() {
    $(() => {
      const $form = $('.js-create-password-form');
      const self = this;
      if (!$form.length) {
        return;
      }

      const formValidation = $form.validateForm();
      let $notification = null;

      formValidation.onErrorUpdated = function() {
        if (this.hasErrors()) {
          this.$submitButton.prop('disabled', 'disabled');
        } else {
          this.$submitButton.removeAttr('disabled');
        }
      };

      $form.on('submit', function(e) {
        e.preventDefault();

        if ($notification) {
          $notification.remove();
        }

        formValidation.$submitButton.addClass('btn--loading').prop('disabled', 'disabled').text('Cargando...');

        formValidation.validate().then(valid => {

          if (valid) {
            // console.log('fields: ', formValidation.$fields);


            self.submit(formValidation.$fields, $form)
              .then(data => {
                // window.location.href = data.redirectUrl;
                const rut = document.querySelector("input[name='dwfrm_order_custom_rut']").value;
                self.sendCreateGuestPasswordSuccess(rut);
                const $success_create_user_message = $('#success-create-user-message');
                const $create_user_wrapper = $('#create-user-wrapper');

                $success_create_user_message.removeClass('hidden').show();
                $create_user_wrapper.addClass('hidden').hide();

                formValidation.$submitButton.removeClass('btn--loading').removeAttr('disabled').text('Enviar');
                formValidation.resetForm();
              })
              .catch(e => {
                self.sendCreateGuestPasswordFailed(e);
                // window.location.href = window.location.href;
                $notification = window.formNotification('error', 'Error', 'Complete los campos por favor');
                formValidation.$form.prepend($notification.css('marginBottom', 20));
                formValidation.$submitButton.removeClass('btn--loading').removeAttr('disabled').text('Enviar');
              })
              .finally(() => {
                // this.formValidation.$submitButton.empty().removeClass('btn--loading').text('Crear cuenta');
              });
          } else {
            $notification = window.formNotification('error', 'Error', 'Complete los campos por favor');
            formValidation.$form.prepend($notification.css('marginBottom', 20));
            formValidation.$submitButton.removeClass('btn--loading').removeAttr('disabled').text('Enviar');
          }
        });
      });
    });
  },
  
  submit(fields, $form) {
    window.headerCustomer.setUser('New Customer name confirmation');
    const $submitLogin = $($form).find('[name$="dwfrm_profile_confirm"]');
    let data = $form.serialize();  
    data += '&' + $submitLogin.attr('name') + '=';
    const url = util.appendParamToURL($form.attr('action'),'format', 'ajax');
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        dataType: 'json',
        success: function (resp) {
            if (resp.data.errorCode === 200) {
                resolve(resp.data);
            } else {
                reject(resp.data);
            }
        },
        failure: function (e) {
          reject(e);
        }
      });
    });

  },
  sendCreateGuestPasswordSuccess(rut){
    let event = {
      'event-interactive-category': window.Resources.GTM_CREATE_PASSWORD_SUCCESS_CATEGORY,
      'event-interactive-accion': window.Resources.GTM_CREATE_PASSWORD_SUCCESS_ACTION,
      'event-interactive-tag': window.location.pathname,
      'event-interactive-rut': btoa(rut), 
      'event': window.Resources.GTM_CREATE_PASSWORD_SUCCESS_TAG,
    };

    window.dataLayer.push(event);
  },
  sendCreateGuestPasswordFailed(error){
    let event = {
      'event-interactive-category': window.Resources.GTM_CREATE_PASSWORD_ERROR_CATEGORY, 
      'event-interactive-accion': window.Resources.GTM_CREATE_PASSWORD_ERROR_ACTION, 
      'event-interactive-tag': error.message, 
      'event': window.Resources.GTM_CREATE_PASSWORD_ERROR_TAG,
    };
    window.dataLayer.push(event);
  }  
};


module.exports = createPassword;