const util = require('../../util')

const uiNotification = function(type, title, text) {
  this.$notificationsContainer = null;

  this.mountContainer = () => {
      let $notificationsContainer = $('#notifications-container');

      if ($notificationsContainer.length) {
          this.$notificationsContainer = $notificationsContainer;
          return;
      }

      $notificationsContainer = $('<div></div>');
      $notificationsContainer
          .attr('id', 'notifications-container')
          .addClass('notification-ui')
          .css({
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              pointerEvents: 'none',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
              padding: 16,
              zIndex: 9999,
          });

      $('body').append($notificationsContainer);

      this.$notificationsContainer = $notificationsContainer;
  };

  this.createIconNotification = (type) => {
    const $iconNotification = $('<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.61739 0.955832L8.85456 0.05943C8.82422 0.01981 8.77654 0 8.7332 0C8.68552 0 8.64218 0.01981 8.61184 0.05943L3.32402 6.14605L1.39961 3.94714C1.36493 3.90752 1.32159 3.88771 1.27825 3.88771C1.2349 3.88771 1.19156 3.90752 1.15689 3.94714L0.385385 4.82868C0.316037 4.90792 0.316037 5.03174 0.385385 5.11098L2.81258 7.88437C2.96861 8.06266 3.15932 8.16667 3.31969 8.16667C3.54941 8.16667 3.74878 7.97352 3.82247 7.89428H3.8268L9.62173 1.23812C9.6824 1.15393 9.6824 1.03012 9.61739 0.955832Z" fill="#009372"/></svg>');
    return $('<div></div>')
      .css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })
      .append($iconNotification.css('display', 'block'));
  };

  this.createNotification = (type, title, text) => {
      const $notification = $('<div></div>');
      const $notificactionTitle = $('<div>' + title + '</div>');
      const $notificactionDescription = $('<div>' + text + '</div>');
      const $notificactionIcon = this.createIconNotification(type);
      const $notificactionInner = $('<div></div>');
      const $notificactionWrap = $('<div></div>');
      const $closeButton = $('<button type="button"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: block;"><path d="M19.6852 2.57118C20.1048 2.15028 20.105 1.46954 19.6852 1.04978C19.2634 0.629361 18.5832 0.629361 18.1628 1.04978L10 9.21237L1.83681 1.04978C1.41827 0.629215 0.735295 0.629215 0.315249 1.04993C-0.105107 1.4696 -0.105107 2.15068 0.315395 2.57118L8.47811 10.7343L0.31591 18.8963C-0.104544 19.3168 -0.104544 19.9976 0.315764 20.4186C0.52117 20.6234 0.794605 20.7346 1.07725 20.7339C1.35922 20.7346 1.63278 20.6233 1.83733 20.4188L10 12.2562L18.1628 20.4188C18.3677 20.6231 18.6413 20.7344 18.924 20.7344C19.206 20.7344 19.4795 20.6231 19.6846 20.4193C20.1052 19.9974 20.105 19.3167 19.6847 18.8963L11.522 10.7343L19.6852 2.57118Z" fill="white"/></svg></button>');

      $notificactionTitle.css({
          fontFamily: "Montserrat, arial, helvetica",
          fontSize: 14,
          lineHeight: '16px',
          color: '#FFFFFF',
          marginBottom: 2,
      });

      $notificactionDescription.css({
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '16px',
          color: '#FFFFFF',
      });

      $notificactionIcon.css({
          flexShrink: 0,
          marginRight: 8,
          width: 20,
          height: 20,
          backgroundColor: '#FFFFFF',
          borderRadius: '50%',
      });

      $notificactionInner.css({
          padding: '2px 0px',
      });

      $notificactionWrap.css({
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexGrow: 1,
          maxWidth: '100%',
      });

      $closeButton
          .on('click', () => {
              this.removeNotification($notification);
          })
          .css({
              flexShrink: 0,
              display: 'block',
              border: 'none',
              marginLeft: 8,
              marginRight: 8,
          });
      
      $notificactionInner.append($notificactionTitle, $notificactionDescription);
      $notificactionWrap.append($notificactionInner, $closeButton);

      if (util.isMobile()) {
        $notification.css({
          borderRadius: 0,
          marginBottom: 10,
        });
        $closeButton.css('marginTop', 2);
      } else {    
        $notification.css({
          borderRadius: 6,
          marginBottom: 20,
        });
        $closeButton.css('marginTop', 8);
      }

      return $notification
          .append($notificactionIcon, $notificactionWrap)
          .addClass(type)
          .css({
              width: '100%',
              maxWidth: 640,
              pointerEvents: 'auto',
              backgroundColor: '#4D4D4D',
              padding: 10,
              color: '#FFFFFF',
              display: 'flex',
          });
  };

  this.addNotification = ($notification) => {
      this.$notificationsContainer.append($notification.hide());
      $notification.fadeIn();
  };

  this.removeNotification = ($notification) => {
      if (!$notification) {
          return;
      }
      
      $notification.fadeOut('normal', () => {
          $notification.remove();
      });
  };

  this.show = (type, title, text) => {
      const $notification = this.createNotification(type, title, text);
      this.mountContainer();

      if (util.isMobile()) {
        this.$notificationsContainer.css('padding', 0);
      } else {
        const $hamburgerMenu = $('#header-hamburger');
        const top = $hamburgerMenu.height() + $hamburgerMenu.offset().top;
        this.$notificationsContainer.css('padding', 16);
        this.$notificationsContainer.css('padding-top', top + 10);

        let savedScrollTop = 0;
        const paddingTop = Number(this.$notificationsContainer.css('padding-top').replace('px', ''));
        $(window).on('scroll', () => {
            window.requestAnimationFrame(() => {
                const scrollTop = $(window).scrollTop();
                const isScrollingDown = savedScrollTop < scrollTop;
                savedScrollTop = scrollTop;
                const newPadding = isScrollingDown ? paddingTop - scrollTop : paddingTop + scrollTop;

                if (isScrollingDown) {
                    if (newPadding > 9)
                        this.$notificationsContainer.css('padding-top', newPadding);
                }
                
                if (!isScrollingDown) {
                    if (paddingTop - scrollTop > 9)
                        this.$notificationsContainer.css('padding-top', paddingTop - scrollTop);
                }
            });
        });
      }

      this.addNotification($notification);

      setTimeout(() => {
          this.removeNotification($notification);
      }, 4000);

      this.$notificationsContainer.data('initilialized', '1');
      return $notification;
  };

  return this.show(type, title, text);
};

module.exports = uiNotification;
