'use strict';

const { unset } = require('lodash');
const { slide } = require('../minicart');
var account = require('./account'),
    ajax = require('../ajax'),
    page = require('../page'),
    progress = require('../progress'),
    minicart = require('../minicart'),
    bonusProducts = require('../bonus-products'),
    quickview = require('../quickview'),
	util = require('../util'),
	tls = require('../tls'),
    cartStoreInventory = require('../storeinventory/cart'),
    sliders = require('../sliders'),
	wishlist = require('./wishlist'),
    dialog = require('../dialog'),
    image = require('../pages/product/image');

var cart = {
    
    update: function (targetURL, params) {
        // var contentOpen = false;
        // if($("#content-bonus-product-availible").hasClass("cart-promo__body-show")){
        //     contentOpen = true;
        // }
        var $targetDIV = $('.js-cart-content');
        progress.show($targetDIV);
        ajax.load({
            url : targetURL,
            data: params,
            target: $targetDIV,
            callback: function (data, textStatus) {
                // loadCarousel();
                minicart.updateCartBadge();
                initializeEvents();
                $('html').removeClass('ui-scroll-disabled');
                // if(contentOpen){
                //     $(".cart-promo__choose-samples-link").click();
                // }
                progress.hide();
            }

        });
    },
    removeProductFromCart: function (pliItem) {
        this.update(Urls.cartRemoveProduct, {'lineItemUUID':pliItem.attr('data-uuid')});
        
    },
    removeProductFromModal: function (uuid) {
        this.update(Urls.cartRemoveProduct, {'lineItemUUID':uuid});
        
    },
    addBonusProduct: function (pliItem) {
        this.update(Urls.addBonusProduct, {'sku':pliItem.attr('data-id')});
        $('html').removeClass('ui-scroll-disabled');

    },
    updateQuantity: function (pliItem) {
        var qty = pliItem.find('.js-cart-quantity-select').val();
        var params = {
            'lineItemUUID': pliItem.attr('data-uuid'),
            'qty': qty
        };

        this.update(Urls.updateLineItemQty, params);
        this.dataLayer(pliItem, 'update');
    },
    updateQty: function (param1,param2) {
        var qty = param1;
        var lineItemUUID = param2
        var params = {
            'lineItemUUID': lineItemUUID,
            'qty': qty
        };

        this.update(Urls.updateLineItemQty, params);
    },
    dataLayer: function(pliItem, action) {
        //    	var lineItemObj = pliItem.attr('data-lineitem');
        //    	lineItemObj = jQuery.parseJSON(lineItemObj);
        //
        //    	lineItemObj.quantity = pliItem.find('.js-cart-quantity-select').val();
        //    	if (action == 'update') {
        //    		//Do not remove this can be improved after go live
        //    		window.dataLayer.push({
        //				'event' : 'cartAddProduct',
        //				'ecommerce' : {
        //					'add' : {
        //						'Products' : lineItemObj
        //					}
        //				}
        //			});
        //    	}
        //    	var productsArr = [lineItemObj];
        
        //    	if (action == 'delete') {
        //    		 window.dataLayer.push({
        //				'event' : 'removeFromCart',
        //				'ecommerce' : {
        //					'remove' : {
        //						'products' : productsArr
        //					}
        //				}
        //			});
        //    	}
            },
	    updateMinicartQty: function(){
		if(parseInt(tls.getCookie("paris_minicart_count")) > 0 && ($('.minicart-quantity').hasClass("hidden") || $('.minicart-quantity').text().trim() == "0")){
			$('.minicart-quantity').removeClass("hidden");
			$('.minicart-quantity').text(parseInt(tls.getCookie("paris_minicart_count")));
		}
	}
};
/**
 * @function
 * @description Submits the coupon form, when adding or removing a coupon
 * @param {JQuery} $trigger - the clicked button which triggered the form submit
 * @return {void}
 */
function submitCouponForm ($trigger) {
    // Populate the request's data with the form's content
    var $form = $trigger.closest('form');
    var $targetDIV = $('.js-cart-content');
    var params = util.getQueryStringParams($form.serialize());
    params[$trigger.attr('name')] = $trigger.attr('value');
    progress.show();

    ajax.load({
        url : $form.attr('action'),
        type : $form.attr('method'),
        target : $targetDIV,
        data : params,
        callback: function (data, textStatus) {
        	minicart.updateCartBadge();
            progress.hide();
            initializeEvents();
        }
    });
}
/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function feedbackUpdateCartProducts() {
    var showModalBg = document.getElementById('alertStockQuantity');
    if (showModalBg) {
        showModalBg.classList.remove('hidden');
        showModalBg.classList.add('fade-in');
        setTimeout(messageUpdateCartProducts(),400);
    }
}
function messageUpdateCartProducts() {
	var showModalMessage = document.querySelector('.feedback-success');
	showModalMessage.classList.remove('hide-message');
	showModalMessage.classList.add('slide-down');
	setTimeout(function () {
		hideMessageUpdateCartProducts();
		showModalMessage.classList.remove('slide-down');
	}, 4500);
}

function hideMessageUpdateCartProducts() {
	var hideModalMessage = document.querySelector('.feedback-success');
	hideModalMessage.classList.remove('slide-down');
	hideModalMessage.classList.add('slide-up');
	setTimeout(function () {
		hideModalUpdateCartProducts();
		hideModalMessage.classList.remove('slide-up');
		hideModalMessage.classList.add('hide-message');
	}, 400);
}
function hideModalUpdateCartProducts() {
	var hideModalContent = document.querySelector('.modal-content-bg');
	hideModalContent.classList.remove('fade-in');
	hideModalContent.classList.add('fade-out');
	setTimeout(function () {
		hideModalContent.classList.add('hidden');
		hideModalContent.classList.remove('fade-out');
	}, 400);
}

function stockUpdateMessage() {
    var changeStockCart = document.querySelector('#changedQuantityText');
    var editCartActions = document.querySelector('.cart-actions');
    if (changeStockCart) {
        if (editCartActions) {
            editCartActions.classList.add('desk-p-abs');
        }
    } else {
        if(editCartActions) {
            editCartActions.classList.remove('desk-p-abs');
        }
    }
}

function getCustomerType(isAuthenticated) {
    return isAuthenticated ? 'authenticated' : 'guest';
}

function initializeEvents() {
    if (window.matchMedia('(max-width: 768px)').matches && bonusProducts.init.length > 0) {
        loadModalPodsTitleContent();
    } else {
        loadCarousel();
        $('#content-bonus-product-availible').addClass('cart-promo__body-show');
    }
    bonusProducts.init();
    var $buttonsMinus = document.querySelectorAll('.js-button-minus');
    var $buttonsPlus = document.querySelectorAll('.js-button-plus');
    $($buttonsMinus).each(function() {
        decrementValue(this,false);
    });
    $($buttonsPlus).each(function() {
        incrementValue(this,false);
    });
    //Modal edit cart for PDP_default
    $('.js-edit-item-details').on('click', function (e) {
    	var _self = $(this);

    	function callback () {
    		if ($('.js-quickview-lineitem-uuid').length > 0 ){
    			$('.js-quickview-lineitem-uuid').text(_self.closest('.js-cart-lineitem').data('uuid'));
			}
			image.lazyLoad();
		}
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart',
            callback : callback
        });
    });
    //Modal edit cart for PDP_default2
    $('.js-edit-item-details-PDP-default2').on('click', function (e) {
    	var _self = $(this);
    	function callback () {
    		if ($('.js-quickview-lineitem-uuid-2').length > 0 ){
    			$('.js-quickview-lineitem-uuid-2').text(_self.closest('.js-cart-lineitem').data('uuid'));
			}
			image.lazyLoad();
		}
        e.preventDefault();
        quickview.showPdpDefault2({
            url: e.target.href,
            source: 'cart',
            callback : callback
        });
    });



    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    //remove product lineitem from cart
    $('.js-cart-container').on('click', '.js-remove-product', function (e) {
        e.preventDefault();
        cart.removeProductFromCart($(this).closest('.js-cart-lineitem'));
        $('html').removeClass('ui-scroll-disabled');
        const isAuthenticated = window.customer ? window.customer.type : null;
        const customerType = getCustomerType(isAuthenticated);
        const dataset = JSON.parse($(this).parents('.box-item-carro')[0].dataset.lineitem);
        pushDatalayerRemoveFromCart(dataset, customerType);

        if (dataset?.id) {
            util.cleanProductListBySKU(dataset.id);
        }
    });

    //add bonus products
    $('.js-cart-container').on('click', '.js-add-bonus-product', function (e) {
        e.preventDefault();
        cart.addBonusProduct($(this).closest('.js-add-bonus-product'));
        $('html').removeClass('ui-scroll-disabled');
        window.dataLayer.push({
            'event-interactive-category': 'Sapling', 
            'event-interactive-accion': 'Agregar al carro', 
            'event-interactive-tag': $(this).attr('data-id'),
            'event': 'event-interactive'
        });
    });

    $('.js-cart-container').on('click', '.js-remove-bonus-product', function (e) {
        window.dataLayer.push({
            'event-interactive-category': 'Sapling', 
            'event-interactive-accion': 'Eliminar del carro', 
            'event-interactive-tag': $(this).attr('data-id'),
            'event': 'event-interactive'
        });
    });

    
    //update product lineitem quantity
    $('.js-cart-container').on('change', '.js-cart-quantity-select', function (e) {
        e.preventDefault();
        cart.updateQuantity($(this).closest('.js-cart-lineitem'));
    });

    //update product lineitem quantity 2.
    $('.js-cart-container').on('click', '.js-button-plus', function(e) {
        e.preventDefault();
        incrementValue(this,true);
    });

    $('.js-cart-container').on('click', '.js-button-minus', function(e) {
        e.preventDefault();
        decrementValue(this,true);
    });

   // Handle coupon adding and removing with AJAX requests
    $('.js-coupon-form').on('click', '.js-apply-coupon', function(e) {
    	e.preventDefault();
        submitCouponForm($(this));

    }).on('click', '.js-remove-coupon', function(e) {
        e.preventDefault();
        submitCouponForm($(this));
    });

    $('.add-option-cart').on('click', function() {
        //TODO: REFACTOR URGENTE DESPUES DEL CYBER
        var pid = $(this).attr('pid');
        var productID= $(this).attr('productID');
        var optionID= $(this).attr('optionID');
        var gexID= $(this).attr('gexID');
        var UUID= $(this).attr('UUID');
        var tipo= $(this).attr('tipo');
        var urlOptions;
        var urlEliminar;
        var haveGarantia =$('#tiene-garantia-'+UUID).attr('value');
        var garantiaID=$('#garantia-'+UUID).attr('value');
        var recicambioID=$('#recicambio-'+UUID).attr('value');
        var instalacionID=$('#instalacion-'+UUID).attr('value');
        var armadoID=$('#armado-'+UUID).attr('value');
        var gexID_B=$('#gexID_B-'+UUID).attr('value');
        var flagBorrado=0;
        var addProductURL = Urls.addProduct;
        var removeProductURL=Urls.cartRemoveProduct;     
       
        //generando url para agregar el option sin borrar los que ya se encuentrar en el carro

       //loading
       if (tipo=='garantia'){ 
            $('.add-gex-'+UUID).addClass('hidden');
            $('.adding-gex-'+UUID).removeClass('hidden');
            $('#garantia-'+UUID).attr('value',optionID);
            $('#gexID_B-'+UUID).attr('value',gexID);
       };
       
       if (tipo=='recicambio'){ 
            $('.add-recicambio-'+UUID).addClass('hidden');
            $('.adding-recicambio-'+UUID).removeClass('hidden');
       };

       if (tipo=='armado'){ 
            $('.add-armado-'+UUID).addClass('hidden');
            $('.adding-armado-'+UUID).removeClass('hidden');
       };

       if (tipo=='instalacion'){ 
            $('.add-instalacion-'+UUID).addClass('hidden');
            $('.adding-instalacion-'+UUID).removeClass('hidden');
        };
        
        if (typeof(recicambioID) != 'undefined'){
            recicambioID='&dwopt_'+pid+'_'+recicambioID+'=si';
        }
        else{
            recicambioID='';
        };

        if (typeof(armadoID) != 'undefined'){
            armadoID='&dwopt_'+pid+'_'+armadoID+'=si';
        }
        else{
            armadoID='';
        };

        if (typeof(instalacionID) != 'undefined'){
            instalacionID='&dwopt_'+pid+'_'+instalacionID+'=si';
        }
        else{
            instalacionID='';
        };

        if (typeof(garantiaID) != 'undefined'){
            garantiaID='&dwopt_'+pid+'_'+garantiaID+'='+gexID_B
        }
        else{
            garantiaID='';
        };

        //pregunto si es variant
        if (productID == 'null')
        {
          productID=pid;    
        };
        
        //consulto si se quiere agregar una gex
        if(typeof(gexID)=='undefined')
         {
          optionID='&dwopt_'+pid+'_'+optionID+'=si';         
 
         }
         else{
          optionID='&dwopt_'+pid+'_'+optionID+'='+gexID   
         };

         //si selecciono no agregar garantia en el radio button

         if((optionID==garantiaID) || (optionID ==  '&dwopt_'+pid +'_undefined='+gexID))
         {
            optionID='';
            garantiaID=''
            flagBorrado=1;

         };

         urlOptions=addProductURL+'?format=ajax&pdpVersion=1'+optionID+recicambioID+armadoID+instalacionID+garantiaID+'&allValues=20%2C999&Quantity=0&uuid=&cartAction=update&pid='+pid+'&productID='+productID+'&add-to-cart-quantity=0'
         $.ajax({
            url:  urlOptions ,
            type: "GET",
            dataType: "html",
            success: function (data) {
                if ((typeof(haveGarantia) == 'undefined') && (garantiaID == '') && (flagBorrado==0)){
                    urlEliminar=removeProductURL+'?format=ajax&pdpVersion=1&lineItemUUID='+UUID; 
                    $.ajax({
                        url:  urlEliminar ,
                        type: "GET",
                        dataType: "html",
                        success: function (data) {
                            cart.removeProductFromCart($(this).closest(''));
                            
                        }
                    }); 
                }
                else{
                                    //refresh page if on the main basket
                    if ($('.js-cart-container').length) {
                        if (location.search != "") {
                            location = location.origin + location.pathname;
                        } else {
                            location.reload();
                        }
                    }
                    // cart.update(Urls.cartShow, null);
                }           
            }
        }); 
       
    })

    $('.remove-option-cart').on('click', function() {
        //TODO: REFACTOR URGENTE DESPUES DEL CYBER
        var pid = $(this).attr('pid');
        var productID= $(this).attr('productID');
        var optionID= $(this).attr('optionID');
        var UUID= $(this).attr('UUID');
        var urlOptions;
        var urlEliminar;
        var haveGarantia =$('#tiene-garantia-'+UUID).attr('value'); // esta variable indica que el producto tiene options de gex , pero no se agregado ninguno
        var garantiaID=$('#garantia-'+UUID).attr('value');  // este variable nos indica que tiene una gex agregada
        var recicambioID=$('#recicambio-'+UUID).attr('value'); // este variable nos indica que tiene  recicambio agregado
        var instalacionID=$('#instalacion-'+UUID).attr('value'); // este variable nos indica que tiene instalacion agregado
        var armadoID=$('#armado-'+UUID).attr('value'); // este variable nos indica que tiene armado agregado
        var gexID_B=$('#gexID_B-'+UUID).attr('value'); // en el caso de tener gex agregada (indicador del gex)
        var addProductURL = Urls.addProduct;
        var removeProductURL=Urls.cartRemoveProduct;     


        if (typeof(recicambioID) != 'undefined'){
        //esto es para borrar el que se llamo y no lo agregue nuevamente
            if (recicambioID != optionID){
                recicambioID='&dwopt_'+pid+'_'+recicambioID+'=si';
            }
            else{
        //limpio la variable        
                recicambioID='';
            }
        }
        else{
            recicambioID='';
        };

        if (typeof(armadoID) != 'undefined'){

            if (armadoID != optionID){
                armadoID='&dwopt_'+pid+'_'+armadoID+'=si';
            }
            else{
                armadoID='';
            }
        }
        else{
            armadoID='';
        };

        if (typeof(instalacionID) != 'undefined'){
            if (instalacionID != optionID){
                instalacionID='&dwopt_'+pid+'_'+instalacionID+'=si';
            }
            else{

                instalacionID='';
            }           
        }
        else{
            instalacionID='';
        };

        if (typeof(garantiaID) != 'undefined'){

            if (garantiaID!=optionID){
            garantiaID='&dwopt_'+pid+'_'+garantiaID+'='+gexID_B;
            }
            else{
                garantiaID='';
            }
        }
        else{
            garantiaID='';
        };
        
        //esto es en el caso que sea variant
        if (productID == 'null')
        {
          productID=pid;    
        };
        
        urlOptions=addProductURL+'?format=ajax&pdpVersion=1'+optionID+recicambioID+armadoID+instalacionID+garantiaID+'&allValues=20%2C999&Quantity=0&uuid=&cartAction=update&pid='+pid+'&productID='+productID+'&add-to-cart-quantity=0'

        $.ajax({
            url:  urlOptions ,
            type: "GET",
            dataType: "html",
            success: function (data) {
                //eliminar el producto anterior para evitar duplicidad
                if ((typeof(haveGarantia) == 'undefined') && (garantiaID == '')&& (typeof(gexID_B) == 'undefined')){

                    urlEliminar=removeProductURL+'?format=ajax&pdpVersion=1&lineItemUUID='+UUID;  
                    $.ajax({
                        url:  urlEliminar ,
                        type: "GET",
                        dataType: "html",
                        success: function (data) {
                            cart.removeProductFromCart($(this).closest(''));
                        }
                    }); 
                }
                else{
                    if ($('.js-cart-container').length) {
                        if (location.search != "") {
                            location = location.origin + location.pathname;
                        } else {
                            location.reload();
                        }
                    }
                }   
            }
        }); 
           
    })

    $('.radioSeleccionGex').on('click', function() { 
        var gexID= $(this).attr('gexID');
        var optionID= $(this).attr('optionID');
        $('.gexButtonSeleccion').attr('gexID',gexID);
        $('.gexButtonSeleccion').attr('optionID',optionID);
    })

    $('.gex-opciones').on('click', function () {

        var UUID= $(this).attr('UUID');
        var getWarrantySlide = document.querySelector('.modal-'+UUID);

    
        var radioGex = $('#gexID_B-' + UUID).attr('value');
        var idGex = UUID + '_' + radioGex;
        
        if (typeof (radioGex) !== 'undefined') {
            document.getElementById(idGex).setAttribute('checked', true);
        };

        getWarrantySlide.classList.remove('warranty-modal--inactive');
        
        setTimeout(function () {
            getWarrantySlide.classList.add('warranty-modal--active');
        }, 20);
    
        var clickOnBackground = document.querySelector('.modal-backgroud-' + UUID);
    
        if (clickOnBackground !== null) {
            clickOnBackground.onclick = function () {
                closeWarrantySlide();
            };
        }
    
        var getButtonClose = document.querySelector('.modal-close-' + UUID);
    
        if (getButtonClose !== null) {
            getButtonClose.onclick = function () {
                closeWarrantySlide(UUID);
            };
        }
    });

    $('#checkout-form').on('submit', function (e) {
        const smartCustomerRegion = JSON.parse(localStorage.getItem('smart_customer_region'));
        const isNoviosCart = SitePreferences.IS_NOVIOS_CART;
        if (!isNoviosCart && !smartCustomerRegion) {
            e.preventDefault();
            return $('#header-comuna-source-btn').click();
        }
    });

    function closeWarrantySlide(UUID){
        var getCloseWarrantySlide = document.querySelector('.modal-'+UUID);
        getCloseWarrantySlide.classList.remove('warranty-modal--active');
        setTimeout(function() {
            getCloseWarrantySlide.classList.add('warranty-modal--inactive');
        }, 20);
    }

	wishlist.removeFromWishListHandler();
    cart.updateMinicartQty();
    stockUpdateMessage();

    $('.close-button').on('click', function() {
        var editCartActions = document.querySelector('.cart-actions');
        //var removeProductCart = document.querySelector('.btn-eliminar-item-carro');
        var withoutStock = document.querySelector('#changedQuantityText');
        if (withoutStock) { 
            editCartActions.classList.remove('desk-p-abs');
        } else {
            editCartActions.classList.remove('desk-p-abs');
        }
    });

	// Method for Message Container for out of stock, or stock less than selected quantity
	$(document).ready(function(){
        minicart.updateCartBadge();
        feedbackUpdateCartProducts();
	});
	// END Method for Message Container for out of stock, or stock less than selected quantity

	$(window).click (function() {
        var closeModalFeedback = document.querySelector(".feedback-success");
        if(closeModalFeedback){
            hideMessageUpdateCartProducts();
        }
	});

    // Show the products promo
    $('.cart-promo__choose-samples-link').on('click', function(){
        // $('#content-bonus-product-availible').addClass('cart-promo__body-show');
        window.dataLayer.push({
            'event-interactive-category': 'Sapling',
            'event-interactive-accion': 'Clic boton',
            'event-interactive-tag': 'Elegir muestras',
            'event': 'event-interactive'
        });
        const getPromoBody = document.querySelector('.cart-promo__body');
        getPromoBody.classList.toggle('cart-promo__body-show');
        const getArrowLink = document.querySelector('.cart-promo__choose-samples-link').children;
        if (window.matchMedia('(max-width: 768px)').matches) {
            $('#content-bonus-product-availible').addClass('cart-promo__body-show');
            $('html').addClass('ui-scroll-disabled');
            // loadModalPodsTitleContent();
        } else {
            getArrowLink[0].classList.toggle('rotate');
            loadCarousel();
        }
    });

    $('.js-delete-product-modal').on('click', function(e) { 
        var promoId = $(this).closest('.js-cart-lineitem').attr('data-id-bonus-promo');
        var promoElements = $(".js-cart-lineitem[data-id-bonus-promo='" + promoId +"']");
        var samplingElements = $(".js-cart-lineitem[data-is-sampling-product='1']");
        if(promoElements.length > 1 || samplingElements.length == 0){
            e.preventDefault();
            cart.removeProductFromCart($(this).closest('.js-cart-lineitem'));
            $('html').removeClass('ui-scroll-disabled');
        } else {
            var uuidModal = $(this).attr('data-uuid-modal');
            var buttonId = "delete-product-modal-" + uuidModal;
            if (window.matchMedia('(max-width: 768px)').matches) {
                $('.' + buttonId).removeAttr('style');
                $('html').addClass('ui-scroll-disabled');
                $('.' + buttonId).addClass('modal__delete-product opened');
                $('html').append('<div class="overlay__delete-product opened"></div>');
                // Get the div promotional pods
                const getModalPromotionalPods = document.querySelector('.modal__delete-product');
                // Create <div> HTML element for title
                const createDivTitleTag = document.createElement('div');
                // Add class for the <div> HTML element for title
                createDivTitleTag.classList.add('modal__delete-product-title');
                // Create H3 Tag element for title
                const createH3TagTitle = document.createElement('H3');
                // Create title text for modal PODS
                const createTitleModalPodsText = document.createTextNode('Se eliminarán tus muestras');
                // Put the H3 tag, into the div element
                createDivTitleTag.appendChild(createH3TagTitle);
                // Put the text 'Muestra de regalo' into H3 tag
                createH3TagTitle.appendChild(createTitleModalPodsText);
                // Put the div with the class 'modal__delete-product-title', the h3 with title, into the modal
                getModalPromotionalPods.insertBefore(createDivTitleTag, getModalPromotionalPods.childNodes[0]);
                // Get the div element with the class 'modal__delete-product-title'
                const getModalTitle = document.querySelector('.modal__delete-product-title');
                // Create the close button HTML element
                const createButtonCloseModal = document.createElement('button');
                // Put the button, into the div 'modal__delete-product-title'
                getModalTitle.appendChild(createButtonCloseModal);
                // Add classes to the button created
                createButtonCloseModal.classList.add('modal__delete-product-title-close');
                // Get te button created
                const getButtonCloseModal = document.querySelector('.modal__delete-product-title-close');
                // Create the attribute type
                const buttonTypeAttribute = document.createAttribute('type');
                // Create the attribute aria-label
                const buttonAriaLabelAtttribute = document.createAttribute('aria-label');
                // Set the attribute value button
                buttonTypeAttribute.value = 'button'
                // Set the aria-label attribute to the button
                buttonAriaLabelAtttribute.value = 'Cerrar'
                // Add the attributes type button and aria-label, to the button element
                getButtonCloseModal.setAttributeNode(buttonTypeAttribute);
                getButtonCloseModal.setAttributeNode(buttonAriaLabelAtttribute);
                // Create X image to the modal
                createButtonCloseModal.innerHTML = '<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m9.846 9.004 7.603-7.602a.6.6 0 1 0-.851-.85L8.995 8.152 1.402.561a.602.602 0 0 0-.85.85l7.593 7.593L.56 16.589a.602.602 0 0 0 .85.85l7.585-7.584 7.594 7.593a.599.599 0 0 0 .85 0 .6.6 0 0 0 0-.85L9.847 9.004Z" fill="#00355B"/></svg>';
                // Close the modal pod
                $('.modal__delete-product-title-close, .overlay__delete-product').on('click', function(){
                    $('html').removeClass('ui-scroll-disabled');
                    $('.' + buttonId).removeClass('modal__delete-product opened');
                    $('.' + buttonId).attr('style', 'display:none');
                    $('.overlay__delete-product').remove();
                    getModalTitle.remove();
                });      
                $('.js-delete-product-and-bonus').each((i, el) => {
                    $(el).on('click', () => {
                        $('html').removeClass('ui-scroll-disabled');
                        $('.' + buttonId).removeClass('modal__delete-product opened');
                        $('.' + buttonId).attr('style', 'display:none');
                        $('.overlay__delete-product').remove();
                        getModalTitle.remove();
                        e.preventDefault();
                        cart.removeProductFromModal($(el).attr('data-uuid'));
                        window.dataLayer.push({
                            'event-interactive-category': 'Sapling', 
                            'event-interactive-accion': 'Eliminar del carro', 
                            'event-interactive-tag': $(el).attr('data-id'),
                            'event': 'event-interactive'
                        });
                    });
                });
            } else {
                $('.' + buttonId).dialog({
                    classes: {
                        "ui-dialog": "ui-dialog__sampling"
                    },
                    modal: true,
                    draggable: false,
                    title: "Se eliminarán tus muestras",
                    width: '385',
                    open: function() {
                        $('html').addClass('ui-scroll-disabled');
                        $('.ui-widget-overlay').each((i, el) => {
                            $(el).on('click', () => handleCloseModal(true));
                        });
    
                        $('.js-close-modal-bonus-product').each((i, el) => {
                            $(el).on('click', () => handleCloseModal(true));
                        });
                        $('.js-delete-product-and-bonus').each((i, el) => {
                            $(el).on('click', () => {
                                handleCloseModal(true)
                                e.preventDefault();
                                cart.removeProductFromModal($(el).attr('data-uuid'));
                                $('html').removeClass('ui-scroll-disabled');
                                window.dataLayer.push({
                                    'event-interactive-category': 'Sapling', 
                                    'event-interactive-accion': 'Eliminar del carro', 
                                    'event-interactive-tag': $(el).attr('data-id'),
                                    'event': 'event-interactive'
                                });
                            });
                        });
                    },
                    close: function() {
                        $(this).dialog("destroy");
                        $('html').removeClass('ui-scroll-disabled');
                    }
                });
                $('.ui-button').attr('aria-label', 'Cerrar');
                function handleCloseModal(isOverlay = false) {
                    if (isOverlay) $('.' + buttonId).dialog('close');
                    $('html').removeClass('ui-scroll-disabled');
                }
            }
        }
    
    });
    
    
    $('.js-product-sampling').on('click', function() {
        var productSkuImg = $(this).attr('id');

        window.dataLayer.push({
            'event-interactive-category': 'Sapling', 
            'event-interactive-accion': 'Ver detalle producto', 
            'event-interactive-tag': $(this).attr('data-id'),
            'event': 'event-interactive'
        });

        if (window.matchMedia('(max-width: 768px)').matches) {
            $('#dialog-' + productSkuImg).removeAttr('style');
            $('.cart-promo__body-show').addClass('cart-promo__body-scroll-disabled');
            $('#dialog-' + productSkuImg).addClass('cart-promo__modal-pod opened');
            $('.cart-promo__body-show').append('<div class="cart-promo__overlay opened"></div>');
            // Get the div promotional pods
            const getModalPromotionalPods = document.querySelector('.cart-promo__modal-pod');
            // Create <div> HTML element for title
            const createDivTitleTag = document.createElement('div');
            // Add class for the <div> HTML element for title
            createDivTitleTag.classList.add('cart-promo__modal-pod-title');
            // Create H3 Tag element for title
            const createH3TagTitle = document.createElement('H3');
            // Create title text for modal PODS
            const createTitleModalPodsText = document.createTextNode('Muestra de regalo');
            // Put the H3 tag, into the div element
            createDivTitleTag.appendChild(createH3TagTitle);
            // Put the text 'Muestra de regalo' into H3 tag
            createH3TagTitle.appendChild(createTitleModalPodsText);
            // Put the div with the class 'cart-promo__modal-pod-title', the h3 with title, into the modal
            getModalPromotionalPods.insertBefore(createDivTitleTag, getModalPromotionalPods.childNodes[0]);
            // Get the div element with the class 'cart-promo__modal-pod-title'
            const getModalTitle = document.querySelector('.cart-promo__modal-pod-title');
            // Create the close button HTML element
            const createButtonCloseModal = document.createElement('button');
            // Put the button, into the div 'cart-promo__modal-pod-title'
            getModalTitle.appendChild(createButtonCloseModal);
            // Add classes to the button created
            createButtonCloseModal.classList.add('cart-promo__modal-pod-title-close');
            // Get te button created
            const getButtonCloseModal = document.querySelector('.cart-promo__modal-pod-title-close');
            // Create the attribute type
            const buttonTypeAttribute = document.createAttribute('type');
            // Create the attribute aria-label
            const buttonAriaLabelAtttribute = document.createAttribute('aria-label');
            // Set the attribute value button
            buttonTypeAttribute.value = 'button'
            // Set the aria-label attribute to the button
            buttonAriaLabelAtttribute.value = 'Cerrar'
            // Add the attributes type button and aria-label, to the button element
            getButtonCloseModal.setAttributeNode(buttonTypeAttribute);
            getButtonCloseModal.setAttributeNode(buttonAriaLabelAtttribute);
            // Create X image to the modal
            createButtonCloseModal.innerHTML = '<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m9.846 9.004 7.603-7.602a.6.6 0 1 0-.851-.85L8.995 8.152 1.402.561a.602.602 0 0 0-.85.85l7.593 7.593L.56 16.589a.602.602 0 0 0 .85.85l7.585-7.584 7.594 7.593a.599.599 0 0 0 .85 0 .6.6 0 0 0 0-.85L9.847 9.004Z" fill="#00355B"/></svg>';
            // Close the modal pod
            $('.cart-promo__modal-pod-title-close, .cart-promo__overlay').on('click', function(){
                $('.cart-promo__body-show').removeClass('cart-promo__body-scroll-disabled');
                $('#dialog-' + productSkuImg).removeClass('cart-promo__modal-pod opened');
                $('#dialog-' + productSkuImg).attr('style', 'display:none');
                $('.cart-promo__overlay').remove();
                getModalTitle.remove();
            });            
        } else {
            $('#dialog-' + productSkuImg).dialog({
                classes: {
                    "ui-dialog": "ui-dialog__sampling"
                },
                modal: true,
                draggable: false,
                title: "Muestra de regalo",
                width: '385',
                open: function() {
                    $('html').addClass('ui-scroll-disabled');
                    $('.ui-widget-overlay').each((i, el) => {
                        $(el).on('click', () => handleCloseModal(true));
                    });
                },
                close: function() {
                    $('html').removeClass('ui-scroll-disabled');
                }
            });
            $('.ui-button').attr('aria-label', 'Cerrar');
            function handleCloseModal(isOverlay = false) {
                if (isOverlay) $('#dialog-' + productSkuImg).dialog('close');
                $('html').removeClass('ui-scroll-disabled');
            }
        }
        
    });
    
    $('#js-click-continue-to-payment-submit-form').on('click', function() {
        const url = window.Urls.openCartLastMilleProductsModal;

        const smartCustomerRegion = JSON.parse(localStorage.getItem('smart_customer_region'));
        const isNoviosCart = SitePreferences.IS_NOVIOS_CART;
        if (!isNoviosCart && !smartCustomerRegion) {
            return $('#header-comuna-source-btn').click();
        }
        
        dialog.open({
            url,
            options: {
            title: window.Resources.CART_PERSONALIZE_CAROUSEL_MODAL_TITLE,
            draggable: false,
            autoOpen: false,
            responsive: true,
            modal: true,
            fluid: true,
            width: 700,
            dialogClass: 'ui-dialog-full',
            close() {
                const $modal = $(this);
                $modal.dialog('close')
            },
            },
            callback($modal) {
            
                // Add event listener to submit button from modal               
                $('#js-click-continue-to-payment-last-mille-modal').on('click', function() {
                    page.redirect(Urls.cartCheckout);
                })
                
                // Initialize carousel
                const lastMilleProductsCarousel = $('.last-mille-products-carousel');
                lastMilleProductsCarousel.each(function (index, element) {
                    const lastMilleProductsPerLine = $(element).data('itemsPerLine') || 4;
                    
                    if ($(element).children('li').length > 0 && $(element).is(':not(.slick-initialized)')) {
                        const el = $(element);
                        const listMarkingEnabled = el.data('listMarking') || 0;
                        const listMarkingDescription = el.data('listMarkingDescription');
                        const listMarkingSlot = el.data('listMarkingSlot');
    
                        const Marking = listMarkingEnabled ? new ProductsMarking() : null;
                        if (listMarkingEnabled) {
                            const listName = listMarkingSlot !== null && listMarkingSlot !== undefined ? `${listMarkingDescription} - ${listMarkingSlot}` : `${listMarkingDescription}`;
                            Marking.defineListName(listName);
                        }
    
                        el.on('init', (e, slick) => {
                            if (!!Marking) {
                                Marking.handleChangeSlide(slick);
                            }
    
                            const { handleAddToCartFromLastMilleModal } = require('./product/addToCart');
                            
                            el.find('.product-tile').each(function(i, el) {
                                $(el).find('.last-mille-product-tile-add-to-cart').on('click', handleAddToCartFromLastMilleModal);	
                            })
                        });
    
                        el.slick(getSliderConfigLastMilleProductsModal(lastMilleProductsPerLine));
    
                        el.on('afterChange', (e, slick, currentSlide) => {
                            if (!!Marking) {
                                Marking.handleChangeSlide(slick, currentSlide);
                            }
    
                            setTimeout(() => {
                                image.lazyLoad();
                            }, 100);
                        });
                    }
                });
            },
        });
    });
    
}

function loadModalPodsTitleContent() {
    createButtonSaveSamples();
    // Get the div promotional pods
    const getModalPromotionalPods = document.querySelector('.cart-promo__body');
    // Create <div> HTML element for title
    const createDivTitleTag = document.createElement('div');
    // Add class for the <div> HTML element for title
    createDivTitleTag.classList.add('cart-promo__modal-title');
    // Create H3 Tag element for title
    const createH3TagTitle = document.createElement('H3');
    // Create title text for modal PODS
    const createTitleModalPodsText = document.createTextNode('Elegir muestras');
    // Put the H3 tag, into the div element
    createDivTitleTag.appendChild(createH3TagTitle);
    // Put the text 'Elegir muestras' into H3 tag
    createH3TagTitle.appendChild(createTitleModalPodsText);
    
    if (getModalPromotionalPods != null){
        // Put the div with the class 'cart-promo__modal-title', the h3 with title, into the modal
        getModalPromotionalPods.insertBefore(createDivTitleTag, getModalPromotionalPods.childNodes[0]);
    }   
    // Get the div element with the class 'cart-promo__modal-title'
    const getModalTitle = document.querySelector('.cart-promo__modal-title');
    // Create the close button HTML element
    const createButtonCloseModal = document.createElement('button');
    if (getModalTitle != null){
        // Put the button, into the div 'cart-promo__modal-title'
        getModalTitle.appendChild(createButtonCloseModal);
    }
    // Add classes to the button created
    createButtonCloseModal.classList.add('cart-promo__modal-title-close');
    
    // Get te button created
    const getButtonCloseModal = document.querySelector('.cart-promo__modal-title-close');
    // Create the attribute type
    const buttonTypeAttribute = document.createAttribute('type');
    // Create the attribute aria-label
    const buttonAriaLabelAtttribute = document.createAttribute('aria-label');
    // Set the attribute value button
    buttonTypeAttribute.value = 'button';
    // Set the aria-label attribute to the button
    buttonAriaLabelAtttribute.value = 'Cerrar';
    if (getButtonCloseModal != null){
        // Add the attributes type button and aria-label, to the button element
        getButtonCloseModal.setAttributeNode(buttonTypeAttribute);
        getButtonCloseModal.setAttributeNode(buttonAriaLabelAtttribute);
    }
    // Create X image to the modal
    createButtonCloseModal.innerHTML = '<svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m9.846 9.004 7.603-7.602a.6.6 0 1 0-.851-.85L8.995 8.152 1.402.561a.602.602 0 0 0-.85.85l7.593 7.593L.56 16.589a.602.602 0 0 0 .85.85l7.585-7.584 7.594 7.593a.599.599 0 0 0 .85 0 .6.6 0 0 0 0-.85L9.847 9.004Z" fill="#00355B"/></svg>';
    // get the class button
    const getTheDivButton = document.querySelector('.cart-promo__modal-button');
    // Function to close modal
    $('.cart-promo__modal-title-close').on('click', function(){
        $('.cart-promo__body').removeClass('cart-promo__body-show');
        $('html').removeClass('ui-scroll-disabled');
        getModalTitle.remove();
        getTheDivButton.remove();
    });

    $('.btn-primary__save-samples').on('click', function(){
        $('.cart-promo__body').removeClass('cart-promo__body-show');
        $('html').removeClass('ui-scroll-disabled');
        getModalTitle.remove();
        getTheDivButton.remove();
    });
}

function loadCarousel() {
    $('.cart-promo__product-container-slider').not('.slick-initialized').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        draggable: false
    });
}


function createButtonSaveSamples() {
    if( $('#qtyBonusProducts').val() > 0){
        // Get the div promotional pods
        const getModalPromotionalPods = document.querySelector('.cart-promo__body');
        // Create <div> HTML element for button "Save samples"
        const createDivButtonBottom = document.createElement('div');
        // Add class for the <div> HTML element for button
        createDivButtonBottom.classList.add('cart-promo__modal-button');
        // Create <button> Tag element for title
        const createButtonTag = document.createElement('button');
        // Create the text for "Save samples" button
        const createTextButton = document.createTextNode('Continuar');
        // Put the button, into the div element
        createDivButtonBottom.appendChild(createButtonTag);
        // Put the text 'Guardar muestras' into H3 tag
        createButtonTag.appendChild(createTextButton);
        // Imsert the button in the DOM
        getModalPromotionalPods.insertBefore(createDivButtonBottom, getModalPromotionalPods.childNodes[3]);
        // Add class to the button
        createButtonTag.classList.add('btn', 'btn-primary', 'btn-primary__save-samples');
        // Get the save samples button
        const getSaveSamplesButton = document.querySelector('.btn-primary__save-samples');
        // Create the attribute type
        const buttonSaveSamplesTypeAttribute = document.createAttribute('type');
        // Set the attribute value button
        buttonSaveSamplesTypeAttribute.value = 'submit';
        // Put the attribute into the button
        getSaveSamplesButton.setAttributeNode(buttonSaveSamplesTypeAttribute);
    }
}

/**
 * 1. Open last mille products modal
 * 2. Add event listener to modal's continue to payment button
 * 3. Add event listener to add to cart for modal's product pods
 */

function getSliderConfigLastMilleProductsModal(itemsPerLine, page) {
	const sliderConfig = {
		slidesToScroll: 1,
		slidesToShow: 2,
		dots: true,
		lazyLoad: 'ondemand',
		centerMode: false,
		arrows: false,
		mobileFirst: true,
		dotsClass: 'last-mille-modal-slick-dots',
		responsive: [{
			breakpoint: 1023,
			settings: {
				slidesToShow: itemsPerLine,
				arrows: true,
			}
		}, {
			breakpoint: 1365,
			settings: {
				slidesToShow: itemsPerLine,
				arrows: true,
			}
		}]
	};

	if (page !== 'undefined' && page === 'home') {
		sliderConfig.responsive[4] = {
			breakpoint: 992,
			settings: {
				centerMode: false,
				slidesToShow: itemsPerLine,
				arrows: false
			}
		}
	}

	return sliderConfig
}

function incrementValue(_this, param) {
    var isIncrement = param || false;
    var fieldName = $(_this).data('field');
    var parent = $(_this).closest('div');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);
    var maxItems = parseInt(parent.find('input[name="maxItems"]').first().val(),10);
    var maxItemsPreference = parseInt(parent.find('input[name="maxItemsPreference"]').first().val(),10);
    var uuid = parent.find('input[name="uuid"]').first().val();
    var dataset = JSON.parse(parent.parents('.box-item-carro')[0].dataset.lineitem);

    if (!isNaN(currentVal) && currentVal  >= 1) {
        if (maxItems > maxItemsPreference) {
            if (currentVal < maxItemsPreference){
                if (isIncrement){
                    var qtyCart = currentVal + 1;
                    cart.updateQty(qtyCart,uuid);
                    $(_this).prop('disabled', false);
                    dataset.quantity = qtyCart;
                    pushDatalayerAddToCart(dataset);

                }else {
                    $(_this).prop('disabled', false);
                }
            } else {
                $(_this).prop('disabled', true);
            }
        } else {
            if (currentVal < maxItems) {
                if (isIncrement){
                    var qtyCart = currentVal + 1;
                    parent.find('input[name=' + fieldName + ']').val(currentVal + 1);
                    cart.updateQty(qtyCart,uuid);
                    $(_this).prop('disabled', false);
                    dataset.quantity = qtyCart;
                    pushDatalayerAddToCart(dataset);
                }else {
                    $(_this).prop('disabled', false);
                }
            } else {
                $(_this).prop('disabled', true);
            }
        }
    } else {
        parent.find('input[name=' + fieldName + ']').val(1);
        cart.updateQty(currentVal,uuid);
        var qtyCart = currentVal + 1;
        dataset.quantity = qtyCart;
        pushDatalayerAddToCart(dataset);
    }
}

function decrementValue(_this, param) {
    var isAuthenticated = window.customer ? window.customer.type : null;
    var isDecrement = param || false;
    var fieldName = $(_this).data('field');
    var parent = $(_this).closest('div');
    var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);
    var uuid = parent.find('input[name="uuid"]').first().val();
    var dataset = JSON.parse(parent.parents('.box-item-carro')[0].dataset.lineitem);

    const customerType = getCustomerType(isAuthenticated);

    if (!isNaN(currentVal) && currentVal > 1) {
        if (isDecrement){
            var qty = currentVal - 1;
            parent.find('input[name=' + fieldName + ']').val(currentVal - 1);
            cart.updateQty(qty,uuid);
            dataset.quantity = qty;
            pushDatalayerRemoveFromCart(dataset, customerType);
        }
    } else {
        parent.find('input[name=' + fieldName + ']').val(1);
    }
}

function pushDatalayerAddToCart(product) {
    delete product.image_data;
    window.dataLayer.push({  
        'event': 'addToCart',
        'ecommerce': {
            'add': {
                'products': [product]
            }
        }
    });
}

function pushDatalayerRemoveFromCart(product, customerType) {
    delete product.image_data;
    window.dataLayer.push({
        'event' : 'removeFromCart',
        'type-User': customerType,
        'ecommerce' : {
            'remove' : {
                'products' : [product]
            }
        }
    });
}

  
exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
};