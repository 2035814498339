'use strict';

const sellerDetails = {
  className: 'seller-details',

  timeout: null,

  init() {
    $(() => {
      this.start();
    });

    $(window).on('resize', () => {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.restart();
      }, 100);
    });
  },

  start() {
    if (!window.matchMedia("(max-width: 767px)").matches) {
      return;
    }

    $(`.${this.className}`).each((i, el) => {
      const $container = $(el);
      const $showButton = $container.find(`.${this.className}__link--show`);
      const $hideButton = $container.find(`.${this.className}__link--hide`);
  
      this.toggleAction($container);
  
      $showButton.off('click', this.handleToggle.bind(this));
      $showButton.on('click', this.handleToggle.bind(this));
      $hideButton.off('click', this.handleToggle.bind(this));
      $hideButton.on('click', this.handleToggle.bind(this));
    });
  },

  restart() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      $(`.${this.className}`).each((i, el) => {
        const $container = $(el);
        const $showButton = $container.find(`.${this.className}__link--show`);
        const $hideButton = $container.find(`.${this.className}__link--hide`);

        this.toggleAction($container, true);
    
        $showButton.off('click', this.handleToggle.bind(this));
        $showButton.on('click', this.handleToggle.bind(this));
        $hideButton.off('click', this.handleToggle.bind(this));
        $hideButton.on('click', this.handleToggle.bind(this));
      });
    } else {
      $(`.${this.className}`).each((i, el) => {
        const $container = $(el);
        const $boxes = $container.find(`.${this.className}__boxes`);
        const $showButton = $container.find(`.${this.className}__link--show`);
        const $hideButton = $container.find(`.${this.className}__link--hide`);

        $boxes.show();
        $showButton.hide();
        $hideButton.hide();
      });
    }
  },

  handleToggle(e) {
    if (!e) {
      return;
    }

    const $container = $(e.target).parents(`.${this.className}`);
    this.toggleAction($container);
  },

  toggleAction($container, hide) {
    if (!$container.length) {
      return;
    }

    const $boxes = $container.find(`.${this.className}__boxes`);
    const $showButton = $container.find(`.${this.className}__link--show`);
    const $hideButton = $container.find(`.${this.className}__link--hide`);

    if (hide) {
      $boxes.hide();
      $showButton.show();
      $hideButton.hide();
    } else {
      $boxes.toggle();

      if ($boxes.is(':visible')) {
        $showButton.hide();
        $hideButton.show();
      } else {
        $showButton.show();
        $hideButton.hide();
      }
    }
  },
};

module.exports = sellerDetails;