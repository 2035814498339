function isMobile() {
  return window.innerWidth <= 990;
}

function isDesktop() {
  return !isMobile();
}

function prefersMotion() {
  return window.matchMedia('(prefers-reduced-motion: no-preference)').matches;
}

function isElementInViewport(element) {
  const elementRectangle = element.getBoundingClientRect();
  return (
    elementRectangle.top >= 0 &&
    elementRectangle.left >= 0 &&
    elementRectangle.bottom <= window.innerHeight &&
    elementRectangle.right <= window.innerWidth
  );
}

function hasSubmenu(itemElement) {
  return itemElement.hasAttribute('aria-expanded');
}

function expandItem(itemElement) {
  if (!hasSubmenu(itemElement)) return;
  itemElement.setAttribute('aria-expanded', 'true');
}

function collapseItem(itemElement) {
  if (!hasSubmenu(itemElement)) return;
  itemElement.setAttribute('aria-expanded', 'false');
}

function isItemExpanded(itemElement) {
  return itemElement.getAttribute('aria-expanded') === 'true';
}

function toggleItem(itemElement) {
  if (isItemExpanded(itemElement)) {
    collapseItem(itemElement);
  } else {
    expandItem(itemElement);
  }
}

function collapseAllMainMenuItems() {
  const itemLinkElements = document.querySelectorAll('.main-menu__item-link');

  itemLinkElements.forEach((itemLinkElement) => {
    collapseItem(itemLinkElement);
  });
}

function expandAllSubMenuItemsFrom(anyMainMenuItemElement) {
  const mainMenuItemElement = anyMainMenuItemElement.closest('.main-menu__item');
  const subMenuItemLinkElements = mainMenuItemElement.querySelectorAll(
    '.main-menu__submenu-item-link'
  );

  subMenuItemLinkElements.forEach((subMenuItemLinkElement) => {
    expandItem(subMenuItemLinkElement);
  });
}

function collapseAllSubMenuItemsFrom(anyMainMenuItemElement) {
  const mainMenuItemElement = anyMainMenuItemElement.closest('.main-menu__item');
  const subMenuItemLinkElements = mainMenuItemElement.querySelectorAll(
    '.main-menu__submenu-item-link'
  );

  subMenuItemLinkElements.forEach((subMenuItemLinkElement) => {
    collapseItem(subMenuItemLinkElement);
  });
}

function expandAllSubMenuItems() {
  const subMenuItemLinkElements = document.querySelectorAll('.main-menu__submenu-item-link');

  subMenuItemLinkElements.forEach((subMenuItemLinkElement) => {
    expandAllSubMenuItemsFrom(subMenuItemLinkElement);
  });
}

function collapseAllSubMenuItems() {
  const subMenuItemLinkElements = document.querySelectorAll('.main-menu__submenu-item-link');

  subMenuItemLinkElements.forEach((subMenuItemLinkElement) => {
    collapseAllSubMenuItemsFrom(subMenuItemLinkElement);
  });
}

function onResize({ callbackDesktop, callbackMobile }) {
  let isDesktopLastTime = isDesktop();
  let isMobileLastTime = isMobile();

  function onResizeHandler(bypassCache = false) {
    const isDesktopNow = isDesktop();
    const isMobileNow = isMobile();

    if (isDesktopNow !== isDesktopLastTime || bypassCache) {
      if (isDesktopNow) {
        callbackDesktop();
      } else {
        callbackMobile();
      }
    }

    isDesktopLastTime = isDesktopNow;
    isMobileLastTime = isMobileNow;
  }

  return onResizeHandler;
}

function initializeToggleAllSubMenuBetweenDesktopAndMobile() {
  const onResizeHandler = onResize({
    callbackDesktop: expandAllSubMenuItems,
    callbackMobile: collapseAllSubMenuItems,
  });
  const onResizeHandlerWrapper = () => onResizeHandler();

  window.addEventListener('resize', onResizeHandlerWrapper);

  onResizeHandler(true);
}

function expandMainMenuItem(mainMenuItemLinkElement) {
  if (isItemExpanded(mainMenuItemLinkElement)) return;
  collapseAllMainMenuItems();
  expandItem(mainMenuItemLinkElement);
  if (isDesktop()) expandAllSubMenuItemsFrom(mainMenuItemLinkElement);
}

function toggleMainMenuItem(mainMenuItemLinkElement) {
  if (isItemExpanded(mainMenuItemLinkElement)) {
    collapseItem(mainMenuItemLinkElement);
  } else {
    expandMainMenuItem(mainMenuItemLinkElement);
  }
}

function expandSubMenuItem(subMenuItemLinkElement) {
  collapseAllSubMenuItemsFrom(subMenuItemLinkElement);
  expandItem(subMenuItemLinkElement);
}

function toggleSubMenuItem(subMenuItemLinkElement) {
  if (isItemExpanded(subMenuItemLinkElement)) {
    collapseItem(subMenuItemLinkElement);
  } else {
    expandSubMenuItem(subMenuItemLinkElement);
  }
}

function openMainMenu() {
  const mainMenuOpenedEvent = new CustomEvent('main-menu-opened');
  document.dispatchEvent(mainMenuOpenedEvent);

  const mainMenuButtonElement = document.querySelector('.main-menu__toggle-button');
  if (isDesktop()) collapseAllMainMenuItems();
  expandItem(mainMenuButtonElement);
}

function closeMainMenu() {
  const mainMenuClosedEvent = new CustomEvent('main-menu-closed');
  document.dispatchEvent(mainMenuClosedEvent);

  const mainMenuButtonElement = document.querySelector('.main-menu__toggle-button');
  if (isDesktop()) collapseAllMainMenuItems();
  collapseItem(mainMenuButtonElement);
}

function isMainMenuOpen() {
  const mainMenuButtonElement = document.querySelector('.main-menu__toggle-button');
  return isItemExpanded(mainMenuButtonElement);
}

function toggleMainMenu() {
  if (isMainMenuOpen()) {
    closeMainMenu();
  } else {
    openMainMenu();
  }
}

function lockPageScrolling() {
  document.body.style.overflow = 'hidden';
  document.documentElement.style.overflow = 'hidden';
}

function unlockPageScrolling() {
  document.body.style.removeProperty('overflow');
  document.documentElement.style.removeProperty('overflow');
}

function initializeLockPageScrollOnMenuOpen() {
  document.addEventListener('main-menu-opened', lockPageScrolling);
  document.addEventListener('main-menu-closed', unlockPageScrolling);
}

function appendOverlayElements() {
  const overlayBodyElement = document.createElement('div');
  overlayBodyElement.classList.add('main-menu__overlay', 'main-menu__overlay--body');
  overlayBodyElement.setAttribute('aria-hidden', 'true');
  const bodyElement = document.body;
  bodyElement.appendChild(overlayBodyElement);

  const overlayHeaderElement = document.createElement('div');
  overlayHeaderElement.classList.add('main-menu__overlay', 'main-menu__overlay--header');
  overlayHeaderElement.setAttribute('aria-hidden', 'true');
  const headerElement = document.querySelector('.new-header');
  headerElement.appendChild(overlayHeaderElement);
}

function showOverlay() {
  const overlayElements = document.querySelectorAll(
    '.main-menu__overlay--body, .main-menu__overlay--header'
  );
  overlayElements.forEach((overlayElement) => {
    overlayElement.classList.add('main-menu__overlay--show');
  });
}

function hideOverlay() {
  const overlayElements = document.querySelectorAll(
    '.main-menu__overlay--body, .main-menu__overlay--header'
  );
  overlayElements.forEach((overlayElement) => {
    overlayElement.classList.remove('main-menu__overlay--show');
  });
}

function initializeOverlayOnMenuOpen() {
  appendOverlayElements();
  document.addEventListener('main-menu-opened', showOverlay);
  document.addEventListener('main-menu-closed', hideOverlay);
}

function setElementPositionBelow(referenceElement, elementToAdjust) {
  const referenceRectangle = referenceElement.getBoundingClientRect();
  const bottomOfReference = referenceRectangle.bottom;

  elementToAdjust.style.top = `${bottomOfReference}px`;
}

function resetElementPosition(elementToAdjust) {
  elementToAdjust.style.removeProperty('top');
}

function setElementHeightRelativeTo(referenceElement, elementToAdjust) {
  const referenceRectangle = referenceElement.getBoundingClientRect();
  const bottomOfReference = referenceRectangle.bottom;

  elementToAdjust.style.height = `calc(100vh - ${bottomOfReference}px)`;
}

function resetElementHeight(elementToAdjust) {
  elementToAdjust.style.removeProperty('height');
}

function handleDesktopMainMenuPositioning() {
  const headerElement = document.querySelector('#header-hamburger');
  const mainMenuWrapperElement = document.querySelector('.main-menu__toggle-wrapper');
  const subMenuWrapperElements = document.querySelectorAll('.main-menu__submenu-wrapper');

  if (isDesktop()) {
    setElementPositionBelow(headerElement, mainMenuWrapperElement);
    setElementHeightRelativeTo(headerElement, mainMenuWrapperElement);

    subMenuWrapperElements.forEach((subMenuWrapperElement) => {
      setElementHeightRelativeTo(headerElement, subMenuWrapperElement);
    });
  } else {
    resetElementPosition(mainMenuWrapperElement);
    resetElementHeight(mainMenuWrapperElement);

    subMenuWrapperElements.forEach((subMenuWrapperElement) => {
      resetElementHeight(subMenuWrapperElement);
    });
  }
}

function initializeMenuPositioningOnDesktop() {
  document.addEventListener('main-menu-opened', function () {
    handleDesktopMainMenuPositioning();
    window.addEventListener('resize', handleDesktopMainMenuPositioning);
  });

  document.addEventListener('main-menu-closed', function () {
    window.removeEventListener('resize', handleDesktopMainMenuPositioning);
  });
}

function handleClickMainMenuButton() {
  if (isMainMenuOpen()) {
    const closeCustomEvent = new CustomEvent('main-menu-close-click');
    document.dispatchEvent(closeCustomEvent);

    closeMainMenu();
  } else {
    const openCustomEvent = new CustomEvent('main-menu-open-click');
    document.dispatchEvent(openCustomEvent);

    openMainMenu();
  }
}

function scrollToSubMenuItemLinkElement(subMenuItemLinkElement) {
  if (isElementInViewport(subMenuItemLinkElement)) return;

  const subMenuListWrapperElement = subMenuItemLinkElement.closest(
    '.main-menu__submenu-list-wrapper'
  );
  const mainMenuItemHeaderWrapper = subMenuListWrapperElement.previousElementSibling;
  const headerBorderTop = parseInt(
    window.getComputedStyle(mainMenuItemHeaderWrapper).borderTopWidth
  );

  subMenuListWrapperElement.scroll({
    top: subMenuItemLinkElement.offsetTop - subMenuItemLinkElement.offsetHeight - headerBorderTop,
    left: 0,
    behavior: 'smooth',
  });
}

function handleClickItemOnMobile(event) {
  if (isDesktop()) return;

  const targetElement = event.target;

  const itemLinkElement = targetElement.closest('.main-menu__item-link');
  if (itemLinkElement) {
    if (!hasSubmenu(itemLinkElement)) return;
    event.preventDefault();
    expandMainMenuItem(itemLinkElement);
    return;
  }

  const subMenuItemLinkElement = targetElement.closest('.main-menu__submenu-item-link');
  if (subMenuItemLinkElement) {
    if (!hasSubmenu(subMenuItemLinkElement)) return;
    event.preventDefault();
    toggleSubMenuItem(subMenuItemLinkElement);
    scrollToSubMenuItemLinkElement(subMenuItemLinkElement);
    return;
  }

  const backButtonElement = targetElement.closest('.main-menu__item-back-button');
  if (backButtonElement) {
    const itemHeaderTitle = backButtonElement.dataset.item;
    const backCustomEvent = new CustomEvent('main-menu-back-button-clicked', {
      detail: {
        item: itemHeaderTitle,
      },
    });
    document.dispatchEvent(backCustomEvent);

    collapseAllMainMenuItems();
    return;
  }

  const closeButtonElement = targetElement.closest(
    '.main-menu__header-close-button, .main-menu__item-close-button'
  );
  if (closeButtonElement) {
    const closeCustomEvent = new CustomEvent('main-menu-close-click');
    document.dispatchEvent(closeCustomEvent);

    closeMainMenu();
  }
}

function handleClickOutsideMainMenu(event) {
  const mainMenuElement = document.querySelector('.main-menu');
  const isWithinBoundaries = event.composedPath().includes(mainMenuElement);

  if (isWithinBoundaries) return;

  const closeCustomEvent = new CustomEvent('main-menu-close-click');
  document.dispatchEvent(closeCustomEvent);

  closeMainMenu();
}

function initializeCloseMenuOnClickOutside() {
  document.addEventListener('main-menu-opened', function () {
    document.addEventListener('click', handleClickOutsideMainMenu);
  });
  document.addEventListener('main-menu-closed', function () {
    document.removeEventListener('click', handleClickOutsideMainMenu);
  });
}

function handleHoverItemOnDesktop(event) {
  if (isMobile()) return;

  const targetElement = event.target;
  const itemLinkElement = targetElement.closest('.main-menu__item-link');
  if (!itemLinkElement) return;

  expandMainMenuItem(itemLinkElement);
}

function initializeKeyboardInteraction() {
  const mainMenuElement = document.querySelector('.main-menu');

  function handleKeyboardInteraction(event) {
    if (event.key === 'Escape') {
      closeMainMenu();
      return;
    }

    if (event.target.classList.contains('main-menu__item-link')) {
      const itemLinkElement = event.target;

      if (event.key === 'Enter' || event.key === ' ') {
        if (!hasSubmenu(itemLinkElement)) return;
        event.preventDefault();
        toggleMainMenuItem(itemLinkElement);
      }
    }
  }

  document.addEventListener('main-menu-opened', function () {
    mainMenuElement.addEventListener('keydown', handleKeyboardInteraction);
  });
  document.addEventListener('main-menu-closed', function () {
    mainMenuElement.removeEventListener('keydown', handleKeyboardInteraction);
  });
}

function getPercentageOffset(element) {
  const elementWidth = element.offsetWidth;
  const elementLeft = element.getBoundingClientRect().left;
  return (elementLeft / elementWidth) * 100;
}

function initializeGesturesOnMobile() {
  if (!prefersMotion()) return;

  const containerElement = document.querySelector('.main-menu__toggle-wrapper');
  let closestElement;
  let onDragEndCallback;

  let startX = 0;
  let isDragging = false;

  function dispatchBackWithGestureCustomEvent(subMenuElement) {
    const closestBackButtonElement = subMenuElement
      .closest('.main-menu__submenu-wrapper')
      ?.querySelector('.main-menu__item-back-button');

    if (!closestBackButtonElement) return;

    const itemHeaderTitle = closestBackButtonElement.dataset.item;

    const backCustomEvent = new CustomEvent('main-menu-back-gesture', {
      detail: {
        item: itemHeaderTitle,
      },
    });
    document.dispatchEvent(backCustomEvent);
  }

  function dispatchCloseWithGestureCustomEvent() {
    const closeCustomEvent = new CustomEvent('main-menu-close-gesture');
    document.dispatchEvent(closeCustomEvent);
  }

  function handleTouchStart(event) {
    if (isDesktop()) return;
    const targetElement = event.target;

    const menuWrapper = targetElement.closest('.main-menu__toggle-wrapper');
    const subMenuWrapper = targetElement.closest('.main-menu__submenu-wrapper');

    if (subMenuWrapper) {
      closestElement = subMenuWrapper;
      onDragEndCallback = () => {
        collapseAllMainMenuItems();
        dispatchBackWithGestureCustomEvent(event.target);
      };
    } else if (menuWrapper) {
      closestElement = menuWrapper;
      onDragEndCallback = () => {
        closeMainMenu();
        dispatchCloseWithGestureCustomEvent();
      };
    }

    startX = event.touches[0].clientX;
    isDragging = true;
  }

  function handleTouchMove(event) {
    if (isDragging) {
      const deltaX = event.touches[0].clientX - startX;

      const isDraggingToTheRight = deltaX > 0;
      if (isDraggingToTheRight) return;

      closestElement.style.transition = 'none';
      closestElement.style.transform = `translateX(${deltaX}px)`;
    }
  }

  function handleTouchEnd(event) {
    if (isDragging) {
      const percentage = getPercentageOffset(closestElement);
      const closeThresholdPercentage = -30;

      if (percentage <= closeThresholdPercentage) {
        closestElement.style.removeProperty('transition');
        closestElement.style.transitionDuration = '200ms';
        closestElement.style.transitionTimingFunction = 'linear';
        closestElement.style.transform = `translateX(-100%)`;

        onDragEndCallback();

        closestElement.addEventListener(
          'transitionend',
          function () {
            closestElement.style.removeProperty('transition');
            closestElement.style.removeProperty('transform');
          },
          { once: true }
        );
      } else {
        closestElement.style.removeProperty('transition');
        closestElement.style.removeProperty('transform');
      }
      isDragging = false;
    }
  }

  document.addEventListener('main-menu-opened', function () {
    containerElement.addEventListener('touchstart', handleTouchStart);
    containerElement.addEventListener('touchmove', handleTouchMove);
    containerElement.addEventListener('touchend', handleTouchEnd);
  });
  document.addEventListener('main-menu-closed', function () {
    containerElement.removeEventListener('touchstart', handleTouchStart);
    containerElement.removeEventListener('touchmove', handleTouchMove);
    containerElement.removeEventListener('touchend', handleTouchEnd);
  });
}

function pushToDataLayer(tag) {
  const dataLayer = window.dataLayer;
  if (dataLayer) {
    dataLayer.push(tag);
  } else {
    console.info(tag);
  }
}

function initializeTagging() {
  function tagOpenMenuClick() {
    const tag = {
      'event-interactive-category': 'menu',
      'event-interactive-accion': 'click',
      'event-interactive-tag': 'Abrir',
      'event-interactive-value': '',
      'event': 'event-interactive',
    };
    pushToDataLayer(tag);
  }

  function tagCloseMenuClick() {
    const tag = {
      'event-interactive-category': 'menu',
      'event-interactive-accion': 'click',
      'event-interactive-tag': 'Cerrar',
      'event-interactive-value': '',
      'event': 'event-interactive',
    };
    pushToDataLayer(tag);
  }

  function tagItemClick(event) {
    const tag = {
      'event-interactive-category': 'menu',
      'event-interactive-accion': 'click',
      'event-interactive-tag': event.detail.item,
      'event-interactive-value': '',
      'event': 'event-interactive',
    };
    pushToDataLayer(tag);
  }

  function tagCategoriesItemClick(event) {
    const tag = {
      'event-interactive-category': 'menu',
      'event-interactive-accion': 'seleccionar',
      'event-interactive-tag': event.detail.item,
      'event-interactive-value': '',
      'event': 'event-interactive',
    };
    pushToDataLayer(tag);
  }

  function tagBackButtonClick(event) {
    const tag = {
      'event-interactive-category': 'menu',
      'event-interactive-accion': 'click',
      'event-interactive-tag': `Volver de ${event.detail.item}`,
      'event-interactive-value': '',
      'event': 'event-interactive',
    };
    pushToDataLayer(tag);
  }

  function tagBackGesture(event) {
    const tag = {
      'event-interactive-category': 'menu',
      'event-interactive-accion': 'gesture',
      'event-interactive-tag': `Volver de ${event.detail.item}`,
      'event-interactive-value': '',
      'event': 'event-interactive',
    };
    pushToDataLayer(tag);
  }

  function tagCloseMenuGesture() {
    const tag = {
      'event-interactive-category': 'menu',
      'event-interactive-accion': 'gesture',
      'event-interactive-tag': 'Cerrar',
      'event-interactive-value': '',
      'event': 'event-interactive',
    };
    pushToDataLayer(tag);
  }

  document.addEventListener('main-menu-open-click', tagOpenMenuClick);
  document.addEventListener('main-menu-close-click', tagCloseMenuClick);
  document.addEventListener('main-menu-close-gesture', tagCloseMenuGesture);

  document.addEventListener('main-menu-opened', function () {
    document.addEventListener('main-menu-item-clicked', tagItemClick);
    document.addEventListener('main-menu-categories-item-clicked', tagCategoriesItemClick);
    document.addEventListener('main-menu-back-button-clicked', tagBackButtonClick);
    document.addEventListener('main-menu-back-gesture', tagBackGesture);
  });

  document.addEventListener('main-menu-closed', function () {
    document.removeEventListener('main-menu-item-clicked', tagItemClick);
    document.removeEventListener('main-menu-categories-item-clicked', tagCategoriesItemClick);
    document.removeEventListener('main-menu-back-button-clicked', tagBackButtonClick);
    document.removeEventListener('main-menu-back-gesture', tagBackGesture);
  });
}

function initializeClickItemCustomEvents() {
  function handleClickItemCustomEvents(event) {
    const targetElement = event.target;

    const itemLinkElement = targetElement.closest(
      '.main-menu__item-link, .main-menu__submenu-item-link, .main-menu__sub-submenu-item-link'
    );

    if (!itemLinkElement) return;
    if (isMobile() && hasSubmenu(itemLinkElement)) return;

    const itemName = itemLinkElement.dataset.item;
    let customEventName = 'main-menu-item-clicked';

    const isCategoriesItem = itemLinkElement.closest('.main-menu__section-wrapper--categories');
    if (isCategoriesItem) customEventName = 'main-menu-categories-item-clicked';

    const itemClickedCustomEvent = new CustomEvent(customEventName, {
      detail: {
        item: itemName,
      },
    });
    document.dispatchEvent(itemClickedCustomEvent);
  }

  const mainMenuWrapperElement = document.querySelector('.main-menu__toggle-wrapper');

  document.addEventListener('main-menu-opened', function () {
    mainMenuWrapperElement.addEventListener('click', handleClickItemCustomEvents);
  });

  document.addEventListener('main-menu-closed', function () {
    mainMenuWrapperElement.removeEventListener('click', handleClickItemCustomEvents);
  });
}

function initializeMainMenuEssentials() {
  const mainMenuButtonElement = document.querySelector('.main-menu__toggle-button');
  const mainMenuWrapperElement = document.querySelector('.main-menu__toggle-wrapper');

  mainMenuButtonElement.addEventListener('click', handleClickMainMenuButton);

  document.addEventListener('main-menu-opened', function () {
    mainMenuWrapperElement.addEventListener('click', handleClickItemOnMobile);
    mainMenuWrapperElement.addEventListener('mouseover', handleHoverItemOnDesktop);
  });

  document.addEventListener('main-menu-closed', function () {
    mainMenuWrapperElement.removeEventListener('click', handleClickItemOnMobile);
    mainMenuWrapperElement.removeEventListener('mouseover', handleHoverItemOnDesktop);
  });

  initializeClickItemCustomEvents();
}

function initializeUserEvents() {
    const mainMenuButtonElement = document.querySelector('.header-customer__wrap');
    mainMenuButtonElement.addEventListener('click', handleClickHeaderCustomer);
}

function handleClickHeaderCustomer() {
    const userAuth = document.querySelector('#userIsAuthenticated');
    if(isMobile() && userAuth.value === 'true') {
        if (document.querySelector('.js-account-panel')) {
            document.querySelector('.js-account-panel').classList.toggle('hidden');
            if (!document.querySelector('.js-account-panel').classList.contains('hidden') && document.querySelector('#js-box-search-header').classList.contains('visible-search')) {
                document.querySelector('.js-show-search').click();
            }
        }
    }
}

function initializeMainMenu({
  showOverlay = true,
  closeOnClickOutside = true,
  gesturesOnMobile = true,
  keyboardInteraction = true,
  tagging = true,
  startOpen = false,
} = {}) {
  initializeMainMenuEssentials();
  initializeLockPageScrollOnMenuOpen();
  initializeToggleAllSubMenuBetweenDesktopAndMobile();
  initializeMenuPositioningOnDesktop();
  initializeUserEvents();
  if (showOverlay) initializeOverlayOnMenuOpen();
  if (closeOnClickOutside) initializeCloseMenuOnClickOutside();
  if (gesturesOnMobile) initializeGesturesOnMobile();
  if (keyboardInteraction) initializeKeyboardInteraction();
  if (tagging) initializeTagging();
  if (startOpen) {
    setTimeout(() => {
      openMainMenu();
    }, 0);
  }

  return {
    open: openMainMenu,
    close: closeMainMenu,
    toggle: toggleMainMenu,
    isOpen: isMainMenuOpen,
  };
}

const mainMenu = {
    initializeMainMenu,
    openMainMenu
}

//export default initializeMainMenu;
module.exports = mainMenu;