var dialog = require('../../dialog'),
formPrepare = require('./formPrepare');

exports.init = function () {

//    formPrepare.init({
//        formSelector: 'form.js-form-novios-code-validation',
//        continueSelector: '.js-form-novios-code-validation-button'
//    });
	$('#inputCodeNovios').on('change',function(){
		var $continue = $('.js-form-novios-code-validation-button');
		if ($('form.js-form-novios-code-validation').valid()) {
			$continue.removeAttr('disabled');
		} 
	});
	
	$('.js-remove-special-program-code').on('click', function(e){
		e.preventDefault();
		var deleteUrl = $(e.currentTarget).attr('href');
	    dialog.open({
	    	html: Resources.ABOUT_TO_DELETE_SPECIAL_PROGRAM_CODE,
	        options: {
	            title: Resources.DELETE_SPECIAL_PROGRAM_CODE,
	            titleClass: 'text-uppercase',
                width: 600,
	            //height: '483',
	            responsive: true,
	            collision: 'none',
	            modal: true,
	            dialogClass: 'alert-modal remove-special-program-code-dialog',
	            close: function() { $( this ).dialog( "close" ) },
	            buttons: [
	                {
	                    text: Resources.CANCEL,
	                    class: "btn btn-secondary btn-forms text-uppercase",
	                    click: function() {
	                    $( this ).dialog( "close" );
	                    }
	                },
	                {
	                    text: Resources.DELETE,
	                    class: "btn btn-primary btn-forms btn-remove-special-program-code text-uppercase",
	                    click: function() {
	                      window.location.href = deleteUrl;
	                    }
	                  }
	              ]
	        }
	    });
    });
    
    
};