'use strict';

var util = require('../../util');
var shipping = require('./shipping');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
	
	$('.js-address .js-select-addresses :radio').on('click', function () { 
    	var $form = $(this).closest('form');
        if (!$('.js-shipping-form').hasClass('hidden')) {
            $('.js-add-new-address').trigger('click');
            $('.js-add-new-address').prop('checked', false);
		}
		if($('.js-submit-shipping')){
			$('.js-submit-shipping').removeAttr('disabled');
		}
        $('.js-select-addresses :radio').prop('checked', false);
        
        var $element = $(this);
        $element.prop('checked', true);        
        
        var selectedAddress = $($element).data('address');        
        if (!selectedAddress) { 
        	return; 
        }
        
		util.fillAddressFields(selectedAddress, $form);
		$('.js-address-form').removeClass('addressVerified');
		if(selectedAddress.latitude && selectedAddress.longitude) $('.js-address-form').addClass('addressVerified');
		
        // re-validate the form
        $form.validate().form();
		shipping.validateFormFields();
		if($('#GoogleMapsPlaceholder')){
			$('#GoogleMapsPlaceholder').hide();
			$('#GoogleMapsPlaceholder').empty();
		}
    });
};
