'use strict';

var dialog = require('./dialog'),
    progress = require('./progress'),
    page = require('./page'),
    minicart = require('./minicart'),
    ajax = require('./ajax'),
    util = require('./util');

function getBonusProducts() {
    var bonusproducts = [];

    $.ajax({
        type: 'GET',
        dataType: 'json',
        cache: false,
        contentType: 'application/json',
        url: Urls.getBonusInfo
    }).done(function(data){
        if(data.status){
            if(data.maxBonusProductLineItems == data.qtyBonusProductLineItems && window.matchMedia('(max-width: 768px)').matches){
                $('#qty-bonus-items').text("¡Agregaste " + data.maxBonusProductLineItems + " muestras de regalo!");
                $('.cart-promo__message p').text("Ya elegiste tus favoritas y las recibirás gratis por la compra de uno de tus productos");
                $('.js-select-element-text').text("Cambiar muestras");
            } else {
                $('#qty-bonus-items').text("¡Tienes " + data.maxBonusProductLineItems + " muestras de regalo!");
            }
            if(window.matchMedia('(max-width: 768px)').matches){
                $('#bonus-product-list').css("padding", "0px 16px 60px");
            }
        }
    });
}

function update(targetURL) {
    page.reload();
}

function initButtons() {
    $('.js-bonus-product-line-item').click(function() {
        switch($(this).data("status")) {
            case "add":
                var product = {
                    "sku":"" + $(this).data("id")
                };
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    cache: false,
                    contentType: 'application/json',
                    url: Urls.addBonusProduct,
                    data: JSON.stringify(product)
                }).done(function(data){
                    update(Urls.cartShow);
                });
                break;
            case "remove":
                var product = {
                    "lineItemUUID":"" + $(this).data("uuid")
                };
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    cache: false,
                    contentType: 'application/json',
                    url: Urls.cartRemoveProduct,
                    data: JSON.stringify(product)
                }).done(function(data){
                    update(Urls.cartShow);
                });
                break;
          }
    });
}

var bonusProducts = {
    init: function () {
        initButtons();
        getBonusProducts();
    },
    modal: function () {
        dialog.open({
            target: $bonusProduct,
            url: url,
            options: {
                width: 320,
                height: window.innerHeight,
                title: Resources.BONUS_PRODUCTS,
                dialogClass: 'bonus-products-dialog',
                draggable: false,
                modal: true,
                open: function () {
                    
                },
                close: function() {
                    
                },
                show: { effect: "drop", direction: "right", duration: 300 },
                hide: { effect: "drop", direction: "right", duration: 300 }
            },
            callback: function () {

            }
        });
    }
};

module.exports = bonusProducts;
