'use strict';
var dialog = require('./dialog');
var libroreclamaciones = {
    init: function() {
        $(document).ready(function() {
            $("#dwfrm_libroreclamaciones_claimDate").prop("disabled", true);
            showHideFields($("select#dwfrm_libroreclamaciones_adult").val());
            $(".js-nav-tab").change(function(){
                var method = $(this).find('a').attr('href').substring(1);
                var isChecked = $("#"+method).find(".js-terms-and-cond").is(':checked');
                if (!isChecked) {
                    $("#"+method).find("button[name*=billing_save]").attr("disabled", true);
                }
            });
    
            if ($(".js-terms-and-cond").length > 0) {
                $(".js-terms-and-cond").change(function() {
                    $(".js-terms-and-cond").each(function(){
                        var isChecked = $(this).is(':checked');
                        if (!isChecked) {
                            $("#dwfrm_libroreclamaciones").find("button[name*=dwfrm_libroreclamaciones_save]").attr("disabled", true);
                            return false;
                        } else {
                            $("#dwfrm_libroreclamaciones").find("button[name*=dwfrm_libroreclamaciones_save]").attr("disabled", false);
                        }
                    });
                });
            }
            if ($(".js-load-content-asset").length > 0) {
                $(".js-load-content-asset").click(function(e){
                    e.preventDefault();
                    dialog.open({
                        url: $(e.target).attr('href'),
                    });
                });
            }
        });
        $("select#dwfrm_libroreclamaciones_adult").change(function(){
            showHideFields($("select#dwfrm_libroreclamaciones_adult").val());
        });
    }
}
function showHideFields(value){
    $("form#dwfrm_libroreclamaciones :input").each(function(){
        var input = $(this); // This is the jquery object of the input, do what you will
        if(value == 'mayordeedad'){
            if(input.attr("id") != null){
                if(input.attr("id").includes("tutor")){
                    if(input.attr("type") == 'text'){
                        input.val("N/A");
                    }else{
                        input.find('option:last').attr('selected', 'selected');
                    }
                    input.parents(".input-element").hide();
                }
            }
        }else{
            if(input.attr("id") != null){
                if(input.attr("id").includes("tutor")){
                    if(input.attr("type") == 'text'){
                        input.val("");
                    }else{
                        input.find('option:first').attr('selected', 'selected');
                    }
                    input.parents(".input-element").show();
                }
            }
        }
    });
}
module.exports = libroreclamaciones;