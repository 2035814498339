'use strict';
var productguide = {
    init: function() {
            $(document).ready(function() {
                if ($('#product-guide-search').length > 0) {
                    searchProduct();	
                }
            });

            $('#product-guide-search input[type=checkbox]').click(function() {
                var name = $(this).data('name');
                if($(this).data('condition') == 'all'){
                    if ($(this).is(":checked")) {
                        setCheckboxByDataName(name, true);
                    }else{
                        setCheckboxByDataName(name, false);
                    }
                }else{
                    var count = 0;
                    var total = 0;
                    $('#product-guide-search input[type=checkbox]').each(function(){
                        if (name == $(this).data('name') && $(this).data('condition') != "all" ) {
                            total ++;
                        }
                        if ($(this).is(":checked") && $(this).data('condition') != "all") {
                            count ++;
                        }
                    });
                    if(total == count){
                        $('#product-guide-search input[type=checkbox]').each(function(){
                            if($(this).data('name') == name && $(this).data('condition') == "all"){
                                $(this).prop('checked', true);
                            }
                        });
                    }else{
                        $('#product-guide-search input[type=checkbox]').each(function(){
                            if($(this).data('name') == name && $(this).data('condition') == "all"){
                                $(this).prop('checked', false);
                            }
                        });
                    }
                }
            });
            $("input[data-name='productGuideA']").change(function() {
                $("input[data-name='productGuideB']").each(function(){ //iterate all listed checkbox items
                    this.checked = false; //change ".checkbox" checked status
                });
                $('input[name="priceRange"]').prop('checked',false)
                searchProduct();
            });
            $("input[data-name='productGuideB']").change(function() {
                $('input[name="priceRange"]').prop('checked',false)
                searchProduct();
            });
            $('#product-guide-search :input').not("input[data-name='productGuideA']").not("input[data-name='productGuideB']").on('keyup change paste', function(){
                searchProduct();
            });
    }
}

function setCheckboxByDataName(name, status){
    $('#product-guide-search input[type=checkbox]').each(function(){
        if($(this).data('name') == name){
            $(this).prop('checked', status);
        }
    });
}

function searchProduct(){
    var form =  $("#product-guide-search");
    var filters = {};
    //var type_collection = [];
    if ($('#product-guide-category-search').length > 0) {
        filters['category'] = $("#product-guide-category-search").val();
    }
    if ($('#product-guide-promotion-search').length > 0) {
        filters['promotionId'] = $("#product-guide-promotion-search").val();
    }
    filters['filters'] = [];
    $(form).find(':input').each(function() {
        if($(this).is(':checked')){
            switch ($(this).data('type')) {
                case 'fixed':
                    var filter = {
                        "type":$(this).data('type'),
                        "values":$(this).data('value'),
                        "name":$(this).data('name')
                    };
                    filters.filters.push(filter);
                    break;
                case 'price':
                    var filter = {
                        "type":$(this).data('type'),
                        "maxPrice":$(this).data('maxprice'),
                        "minPrice":$(this).data('minprice')
                    };
                    filters.filters.push(filter);
                    break;
                case 'collection':
                    var exist = 0;
                    for (i = 0; i < filters.filters.length; i++) {
                        if(filters.filters[i].name == $(this).data('name')){
                            exist = 1;	
                            filters.filters[i].values.push($(this).data('value'));
                        }
                    }
                    if(exist == 0){
                        var filter = {
                            "type":$(this).data('type'),
                            "name":$(this).data('name'),
                            "values":[
                                $(this).data('value')
                            ]
                        };
                        filters.filters.push(filter);
                    }
                    break;
                case 'range':
                    var filter = {
                        "type":$(this).data('type'),
                        "maxValue":$(this).data('maxvalue'),
                        "minValue":$(this).data('minvalue'),
                        "name":$(this).data('name')
                    };
                    filters.filters.push(filter);
                    break;
            }
        }
    });
    console.log(JSON.stringify(filters));

    let str = [];
    for (let p in filters){
        if (filters.hasOwnProperty(p)) {
            if(p !== "filters") str.push(encodeURIComponent(p) + "=" + encodeURIComponent(filters[p]));
            else{
                filters[p].forEach(function (filter, index) {
                    for (let attr in filter){
                        if (filter.hasOwnProperty(attr)) {
                            let value = Array.isArray(filter[attr]) ? filter[attr].join('|') : filter[attr];
                            str.push(encodeURIComponent(attr) + (index+1) + "=" + encodeURIComponent(value));
                        }
                    }
                });
            }
        }
    }

    var data = $.ajax({
        contentType: 'application/json',
        type: "GET",
        data: decodeURIComponent(str.join("&")),
        dataType: "json",
        url: Urls.getQtyFilteredProduct,
        success: function(data){
            $('#product-guide-counter-text').each(function () {
                $(this).prop('Counter',parseInt($("#product-guide-counter-text").text())).animate({
                    Counter: data.data.count
                }, {
                    duration: 500,
                    easing: 'swing',
                    step: function (now) {
                        $(this).text(Math.ceil(now));
                    }
                });
            });
            if(data.data.count == '0'){
                $("#product-guide-link-result").attr("disabled", true);
                $("#product-guide-link-result").removeAttr("href");
            }else{
                $("#product-guide-link-result").attr("disabled", false);
                $("#product-guide-link-result").attr("href", data.data.url);
            }
            
        },
        error: function(){
            console.log("Device control failed");
        }
    });
}

module.exports = productguide;