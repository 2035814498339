'use strict';

/**
 * @function
 * @description add classes and href to current step
 */
exports.init = function () {
	if ($('.box-user-personal-data.open').length) {
		$('.one').addClass('active');
		$('.two').addClass('off');
		$('.three').addClass('off');
	} else if ($('.box-type-delivery.open').length || $('.box-novios.open').length) {
		$('.one').removeClass('active');
		$('.one').addClass('check');
		$('.two').addClass('active');
		$('.three').addClass('off');
	} else if ($('.box-payment.open').length) {
		$('.one, .two').removeClass('active');
		$('.one, .two').addClass('check');
		$('.three').addClass('active');
	}
	$('.containerB .progressbar a').each(function () {
		if ($(this).find('li').hasClass('check')) {
			if ($(this).hasClass('js-step-two-novios')) return true;
			$(this).css({
				'pointer-events': 'auto',
				'cursor': 'pointer'
			});
		}
	});
};
