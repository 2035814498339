'use strict';

const dataLayer = require('./../dataLayer.js');

const navigationDrawerMobile = {
  elementSelector: '.navigation-drawer-mobile',
  elementWrapperSelector: '.navigation-drawer-mobile__wrapper',
  toogleButtonSelector: '.navbarToggle.icon.nav-icon.mob-left.open-drawer, .navigation-drawer-header-mobile__close-icon, .navigation-drawer-category-list-mobile__header button:last-child, .js-click-login-mobile, .js-navigation-drawer-mobile--close',
  itemListSelector: '.navigation-drawer-body-mobile__list-item > a',
  subItemListSelector: '.navigation-drawer-category-list-mobile .navigation-drawer-category-list-mobile__body > ul > li > a.subItem__link',
  linkCencosudCard: '.navigation-drawer-category-list-mobile .navigation-drawer-category-list-mobile__body > ul > li > a.cencosud-card__link',

  init() {
    $(document).ready(() => {
      this.openMenuEvent();
      this.clickItemMenuEvents();
    });
  },

  openMenuEvent() {
    const self = this;

    $(this.toogleButtonSelector).each(function(i, el) {
      $(el).click(function(e) {
        e.preventDefault();
        const openClass = 'navigation-drawer-mobile--open';
        let tag = 'abrir';

        if ($(self.elementSelector).hasClass(openClass)) {
          $('html, body').removeAttr('style');
          $(self.elementSelector).removeClass(openClass);
          tag = 'cerrar';
        } else {
          $(self.elementSelector).addClass(openClass);
          $('html, body').css({
            margin: 0,
            height: '100%',
            overflow: 'hidden',
          });
        }

        self.pushDatalayerHeaderMenu('click', tag);
      });
    });
  },

  clickItemMenuEvents() {
    const self = this;

    $(this.itemListSelector).each(function(i, el) {
      $(el).bind('click', self.handleClickCategory.bind(self));
    });

    $(this.subItemListSelector).each(function () {
      $(this).bind('click', self.handleClickSubCatergory);
    });

    $(this.elementSelector).find('a').each(function(i, el) {
      $(el).click(function(e) {
        const element = e.target.tagName === 'SPAN' || e.target.tagName === 'SVG' ? e.target.parentElement : e.target;
        if (element.dataset && element.dataset.category) {
          let type = element.dataset.type || 'seleccionar';
          self.pushDatalayerHeaderMenu(type, element.dataset.category);
        }
      });
    });

  },

  handleClickCategory(e) {
    e.preventDefault();
    const self = this;
    const openClass = 'navigation-drawer-body-mobile__list-item-inner--open';
    const openClass_ = 'navigation-drawer-body-mobile__list-item--open';
    const backButtonSelector = '.navigation-drawer-category-list-mobile .navigation-drawer-category-list-mobile__header button:first-child';
    const openViewClass = 'navigation-drawer-category-list-view';

    // get selected category 
    const selectedCategory = e.target.innerText;
    self.pushDatalayerHeaderMenu('seleccionar', selectedCategory);

    $(this.itemListSelector).removeClass(openClass);
    $(this.elementWrapperSelector)
      .css('overflow-y', 'hidden')
      .scrollTop(0);

    const parentElement = $(e.target).prop('tagName') === 'A' ? $(e.target).parent() : $(e.target).parent().parent();
    parentElement.addClass(openClass_);

    const subcategoryContainer = $(e.target).prop('tagName') === 'A' ? $(e.target).next() : $(e.target).parent().next();

    subcategoryContainer.children().addClass(openViewClass);

    subcategoryContainer
      .addClass(openClass)
      .find(backButtonSelector)
      .bind('click', function(e) {
        subcategoryContainer.removeClass(openClass);
        subcategoryContainer.children().removeClass(openViewClass);
        parentElement.removeClass(openClass_);
        $(self.elementWrapperSelector).removeAttr('style');

        const element = e.target.tagName === 'I' ? e.target.parentElement : e.target;
        if (element.dataset && element.dataset.category) {
          self.pushDatalayerHeaderMenu('click', element.dataset.category);
        }
      });
  },

  handleClickSubCatergory(e) {
    e.preventDefault();
    
    // get selected category & subcategory
    const selectedCategory = $("div[class='navigation-drawer-category-list-mobile navigation-drawer-category-list-view'] div[class='navigation-drawer-category-list-mobile__header-title']");
    const selectedSubcategory = e.target.innerText;

    const SubcategoryTag = selectedCategory[0].innerText + '/' + selectedSubcategory;
    navigationDrawerMobile.pushDatalayerHeaderMenu('seleccionar', SubcategoryTag);

    const el = $(e.target);

    el.parent().siblings().removeClass('active');

    if (el.parent().hasClass('active')) {
      el.parent().removeClass('active');
    } else {
      el.parent().addClass('active');
    }
  },

  pushDatalayerHeaderMenu(action, tag) {
    window.dataLayer.push(
      dataLayer.eventInteractiveMenu('menu', action, tag),
    );
  },
};

module.exports = navigationDrawerMobile;
