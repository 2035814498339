const modalIframe = {
  className: 'js-modal-iframe',

  template: null,

  init() {
    $(() => {
      const $elements = $(`.${this.className}:not(.js-modal-iframe--linked)`);

      $elements.each((i, el) => {
        $(el).addClass('js-modal-iframe--linked');
        $(el).on('click', this.handleClick.bind(this));
      });
    });
  },

  handleClick(e) {
    e.preventDefault();
    let $anchor = $(e.target);
    const anchorTagName = $anchor.prop('tagName');

    if (anchorTagName !== 'A' && anchorTagName !== 'BUTTON') {
      $anchor = $anchor.parents(`.${this.className}`);
    }

    const url = $anchor.data('url');
    const title = $anchor.attr('title') || '';

    this.template = $(this.getTemplate(url, title));

    this.template.find('.modal-iframe__background').click(this.handleClose.bind(this));
    this.template.find('.modal-iframe__close').click(this.handleClose.bind(this));
    
    $('html, body').css({
      overflow: 'hidden',
    });
   
    $('body').append(this.template);

    setTimeout(() => {
      this.template.addClass('modal-iframe--active');
    }, 20);
  },

  handleClose(e) {
    $('html, body').removeAttr('style');

    this.template.removeClass('modal-iframe--active');

    setTimeout(() => {
      this.template.remove();
    }, 250);

  },

  getTemplate(url, title) {
    return `
      <div class="modal-iframe">
        <div class="modal-iframe__background" />
        <div class="modal-iframe__wrapper">
          <button type="button" class="modal-iframe__close" />
          <iframe class="modal-iframe__inner" src="${url}" title="${title}" loading="lazy" allow></iframe>
        </div>
      </div>
    `;
  },
};

module.exports = modalIframe;
