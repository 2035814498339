const quickFilters = {
  className: 'quick-filters',

  init() {
    $(`.${this.className}`).each((i, el) => {
      this.initSlider($(el));
    });
  },

  initSlider($el) {
    const $listWrapper = $el.find('.quick-filters__list-wrapper');
    const $list = $el.find('.quick-filters__list');
    const $prevButton = $('<button type="button" class="quick-filters__list-button" data-action="prev"></button>');
    const $nextButton = $('<button type="button" class="quick-filters__list-button" data-action="next"></button>');

    if ($list.prop('scrollWidth') > $list.prop('clientWidth')) {
      $listWrapper.append($prevButton);
      $listWrapper.append($nextButton);

      this.initClickEvents($el);
    }

    const handleScroll = (e = null) => {
      const scrollLeft = e ? $(e.target).scrollLeft() : 0;
      const clientWidth = $list.prop('clientWidth');
      const scrollWidth = $list.prop('scrollWidth');

      if (scrollLeft + clientWidth < scrollWidth) {
        $listWrapper.addClass('quick-filters__list-wrapper--shadow-end');
        $nextButton.show();
      }

      if (scrollLeft > 0) {
        $listWrapper.addClass('quick-filters__list-wrapper--shadow-start');
        $prevButton.show();
      }

      if (scrollWidth === scrollLeft + clientWidth) {
        $listWrapper.removeClass('quick-filters__list-wrapper--shadow-end');
        $nextButton.hide();
      }

      if (scrollLeft === 0) {
        $listWrapper.removeClass('quick-filters__list-wrapper--shadow-start');
        $prevButton.hide();
      }
    };

    handleScroll();
    $list.on('scroll', handleScroll);
  },

  initClickEvents($el) {
    $el.find('.quick-filters__list-button').each((i, btn) => {
      const dataAction = $(btn).data('action');

      if (dataAction === 'next') {
        $(btn).on('click', e => this.handleClickNext($el));
      }

      if (dataAction === 'prev') {
        $(btn).on('click', e => this.handleClickPrev($el));
      }
    })
  },

  handleClickNext($el) {
    const $list = $el.find('.quick-filters__list');

    $list.animate({
      scrollLeft: $list.scrollLeft() + 70,
    }, 250);
  },

  handleClickPrev($el) {
    const $list = $el.find('.quick-filters__list');

    $list.animate({
      scrollLeft: $list.scrollLeft() - 70,
    }, 250);
  },
};

module.exports = quickFilters;
