'use strict';

const progress = require('../../../js/progress');
const util = require('../../../js/util');
const page = require('../../../js/page');

let formSelectorGuest = '#personal-details-form';

function analyticsGuestUser() {
	var smartCustomerRegion = localStorage.getItem('smart_customer_region');
	let category = window.Resources.GTM_STEP_2_GUEST_IDENT_CATEGORY;
	let accion = window.Resources.GTM_STEP_2_GUEST_IDENT_ACTION;
	let tag = window.Resources.GTM_STEP_2_GUEST_IDENT_TAG;
	let encodedRut = btoa($('#dwfrm_personaldetails_rut').val());
	var smartCustomerGARegion = '';
	var smartCustomerGAComuna = '';
	if (!_.isEmpty(smartCustomerRegion)) {
		smartCustomerRegion = JSON.parse(smartCustomerRegion);
		smartCustomerGARegion = smartCustomerRegion.name;
		smartCustomerGAComuna = smartCustomerRegion.comuna.name;
	}
	if (!_.isEmpty($('#dwfrm_personaldetails_rut').val()) && !_.isEmpty(smartCustomerGARegion) && !_.isEmpty(smartCustomerGAComuna)) {
		let ga = {
			'event-interactive-category': category,
			'event-interactive-accion': accion,
			'event-interactive-tag': tag,
			'event-interactive-rut': encodedRut,
			'event-interactive-tipoLogin': '',
			'event-interactive-region': smartCustomerGARegion,
			'event-interactive-comuna': smartCustomerGAComuna,
			'event': 'event-interactive',
		};
		window.dataLayer.push(ga);
	}
}

const initializeSendAnalytics = () => {
    let formPersonalDetail = document.querySelector(formSelectorGuest);
    formPersonalDetail.addEventListener('submit', () => {
        analyticsGuestUser();
    });
};

exports.init = function () {
    initializeSendAnalytics();
    const $form = $(formSelectorGuest);
    if ($form.length === 0) {
        return;
    }
    this.formValidation = $form.validateForm();
    let errorsCount = 0;
    this.formValidation.onErrorUpdated = function () {
        errorsCount = this.hasErrors();
        if (errorsCount) {
            if ($('#dwfrm_profile_customer_conditions')[0].checked) {
                this.$submitButton.prop('disabled', 'disabled');
            } 
        }
        if (errorsCount === 0) {
            if ($('#dwfrm_profile_customer_conditions')[0].checked) {
                this.$submitButton.removeAttr('disabled');
            }
        }
    };
    $('.dwfrm_profile_customer_conditions_checkbox').on('change', () => {
        if ($('#dwfrm_profile_customer_conditions').length) {
            if ($('#dwfrm_profile_customer_conditions')[0].checked) {
                $('#dwfrm_profile_customer_conditions_label').addClass('checkbox-label__label--checked');
                $('#dwfrm_profile_customer_conditions_error').css('display', 'none');
                $('#dwfrm_profile_customer_conditions_checkbox').removeClass('error');
            } else {
                $('#dwfrm_profile_customer_conditions_label').removeClass('checkbox-label__label--checked');
                $('#dwfrm_profile_customer_conditions_error').css('display','');
                $('#dwfrm_profile_customer_conditions_checkbox').addClass('error');
            }
        }
        if (errorsCount) {
            if (!$('#dwfrm_profile_customer_conditions')[0].checked) {
                $('#btn-unregistered-user-continue').prop('disabled', 'disabled');
            }
        }
        if (errorsCount === 0) {
            if ($('#dwfrm_profile_customer_conditions')[0].checked) {
                $('#btn-unregistered-user-continue').removeAttr('disabled');
            } else {
                $('#btn-unregistered-user-continue').prop('disabled', 'disabled');
            }
        }
    });
    $form.find('a[data-action="login"]').on('click', function (e) {
        e.preventDefault();
        progress.show();
        const homeUrl = window.Urls.home;
        let url = util.appendParamToURL(homeUrl, 'showform', 'true');
        page.redirect(url);
    });
};
