const uiSelect = {
	className: 'select-ui',

	init() {
		const $selects = $(`.${this.className}:not(.${this.className}--initialized)`).find(`.${this.className}__inner`);
		$selects.each((i, select) => {
			const $select = $(select);
			this.initSelect($select);
		});
	},

	initSelect($select) {
		const $selectContainer = $select.parents(`.${this.className}`);

		$selectContainer.addClass(`${this.className}--initialized`);
		$select
			.on('change', (e) => {
				const value = $(e.target).val();
				this.setValue($select, value, true);
			})
			.trigger('change');
	},

	setValue($select, value, stopPropagationEvent = false) {
		$select.val(value);

		if (!value) {
			$select.removeClass(`${this.className}__inner--active`);
			return;
		}

		if (value.length) {
			$select.addClass(`${this.className}__inner--active`);
		} else {
			$select.removeClass(`${this.className}__inner--active`);
		}


		if (!stopPropagationEvent) {
			$select.trigger('change');
		}
	},

	addError($select, message) {
		if ($select.parents(`.${this.className}`).hasClass(`${this.className}--success`)) {
			this.uncheck($select);
		}

		this.removeError($select);
		$select.parents(`.${this.className}`).addClass(`${this.className}--error`);
		$select.parents(`.${this.className}__wrapper`).after(`<div class="${this.className}__error">${message}</div>`);
	},

	removeError($select) {
		$select.parents(`.${this.className}`).removeClass(`${this.className}--error`);
		$select.parents(`.${this.className}`).find(`.${this.className}__error`).remove();
	},

	check($select) {
		if ($select.parents(`.${this.className}`).hasClass(`${this.className}--error`)) {
			this.removeError($select);
		}

		$select.parents(`.${this.className}`).addClass(`${this.className}--success`);
	},

	uncheck($select) {
		$select.parents(`.${this.className}`).removeClass(`${this.className}--success`);
	}
};

module.exports = uiSelect;
