'use strict';

var ajax = require('../../ajax');
var dialog = require('../../dialog');

exports.init = function () {
	
	$('.remove-giftcard').on('click', function(e){
		e.preventDefault();
		var deleteUrl = $(e.target).attr('href');
		
		dialog.openConfirmation($('.js-giftcard-container'), Resources.DELETE_GIFTCARD_TITLE, Resources.DELETE_GIFTCARD, function () { window.location.href = deleteUrl; });
	});
	
	if ( $('#restrictions-dialog').length > 0 ) {
		var url = Urls.giftcardRestrictedProducts;
		var restrictedDepartments = $('#restrictions-dialog')[0].innerText;
		
		ajax.load({
			url: url,
			data: {
				departments: restrictedDepartments 
			},
			callback: function(data) {
				dialog.open({
		            html: data,
		            options: {
		            	width: 900,
                        dialogClass: 'restricted-product-dialog',
		                open: function () {
		                	$('#js-restrictions-giftcard-other-method').on('click', function () {
		                		$('.js-giftcard-header-link').trigger('click');
		                		dialog.close();
		                		return false;
		                	});
		                }
		            }
		        });
			}
		});
		
	}

	if ($('.js-giftcard-pay').length) {
		$('.js-giftcard-pay').click(function () {
			$(this).prop('disabled', true);
			window.location.href = $(this).data('href');
		});
	}
};