'use strict';

var compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    addToCart = require('../../js/pages/product/addToCart'),
    progress = require('../progress'),
    page = require('../page'),
    ajax = require('../ajax'),
    sliders = require('./../sliders'),
    util = require('../util'),
    image = require('./product/image'),
    rating = require('../rating');
const urlPath = window.location.pathname;
var isSearch = false;
if(window.searhRefinedByAttribute){
    isSearch = true;
}
const { isEmpty, throttle, memoize } = require('lodash');
window.smartCustomer = require('../smartCustomer');

function fetchCall(settings) {
    return new Promise((resolve, reject) => {
        $.ajax({
            success: function (data) {
                resolve(data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                reject(textStatus);
            },
            ...settings
        });
    });
}
const memoizedFetchCall = memoize(fetchCall, (args) => JSON.stringify(args));

function getInfiniteScrollPanel() {
    var $infiniteScrollPanel = $('#infinite-scroll-panel');
    if ($infiniteScrollPanel.length) return $infiniteScrollPanel;
    return $('<div id="infinite-scroll-panel"></div>');
}

function removeInfiniteScrollPanel() {
    $('#infinite-scroll-panel').remove();
}

function infiniteScrollFinishedMessage() {
    removeInfiniteScrollPanel();

    var $loadingPlaceholder = $('.infinite-scroll-placeholder').first();
    var $panel = getInfiniteScrollPanel();

    $panel.append('<p role="status">'+Resources.INFINITE_SCROLL_LOADING_END+'</p>');
    $panel.insertAfter($loadingPlaceholder.parent());
}

function addInfiniteScrollButton() {
    var $unloadedPlaceholder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]').first();

    if ($unloadedPlaceholder.length) {
        removeInfiniteScrollPanel();

        var $panel = getInfiniteScrollPanel();
        var $loadMoreButton = $('<button type="button" id="infinite-scroll-button" class="btn btn--primary-outline">'+Resources.INFINITE_SCROLL_BTN+'</button>');

        $panel.append($loadMoreButton);
        $panel.insertAfter($unloadedPlaceholder.parent());

        $loadMoreButton.one('click', infiniteScroll);
    } else {
        infiniteScrollFinishedMessage();
    }
}

function fillGridWithSkeletons(columns, productsInPLP) {
    return util.getNumberOfRemainingProductsInGrid(columns, productsInPLP) + columns;
}

function addInfiniteScrollLoading() {
    var $ul = $('#search-result-items');

    var $panel = getInfiniteScrollPanel();
    var $loadingContainer = $('<div id="infinite-scroll-loading"><p role="status" aria-label="'+Resources.INFINITE_SCROLL_LOADING_LABEL+'">'+Resources.INFINITE_SCROLL_LOADING+'...</p></div>');
    $panel.append($loadingContainer);
    $panel.insertAfter($ul.parent());

    if (SitePreferences.IS_ENABLE_SKELETONS) {
        if ($('.onecolumn').length >= 1) {
            for (var i = 1; i <= 3; i++) {
                var $li = $('<li aria-hidden="true" data-skeleton></li>');
                var $skeleton = $('<div class="onecolumn skeleton-onecolumn"></div>');

                var $skeleton_img = $('<div class="skeleton__img skeleton__animated"></div>');
                var $skeleton_description = $('<div class="skeleton__description"></div>');
                var $skeleton_price = $('<div class="skeleton__price"></div>');

                $skeleton_description.append('<div class="skeleton__description1 skeleton__animated"></div>');
                $skeleton_description.append('<div class="skeleton__description2 skeleton__animated"></div>');
                $skeleton_description.append('<div class="skeleton__description3 skeleton__animated"></div>');
                $skeleton_description.append('<div class="skeleton__description4 skeleton__animated"></div>');

                $skeleton_price.append('<div class="skeleton__price1 skeleton__animated"></div>');
                $skeleton_price.append('<div class="skeleton__price2 skeleton__animated"></div>');
                $skeleton_price.append('<div class="skeleton__price3 skeleton__animated"></div>');

                $skeleton.append($skeleton_img);
                $skeleton.append($skeleton_description);
                $skeleton.append($skeleton_price);

                $li.append($skeleton);
                $ul.append($li);
            }
        } else {
            var productsInPLP = $ul.find('> li').length;

            // Three columns variables
            var skeletonsToFill = fillGridWithSkeletons(3, productsInPLP);
            var liClasses = '';
            var columnClasses = 'threecolumn';

            var isFourColumns = $('.threecolumn').length === 0;
            if (isFourColumns) {
                skeletonsToFill = fillGridWithSkeletons(4, productsInPLP);
                liClasses = $ul.find('li').first().attr("class");
                columnClasses = '';
            }

            for (var i = 1; i <= skeletonsToFill; i++) {
                var $li = $('<li class="' + liClasses + '" aria-hidden="true" data-skeleton></li>');
                var $skeleton = $('<div class="' + columnClasses + ' skeleton"></div>');

                var $skeleton_img = $('<div class="skeleton__img skeleton__animated"></div>');
                var $skeleton_content = $('<div class="skeleton__content"></div>');
                var $skeleton_variations = $('<div class="skeleton__variations"></div>');

                $skeleton_content.append('<div class="skeleton__description skeleton__animated"></div>');
                $skeleton_content.append('<div class="skeleton__price skeleton__animated"></div>');

                var randomVariationQty = Math.floor(Math.random() * (4 - 2 + 1)) + 2;
                for (var j = 0; j < randomVariationQty; j++) {
                    $skeleton_variations.append('<div class="skeleton__variation skeleton__animated"></div>');
                }

                $skeleton.append($skeleton_img);
                $skeleton.append($skeleton_content);
                $skeleton_content.append($skeleton_variations);

                $li.append($skeleton);
                $ul.append($li);
            }
        }
    }
}

function removeInfiniteScrollLoading() {
    $('#infinite-scroll-loading').remove();
    $('#search-result-items [data-skeleton]').remove();
}

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1) {
        removeInfiniteScrollPanel();

        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        addInfiniteScrollLoading();

        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            var htmlResponse = $.parseHTML(html);
            var ulTag = null;
            var infiteDiv = null;
            htmlResponse.forEach((ele) => {
                if(ele.tagName?.toLowerCase() === 'ul'){
                    ulTag = ele;
                }
                if(ele.classList?.contains('infinite-scroll-placeholder')){
                    infiteDiv = ele;
                }
            });
            var actualList = $('#search-result-items');
            if(ulTag){
                actualList.append($(ulTag).html());
            }
            if(infiteDiv){
                $('div.search-result-content').append(infiteDiv);
            }
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);
                image.lazyLoad();
                productTile.init();
                addToCart.init();
            },
            complete: function(){
                addInfiniteScrollButton();
                removeInfiniteScrollLoading();
            }
        });
    }
}

function placeRefinementResponse(response) {
	var listing = $('.js-search-result-wrapper');
	var refinements = $('.refinements');

	if (response.find('.js-search-result-wrapper').length > 0 && response.filter('.refinements').length > 0) {
		listing.html(response.find('.js-search-result-wrapper').html());
        refinements.html(response.filter('.refinements').html());
        if (window.screen.width <= 768) {
            $('#secondary').css('z-index', '100');
        }
	} else {
		$('#main').html(response);
    }
}

function setPageTitleForPLP(){
    const searchIsNotRefined = document.getElementById('searchOnlyByCategory') && document.getElementById('searchOnlyByCategory').value === 'true';
    const isCategoryPLP = document.getElementById('isCategoryEvent') && document.getElementById('isCategoryEvent').value === 'true';
    if( searchIsNotRefined || isCategoryPLP ) {
        if(document.getElementById('pageTitle') && document.getElementById('pageTitle').value.length){
            document.title = document.getElementById('pageTitle').value;
        }
        
        if(document.getElementById('pageDescription') && document.getElementById('pageDescription').value.length){
            document.querySelector('meta[name="description"]').content = document.getElementById('pageDescription').value;
        }
    }
}
/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url) {
    if (!url || url === window.location.href) return;
    progress.show($('.search-result-content'));
    var options = {
        url: url,
        callback: function (response) {
            var $response = $(response);
            if($('<div>' + response + '</div>').find('.js-ajax-category-landing').length > 0) {
                page.reload();
                setTimeout(function() {
                    placeRefinementResponse($response)
                }, 5000);
            } else {
                placeRefinementResponse($response)
            }
            compareWidget.init();
            productTile.init();
            progress.hide();
            history.pushState(undefined, '', url);
            //initializeEvents();
            getUrlWithoutRefinement();
            getRefinementsByItem();
            getRefinementsByPriceRange();
            removeTagPriceRange();
            //keepOpenModalProductsRefinement();
            sliders.initializeColorCarousels();
            sliders.initializeBrandSlider();
            sliders.initializeSliders();
            sliders.initializeEinSliders();
            setTimeout(function(){image.lazyLoad(); }, 100);
            window.actionResults.restart();
            window.quickFilters.init();
            window.refinementFilters.init();
            window.smartCustomer.init();
            $('html, body').removeAttr('style');
            init();
        }
    };
    ajax.load(options);
}

function formatCurrency(currency) {
    if(currency.indexOf('.') !== -1) {
        currency = currency.toString().replace(/\./g, '');
    }
    return currency.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

/**
 * @private
 * @function
 * @description resize sortBy select width based on a hidden span width that it filled with select actual value
 */
function resizeSortBySelect() {
    $('#sort-tmp-option').html($('#grid-sort-header option:selected').text());
    $('#grid-sort-header').width($('#sort-tmp-option').width()+16);
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    addToCart.init();
    var $main = $('#main');
    $main.off('click');
    resizeSortBySelect();

    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });
    });

    $main.find('.refinement ul.hidden').removeClass('hidden').hide();

    // handle toggle refinement blocks
    $main.on('click', '.refinement div', function () {
        $(this).toggleClass('ui-state-active')
            .siblings('ul').slideToggle(300);
            // placeHolderInSearchFiltersPlp();
            onClickInputPlp();
    });
    //this function always take care of keeping 
    //the menu option with brand id scrolled . jira PTECH-4547
    $main.ready( function () {
        $('.main-refinement-wrapper').find('h3').each( function( index, element ) {
            var menuId = $('#brand').attr('id');
            if (menuId) {
                if( $(element).attr('id') && (menuId == $(element).attr('id')) ) {
                    $(this).click();
                    return false;
                }
            }
        });
        // handle gtm open/close filter events
        $('.refinement_bar-header').on('click', function () {
            window.dataLayer.push({
                'event-interactive-category': 'filtros apertura',
                'event-interactive-accion': $(this).closest('.ui-state-active').length ? 'cerrar filtro' : 'abrir filtro',
                'event-interactive-tag': $(this).closest('.refinement').find('h3').text().trim(),
                'event': 'event-interactive'
            });
    });
    });
    var helements = $main.find('.refinement h3~ul');
    $.each(helements, function () {
    	if ($(this).find('li').length == 0) {
    		$(this).siblings('h3').hide();
    	}
    });

    
    $main.on('click', '.refinements .close-icon, .pagination a, .breadcrumb-refinement-value a', function (e) {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($('.pt_product-search-noresult').length > 0 ||
            $(this).parents('.category-refinement').length > 0 ||
            $(this).parents('.folder-refinement').length > 0 ||
            $(this).parent().hasClass('unselectable') ||
            $(this).hasClass('refinement-location')
        ) {
            return;
        }

        if (!$(this).hasClass('close-icon')) {
            window.dataLayer.push({
            	'event-interactive-category': 'filtros seleccion', //$(this).closest('.refinement').find('h3').text().trim(),
            	'event-interactive-accion': window.location.pathname,
            	'event-interactive-tag': $(this).closest('.refinement').find('h3').text().trim()+'/'+$(this).text().trim(),
            	'event':'event-interactive'
    		});
        }
        e.preventDefault();
        const url = $(this).hasClass('close-icon') ? $(this).attr('data-href') : $(this).attr('href');
        updateProductListing(url);
    });

    $(document).ready(function (e) {
        if(document.querySelector("div.refinement-content > div.refinement.isSameDayDelivery")){
            document.querySelector("div.refinement-content > div.refinement.isSameDayDelivery").style.display = 'none';
        }
        if(document.querySelector("div.refinement-content > div.refinement.isExported")){
            document.querySelector("div.refinement-content > div.refinement.isExported").style.display = 'none';
        }
        if(document.querySelector("div.refinement-content > div.refinement.isUpdated")){
            document.querySelector("div.refinement-content > div.refinement.isUpdated").style.display = 'none';
        }
        if(document.querySelector("[data-name-attr='communesAvailableForSameDay']")){
            document.querySelector("[data-name-attr='communesAvailableForSameDay']").style.display = 'none';
        }
        initializeFooterMobile();

        if ($('.pt_productsearchresults .pt_product-search-noresult').length > 0) {
            $('.pt_productsearchresults').addClass('no-margin');
		}
		if($('.js-suggested-phrase').length > 0){
			window.dataLayer.push({
				'event-interactive-category':'Search Autocorrections',
				'event-interactive-accion': $('.js-suggested-phrase').data('searchphrase'),
				'event-interactive-tag': $('.js-suggested-phrase').data('suggestedphrase'),
				'event':'event-interactive'
			});
        }
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by #grid-sort-header', function (e) {
        if ($('.js-refinement-for-all').length){
            progress.show();

            const eventUrl = document.location.search.match(/(?:^|[?&])pmid=([^&]*)/);
            const actionStr = eventUrl ? eventUrl.pop().toLowerCase() : $('.current-category').text().trim();

            window.dataLayer.push({
                'event-interactive-category': 'sorting-PLP',
                'event-interactive-accion': actionStr,
                'event-interactive-tag': $(this).find('option:selected').text().trim(),
                'event': 'event-interactive',
            });
            window.location.href = $(this).find('option:selected').val()
        } else {
            e.preventDefault();
            updateProductListing($(this).find('option:selected').val());
        }
    })
    
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
    });

    $main.on('click', '.refinements #close, .refinement-footer', function () {
        $('.main-refinement-wrapper').removeClass('open');
        $('.refinement-header').removeClass('slide-down');
        $('.refinement-footer').css('display', 'none');
        $('html, body').removeAttr('style');
        $('.new-header').removeAttr('style');

        /* restart sticky nav in results page */
        window.actionResults.restart();

        setTimeout(function(){image.lazyLoad(); }, 100);
        
        if (!$(this).hasClass('refinement-footer')) {
            window.dataLayer.push({
                'event-interactive-category': 'filtros apertura',
                'event-interactive-accion': window.location.pathname,
                'event-interactive-tag': 'cerrar',
                'event':'event-interactive'
            });
        }
        init();
    });

    $main.on('click', '.js-refinement-view-products-mobile', function () {
        $('.main-refinement-wrapper').removeClass('open');
        $('.refinement-header').removeClass('slide-down');
        $('html, body').removeAttr('style');
        $('.new-header').removeAttr('style');
        setTimeout(function () { image.lazyLoad(); }, 100);
        init();
    });

    $main.on('click', '.refinements .refinement-header', handleOpenFilters);
    $main.on('click', 'button.js-open-refinement', handleOpenFilters);

    function handleOpenFilters(e) {
        window.dataLayer.push({
            'event-interactive-category': 'filtros apertura', //$(this).closest('.refinement').find('h3').text().trim(),
            'event-interactive-accion': window.location.pathname,
            'event-interactive-tag': 'abrir',
            'event':'event-interactive'
        });
        util.matchMedia(767, checkDeviceWidth, e);
    }

    if ($('.selected-refinements-wrapper .refinement ul').children().length == 0) {
        $('.selected-refinements-wrapper').hide();
    }
    dataLayer.push({'event': 'optimize.activate'});

    deleteRefinement();
    
    var url = window.location.href;
	var currencyPattern = /\B(?=(\d{3})+(?!\d))/g; 
	var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    
	if(document.getElementById("price-refinement") === null && url.indexOf('pmin') !== -1){
        var minPrice = formatCurrency(urlParams.get('pmin').toString().replace(currencyPattern, "%2C").replace('.00',''));
		var maxPrice = formatCurrency(urlParams.get('pmax').toString().replace(currencyPattern, "%2C").replace('.00',''));
        
		document.getElementById("minPrice").value = minPrice;
        document.getElementById("maxPrice").value = maxPrice;
        
        if(document.getElementById("price-range-selected") !== null) {
            document.getElementById("price-range-selected").remove();
        }
        
		var priceLabel = '$' + minPrice.toString().replace(currencyPattern, ".") + '-$' + maxPrice.toString().replace(currencyPattern, ".");
		var ul = document.getElementById("refinements");
		var li = document.createElement("li");
		li.innerHTML = "<div id='price-range-selected' class='clear-refinement'><span>" + priceLabel + "</span><a class='close-icon js-remove-custom-price-range' title='Mostrar todas las opciones'><svg width='14' height='14' xmlns='http://www.w3.org/2000/svg'><path d='M7.635 7.003l5.702-5.702a.45.45 0 10-.639-.638L6.996 6.365 1.302.67a.451.451 0 00-.639.637L6.36 7.003.67 12.692a.451.451 0 00.638.638L6.996 7.64l5.696 5.695a.449.449 0 00.638 0 .45.45 0 000-.638L7.635 7.003z' fill='#4D4D4D' fill-rule='evenodd'/></svg></a></div>";
		ul.appendChild(li);
        removeTagPriceRange();
    }
    
    if(document.getElementById("price-range-selected") !== null) {
        document.getElementById("price-range").removeAttribute("style");
        document.getElementById("price-h3").classList.add("expanded");
        document.getElementById("price-h3").classList.add("ui-state-active");
    }

    $('#minPrice').on('focus', movePriceRange);
    $('#maxPrice').on('focus', movePriceRange);
    expressDeliveryCheckboxChange();
    expressDeliveryFilter();
    samedayDeliveryCheckboxChange();
    samedayDeliveryFilter();
    pickupDeliveryCheckboxChange();
    pickupDeliveryFilter();
    featuredPromotionCheckboxChange();
    featuredPromotionFilter();
    rating.initPodRating();
}

var movePriceRange = function() {
    var userAgent = navigator.userAgent.toLowerCase();
    var isAndroid = userAgent.indexOf("android") > -1;
    if(isAndroid) {
        var elemento = $('.main-refinement-wrapper.open');
        var alto = $(window).height();
        var cp = $(this).offset().top + elemento.scrollTop();
        var paddingTop = 42;
        
        if (cp > (alto / 2)) {
            elemento.animate({ scrollTop: cp - paddingTop, }, 200);
        }
    }
};

function getSeoURL(url){
    url = util.removeParamFromURL(url, 'format');
    var lastChar = url[url.length -1];

    return lastChar === '?' ? url.substring(0, url.length - 1) : url;
}

function initializeFooterMobile(){
    if ($('div .clear-refinement').length > 0) {
        if ($('.js-refinement-selected-text').length > 0 && $('.main-refinement-wrapper').hasClass('open')) {
            $('.refinement-header').css('z-index', '0');
            $('.js-refinement-selected-text').show();
            $('.js-refinement-footer').show();

            if (screen.width <=768) {
                $('.js-refinement-delete-filters-link-mobile').show();
                $('.js-refinement-delete-filters-link').hide();
            } else {
                $('.js-refinement-delete-filters-link').show();
            }
        }
    } else {
        if ($('.js-refinement-selected-text').length > 0) {
            $('.js-refinement-selected-text').hide();
            $('.js-refinement-delete-filters-link').hide();
            $('.js-refinement-footer').hide();
        }
    }
}

function checkDeviceWidth(mq, e) {
	if (mq.matches && e) {
        $('.main-refinement-wrapper').toggleClass('open');
        if ($('.main-refinement-wrapper').hasClass('open')) {
            $('#secondary').css('z-index', '102');

            if(window.screen.width <= 768) {
                $('body').css({'overflow': 'hidden', 'height': $(window).height(),'position': 'fixed', 'width': '100%'});
                $('html').css({'overflow': 'hidden', 'height': $(window).height(),'position': 'fixed', 'width': '100%'});
            }

            $('.pt_productsearchresults').css('overflow', 'hidden');
            $('.new-header').css('z-index', '0');
            $('.refinement-header').css('z-index', '0');
            setTimeout(initializeFooterMobile, 1000);
        } else {
            $('html, body').removeAttr('style');
        }
	} else {
       $('.main-refinement-wrapper').removeClass('open');
       $('html, body').removeAttr('style');
       $('.main-refinement-wrapper').css('height', 'auto');
    }
}

function deleteRefinement(){
    $('.js-delete-refinement').on('click', function (e) {
        if ($('.js-refinement-for-all').length){
            progress.show();
            window.location.href = $(this).attr('data-href');
            e.stopPropagation();
        } else {
            e.preventDefault();
            if (screen.width <=768) {
                progress.show();
                var url = $(this).attr('data-href');
                if(url.indexOf('format=ajax') === -1){
                    url = util.appendParamToURL(url, 'format', 'ajax');
                }

                function renderResults(data) {
                    var parser = new DOMParser();
                    var dataHTML = parser.parseFromString(data, "text/html").querySelector("#category-product-hits");

                    if(dataHTML){
                        $('#category-product-hits').html(dataHTML);
                        url = getSeoURL(url);
                        window.history.pushState(undefined, document.title, url);
                        keepOpenModalProductsRefinement();
                        initializeEvents();
                        getUrlWithoutRefinement();
                        getRefinementsByItem();
                        getRefinementsByPriceRange();
                        initializeFooterMobile();
                        removeTagPriceRange();
                        image.lazyLoad();
                        $('.refinement-header').css('z-index', '0');
                    }
                    progress.hide();

                    if (window.screen.width <= 768) {
                        $('body').css({'overflow': 'hidden', 'height': $(window).height(),'position': 'fixed', 'width': '100%'});
                        $('html').css({'overflow': 'hidden', 'height': $(window).height(),'position': 'fixed', 'width': '100%'});
                    }
                }
                
                memoizedFetchCall({ url }).then(renderResults);
            }
            if (screen.width <=768) {
                e.stopPropagation();
            }
            window.quickFilters.init();
            window.refinementFilters.init();
        }
    });
}

function removeDots(value) {
    if(value.toString().indexOf('.') !== -1) {
        value = value.toString().replace(/\./g, '');
    }

    return value;                                                                                                                            
}

function removeParameterFromUrl(url, parameter) {
    return url
      .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
      .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
}

function removeTagPriceRange() {
    $('.js-remove-custom-price-range').on('click', function(e) {
        var url = e.target.baseURI;

        if (url.indexOf('pmin') !== -1) {
            url = removeParameterFromUrl(url, 'pmin');
        }

        if (url.indexOf('pmax') !== -1) {
            url = removeParameterFromUrl(url, 'pmax');
        }

        e.preventDefault();
        progress.show();

        function renderResults(data) {
            var parser = new DOMParser();
            var dataHTML = parser.parseFromString(data, "text/html").querySelector("#wrapper");
            if (dataHTML) {
                $('#wrapper').html(dataHTML);
                window.history.pushState(undefined, document.title, url);
                initializeEvents();
                keepOpenModalProductsRefinement();
                getUrlWithoutRefinement();
                getRefinementsByItem();
                getRefinementsByPriceRange();
                removeTagPriceRange();
                image.lazyLoad();
            }
            progress.hide();
            window.quickFilters.init();
            window.refinementFilters.init();
            if (window.screen.width <= 768) {
                $('body, html').css({
                    'overflow': 'hidden',
                    'height': $(window).height(),
                    'position': 'fixed',
                    'width': '100%'
                });
            }
        }
        if ($('.js-refinement-for-all').length){
            window.location.href = url
        } else {
            memoizedFetchCall({ url }).then(renderResults);
        }
    });
}

function getRefinementsByPriceRange() {
    $('.js-refinement-price-range').on('click', function (e) {        
        var url = e.target.baseURI;
        var currencyPattern = /\B(?=(\d{3})+(?!\d))/g;                                                                                  
       
        var minPriceByDefault = $('#minPrice').data('minval');
        var maxPriceByDefault = $('#minPrice').data('maxval');
        if (parseInt(removeDots($('#minPrice').val())) > parseInt(removeDots($('#maxPrice').val()))) {
            var minPrice = removeDots($('#maxPrice').val());
            var maxPrice = removeDots($('#minPrice').val());
        } else {
            var minPrice = removeDots($('#minPrice').val());
            var maxPrice = removeDots($('#maxPrice').val());
        }
        if(isNaN(minPrice) && isNaN(maxPrice)){
            $('#input-price-error').html('Ingresa al menos un precio v&aacute;lido');
            $('#minPrice').addClass('input-price-invalid');
            $('#maxPrice').addClass('input-price-invalid');
        } else {
            minPrice = isEmpty(minPrice) ? minPriceByDefault : Number(minPrice);
            maxPrice = isEmpty(maxPrice) ? maxPriceByDefault : Number(maxPrice);
    
            if((minPrice > maxPriceByDefault) || (maxPrice < minPriceByDefault)) {
                $('#input-price-error').html('Ingresa un rango de precios v&aacute;lido');
                $('#minPrice').addClass('input-price-invalid');
                $('#maxPrice').addClass('input-price-invalid');
            } else {
                if (url.indexOf('pmin') !== -1) {
                    url = removeParameterFromUrl(url, 'pmin');
                }
        
                if (url.indexOf('pmax') !== -1) {
                    url = removeParameterFromUrl(url, 'pmax');
                }
        
                var minPriceURL = minPrice.toString().replace(currencyPattern, "%2C") + ".00";
                var maxPriceURL = maxPrice.toString().replace(currencyPattern, "%2C") + ".00";
                if (url.match(/\?./)) {
                    url = url + "&pmin=" + minPriceURL + "&pmax=" + maxPriceURL;
                } else {
                    url = url + "?pmin=" + minPriceURL + "&pmax=" + maxPriceURL;
                }
                

                e.preventDefault();
                progress.show();

                function renderResults(data) {
                    var parser = new DOMParser();
                    var dataHTML = parser.parseFromString(data, "text/html").querySelector("#wrapper");
                    if (dataHTML) {
                        $('#wrapper').html(dataHTML);
                        $('#price-range').removeAttr("style");
                        document.getElementById("price-h3").classList.add("expanded");
                        document.getElementById("price-h3").classList.add("ui-state-active");

                        if(document.getElementById("price-range-selected") !== null) {
                            document.getElementById("price-range-selected").remove();
                        }

                        var priceLabel = '$' + minPrice.toString().replace(currencyPattern, ".") + '-$' + maxPrice.toString().replace(currencyPattern, ".");
                        var ul = document.getElementById("refinements");
                        var li = document.createElement("li");
                        li.innerHTML = "<div id='price-range-selected' class='clear-refinement'><span>" + priceLabel + "</span><a class='close-icon js-remove-custom-price-range' title='Mostrar todas las opciones'><svg width='14' height='14' xmlns='http://www.w3.org/2000/svg'><path d='M7.635 7.003l5.702-5.702a.45.45 0 10-.639-.638L6.996 6.365 1.302.67a.451.451 0 00-.639.637L6.36 7.003.67 12.692a.451.451 0 00.638.638L6.996 7.64l5.696 5.695a.449.449 0 00.638 0 .45.45 0 000-.638L7.635 7.003z' fill='#4D4D4D' fill-rule='evenodd'/></svg></a></div>";
                        ul.appendChild(li);

                        window.history.pushState(undefined, document.title, url);
                        initializeEvents();
                        getUrlWithoutRefinement();
                        getRefinementsByItem();
                        getRefinementsByPriceRange();
                        image.lazyLoad();
                        removeTagPriceRange();
                        $('html, body').removeAttr('style');
                    }
                    progress.hide();
                    window.quickFilters.init();
                    window.refinementFilters.init();
                }
                if ($('.js-refinement-for-all').length){
                    window.location.href = url
                } else {
                    memoizedFetchCall({ url }).then(renderResults);
                    e.stopPropagation();
                }
                
            }
        }
    });
}

function expressDeliveryCheckboxChange(){
    let isExpressOptions = document.querySelectorAll("div.refinement.isExpress > ul > li > :is(a.js-refinement-item, span.js-refinement-item)");
    let existsTrueExpressOption = false;
    for (var i=0; i < isExpressOptions.length; i++) {
        if (isExpressOptions[i].title.includes('true') || isExpressOptions[i].title.includes('24')) {
            existsTrueExpressOption = true;
        }
    }
    if(document.querySelector("div.refinement-content > div.refinement.isExpress") && existsTrueExpressOption){
        $( ".express-delivery-filter" ).click(function() {
            var elements = document.getElementsByClassName('express-delivery-filter');
            for (var i=0; i<elements.length; i++) {
                elements[i].checked = $(this).is(':checked');
                if ($(this).attr('id') == 'express-delivery-filter-render-template') {
                    var elements = document.querySelectorAll("div.refinement.isExpress > ul > li > :is(a.js-refinement-item, span.js-refinement-item)");
                    for (var i=0; i<elements.length; i++) {
                        if (elements[i].title.includes('true') || elements[i].title.includes('24')) {
                            elements[i].click();
                        }
                    }
                }
            }
        });
        if (document.querySelector("div.refinement.isExpress > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)")) {
            var elements = document.getElementsByClassName('express-delivery-filter');
            for (var i=0; i<elements.length; i++) {
                elements[i].checked = true;
            }
        }
        if (document.querySelector("div.refinement-content > div.refinement.isExpress")) {
            document.querySelector("div.refinement-content > div.refinement.isExpress").style.display = 'none';
        }
    } else {
        var elements = document.getElementsByClassName('refinement-express-delivery');
        for (var i=0; i<elements.length; i++) {
            elements[i].style.display = 'none';
        }
        
        
        if(document.querySelector("div.refinement-content > div.refinement-express-delivery")){
            document.querySelector("div.refinement-content > div.refinement-express-delivery").style.display = 'none';
        }
    }
    if(document.querySelector("div.refinement-content > div.refinement.communesAvailableForSameDay")){
        document.querySelector("div.refinement-content > div.refinement.communesAvailableForSameDay").style.display = 'none';
    }
}



function expressDeliveryFilter(){
    $('#express-delivery-filter-refine-bar').change(function(){
        if(document.querySelector("div.refinement-content > div.refinement.isExpress")){
            if($(this).is(':checked')){
                var elements = document.querySelectorAll("div.refinement.isExpress > ul > li.noselected > :is(a.js-refinement-item, span.js-refinement-item)");
                for (var i=0; i<elements.length; i++) {
                    if(elements[i].title.includes('true') || elements[i].title.includes('24')){
                        elements[i].click();
                    }
                }
            } else {
                if(document.querySelector("div.refinement.isExpress > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)")){
                    document.querySelector("div.refinement.isExpress > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)").click();
                }
            }
        }
    });
}


function pickupDeliveryCheckboxChange(){
    let pickUpOptions = document.querySelectorAll("div.refinement.pickUp > ul > li > :is(a.js-refinement-item, span.js-refinement-item)");
    let existsTruePickUpOption = false;
    for (var i=0; i < pickUpOptions.length; i++) {
        if (pickUpOptions[i].title.includes('true') || pickUpOptions[i].title.toLowerCase().includes('retiro')) {
            existsTruePickUpOption = true;
        }
    }
    if(document.querySelector("div.refinement-content > div.refinement.pickUp") && existsTruePickUpOption){
        $( '.pickup-delivery-filter' ).click(function() {
            var elements = document.getElementsByClassName('pickup-delivery-filter');
            for (var i=0; i<elements.length; i++) {
                elements[i].checked = $(this).is(':checked');
                if($(this).attr('id') == 'pickup-delivery-filter-render-template'){
                    var elements = document.querySelectorAll("div.refinement.pickUp > ul > li > :is(a.js-refinement-item, span.js-refinement-item)");
                    for (var i=0; i<elements.length; i++) {
                        if(elements[i].title.includes('true') || elements[i].title.toLowerCase().includes('retiro')){
                            elements[i].click();
                        }
                    }
                }
            }
        });
        if(document.querySelector("div.refinement.pickUp > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)")){
            var elements = document.getElementsByClassName('pickup-delivery-filter');
            for (var i=0; i<elements.length; i++) {
                elements[i].checked = true;
            }
        }
    } else {
        var elements = document.getElementsByClassName('refinement-pickup-delivery');
        for (var i=0; i<elements.length; i++) {
            elements[i].style.display = 'none';
        }
        if(document.querySelector("div.refinement-content > div.refinement-pickup-delivery")){
            document.querySelector("div.refinement-content > div.refinement-pickup-delivery").style.display = 'none';
        }
    }
    if(document.querySelector("div.refinement-content > div.refinement.pickUp")){
        document.querySelector("div.refinement-content > div.refinement.pickUp").style.display = 'none';
    }
}



function pickupDeliveryFilter(){
    $('#pickup-delivery-filter-refine-bar').change(function(){
        if(document.querySelector("div.refinement-content > div.refinement.pickUp")){
            if($(this).is(':checked')){
                var elements = document.querySelectorAll("div.refinement.pickUp > ul > li.noselected > :is(a.js-refinement-item, span.js-refinement-item)");
                for (var i=0; i<elements.length; i++) {
                    if(elements[i].title.includes('true') || elements[i].title.toLowerCase().includes('retiro')){
                        elements[i].click();
                    }
                }
            } else {
                if(document.querySelector("div.refinement.pickUp > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)")){
                    document.querySelector("div.refinement.pickUp > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)").click();
                }
            }
        }
    });
}

function samedayDeliveryCheckboxChange(){
    const smartCustomerRegion = JSON.parse(localStorage.getItem('smart_customer_region'));
    if(document.querySelector("div.refinement-content > div.refinement.communesAvailableForSameDay") && smartCustomerRegion){
        $( ".sameday-delivery-filter" ).click(function() {
            var elements = document.getElementsByClassName('sameday-delivery-filter');
            for (var i=0; i<elements.length; i++) {
                elements[i].checked = $(this).is(':checked');
                if($(this).attr('id') == 'sameday-delivery-filter-refine-bar'){
                    var elements = document.querySelectorAll("div.refinement.communesAvailableForSameDay > ul > li > :is(a.js-refinement-item, span.js-refinement-item)");
                    for (var i=0; i<elements.length; i++) {
                        if(elements[i].title.includes(smartCustomerRegion.comuna.id)){
                            elements[i].click();
                        }
                    }
                }
            }
        });
        if(document.querySelector("div.refinement.communesAvailableForSameDay > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)")){
            var elements = document.getElementsByClassName('sameday-delivery-filter');
            for (var i=0; i<elements.length; i++) {
                elements[i].checked = true;
            }
        }
    } else {
        var elements = document.getElementsByClassName('refinement-sameday-delivery');
        for (var i=0; i<elements.length; i++) {
            elements[i].style.display = 'none';
        }
        if(document.querySelector("div.refinement-content > div.refinement-sameday-delivery")){
            document.querySelector("div.refinement-content > div.refinement-sameday-delivery").style.display = 'none';
        }  
    }
    if(document.querySelector("div.refinement-content > div.refinement.communesAvailableForSameDay")){
        document.querySelector("div.refinement-content > div.refinement.communesAvailableForSameDay").style.display = 'none';
    }
}

function samedayDeliveryFilter(){
    $('#sameday-delivery-filter-refine-bar').change(function(){
        const smartCustomerRegion = JSON.parse(localStorage.getItem('smart_customer_region'));
        if(document.querySelector("div.refinement-content > div.refinement.communesAvailableForSameDay") && smartCustomerRegion && smartCustomerRegion.sameDay){
            if($(this).is(':checked')){
                var elements = document.querySelectorAll("div.refinement.communesAvailableForSameDay > ul > li.noselected > :is(a.js-refinement-item, span.js-refinement-item)");
                for (var i=0; i<elements.length; i++) {
                    if(elements[i].title.toLowerCase().includes(smartCustomerRegion.comuna.id)){
                        elements[i].click();
                    }
                }
            } else {
                if(document.querySelector("div.refinement.communesAvailableForSameDay > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)")){
                    document.querySelector("div.refinement.communesAvailableForSameDay > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)").click();
                }
            }
        }else if(smartCustomerRegion && !smartCustomerRegion.sameDay){
            if(document.querySelector("div.refinement.communesAvailableForSameDay > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)")){
                document.querySelector("div.refinement.communesAvailableForSameDay > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)").click();
            }
        }
    });
}


function featuredPromotionCheckboxChange(){
    if(document.querySelector('div.refinement-content > div.refinement.refinement-promo')){
        $( '.featured-campaign-filter' ).click(function() {
            let featuredPromotionName = this.dataset.campaignId;
            let elements = document.getElementsByClassName('featured-campaign-filter');
            for (let i=0; i<elements.length; i++) {
                elements[i].checked = $(this).is(':checked');
                if($(this).attr('id') == 'featured-campaign-filter-render-template'){
                    let elements = document.querySelectorAll('div.refinement.refinement-promo > ul > li > :is(a.js-refinement-item, span.js-refinement-item)');
                    for (let i=0; i<elements.length; i++) {
                        if(elements[i].title.includes(featuredPromotionName)){
                            elements[i].click();
                        }
                    }
                }
            }
        });
        if(document.querySelector('div.refinement.refinement-promo > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)')){
            let elements = document.getElementsByClassName('featured-campaign-filter');
            for (let i=0; i<elements.length; i++) {
                elements[i].checked = true;
            }
        }
    } else {
        let elements = document.getElementsByClassName('refinement-featured-campaign');
        for (let i=0; i<elements.length; i++) {
            elements[i].style.display = 'none';
        }
        
        
        if(document.querySelector('div.refinement-content > div.refinement-featured-campaign')){
            document.querySelector('div.refinement-content > div.refinement-featured-campaign').style.display = 'none';
        }
    }
}



function featuredPromotionFilter(){
    $('#featured-campaign-filter-refine-bar').change(function(){
        let featuredPromotionName = this.dataset.campaignId;
        if(document.querySelector('div.refinement-content > div.refinement.refinement-promo')){
            if($(this).is(':checked')){
                var elements = document.querySelectorAll('div.refinement.refinement-promo > ul > li.noselected > :is(a.js-refinement-item, span.js-refinement-item)');
                for (var i=0; i<elements.length; i++) {
                    if(elements[i].title.includes(featuredPromotionName)){
                        elements[i].click();
                    }
                }
            } else {
                if(document.querySelector('div.refinement.refinement-promo > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)')){
                    document.querySelector('div.refinement.refinement-promo > ul > li.selected > :is(a.js-refinement-item, span.js-refinement-item)').click();
                }
            }
        }
    });
}

function onClickInputPlp() {
    if (document.body.contains(document.querySelector('.ui-state-active'))) {
        const getIdFilter = document.querySelector('.ui-state-active').nextElementSibling?.id;
        const checkIfExist = document.querySelector('#searchInPlp' + getIdFilter);        

        if (document.body.contains(checkIfExist)) {
            const getTheId = document.querySelector('#searchInPlp' + getIdFilter).getAttribute('id');
            const getTheText = document.querySelector('.ui-state-active').textContent.toLowerCase();
            document.getElementById(getTheId).placeholder = 'Buscar ' + getTheText + '...';
            function searchInFilters() {
                const inputSearchFilter = document.getElementById(getTheId);
                const filter = inputSearchFilter.value.toUpperCase();
                const ulListFilters = document.getElementById(getIdFilter).getAttribute('id');
                const listFilter = document.querySelectorAll('[data-src-plp="' + ulListFilters + '"]');
              
                for (let i = 0; i < listFilter.length; i++) {
                    let filterElement;
                    if (listFilter[i].getElementsByClassName('obflink').length === 0) {
                        filterElement = listFilter[i].getElementsByTagName('a')[0];
                    } else {
                        filterElement = listFilter[i].getElementsByTagName('span')[0];
                    }
                    const txtValue = filterElement.textContent || filterElement.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        listFilter[i].style.display = '';
                    } else {
                        listFilter[i].style.display = 'none';
                    }
                }
            }
            document.getElementById(getTheId).addEventListener('keyup', searchInFilters);
        }
    }
}

// TODO: JD
// El contador evita que se duplique el evento gtm al selecionar tipo de entrega
// Horrible pero funciona, necesito mas tiempo para analizar el motivo =(
let counter = 0;
function getRefinementsByItemHandler(e) {
    const url = $(this).hasClass('obflink') ? $(this).data('refine') : e.currentTarget.href;
    if ($('.js-refinement-for-all').length) {
        e.preventDefault();
        progress.show();
        if ($(this).parents('.category-image-products__refinements-tabs-content').length === 0) {
            if (!$(this).hasClass('quick-filters__list-item-anchor')) {
                if (
                    !$('.pt_product-search-noresult').length > 0 ||
                    !$(this).parents('.folder-refinement').length > 0 ||
                    !$(this).parent().hasClass('unselectable')
                ) {
                    if (!$(this).hasClass('close-icon')) {
                        if (counter === 0) {
                            window.dataLayer.push({
                                'event-interactive-category': 'filtro Seleccion',
                                'event-interactive-accion': urlPath,
                                'event-interactive-tag': $(this).closest('.refinement').find('h3').text().trim() + ': ' + $(this).text().trim(),
                                'event': 'event-interactive',
                                'dimension39': $(this).closest('.refinement').find('h3').attr('id').trim() + ':' + $(this).text().trim(),
                                'dimension45': isSearch
                            });
                            counter++;
                        }
                    }
                }
                const refinementAttributeID = $(this).closest('.refinement').find('h3').get()[0].id;
            }
        }
        if($(this).hasClass('quick-filters__list-item-anchor')) {
            window.dataLayer.push({
                'event-interactive-category': 'filtro rapido',
                'event-interactive-accion': urlPath,
                'event-interactive-tag': "" + $(this).data('attribute') + ":" + $(this).data('value'),
                'event': 'event-interactive'
            });
        }
        window.location.href = url;
        e.stopPropagation();
    } else {
        const urlFilter = new URL(url);
        const isExpress = urlFilter.search.includes('isExpress');
        const isSameDay = urlFilter.search.includes('AvailableForSameDay');
    
        let tracerName = 'Filter PLP';
        if (isExpress) {
            tracerName = 'Filter PLP isExpress';
        }
        if (isSameDay) {
            tracerName = 'Filter PLP isSameDay';
        }
    
        newrelic.setCurrentRouteName(urlFilter.pathname);
    
        const filterTracer = newrelic
            .interaction()
            .actionText('change')
            .setName(tracerName)
            .createTracer('RefinmentsPLPTracer');
        
        if ($(this).parents('.category-image-products__refinements-tabs-content').length === 0) {
            if (!$(this).hasClass('quick-filters__list-item-anchor')) {
                if (
                    !$('.pt_product-search-noresult').length > 0 ||
                    !$(this).parents('.folder-refinement').length > 0 ||
                    !$(this).parent().hasClass('unselectable')
                ) {
                    if (!$(this).hasClass('close-icon')) {
                        window.dataLayer.push({
                            'event-interactive-category': 'filtro Seleccion',
                            'event-interactive-accion': urlPath,
                            'event-interactive-tag': $(this).closest('.refinement').find('h3').text().trim() + ': ' + $(this).text().trim(),
                            'event': 'event-interactive',
                            'dimension39': $(this).closest('.refinement').find('h3').attr('id').trim() + ':' + $(this).text().trim(),
                            'dimension45': isSearch
                        });
                    }
                }
                const refinementAttributeID = $(this).closest('.refinement').find('h3').get()[0].id;
            }
        }
        if($(this).hasClass('quick-filters__list-item-anchor')) {
            window.dataLayer.push({
                'event-interactive-category': 'filtro rapido',
                'event-interactive-accion': urlPath,
                'event-interactive-tag': "" + $(this).data('attribute') + ":" + $(this).data('value'),
                'event': 'event-interactive'
            });
        }
        e.preventDefault();
        progress.show();
        const menuOpen = $('.main-refinement-wrapper').hasClass('open');
        var quickFilterElement = false;
        if($(this).hasClass('quick-filters__list-item-anchor')){
            quickFilterElement = $(this);
        }
    
        function renderResults(data) {
            var parser = new DOMParser();
            var dataHTML = parser.parseFromString(data, "text/html").querySelector("#wrapper");
            if (dataHTML) {
                $('#wrapper').html(dataHTML);
                window.history.pushState(undefined, document.title, url);
                if (menuOpen) {
                    keepOpenModalProductsRefinement();
                }
                initializeEvents();
                getUrlWithoutRefinement();
                getRefinementsByItem();
                getRefinementsByPriceRange();
                removeTagPriceRange();
                image.lazyLoad();
    
                if (document.getElementById('searchOnlyByCategory') && document.getElementById('searchOnlyByCategory').value.length) {
                    if (document.getElementById('searchOnlyByCategory').value === "true") {
                        if (document.getElementById('pageTitle') && document.getElementById('pageTitle').value.length) {
                            document.title = document.getElementById('pageTitle').value;
                        }
    
                        if (document.getElementById('pageDescription') && document.getElementById('pageDescription').value.length) {
                            document.querySelector('meta[name="description"]').content = document.getElementById('pageDescription').value;
                        }
                    } else {
                        var title = parser.parseFromString(data, "text/html").querySelector('title');
    
                        if (title && title.innerText && title.innerText.length) {
                            document.title = title.innerText;
                        }
    
                        var description = parser.parseFromString(data, "text/html").querySelector('meta[name="description"]');
    
                        if (description && description.content && description.content.length) {
                            document.querySelector('meta[name="description"]').content = description.content;
                        }
                    }
                }
            }
            progress.hide();
            if (quickFilterElement != false) {
                if (!quickFilterElement.hasClass('quick-filters__list-item-anchor')) {
                    $('.main-refinement-wrapper').find('h3').each(function (index, element) {
                        // jira PTECH-4547
                        var menuId = $(this).find('input[name="menuId"]').first().val();
                        if ($(this).get()[0].id == refinementAttributeID && (menuId != refinementAttributeID)) {
                            $(this).click();
                        }
                    });
                }
            }
            if (window.screen.width <= 768) {
                if (menuOpen) {
                    $('body, html').css({
                        'overflow': 'hidden',
                        'height': $(window).height(),
                        'position': 'fixed',
                        'width': '100%'
                    });
                }
            }
            window.smartCustomer.init();
            window.quickFilters.init();
            window.refinementFilters.init();
            // selected mac tab
            const selectedMac = e.currentTarget.closest('.category-image-products__refinements-tabs-panel');
            window.tabs.init(selectedMac);
        }
    
        memoizedFetchCall({ url })
            .then(renderResults)
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                newrelic.interaction().save();
                filterTracer();
            });
    
        e.stopPropagation();
    }
}

function getRefinementsByItem() {
    $('.js-refinement-item').off('click', getRefinementsByItemHandler);
    $('.js-refinement-item').on('click', getRefinementsByItemHandler);
}

function getRootRefinementsPage(url){
    progress.show();

    function renderResults(data) {
        var parser = new DOMParser();
        var dataHTML = parser.parseFromString(data, "text/html");
        var refinementList = dataHTML.querySelectorAll('.close-icon');

        if (refinementList) {
            if (refinementList.length > 0) {
                var refinementUrl = $(refinementList[0]).attr('data-href');
                getRootRefinementsPage(refinementUrl);
            } else if (refinementList.length === 0) {
                url = getSeoURL(url);
                window.location.href = url;
            }
        } else {
            progress.hide();
        }
    }

    memoizedFetchCall({ url }).then(renderResults);
}

function deleteAllRefinements(){
    var refinementList = document.querySelectorAll('.close-icon');

    if(refinementList && refinementList.length > 0){
        var url = $(refinementList[0]).attr('data-href');
        getRootRefinementsPage(url);
    }
    setTimeout(function(){image.lazyLoad(); }, 100);
}

function getUrlWithoutRefinement(){
    $('.js-refinement-delete-filters-link').on('click', function (e) {
        e.preventDefault();
        deleteAllRefinements();
    });

    $('.js-refinement-delete-filters-link-mobile').on('click', function (e) {
        e.preventDefault();
        deleteAllRefinements();
        init();
    });
}

function keepOpenModalProductsRefinement(){
    if (screen.width <=768) {
        $('.main-refinement-wrapper').toggleClass('open');
        if ($('.main-refinement-wrapper').hasClass('open') === false) {
        	$('#secondary').css('top', 64);
        } else {
            $('html, body').removeAttr('style');
        }
	} else {
       $('.main-refinement-wrapper').removeClass('open');
       $('html, body').removeAttr('style');
       $('.main-refinement-wrapper').css('height', 'auto');
    }
}

function init() {
    var noEvent = document.location.search.match(/(?:^|[?&])cgid=([^&]*)/);

    // if (typeof(window.pageType) == 'undefined') {
    //     window.dataLayer.push({
    //         'userID': userIdDataLayer,
    //         'page': window.location.pathname, 
    //         'pagename' : window.location.pathname,
    //     });
    //     window.dataLayer.push({
    //         'event':'page-virtual',
    //     });
    // } else {
    //     if(window.pageType.type != 'Search') {
    //         if (noEvent) {
    //             window.dataLayer.push({
    //                 'userID': userIdDataLayer,
    //                 'page': window.location.search, 
    //                 'pagename' : window.pageType.type,
    //             });
    //         } else {
    //             window.dataLayer.push({
    //                 'userID': userIdDataLayer,
    //                 'page': window.location.pathname, 
    //                 'pagename' : window.pageType.type,
    //             });
    //         }
    //         window.dataLayer.push({
    //             'event':'page-virtual',
    //         });
    //     }
    // }

    var breadCrumb = document.querySelector('.breadcrumb.PLPbreadcrumbs');
    var eventUrl = document.location.search.match(/(?:^|[?&])pmid=([^&]*)/);
    // if (breadCrumb) {
    //     if (productListBreadCrumb.innerText == '' && eventUrl) {
    //         window.dataLayer.push({
    //             'userID': userIdDataLayer,
    //             'page': '/search?pmid=' + eventUrl.pop().toLowerCase(),
    //             'pagename' : 'PLP - Event',
    //         });
    //         window.dataLayer.push({
    //             'event':'page-virtual',
    //         });
    //     }
    // }
    
    $('.PLPbreadcrumbs').on('click', function (e) {
        window.dataLayer.push({
            'event-interactive-category': 'breadcrumb',
            'event-interactive-accion':  'click: PLP',
            'event-interactive-tag': 'PLP - Category',
            'event': 'event-interactive'
        });
    });

    compareWidget.init();
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        addInfiniteScrollButton();
    }
    productTile.init();
    setPageTitleForPLP();
    initializeEvents();
    getUrlWithoutRefinement();
    expressDeliveryCheckboxChange();
    expressDeliveryFilter();
    samedayDeliveryCheckboxChange();
    samedayDeliveryFilter();
    pickupDeliveryCheckboxChange();
    pickupDeliveryFilter();
    featuredPromotionCheckboxChange();
    featuredPromotionFilter();
    getRefinementsByItem();
    getRefinementsByPriceRange();
    removeTagPriceRange();
    sliders.initializeBrandSlider();

    /*addEventListener('load', function(){
        window.dataLayer.push({
            'event': 'page-virtual',
        });
    }, false);*/

    if (SitePreferences.IS_ENABLE_PRODUCT_IN_VIEWPORT) {
        addEventListener('load', isProductInViewport, false);
        addEventListener('click', throttle(isProductInViewport, 200), false);
        addEventListener('scroll', throttle(isProductInViewport, 500), false);
        addEventListener('resize', throttle(isProductInViewport, 500), false);
    }

    var elements = document.getElementsByClassName('refinement seller');
    for (var i=0; i<elements.length; i++) {
        elements[i].style.display = 'none';
    }
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var param = urlParams.get('issellerplp');
    if(param == 1){
        var categoryrefinements = document.getElementsByClassName('refinement category-refinement');
        for (var i=0; i<categoryrefinements.length; i++) {
            categoryrefinements[i].style.display = 'none';
        }
    }
};


exports.init = function () {
    init();
};

// Analytics plpProductView
var viewedProducts = viewedProducts || [];
var seenProducts = seenProducts || [];
var uploadedImpressionsQty = 0;
var productListPath;
var searchedProductList = document.querySelector('.breadcrumb.PLPbreadcrumbs .searched-term');
var productListBreadCrumb = document.querySelector('.breadcrumb.PLPbreadcrumbs');
var eventUrl = document.location.search.match(/(?:^|[?&])pmid=([^&]*)/);

if (searchedProductList) {
    productListPath = 'PLP Search: ' + searchedProductList.title.toLowerCase();
} else if (productListBreadCrumb) {
    if (productListBreadCrumb.innerText != '') {
        var breadcrubElements = productListBreadCrumb.getElementsByClassName('breadcrumb-element');
        if (document.getElementsByClassName('breadcrumb')[0].getElementsByClassName('breadcrumb-result-text')[0] != undefined) {
            var breadcrumbEnd = productListBreadCrumb.getElementsByClassName('breadcrumb-result-text')[0].innerText;
        } else {
            var breadcrumbEnd = '';
        }
        var plpPath = '';
        if (breadcrubElements) {
            for (var i = 0; i < breadcrubElements.length; i++) {
                plpPath = plpPath + breadcrubElements[i].innerText + '/';
            }
        }
        var productListPath = 'PLP Category: ' + plpPath + breadcrumbEnd;
    } else if (productListBreadCrumb.innerText == '' && eventUrl) {
        productListPath = 'PLP Event: ' + eventUrl.pop().toLowerCase();
    } else if (productListBreadCrumb.innerText == '') {
        productListPath = 'PLP URL: ' + document.location.pathname.slice(1);
    }
}

if (window.location.href.indexOf('#sdr') !== -1) {
    window.productListPath = 'PLP Redirect - ' + localStorage.getItem('search-text');
} else {
    window.productListPath = productListPath;
}

/**
 * @function
 * @description Calculates how many impressions to push per batch based on the current grid
 * @param {NodeListOf<Element>} productList Total products listed on current PLP page
 */
function getImpressionsQty(productList) {
    var columnsQty = util.getColumnsQty(productList);

    if (columnsQty === 1) {
        return 3;
    } else {
        return columnsQty;
    }
}

function isProductInViewport() {
    var productList = document.querySelectorAll('.js-plp-result .product-tile');
    var currentPage = document.location.href;
    if (productList) {
        for (i=0;i<productList.length;i++) {
            var el = productList[i];
            if (!seenProducts.includes(currentPage+'-'+el.dataset.itemid)) {
                var rect = el.getBoundingClientRect();
                var startPos = 0,productPos;
                if (document.location.search.match(/(?:^|[?&])start=([^&]*)/)) {
                    startPos = parseInt(document.location.search.match(/(?:^|[?&])start=([^&]*)/).pop());
                }
                productPos = parseInt(el.parentNode.closest('li').dataset.position) + startPos;
                if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight ||
                document.documentElement.clientHeight) + rect.height*0.6 &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
                    var productInfo = JSON.parse(el.dataset.product);
                    delete productInfo.image_data;
                    productInfo['dimension30'] = productInfo.brand;
                    productInfo.position = productPos;

                    if(window.location.href.indexOf('#sdr') !== -1) {
                        productInfo.list = 'PLP Redirect - ' + localStorage.getItem('search-text');
                    } else {
                        productInfo.list = productListPath;
                    }
                    
                    viewedProducts.push(productInfo);
                    seenProducts.push(currentPage+'-'+el.dataset.itemid);
                    window.viewedProducts = viewedProducts;
                }

                if (viewedProducts.length) {
                    var impressionsQty = getImpressionsQty(productList);
                    var rowImpressions = [];

                    if (viewedProducts.length >= impressionsQty) {
                        rowImpressions = viewedProducts.splice(0, impressionsQty);
                    } else if (productList.length - uploadedImpressionsQty === viewedProducts.length) {
                        rowImpressions = viewedProducts.splice(0, viewedProducts.length);
                    }

                    if (rowImpressions.length) {
                        rowImpressions.sort((a, b) => {
                            return a.position - b.position;
                        });
                        dataLayer.push({
                            "event": "plpProductView",
                            "ecommerce": {
                                "currencyCode": "CLP",
                                "impressions": rowImpressions
                            }
                        });
                        uploadedImpressionsQty += rowImpressions.length;
                    }

                    window.viewedProducts = viewedProducts;
                }
            }
        }
    } 
}
// END Analytics plpProductView
