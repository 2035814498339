'use strict';

const util = require('./util');
const { throttle } = require('lodash');

/**
 * @function
 * @description AppsFlyer Smart Banner code snippet.
 */
function smartBannerAppsFlyerSnippet() {
    !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "6fd6e5d3-f86b-49dc-8e0c-d178515d9c78"}})
    AF('banners', 'showBanner', { additionalParams: { deep_link_value: window.location.href, af_ad: window.location.pathname}});
}

/**
 * @function
 * @description Move the Add to Cart wrapper above the Smart Banner to avoid overlapping.
 */
function moveAddCartAboveSmartBanner() {
    const smartBannerWrapper = document.querySelector('[id^="smart-style"]')?.closest('.responsive-wrapper');
    const addCartWrapper = document.querySelector('.message-add-cart')?.closest('.box-boton');
    const addCartBottomPosition = smartBannerWrapper ? smartBannerWrapper.offsetHeight : 0;

    if (addCartWrapper) addCartWrapper.style.bottom = addCartBottomPosition + 'px';
}

/**
 * @function
 * @description Initializes Smart Banner logic
 */
exports.init = function () {
    const is_ios = typeof navigator.standalone === 'boolean';
    const is_safari = /Version\/[\d.]+.*Safari/i.test(navigator.userAgent);
    const is_mobile = util.isMobile();
    const is_pdp = !!document.querySelector('body.page-product');

    // Display the AppsFlyer Smart Banner in all phone browsers except Safari.
    if (!(is_ios && is_safari) && is_mobile) {
        smartBannerAppsFlyerSnippet();
    }

    if (is_pdp && is_mobile) {
        moveAddCartAboveSmartBanner();
        addEventListener('scroll', throttle(moveAddCartAboveSmartBanner, 1000), false);
    }
};
