const countdown = {
  selector: '.countdown',

  init() {
    $(() => {
      $(this.selector).each((i, el) => {
        let timeout = $(el).data('timeout');
        if (timeout) {
          this.renderCountdown(el, timeout);
        }
      });
    });
  },

  renderCountdown(el, timeout) {
    const date = timeout.split(' ');
    timeout = date[0].split('/');
    const hours = date[1].split(':')[0];
    const minutes = date[1].split(':')[1];
    const day = timeout[0] || 1;
    const month = timeout[1] > 0 ? timeout[1] - 1 : 0;
    const year = timeout[2] || 2000;

    let timeoutDate = new Date(year, month, day);
    timeoutDate.setHours(hours, minutes);

    const interval = setInterval(() => this.handleInterval(interval, timeoutDate, el), 1000);
  },

  handleInterval(interval, timeoutDate, el) {
    // Get today's date and time
    const now = new Date().getTime();
    const distance = timeoutDate - now;
    const data = this.getTime(distance);

    const html = this.getTemplate({
      hours: {
        label: 'HR',
        value: data.hours,
      },
      minutes: {
        label: 'MIN',
        value: data.minutes,
      },
      seconds: {
        label: 'SEG',
        value: data.seconds,
      },
    });

    $(el).html(html);
  
    if (distance < 0) {
      clearInterval(interval);
      const phrase = $(el).data('finish') || '';
      $(el).html(this.getFinishTemplate(phrase));
    }
  },

  getTime(milliseconds) {
    const h = Math.floor(milliseconds / (60 * 60 * 1000));
    const m = Math.floor((milliseconds / (60 * 1000)) % 60);
    const s = Math.floor((milliseconds / 1000) % 60);
    
    return {
      hours: h < 10 ? `0${h}`: h,
      minutes: m < 10 ? `0${m}`: m,
      seconds: s < 10 ? `0${s}`: s,
    };
},

  getTemplate({hours, minutes, seconds}) {
    return `
      <div class="countdown__inner">
        <div class="countdown__group countdown__group--hours">
          <div class="countdown__value">
            ${hours.value}
          </div>
          <div class="countdown__term">
            ${hours.label}
          </div>
        </div>
        <div class="countdown__separation">:</div>
        <div class="countdown__group countdown__group--minutes">
          <div class="countdown__value">
            ${minutes.value}
          </div>
          <div class="countdown__term">
            ${minutes.label}
          </div>
        </div>
        <div class="countdown__separation">:</div>
        <div class="countdown__group countdown__group--seconds">
          <div class="countdown__value">
            ${seconds.value}
          </div>
          <div class="countdown__term">
            ${seconds.label}
          </div>
        </div>
      </div>
    `;
  },

  getFinishTemplate(phrase) {
    return `
      <div class="countdown__finish">
        ${phrase}
      </div>
    `;
  },
};

module.exports = countdown;
