'use strict'

// TODO: CCMR-53899 OPTIMIZAR POR FAVOR
const tabs = {
    init(selected = false) {
        const tabs = document.querySelectorAll('.category-image-products__refinements-item');
        const tabLinks = document.querySelectorAll('.category-image-products__refinements-tabs p');
        const tabPanels = document.querySelectorAll('.category-image-products__refinements-tabs-panel');
       
        if (selected) {
            const selectedIndex = selected.getAttribute('data-index');

            const tab = [...tabs].filter(el => el.getAttribute('data-index') == selectedIndex);
            tab[0].classList.add('category-image-products__refinements-item--active');

            const panel = [...tabPanels].filter(el => el.getAttribute('data-index') == selectedIndex);
            panel[0].classList.add('category-image-products__refinements-tabs-panel--active');
        } else {
            const defaultItem = document.querySelector('.category-image-products__refinements-item');
            const defaultPanel = document.querySelector('.category-image-products__refinements-tabs-panel');
            if(defaultItem){
                defaultItem.classList.add('category-image-products__refinements-item--active');
            }
            if(defaultPanel){
                defaultPanel.classList.add('category-image-products__refinements-tabs-panel--active');
            }
        }

        for(let el of tabLinks) {
            el.addEventListener('click', e => {
                e.preventDefault();
    
                document
                    .querySelector('.category-image-products__refinements-item--active')
                    .classList.remove('category-image-products__refinements-item--active');
                document
                    .querySelector('.category-image-products__refinements-tabs-panel--active')
                    .classList.remove('category-image-products__refinements-tabs-panel--active');
    
                const parentListItem = el.parentElement;
                parentListItem.classList.add('category-image-products__refinements-item--active');
                const index = [...parentListItem.parentElement.children].indexOf(parentListItem);
    
                const panel = [...tabPanels].filter(el => el.getAttribute('data-index') == index);
                panel[0].classList.add('category-image-products__refinements-tabs-panel--active');
            });
        }
    },
}

module.exports = tabs;