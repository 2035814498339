'use strict';
const dialog = require('../../dialog');
const dataLayer = require('../../dataLayer.js');
const _ = require('lodash');
const progress = require('../../progress');
const ajax = require('../../ajax');
const minicart = require('../../minicart');
const page = require('../../page');
const util = require('../../util');
const newLogin = require('../../new-login');
let deviceType = '';
let smartCustomerAddress = ''
const sampling = {
    update: function (targetURL, params) {
        const $target = $('#dialog-container');
        ajax.load({
            url : targetURL,
            data: params,
            target: $target,
            callback: function (data) {
                samplingpdp.initializeSamplingEvents(deviceType);
                if (params.type === 'add') {
                    $('.js-continue-to-cart').removeAttr('disabled');
                } else {
                    $('.js-continue-to-cart').attr('disabled');
                }
            }
        });
    },
    addBonusProduct: function (item) {
        this.update(Urls.addBonusProduct, {'sku': item.attr('data-id'), source: 'pdp', type: 'add'});
        $('html').removeClass('ui-scroll-disabled');
    },
    removeProductFromCart: function (item) {
        this.update(Urls.cartRemoveProduct, {'lineItemUUID': item.attr('data-uuid'), source: 'pdp', type: 'remove'});
    }
};

let samplingpdp = {
    initSampling: (pid) => {
        if (window.matchMedia('(min-width: 719px)').matches) {
            samplingpdp.execSamplingModal(pid, 'desktop')
        } else {
            samplingpdp.execSamplingModal(pid, 'mobile')
        }
    },
    execSamplingModal: (productID, type) => {
        const mustOpenSamplingModal = samplingpdp.callShowSamplingModal(productID);
        if (mustOpenSamplingModal) {
            samplingpdp.showSamplingModal(type);
        } else {
            if (type === 'desktop') {
                minicart.showAjax(true);
            } else {
                add2CartModule.startModalAddedToCart();
            }
            
        }
    },
    
    callShowSamplingModal:(productID) => {
        let mustOpenSamplingModal = false;
        ajax.getJson({
            url: Urls.showSamplingModalInPDP,
            data: { 'sku': productID },
            async: false,
            callback: function (data) {
                if (data !== undefined || data !== null) {
                    if (data.success) {
                        mustOpenSamplingModal = data.mustOpenSamplingModal;
                    }
                }
            }
        });
        return mustOpenSamplingModal;
    },
    showSamplingModal: (type) => {
        dialog.open({
            url: Urls.productSamplingCart,
            options: {
                title: '¡Tienes 1 muestra de regalo!',
                draggable: false,
                autoOpen: false,
                responsive: true,
                modal: true,
                fluid: true,
                dialogClass: 'ui-dialog-full',
                open: function () {
                    //$('.ui-dialog-titlebar').prepend(Resources.BONUS_PRODUCT_SVG);
                    samplingpdp.initializeSamplingEvents(type);
                },
                close: function () {
                    //document.getElementById('svg-gift').remove();
                    //Validar accion 'Añadir al carro' o 'Comprar ahora' 
                    //$('#svg-gift').remove();
                    if (type === 'desktop') {
                        $('.bonus-item-promo').off('click');
                        minicart.showAjax(true);
                    } else {
                        $('.bonus-item-promo').off('click');
                        add2CartModule.startModalAddedToCart();
                    }
                },
            }
        });
    },
    initializeSamplingEvents: (type) => {
        $('#content-bonus-product-availible').addClass('cart-promo__body-show');
        deviceType = type;
        if (type === 'desktop') {
            samplingpdp.loadCarousel();
        }
    
        $('.bonus-item-promo').on('click', '.js-add-bonus-product', function (e) {
            e.preventDefault();
        
            $(this).empty().addClass('btn--loading');
            $(this).append($('<div>'));
            $(this).append($('<div>'));
            $(this).append($('<div>'));
        
            sampling.addBonusProduct($(this).closest('.js-add-bonus-product'));
        
            $('html').removeClass('ui-scroll-disabled');
    
            const skuFather = $('#productID').val();
            const skuSampling = $(this).attr('data-id');
            const tag = `${skuFather} / ${skuSampling}`;
            window.dataLayer.push({
                'event-interactive-category': Resources.GTM_PDP_SAMPLING_CATEGORY, 
                'event-interactive-accion': Resources.GTM_PDP_SAMPLING_ACTION_ADD, 
                'event-interactive-tag': tag,
                'event': 'event-interactive'
            });
        });
        
        $('.bonus-item-promo').on('click', '.js-remove-product', function (e) {
            e.preventDefault();
    
            $(this).empty().addClass('btn--loading');
            $(this).append($('<div>'));
            $(this).append($('<div>'));
            $(this).append($('<div>'));
    
            sampling.removeProductFromCart($(this).closest('.js-cart-lineitem'));
            $('html').removeClass('ui-scroll-disabled');
        });
        
        $('.bonus-item-promo').on('click', '.js-remove-bonus-product', function (e) {
            const skuFather = $('#productID').val();
            const skuSampling = $(this).attr('data-id');
            const tag = `${skuFather} / ${skuSampling}`;
            window.dataLayer.push({
                'event-interactive-category': Resources.GTM_PDP_SAMPLING_CATEGORY, 
                'event-interactive-accion': Resources.GTM_PDP_SAMPLING_ACTION_REMOVE, 
                'event-interactive-tag': tag,
                'event': 'event-interactive'
            });
        });
    
        $('.bonus-item-promo').on('click', '.js-continue-to-cart', function (e) {
            const skuFather = $('#productID').val();
            const skuSampling = $('#remove-product-cart').attr('data-id');
            const tag = `${skuFather} / ${skuSampling}`;
            window.dataLayer.push({
                'event-interactive-category': Resources.GTM_PDP_SAMPLING_CATEGORY, 
                'event-interactive-accion': Resources.GTM_PDP_SAMPLING_ACTION_ADD_CART, 
                'event-interactive-tag': tag,
                'event': 'event-interactive'
            });
            dialog.close();
        });
    
        $('.bonus-item-promo').on('click', '.slick-arrow', function (e) {
            const arrow = $(this).attr('aria-label').toLowerCase();
            const tag = `Arrow ${arrow === 'previous' ? 'Left' : 'Right'}`;
            window.dataLayer.push({
                'event-interactive-category': Resources.GTM_PDP_SAMPLING_CATEGORY, 
                'event-interactive-accion': Resources.GTM_PDP_SAMPLING_ACTION_CLICK_ARROW, 
                'event-interactive-tag': tag,
                'event': 'event-interactive'
            });
        });
    },
    loadCarousel:() => {
        $('.cart-promo__product-container-slider').not('.slick-initialized').slick({
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            draggable: false
        });
    }
}
let sizeGuideModal = {
    init: () => {
        if($('#perseo-size-chart-button').length){
            window.Perseo.mountWithProps({
                name: 'PdpSizesGuideModalButton',
                selector: 'perseo-size-chart-button',
                departmentId: window.productJSON.departmentId,
                subDepartmentId: window.productJSON.subDepartmentId,
                claseId: window.productJSON.claseId,
                brand: window.productJSON.brand,
                sku: window.productJSON.id,
            });
        }
    }
}
let readySize = {
    init: () => {
        const showReadySizeButton = window.perseoData.switches.perseoGlobal.showReadySizeButton;
        const isBrandIncluded = $('#Codigo_producto').length > 0;

        if(showReadySizeButton && isBrandIncluded) {
            $('#ready-size-button').attr('style', 'display: flex');
        }
    }
}
const pricesContainer = {
    init: () => {
        if($('#perseo-pdp-prices-container').length){
            window.Perseo.mountWithProps({
                name: 'PricesContainer',
                selector: 'perseo-pdp-prices-container',
                rawSfccData: window.productJSON,
            });
        }
    }
}
let liftingpdp = {
    init: () => {
        $(document).ready( function() {
            readySize.init();
            liftingpdp.dtoProductData();
            legacyFunctions.init();
            add2CartModule.init();
            variationAttrModule.init();
            imageGalleryModule.init();
            productOptionsModule.init();
            liftingpdp.showOutOfStock();
            liftingpdp.updatePrice();
            liftingpdp.feedPromotions();
            liftingpdp.printSku();
            liftingpdp.printModelInfo();
            liftingpdp.lastItemFlagAndPresale();
            wishlist.init();
            levelServicePDP.init();
            shareModal.init(); 
            ratingAndReviews.init();
            shareModal.init();
            sizeGuideModal.init();
            AnalyticsEvents.init();
            pricesContainer.init();
        });
    },
    dtoProductData: () => {
        if(window.productJSON.hasOwnProperty('variants') && window.productJSON.hasOwnProperty('variation_values')){
            window.productJSON.variation_attributes.forEach((element) => {
                if(element.id == 'color'){
                    if(!element.values.some((value) => {
                        if(value.value == window.productJSON.variation_values.color){
                            return true;
                        }
                    })){
                        delete window.productJSON.variation_values.color;
                    }
                }
                if(element.id == 'size'){
                    if(!element.values.some((value) => {
                        if(value.value == window.productJSON.variation_values.size){
                            return true;
                        }
                    })){
                        delete window.productJSON.variation_values.size;
                    }
                }
                if(element.id == 'contenido'){
                    if(!element.values.some((value) => {
                        if(value.value == window.productJSON.variation_values.contenido){
                            return true;
                        }
                    })){
                        delete window.productJSON.variation_values.contenido;
                    }
                }
            });
            window.productJSON.variation_attributes.forEach((element) => {
                if(element.values.length < 2){
                    if(element.id == 'size'){
                        delete window.productJSON.variation_values.size;
                    }

                    if(element.id == 'color'){
                        delete window.productJSON.variation_values.color;
                    }

                    if(element.id == 'contenido'){
                        delete window.productJSON.variation_values.contenido;
                    }
                }
            });
            if(Object.keys(window.productJSON.variation_values).length == 0){
                delete window.productJSON.variation_values
            }
        }  
    },
    printModelInfo: () => {
        if(window.productJSON.infoModelo){
            $('.js-model-info-container').show();
            $('.js-model-info-label').html(window.productJSON.infoModelo);
        }
    },
    printSku: () => {
        $('.js-label-sku').html('SKU ' + window.productJSON.labelSku);
    },
    printTechnicalDetails: (id) => {
        if(window.productJSON.hasOwnProperty('technicalDetails')){
            let productdata = window.productJSON.technicalDetails.filter((element) => element.id == id)[0];
            if(productdata){
                $('.js-technical-details-container').show();
                $('.js-main-technical-details').text('');
                if(productdata.hasOwnProperty('data')){
                    productdata.data.slice(0,5).forEach((element) => {
                        $('.js-main-technical-details').append('<li><p><b>' + element.name + ':</b>&nbsp;' + element.value + '</p></li>');
                    });
                } else {
                    $('.js-technical-details-container').hide();
                }
            } else {
                $('.js-technical-details-container').hide();
            }
        }
    },
    feedPromotions: () => {
        if(window.productJSON.hasOwnProperty('promotions')){
            var string = $.map(window.productJSON.promotions, function(obj){
                let text = obj.labels.join(' ');
                return text
            }).join(' ');
            $('.js-promotion-container').html(string);
            if(window.productJSON.promotions.some((promotion) => {
                if(promotion.id == 'evento-moda-segunda-mano'){
                    return true;
                }
            })){
                $('.js-lifting-pdp-product-promo-segunda-mano').show();
            }
            $('.js-promotion-container').show();
        }
    },
    lastItemFlagAndPresale: () => {
        $('.js-last-item-flag').hide();
        $('.js-special-case-container').hide();
        $('.js-presale-container').hide();
        if(window.productJSON.hasOwnProperty('variants')){
            if(window.productJSON.variants.filter(variant => variant.product_id == window.productJSON.productId)[0]){
                let product = window.productJSON.variants.filter(variant => variant.product_id == window.productJSON.productId)[0];
                if(product.lastitem){
                    $('.js-special-case-container').show();
                    $('.js-last-item-flag').show();
                }
            }
        } else {   
            if(window.productJSON.lastitem){
                $('.js-special-case-container').show();
                $('.js-last-item-flag').show();
            }
        }
        if(window.productJSON.isPreventa){
            $('.js-presale-container').show();
            $('.js-special-case-container').show();
            $('.js-presale-date').text(window.productJSON.preventaFecha);
        }
    },
    showOutOfStock: () => {
        if(!window.productJSON.hasOwnProperty('variants')){
            if(!window.productJSON.orderable){
                $('.js-out-of-stock').show();
            }
        } else {
            if(!(window.productJSON.variants.some((variant) => {
                return variant.orderable
            }))){
                $('.js-out-of-stock').show();
            }
        }
    },
    printPrice: (productPrices) => { 
        productPrices.forEach(price => {
            if(price.priceBookId == 'clp-internet-prices'){
                $('.js-internet-price-container').show();
                if(price.discount){
                    if(price.discount != '0%'){
                        $('.js-internet-price-discount').text(price.discount);
                        $('.js-internet-price-discount').show();
                        if(productPrices.filter(price => price.priceBookId == 'clp-list-prices')[0]){
                            $('.js-internet-price-list-price-strike-price').text(productPrices.filter(price => price.priceBookId == 'clp-list-prices')[0].price);
                        }
                    }
                }
                $('.js-internet-price-price').text(price.price);
            }  
            if(price.priceBookId == 'clp-list-prices'){
                if(!productPrices.some((price) => {
                    if(price.priceBookId == 'clp-internet-prices'){
                        return true;
                    }
                })){
                    $('.js-list-price-container').show();
                    $('.js-list-price-price').text(price.price);
                }
            } 
            
            if(price.priceBookId == 'clp-cencosud-prices'){
                let internetPrice = productPrices.filter(price => price.priceBookId == 'clp-internet-prices')[0];
                $('.js-cenco-price-container').show();
                
                if(internetPrice && internetPrice.value < price.value){
                    
                    $('.js-cenco-price-price').text(internetPrice.price);
                    if(internetPrice.discount != '0%'){
                        $('.js-cenco-price-discount').text(internetPrice.discount);
                        $('.js-cenco-price-discount').show();
                    }
                    
                } else {
                    if(price.discount){
                        if(price.discount != '0%'){
                            $('.js-cenco-price-discount').text(price.discount);
                            $('.js-cenco-price-discount').show();
                        }
                    }
                    $('.js-cenco-price-price').text(price.price);
                }
                
                
            }
        });
    },
    hidePriceContainer: () => {
        $('.js-internet-price-container').hide();
        $('.js-internet-price-discount').hide();
        $('.js-list-price-container').hide();
        $('.js-cenco-price-container').hide();
        $('.js-cenco-price-discount').hide();
    },
    updatePrice: () => {
        liftingpdp.hidePriceContainer();
        if(window.productJSON.hasOwnProperty('variants')){
            if(window.productJSON.variants.filter(variant => variant.product_id == window.productJSON.productId)[0]){
                let productPrices = window.productJSON.variants.filter(variant => variant.product_id == window.productJSON.productId)[0].prices;
                liftingpdp.printPrice(productPrices);
            } else {
                liftingpdp.printPrice(window.productJSON.prices)
            }
        } else {
            liftingpdp.printPrice(window.productJSON.prices)
        }
        
    }
}

const wishlist = {
    init: () => {
        $("#toggleHeart").click(function() {

            if(document.getElementById('userIsRegistered') && document.getElementById('userIsRegistered').value == 'true'){
                if(levelServicePDP.getSku()){
                    if(wishlist.isHeartFull()){
                        wishlist.removeProductFromWishlist();
                    } else {
                        wishlist.addProductToWishlist();
                    }
                    wishlist.showSetHeartState(!wishlist.isHeartFull());
                } else {   
                    let showModalBg = document.getElementById('alertProductInvalidToWishlist');
                    if (showModalBg) {
                        showModalBg.classList.remove('hidden');
                        setTimeout(function(){
                            showModalBg.classList.add('hidden')
                        }, 2000);
                    }
                }
            } else {
                newLogin.loadModal();
            }
        });
        wishlist.getStateWishlist();
    },
    isHeartFull: () => {
        const heartIcon = document.getElementById("toggleHeart");
        return heartIcon.classList.contains('fill');
    },
    setHeartState: (isFilled) => {
        const heartIcon = document.getElementById("toggleHeart");
        if (isFilled) {
            heartIcon.classList.add('fill');
        } else {
            heartIcon.classList.remove('fill');
        }
    },
    showIsHeartFull: () => {
        const isFull = wishlist.isHeartFull();
    },
    showSetHeartState: (isFilled) => {
        wishlist.setHeartState(isFilled);
    },
    getStateWishlist: () => {
        if(levelServicePDP.getSku()){
            wishlist.fetchGetStateWishlist({
                url: Urls.getStateWishlistProduct,
                sku: levelServicePDP.getSku()
            })
            .then(function (data) {
                if(data.status == 'success'){
                    wishlist.setHeartState(data.data);
                }
            })
        } else {
            wishlist.setHeartState(false);
        }
    },
    addProductToWishlist: () => {
        AnalyticsEvents.handleClickAddToWishList(levelServicePDP.getSku(), window.productJSON.breadCrumbs[window.productJSON.breadCrumbs.length - 1].categoryName)
        wishlist.fetchAddProductToWishlist({
            url: Urls.addProductToWishlist,
            sku: levelServicePDP.getSku()
        });
    },
    removeProductFromWishlist: () => {
        wishlist.fetchRemoveProductFromWishlist({
            url: Urls.wishlistRemoveItem,
            sku: levelServicePDP.getSku()
        });
    },
    fetchRemoveProductFromWishlist: ({ url, sku }) => {
        return new Promise(function (resolve, reject) {
            $.ajax({
                type: 'GET',
                data: { pid: sku },
                url: url,
                success: function (data) {
                    resolve(data);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    reject(textStatus);
                }
            });
        });
    },
    fetchAddProductToWishlist: ({ url, sku }) =>{
        return new Promise(function (resolve, reject) {
            $.ajax({
                type: 'POST',
                data: { pid: sku },
                url: url,
                success: function (data) {
                    resolve(data);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    reject(textStatus);
                }
            });
        });
    },
    fetchGetStateWishlist: ({ url, sku }) => {
        return new Promise(function (resolve, reject) {
            $.ajax({
                type: 'GET',
                data: { pid: sku },
                url: url,
                success: function (data) {
                    resolve(data);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    reject(textStatus);
                }
            });
        });
    }
}

let levelServicePDP = {
    init: () => {
        if(!window.productJSON.isIntangible){
            smartCustomerAddress = localStorage.getItem('smart_customer_region');
            levelServicePDP.listenerChangesLocalStorage();
            levelServicePDP.initChangeCommuneBtn();
            if(!window.productJSON.hasOwnProperty('variants')){
                if(window.productJSON.orderable){
                    if (levelServicePDP.validateAddress()) {
                        levelServicePDP.updateCommuneInLevelServiceInfo();
                        levelServicePDP.getLevelService();
                    } else {
                        levelServicePDP.hideLevelServiceElements();
                        $('.js-level-service-missing-address').show();
                    }
                } else {    
                    AnalyticsEvents.handleProductOutStock(window.productJSON.id);
                    levelServicePDP.hideLevelServiceElements();
                    $('.js-lifting-pdp-product-not-availible').show();
                }
            } else {
                if(window.productJSON.variants.some((variant) => {
                    return variant.orderable
                })){
                    if (levelServicePDP.validateAddress()) {
                        levelServicePDP.updateCommuneInLevelServiceInfo();
                        if(levelServicePDP.getSku()) {
                            levelServicePDP.getLevelService();
                        } else {
                            $('.js-level-service-missing-size').show();
                        }
                    } else {
                        $('.js-level-service-missing-address').show();
                    }
                } else {
                    $('.js-lifting-pdp-product-not-availible').show();
                }
            }
        } else {
            $('.js-level-service-intangible-product').show();   
        }
    },
    feedModal: (data) => {
        if(data.data.some((levelService) => {
            if(levelService.type == 'SAMEDAY' && levelService.status.code == '2000'){
                return true;
            }
        })){
            let element = data.data.filter((levelService) => {
                if(levelService.type == 'SAMEDAY' && levelService.status.code == '2000'){
                    return levelService;
                }
            })[0]
            $('.js-modal-container-sameday').show();
            if(element.shippingPrice.status){
                $('.js-label-sameday-modal').html('Recibe hoy ' + element.deliveryDate + ' desde <strong>' + element.shippingPrice.shippingPrice.toLocaleString('es-CL', {
                    style: 'currency',
                    currency: 'CLP'
                  }) + '</strong>');
            } else {
                $('.js-label-sameday-modal').html('Recibe hoy ' + element.deliveryDate);
            }
            
        }

        if(data.data.some((levelService) => {
            if(levelService.type == '24HOURS' && levelService.status.code == '2000'){
                return true;
            }
        })){
            let element = data.data.filter((levelService) => {
                if(levelService.type == '24HOURS' && levelService.status.code == '2000'){
                    return levelService;
                }
            })[0]
            $('.js-modal-container-express').show();
            if(element.shippingPrice.status){
                $('.js-label-express-modal').html('Recibe mañana ' + element.deliveryDate + ' desde <strong>' + element.shippingPrice.shippingPrice.toLocaleString('es-CL', {
                    style: 'currency',
                    currency: 'CLP'
                  }) + '</strong>');
            } else {
                $('.js-label-express-modal').html('Recibe mañana ' + element.deliveryDate);
            }
        }

        if(data.data.some((levelService) => {
            if(levelService.type == 'SCHEDULED' && levelService.status.code == '2000'){
                return true;
            }
        })){
            let element = data.data.filter((levelService) => {
                if(levelService.type == 'SCHEDULED' && levelService.status.code == '2000'){
                    return levelService;
                }
            })[0]
            $('.js-modal-container-scheduled').show();
            if(element.shippingPrice.status){
                $('.js-label-scheduled-modal').html('Elige el día de tu despacho desde <strong>' + element.shippingPrice.shippingPrice.toLocaleString('es-CL', {
                    style: 'currency',
                    currency: 'CLP'
                  }) + '</strong>');
            } else {
                $('.js-label-scheduled-modal').html('Elige el día de tu despacho');
            }
        }

        if(data.data.some((levelService) => {
            if(levelService.type == 'DATERANGE' && levelService.status.code == '2000'){
                return true;
            }
        })){
            let element = data.data.filter((levelService) => {
                if(levelService.type == 'DATERANGE' && levelService.status.code == '2000'){
                    return levelService;
                }
            })[0]
            $('.js-modal-container-daterange').show();
            if(element.shippingPrice.status){
                $('.js-label-daterange-modal').html('Recibe entre <strong>' + element.deliveryDate + ' y ' + element.deliveryDateEnd + '</strong> desde <strong>' + element.shippingPrice.shippingPrice.toLocaleString('es-CL', {
                    style: 'currency',
                    currency: 'CLP'
                  }) + '</strong>');
            } else {
                $('.js-label-daterange-modal').html('Recibe entre <strong>' + element.deliveryDate + ' y ' + element.deliveryDateEnd + '</strong>');
            }
        }

        if(data.data.some((levelService) => {
            if(levelService.type == 'PICKUP' && levelService.status.code == '2000'){
                return true;
            }
        })){
            let date = data.data.filter((levelService) => {
                if(levelService.type == 'PICKUP' && levelService.status.code == '2000'){
                    return levelService;
                }
            })[0].deliveryDate
            $('.js-modal-container-pickup').show();
            $('.js-label-pickup-modal').html('Retira desde el ' + date);
        }
        
    },
    printLevelService: (data) => {
        levelServicePDP.hideLevelServiceContainers();
        levelServicePDP.feedModal(data);
        if(data.data.some((levelService) => {
            if(levelService.type == 'SAMEDAY' && levelService.status.code == '2000'){
                return true;
            }
        })){
            $('.js-box-sameday-level-service').show();
        } else {
            if(data.data.some((levelService) => {
                if(levelService.type == '24HOURS' && levelService.status.code == '2000'){
                    return true;
                }
            })){
                $('.js-box-express-level-service').show();
                
            } else {
                if(data.data.some((levelService) => {
                    if(levelService.type == 'SCHEDULED' && levelService.status.code == '2000'){
                        return true;
                    }
                })){
                    let date = data.data.filter((levelService) => {
                        if(levelService.type == 'SCHEDULED' && levelService.status.code == '2000'){
                            return levelService;
                        }
                    })[0].deliveryDate
                    $('.js-box-scheduled-level-service').show();
                    $('.js-label-scheduled-date-range').html('<strong>' + date + '</strong>');
                } else {
                    if(data.data.some((levelService) => {
                        if(levelService.type == 'DATERANGE' && levelService.status.code == '2000'){
                            return true;
                        }
                    })){
                        let date = data.data.filter((levelService) => {
                            if(levelService.type == 'DATERANGE' && levelService.status.code == '2000'){
                                return levelService;
                            }
                        })[0].deliveryDate

                        let dateEnd = data.data.filter((levelService) => {
                            if(levelService.type == 'DATERANGE' && levelService.status.code == '2000'){
                                return levelService;
                            }
                        })[0].deliveryDateEnd
                        $('.js-box-daterange-level-service').show();
                        $('.js-label-daterange-date-range').html('<strong>' + date + ' y ' + dateEnd + '</strong>');
                    } else {
                        $('.js-level-service-home-delivery-not-availible').show();
                    }
                } 
            }
        }

        if(data.data.filter((levelService) => {
            if(levelService.type != 'PICKUP' && levelService.status.code == '2000'){
                return true;
            }
        }).length > 1){
            $('.js-delivery-modal-container').show();
        }

        if(data.data.some((levelService) => {
            if(levelService.type == 'PICKUP' && levelService.status.code == '2000'){
                return true;
            }
        })){
            let date = data.data.filter((levelService) => {
                if(levelService.type == 'PICKUP' && levelService.status.code == '2000'){
                    return levelService;
                }
            })[0].deliveryDate
            $('.js-box-pickup-level-service').show();
            $('.js-label-pickup-date').html('Desde <strong>el ' + date + '</strong>');
        } else {
            $('.js-level-service-pickup-not-availible').show();
            
        }
    },
    hideLevelServiceContainers: () => {
        $('.js-box-sameday-level-service').hide();
        $('.js-box-express-level-service').hide();
        $('.js-box-scheduled-level-service').hide();
        $('.js-box-daterange-level-service').hide();
        $('.js-box-pickup-level-service').hide();
        $('.js-delivery-modal-opener').hide();
        $('.js-modal-container-sameday').hide();
        $('.js-modal-container-express').hide();
        $('.js-modal-container-pickup').hide();
        $('.js-modal-container-daterange').hide();
        $('.js-modal-container-scheduled').hide();
    },
    printLevelServiceStandard: (data) => {
        $('.js-label-available-home-delivery-standard').hide();
        $('.js-label-not-available-home-delivery-standard').hide();
        $('.js-label-available-pickup-standard').hide();
        $('.js-label-not-available-pickup-standard').hide();
        if(data.status){
            if(data.data.homedelivery || data.data.express || data.data.sameday){
                $('.js-label-available-home-delivery-standard').show();
            } else {
                $('.js-label-not-available-home-delivery-standard').show();
            }
            if(data.data.pickup){
                $('.js-label-available-pickup-standard').show();
            } else {
                $('.js-label-not-available-pickup-standard').show();
            }
        } else {
            $('.js-level-service-not-availible').show();
        }

    },
    initChangeCommuneBtn: () => {
        $('.link-delivery-info-comuna').on('click', () => {
            $('#header-comuna-source-btn').click();
            if ('smart_customer_region' in localStorage) {
                $('#empty-comuna-delivery-info').hide();
            } else {
                $('#empty-comuna-delivery-info').show();
            }
        });
    },
    validateAddress: () => {
        if ('smart_customer_region' in localStorage) {
            try{
                JSON.parse(localStorage.getItem('smart_customer_region'));
                return true;
            } catch (e) {
                levelServicePDP.hideLevelServiceElements();
                $('.js-level-service-missing-address').show();
                return false;
            } 
        } else {
            return false
        }   
    },
    getLevelService: () => {
        if(!window.productJSON.isIntangible){
            if(levelServicePDP.enabledLevelServicePDP()){
                levelServicePDP.fetchDelivery({
                    url: Urls.getNSInfoInPDP,
                    data: {
                        sku: levelServicePDP.getSku(),
                        address: JSON.parse(localStorage.getItem('smart_customer_region'))
                    }
                })
                .then(function (data) {
                    if(data.status){
                        if(data.bundle){
                            data.data = data.data[0];
                        }
                        if(data.data.some((levelService) => {
                            if(levelService.status.code == 2000){
                                return true;
                            }
                        })){
                            levelServicePDP.hideLevelServiceElements();
                            levelServicePDP.printLevelService(data);
                            $('.js-level-service-availible').show();
                        } else {
                            levelServicePDP.hideLevelServiceElements();
                            $('.js-level-service-not-availible').show();
                        }
                    } else {
                        levelServicePDP.getLevelServiceStandard();
                    }
                })
                .catch(function (error) {
                    levelServicePDP.getLevelServiceStandard();
                });
            } else {
                levelServicePDP.getLevelServiceStandard();
            } 
        }
    },
    getLevelServiceStandard:() => {
        levelServicePDP.fetchDelivery({
            url: Urls.getAvailibleShippingMethodsByPreference,
            data: {
                sku: levelServicePDP.getSku(),
                address: JSON.parse(localStorage.getItem('smart_customer_region'))
            }
        })
        .then(function (data) {
            levelServicePDP.hideLevelServiceElements();
            levelServicePDP.printLevelServiceStandard(data);
            $('.js-level-service-standard-mode').show();
            $('.js-delivery-modal-container').hide();
        })
        .catch(function (error) {
            levelServicePDP.hideLevelServiceElements();
            $('.js-level-service-not-availible').show();
        });
    },
    hideLevelServiceElements:() => {
        $('#empty-comuna-delivery-info').hide();
        $('.js-level-service-not-availible').hide();
        $('.js-level-service-standard-mode').hide();
        $('.js-level-service-availible').hide();
        $('.js-level-service-missing-address').hide();
        $('.js-delivery-modal-container').hide();
        $('.js-level-service-missing-size').hide();
    },
    updateSku: (sku) => {
        window.productJSON.productId = sku;
    },
    getSku: () => {
        return ((window.productJSON.productId != window.productJSON.master.master_id) || !window.productJSON.hasOwnProperty('variants')) ? window.productJSON.productId : false;
    },
    enabledLevelServicePDP: () => {
        return(SitePreferences.IS_ENABLE_SHIPPING_INFO_PDP);
    },
    listenerChangesLocalStorage: () => {
        window.listenerLevelService = () => {
            if(smartCustomerAddress != localStorage.getItem('smart_customer_region')){    
                smartCustomerAddress = localStorage.getItem('smart_customer_region');
                if(levelServicePDP.getSku()){
                    levelServicePDP.getLevelService();   
                }
                levelServicePDP.updateCommuneInLevelServiceInfo();
            }
        }
    },
    updateCommuneInLevelServiceInfo: () => {
        if($('#delivery-info-comuna-name').length){
            $('*[id=delivery-info-comuna-name]').each(function(){
                $(this).text(JSON.parse(window.localStorage.getItem('smart_customer_region')).comuna.name.toLocaleLowerCase()).css('text-transform', 'capitalize');
            }); 
        }
        $('.js-lable-address-modal').html('En ' + JSON.parse(window.localStorage.getItem('smart_customer_region')).comuna.name.toLocaleLowerCase()).css('text-transform', 'capitalize');
    },
    fetchDelivery: ({ url, data }) => {
        return new Promise(function (resolve, reject) {
            $.ajax({
                contentType: 'application/json',
                type: 'POST',
                data: JSON.stringify(data),
                dataType: 'json',
                url: url,
                success: function (data) {
                    resolve(data);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    reject(textStatus);
                }
            });
        });
    }
}

let add2CartModule = {
    init: () => {
        add2CartModule.handlerAdd2Cart();
        if(!window.productJSON.hasOwnProperty('variants')){
            if(window.productJSON.orderable){
                window.Perseo.mountWithProps({
                    name: 'AddToCartSection',
                    selector: 'perseo-pdp-add-to-cart-section',
                    rawSfccData: window.productJSON,
                    addToCartSfccCallback: window.handlerAdd2Cart
                });
            } else {
                $('.js-lifting-pdp-product-not-availible').show();
            }
        } else {
            if(window.productJSON.variants.some((variant) => {
                return variant.orderable
            })){
                window.Perseo.mountWithProps({
                    name: 'AddToCartSection',
                    selector: 'perseo-pdp-add-to-cart-section',
                    rawSfccData: window.productJSON,
                    addToCartSfccCallback: window.handlerAdd2Cart
                });
            } else {
                $('.js-lifting-pdp-product-not-availible').show();
            }
        }
    },
    handlerAdd2Cart: () => {
        window.handlerAdd2Cart =  function() {
            samplingpdp.initSampling(window.productJSON.productId);
        };
    },
    startModalAddedToCart:(mensaje) => {
        setTimeout(function () {
            add2CartModule.showModalAddedToCart()
            minicart.updateCartBadge();
        }, 500);
    },
    showModalAddedToCart: (mensaje) => {
        var mobileAddToCartFeedback = document.querySelector('#mobileAddToCartFeedback');
        mobileAddToCartFeedback.classList.remove('closed');
        $('body').addClass('fixed');
        mobileAddToCartFeedback.classList.add('opened');
        var overlayAddToCartFeedback = document.querySelector('.overlay-addToCart-feedback');
        overlayAddToCartFeedback.classList.add('opened');
        overlayAddToCartFeedback.classList.remove('hidden');
    
        $('#mob-checkout-form').removeClass('hidden');
    
        $('.overlay-addToCart-feedback').unbind('click');
        $('.overlay-addToCart-feedback').on('click', function () {
            add2CartModule.stopModalAddedToCart();
            //minicart.close();
            //$(document.body).css('overflow', 'auto');
            $('body').removeClass('fixed');
            $('.overlay-addToCart-feedback').removeClass('opened');
            $('#mobileAddToCartFeedback').removeClass('opened');
            $('#mobileAddToCartFeedback').addClass('closed');
            $('#mob-checkout-form').addClass('hidden');
        });
    
        document.addEventListener('touchstart', handleTouchStart, false);
        document.addEventListener('touchmove', handleTouchMove, false);
    
        var xDown = null;
        var yDown = null;
    
        function getTouches(evt) {
            return evt.touches ||             // browser API
                evt.originalEvent.touches; // jQuery
        }
    
        function handleTouchStart(evt) {
            const firstTouch = getTouches(evt)[0];
            xDown = firstTouch.clientX;
            yDown = firstTouch.clientY;
        };
    
        function handleTouchMove(evt) {
            if (!xDown || !yDown) {
                return;
            }
    
            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;
    
            var xDiff = xDown - xUp;
            var yDiff = yDown - yUp;
    
            if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
                if (xDiff > 0) {
                    /* left swipe */
                } else {
                    /* right swipe */
                }
            } else {
                if (yDiff > 0) {
                    /* up swipe */
                } else {
                    /* down swipe */
                    document.querySelector('#mobileAddToCartFeedback').classList.remove('opened');
                    document.querySelector('.overlay-addToCart-feedback').classList.remove('opened');
                    document.querySelector('#mobileAddToCartFeedback').classList.add('closed');
                    $('body').removeClass('fixed');
                    add2CartModule.stopModalAddedToCart();
                }
            }
            /* reset values */
            xDown = null;
            yDown = null;
        };
    
        $('#mob-go-to-cart').unbind('click');
        $('#mob-go-to-cart').on('click', function () {
            page.redirect(Urls.cartShow);
        });
    
        $('#mob-continue-buying').unbind('click');
        $('#mob-continue-buying').on('click', function () {
            page.redirect(Urls.cartCheckout);
        });
    },
    stopModalAddedToCart: (mensaje) => {
        //setFeedBackStylesToClosed();
        $('span.add-to-cart__label').removeClass('hide');
    },
}

let productOptionsModule = {
    init: () => {
        window.Perseo.mountWithProps({
            payloadHandler: window.productOptionsPerseoHandler,
            name: 'ProductOptions',
            selector: 'perseo-pdp-product-options',
            rawSfccData: window.productJSON,
            productOptionModalHandler: productOptionsModule.initHandlerModal
        });
    },
    initHandlerModal: (payload) => {
        if(payload == '09900999'){
            modalComponent.showModalWarranty();
        } else if(payload == '275274999'){
            modalComponent.showModalInstalacion();
        } else if (payload == '696544999'){
            modalComponent.showModalArmed();     
        } else if(payload == '690665999'){
            modalComponent.showModalRecicambio();
        }
    }
}

let variationAttrModule = {
    init: () => {
        variationAttrModule.initHandler();
        window.Perseo.mountWithProps({
            payloadHandler: window.variationAttributesPerseoHandler,
            name: 'VariationAttributes',
            selector: 'perseo-pdp-variation-attributes',
            rawSfccData: window.productJSON
        });
    },
    initHandler: () => {
        window.variationAttributesPerseoHandler = function(payload) {
            if(payload){
                let data = JSON.parse(payload);
                if(data){
                    if(data.hasOwnProperty('productId')){
                        if(data.productId && data.productId != ''){
                            levelServicePDP.updateSku(data.productId);
                            liftingpdp.printTechnicalDetails(data.productId);
                            levelServicePDP.getLevelService();
                            liftingpdp.updatePrice();
                            wishlist.getStateWishlist();
                            liftingpdp.lastItemFlagAndPresale();
                        }
                    }
                }
            }
        };
    }
}

let imageGalleryModule = {
    init: () => {
        window.Perseo.mountWithProps({
            name: 'ImageGallery',
            selector: 'perseo-pdp-image-gallery',
            rawSfccData: window.productJSON,
        });
    }
}

let legacyFunctions = {
    init: () => {
        if(window.productJSON.hasOwnProperty('breadCrumbs') && window.productJSON.breadCrumbs){
            window.productJSON.breadCrumbs.reverse().forEach(element => {
                $("#lifting-breadcrumbs").append('<li><a href="' + element.url + '"><span>' + element.categoryName + '</span></a></li>');
                $("#js-link-breadcrumbs-back").attr('href', element.url)
            });
        }
        $( '#js-share-modal' ).dialog({
            title: 'Compartir',
            draggable: false,
            autoOpen: false,
            responsive: true,
            modal: true,
            fluid: true,
            dialogClass: 'ui-share-modal ui-new-pdp-modal',
            width: '650',
            open: function(event, ui) {
              $('html').addClass('ui-scroll-disabled');
            },
            close: function(event, ui) {
              $('html').removeClass('ui-scroll-disabled');
            }
        });
     
        $( '#js-share-modal-opener' ).on( 'click', function() {
            $( '#js-share-modal' ).dialog( 'open' );
        });

        $( '#js-delivery-modal' ).dialog({
            title: 'Opciones de entrega disponibles',
            draggable: false,
            autoOpen: false,
            closeOnEscape: true,
            clickOutside: true,
            responsive: true,
            modal: true,
            fluid: true,
            dialogClass: 'ui-delivery-modal ui-new-pdp-modal',
            width: '900',
            open: function(event, ui) {
                 $('html').addClass('ui-scroll-disabled');
            },
            close: function(event, ui) {
                $('html').removeClass('ui-scroll-disabled');
        }
        });
     
        $( '#js-delivery-modal-opener' ).on( 'click', function() {
          $( '#js-delivery-modal' ).dialog( 'open' );
        });
        $('.pdp-main_details').on('click', function() {
            var $desciptionTite = $('#pdp-spec-button-tab-lifting').offset();
            $('html, body').animate({
                scrollTop: $desciptionTite.top
            });
            const getButtonSpec = document.getElementById('pdp-spec-button-tab-lifting');
            const contentSpec = document.getElementById('pdp-spec-content-tab');
            if (typeof(getButtonSpec) != 'undefined' && getButtonSpec != null){
                contentSpec.classList.toggle('pdp-product-info_spec-content-open');
                getButtonSpec.classList.toggle('pdp-product-info_spec-active');
            }
        });
        legacyFunctions.initToggleSpecAndDesc();
    },
    initToggleSpecAndDesc: () => {
        const getButtonSpec = document.getElementById('pdp-spec-button-tab-lifting');
        const contentSpec = document.getElementById('pdp-spec-content-tab');
        const getButtonDesc = document.getElementById('pdp-desc-button-tab');
        const contentDesc = document.getElementById('pdp-desc-content-tab');
        
        function showProductSpec(){
          contentSpec.classList.toggle('pdp-product-info_spec-content-open');
          getButtonSpec.classList.toggle('pdp-product-info_spec-active');
          AnalyticsEvents.handleDisplayProductSpecs(window.productJSON.seller, window.productJSON.id);
        }
        function showProductDesc(){
          contentDesc.classList.toggle('pdp-product-info_desc-content-open');
          getButtonDesc.classList.toggle('pdp-product-info_desc-active');
          AnalyticsEvents.handleDisplayProductDetails(window.productJSON.seller, window.productJSON.id);
        }
        if (typeof(getButtonSpec) != 'undefined' && getButtonSpec != null){
          getButtonSpec.addEventListener('click', showProductSpec);
        }
        if (typeof(getButtonDesc) != 'undefined' && getButtonDesc != null){
          getButtonDesc.addEventListener('click', showProductDesc);
        }
    }
}

let modalComponent = {
    replaceSpanWithH1:() => {
        const spanText = $('.ui-dialog-title').text();
        $('.ui-dialog-title').replaceWith(`<h1 id='ui-id-1' class='ui-dialog-title'>${spanText}</h1>`);
    },
    
    addAttributes:() => {
        $('.ui-dialog-titlebar-close').attr('title', 'Cerrar');
        $('.ui-dialog-title').attr('tabindex', 0);
        $('.ui-dialog-titlebar-close').attr('tabindex', 0);
        $('.ui-dialog-content').attr('tabindex', 0);
    },
    loadModal:({ data, replace, addAttr, options = {} }) => {
        const modalOptions = {
            html: '',
            options: {
                draggable: false,
                autoOpen: false,
                responsive: true,
                modal: true,
                fluid: true,
                dialogClass: 'ui-dialog-full',
                width: 900,
            }
        }
    
        if (typeof data === 'object') {
            if (data.hasOwnProperty('html')){
                modalOptions.html = data.html;
            }
            
            if (data.hasOwnProperty('title')) {
                modalOptions.options.title = data.title;
            }
        } else {
            modalOptions.html = data;
        }
    
        modalOptions.options = { ...modalOptions.options, ...options };
    
        const hasTitle = !!modalOptions.options.title?.trim();
        if (!hasTitle) {
            modalOptions.options.dialogClass += ' ui-dialog-remove-titlebar-whitespace';
        }
        progress.show();
        dialog.open(modalOptions);
        if (replace) {
            modalComponent.replaceSpanWithH1();
        }
        if (addAttr) {
            modalComponent.addAttributes();
        }
        $('#dialog-container').scrollTop(0);
        $('.ui-dialog-full').focus();
        progress.hide();
    },
    closeModal: () => {
        $('.ui-dialog-content').dialog('close');
    },
    openModal:({ targetDomSelector, replace, addAttr, options }) => {
        const data = $(document).find(targetDomSelector);
        const elementToRender = data.clone().find('.content-asset');
        const wrapperContent = elementToRender.find('.site-modal');
        let dataToRender = elementToRender.html();
    
        if (wrapperContent.length && wrapperContent.data('title')) {
            dataToRender = {
                title: wrapperContent.data('title'),
                html: dataToRender,
            }
        }
        modalComponent.loadModal({ data: dataToRender, replace, addAttr, options });
    },
    handleOpenModalListeners: ({ targetSelector, targetDomSelector, replace, addAttr, options }) => {
        modalComponent.openModal({ targetDomSelector, replace, addAttr, options });
    },    
    processModalConcienciaCeleste: (contentAssetID, attributesObject) => {
        const $contentAssetFilled = fillContentAssetPlaceholders(contentAssetID, attributesObject);
        const $contentAssetCleaned = removeContainersWithEmptyPlaceholders($contentAssetFilled);
        $('#' + contentAssetID).find('.content-asset').empty().html($contentAssetCleaned.children());
    },
    analyticsModalConcienciaCeleste: (isModalOpened) => {
        const action = isModalOpened ? 'Conocer más' : 'Cerrar modal';
        const modalTitle = $(this).find('h1').text();
    
        window.dataLayer.push({
            'event-interactive-category': 'Interacciones Conciencia Celeste PDP',
            'event-interactive-accion': `Click botón ${action}`,
            'event-interactive-tag': modalTitle,
            'event': 'event-interactive'
        });
    },
    analyticsLinksConcienciaCeleste: () => {
        const anchorText = $(this).text();
    
        window.dataLayer.push({
            'event-interactive-category': 'Interacciones Conciencia Celeste PDP',
            'event-interactive-accion': 'Click link',
            'event-interactive-tag': anchorText,
            'event': 'event-interactive'
        });
    },
    showModalConcienciaCeleste: () => {
        const $concienciaCelesteObjectElement = $('#yjuñuñuu');
        if (!$concienciaCelesteObjectElement.length) return;
    
        const modalOptions = {
            open: function() {
                analyticsModalConcienciaCeleste.call(this, true);
                $('.modal-conciencia-celeste a').on('click', analyticsLinksConcienciaCeleste);
                $('.ui-widget-overlay').on('click', closeModal);
            },
            close: function() {
                analyticsModalConcienciaCeleste.call(this, false);
                $('.modal-conciencia-celeste a').off('click', analyticsLinksConcienciaCeleste);
                $('.ui-widget-overlay').off('click', closeModal);
            }
        }
    
        const concienciaCelesteObject = JSON.parse($concienciaCelesteObjectElement.val());
    
        const historyID = 'js-data-info-CC-history';
        const sellID = 'js-data-info-CC-sell';
        const tipsID = 'js-data-info-CC-tips';
    
        modalComponent.processModalConcienciaCeleste(historyID, concienciaCelesteObject);
    
        modalComponent.handleOpenModalListeners({ targetSelector: '.js-info-CC-history', targetDomSelector: '#' + historyID, options: modalOptions });
        modalComponent.handleOpenModalListeners({ targetSelector:'.js-info-CC-sell', targetDomSelector: '#' + sellID, options: modalOptions });
        modalComponent.handleOpenModalListeners({ targetSelector: '.js-info-CC-tips', targetDomSelector: '#' + tipsID, options: modalOptions });
    },
    showModalInstalacion:() => {
        modalComponent.handleOpenModalListeners({ targetSelector: '.js-info-instalacion', targetDomSelector: '#js-data-info-instalacion'});
    },
    showModalRecicambio:() => {
        modalComponent.handleOpenModalListeners({ targetSelector: '.js-info-recambio', targetDomSelector: '#js-data-info-recambio'});
    },
    showModalWarranty:() => {
        modalComponent.handleOpenModalListeners({ targetSelector: '.js-info-warranty', targetDomSelector: '#js-data-info-warranty'});
    },
    showModalArmed:() => {
        modalComponent.handleOpenModalListeners({ targetSelector: '.js-info-armed', targetDomSelector: '#js-data-info-armed'});
    },
    showModalExchange:() => {
        modalComponent.handleOpenModalListeners({ targetSelector: '.js-info-exchange', targetDomSelector: '#js-data-info-exchange' });
    }
}

let ratingAndReviews = {
    hideReviewComponentsIfUserNotLogged: () => {
        $('.bv_button_buttonFull').attr('style', 'display: none !important');
        $('button.bv_stars_button_container').attr('style', 'cursor: default !important');
        $('button.bv-write-review').attr('style', 'display: none !important');
        $('.bv_avgRating_component_container').attr('style', 'display: block !important');
        $('div.bv_main_container_line_break').attr('style', 'display: none !important');
        $('button#WAR').attr('style', 'display: block !important');
        $('.bv_main_container_row_flex').attr('style','padding: 0px !important');
        $('.js-rating-container-wrapper').attr('style','padding: 0px !important');
        $('.bv_main_container_row_flex div.bv_button_component_container').attr('style','display: block !important');
        $('.bv_main_container_row_flex div.bv_numReviews_component_container').attr( 'style', 'display: flex !important');
        $('.bv_modal_component_container').attr( 'style', 'position: relative !important' , 'right: 50px');
        $('div.js-rating-container-wrapper').show();    
    },
    expandReadingReviewsSecitonAftetWroteOne: () => {
        $('button.bv-form-actions-submit').on('click', function () {
            setTimeout(function () {
            $('button.bv-mbox-close').on('click', function () {
                if ($('#collapseComments').length > 0 && !$('#collapseComments').hasClass('active')) {
                $('button.bv-write-review').attr('style', 'display: none !important');
                if ($('div.bv-content-placeholder').length > 0) {
                    $('div.bv-content-placeholder').find('a').attr('style', 'display: none !important');
                }
                $('.js-read-review-section').addClass('ui-state-active');
                $('#collapseComments').addClass('active');
                }
            });
            }, 2000);
        });
    },
    addClickEventOnRatingStars: () => {
        $('button.bv_stars_button_container').on('click', function () {
            if ($('#collapseComments').length > 0 && !$('#collapseComments').hasClass('active')) {
            $('button.bv-write-review').attr('style', 'display: none !important');
            if ($('div.bv-content-placeholder').length > 0) {
                $('div.bv-content-placeholder').find('a').attr('style', 'display: none !important');
            }
            $('.js-read-review-section').addClass('ui-state-active');
            $('#collapseComments').addClass('active');
            }
        });
    },
    init: () => {
        $(document).ready(() => {
        const waitForWriteReview = (callback) => {
            setTimeout(callback, 1500);
        };
        waitForWriteReview(() => {
            ratingAndReviews.hideReviewComponentsIfUserNotLogged();
        });

        $(document).ajaxComplete(() => {
            waitForWriteReview(() => {
                ratingAndReviews.hideReviewComponentsIfUserNotLogged();
            });
        });

        setTimeout(() => {
            ratingAndReviews.addClickEventOnRatingStars();
            }, 1500);
        });

        $('.js-write-review').on('click', function (e) {
            setTimeout(() => {
            ratingAndReviews.expandReadingReviewsSecitonAftetWroteOne();
            }, 3500);
    
            if ($(this).attr('href') == 'javascript:void(0)') {
            if ($('button#WAR').length > 0) {
                $('button#WAR').click();
            }
            } else {
            if ($('input#_clickOnWrite').length > 0) {
                $('input#_clickOnWrite').attr('value', 'true');
            }
            if ($('#collapseLogin').length > 0) {
                if ($('#collapseLogin').css('display') == 'none') {
                $('#clickLogin').click();
                }
            }
            }
            return false;
        });

        $('.reviews').on('click', function (e) {
            $('button.bv-write-review').attr('style', 'display: none !important');
            if ($('div.bv-content-placeholder').length > 0) {
            $('div.bv-content-placeholder').find('a').attr('style', 'display: none !important');
            }
        });
    },
        ifWriteReviewShouldBePressed: () => {
        if ($('input#_clickOnWrite').length > 0 && $('input#_clickOnWrite').attr('value') == 'true') {
            $('.js-write-review').click();
        }
        },
    };
let shareModal = {
    init:() => {
        window.Perseo.mountWithProps({
            name: 'PdpShareModal',
            selector: 'perseo-share-modal',
            rawSfccData: window.productJSON,
        });
    }
}

const AnalyticsEvents = {
    init: () => {
        $('.pdp-breadcrumbs').on('click', function (e) {
            AnalyticsEvents.handleBreadcrumbsSpanClick(e);
        });

        $('.drawer-tarjeta-cencosud__action-link').find('p').on('click', function() {
            const $typeEvent = $('#typeEvent').first().val();
            AnalyticsEvents.handleDrawerCard($typeEvent);
        });

        $('.link-show-fee .icon-fee').on('click', function () {
            AnalyticsEvents.handleClickOnQuotaRollout(window.productJSON.id);
        });

        $('#pdp-main_details').on('click', function () {
            AnalyticsEvents.handleMoreProductDetails(window.productJSON.id);
        });
        
        $('.footer__subscribe-btn').on('click', function () {
            AnalyticsEvents.handleSuscription();
        });
        
        $('.js-event-size-guide-modal-legacy').on('click', function (e) {
            AnalyticsEvents.handleSizeModal(true, window.productJSON.brand, window.productJSON.id);
        });

        $('.js-info-exchange').on('click', function (e) {
            AnalyticsEvents.handleChangeReturns(e, window.productJSON.seller, window.productJSON.id);
        });

        $('.js-pdp-products-similar-search').on('click', 'ul li', function (event) {
            const $nodelist = $(event.currentTarget).children() || [];
            if ($nodelist.length) {
                $nodelist.each(function () {
                    const $div = $(this).find('div');
                    if ($div.length) {
                        const seller = $div.find('#productSeller').val();
                        const itemId = $div.data('itemid');
                            AnalyticsEvents.handleSimilarSearch(itemId, seller);
                            return false;
                    }
                });
            }
        });
    },
    sendEventToAnalytics:(category, action, tag) => {
        const EVENT_INTERACTIVE_CATEGORY = category;
        const EVENT_INTERACTIVE_ACCION = action;
        const EVENT_INTERACTIVE_TAG = tag;
        const EVENT_INTERACTIVE = 'event-interactive';

        if (!EVENT_INTERACTIVE_CATEGORY || !EVENT_INTERACTIVE_ACCION  || !EVENT_INTERACTIVE_TAG) {
            return;
        }

        window.dataLayer.push({
            'event-interactive-category': EVENT_INTERACTIVE_CATEGORY,
            'event-interactive-accion':  EVENT_INTERACTIVE_ACCION,
            'event-interactive-tag': EVENT_INTERACTIVE_TAG,
            'event': EVENT_INTERACTIVE
        });
    },
    handleBreadcrumbsSpanClick(event) {
        if (!event) {
            return
        }
        const target = event.target;
        const category = 'breadcrumb';
        const action = 'click: PDP';
        const tag = target.textContent.trim();
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleProductOutStock(productId) {
        const category = 'sin stock pdp';
        const action = 'respuesta';
        const tag = productId;
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleDrawerCard(typeEvent) {
        if (!typeEvent) {
            return;
        }
        let tag = '';
        if (typeEvent === 'orderNow') {
            tag = 'Pídela ahora';
        } else if (typeEvent === 'increaseQuota') {
            tag = 'Aumenta cupo';
        }
        if (!tag) {
            return;
        }
        const category = 'Tarjeta cenco PDP';
        const action = 'Click link';
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleClickOnQuotaRollout(productId) {
        if (!productId) {
            return;
        }
        const category = 'Tarjeta cenco PDP';
        const action = 'Click detalle de cuotas';
        const tag = productId;
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleClickAddToWishList(sku, categoryProduct) {
        const category = 'Click Agregar Favoritos';
        const action = 'PDP - ' + categoryProduct;
        const tag = sku;
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleMoreProductDetails(productId) {
        if (!productId) {
            return;
        }
        const category = 'Interacciones en PDP';
        const action = 'Click link: Revisar más detalles del producto';
        const tag = productId;
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleDisplayProductDetails(seller, productId) {
        const category = 'Interacciones en PDP';
        const action = 'Click desplegar descripcion del producto';
        const tag = seller + ' ' + productId;
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleDisplayProductSpecs(seller, productId) {
        const category = 'Interacciones en PDP';
        const action = 'Click desplegar mas caracteristicas del producto';
        const tag = seller + ' ' + productId;
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleSuscription() {
        const category = 'newsletter PDP';
        const action = 'Clic boton suscribete'
        const tag = ' ';
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleSizeModal(openModal, brand, productId) {
        if (!brand || !productId) {
            return;
        }
        const infoAction = openModal ? 'abrir guía de tallas' : 'cerrar guía de tallas';
        const category = 'pdp tabla de tallas';
        const action = 'Clic link: ' + infoAction;
        const tag = brand + '/' + productId;
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
    handleChangeReturns(event, seller, productId) {
        if(event.currentTarget.innerHTML == '¿Por qué?'){
            if (!seller || !productId) { return }
            const category = 'Interacciones en PDP';
            const action = 'Click link: ¿por que? sin cambios ni devoluciones'
            const tag = seller + ' ' + productId;
            AnalyticsEvents.sendEventToAnalytics(category, action, tag);
        } else {
            const tag = seller;
            const category = 'Interacciones en PDP';
            const action = 'Click link: Cambios y devoluciones';
            AnalyticsEvents.sendEventToAnalytics(category, action, tag);
        }
    },
    handleSimilarSearch(productId, seller) {
        if (!seller || !productId) { return }

        const category = 'Buscar similares PDP';
        const action = 'Click: Carruseles'
        const tag = seller + ' ' + productId;
        AnalyticsEvents.sendEventToAnalytics(category, action, tag);
    },
}
module.exports = liftingpdp;