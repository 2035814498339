'use strict';

var utils = require('./../util');

var desktopSearch = {
  /* variables que se iniciaran en la funcion initDomVariables */

  containerSearch: null,
  formSearch: null,
  inputSearch: null,
  resetSearch: null,
  containerSuggestions: null,
  toggleSearchBtn: null,

  /* fin de variables a inicializar */

  requestTimeoutInstance: null,
  requestTimeout: 500,
  prevSearchUrl: '',

    /* result containers*/
    selectedDivContainer: null,
    nextDivContainer: null,
      
    /* result list items */
    selectedListItem: null,
    nextListItem: null,
    searchSuggestRecent: null,
    searchSuggestRecentList: null,

  init() {
    $(document).ready(() => {
      this.initDomVariables();
      this.initEvents();
      if (!this.inputSearch.val()) {
        this.resetSearch.hide();
      }
    });
  },

  initDomVariables() {
    this.containerSearch = $('#desktop-search');
    this.formSearch = $('#desktop-search__form');
    this.inputSearch = $('#desktop-search__form-input');
    this.resetSearch = $('#desktop-search__form-reset');
    this.containerSuggestions = $('#desktop-search__inner');
    this.overlay = $('#overlay-content');
  },

  initEvents() {
    this.resetSearch.bind('click', this.handleResetSearch.bind(this));
    this.formSearch.bind('submit', this.handleSubmitForm.bind(this));
    this.inputSearch.bind('focus', this.handleFocusSearch.bind(this));
    this.inputSearch.bind('keyup', this.handleKeyUpSearch.bind(this));
    this.inputSearch.bind('keydown', this.handlePressedArrowKeys.bind(this));
    this.inputSearch.bind('keydown', this.handleEnterSearch.bind(this));

    // hide component
    // this.inputSearch.bind('blur', this.handleBlurSearch.bind(this));
    $(document).bind('click', this.handleBlurSearch.bind(this));
    this.containerSearch.bind('click', e => { e.stopPropagation(); });
  },

  handleResetSearch(e) {
    this.getData('');
    this.resetSearch.hide();
    this.inputSearch.val('');
    this.inputSearch.attr('value', '');
    this.inputSearch.focus();
  },

  handleSubmitForm(e) {
    const currentSearch = this.inputSearch.val().trim();
    if (!currentSearch || this.selectedListItem) {
        e.preventDefault();
    } else {
        localStorage.setItem('search-text', currentSearch);

        let storedSearches = JSON.parse(localStorage.getItem('recent-search-phrases')) || [];
        const findInStore = storedSearches.find(item => item === currentSearch);
        if (!findInStore) {
            if(storedSearches.length === 5) {
                storedSearches.shift();
                }
            storedSearches.push(currentSearch);
        }
        localStorage.setItem('recent-search-phrases', JSON.stringify(storedSearches));
    }
  },

  handleFocusSearch(e) {
    // hide desktop menu if it´s open
    $('.navbarToggle input[type="checkbox"]:checked').click();

    // hide customer popup if it´s open
    if (!!window.headerCustomer.$popup && window.headerCustomer.$popup.hasClass('header-popup--active')) {
      window.headerCustomer.handleClick();
    }

    var currentSearchValue = e.target.value;
    this.getData(currentSearchValue);
    this.containerSuggestions.addClass('desktop-search__inner--open');
    this.containerSuggestions.addClass('desktop-search__inner--loading');
    this.overlay.addClass('js-desktop-search-overlay--show');
    $('html, body').addClass('disabled-scroll');
      
    // clear vars for arrowkeys navigation
    this.clearVarsForArrowKeysNavigation();
  },

  handleBlurSearch(e) {
    this.prevSearchUrl = '';
    this.containerSuggestions.removeClass('desktop-search__inner--open');
    this.overlay.removeClass('js-desktop-search-overlay--show');

    // clear vars for arrowkeys navigation
    this.clearVarsForArrowKeysNavigation();

    const isMiniCartOpen = !!$('#box-minicart').has(e.target).length;
    if (isMiniCartOpen) return;
    $('html, body').removeClass('disabled-scroll');
  },

  handleKeyUpSearch(e) {
    var currentSearchValue = this.inputSearch.val();
    if (currentSearchValue.length) {
      this.resetSearch.show();
    } else {
      this.resetSearch.hide();
    }
    this.getSuggestions(currentSearchValue);
  },

  getSuggestions(value) {
    this.containerSuggestions.addClass('desktop-search__inner--loading');
    clearInterval(this.requestTimeoutInstance);
    this.requestTimeoutInstance = window.setTimeout(() => {
      this.getData(value)
    }, this.requestTimeout);
  },

  getData(value) {
    var minLengthToSearch = SitePreferences.SIMPLE_SEARCH_FIELD_MIN_LENGTH;

    var requestUrl = value.length >= minLengthToSearch
      ? utils.appendParamToURL(Urls.searchsuggestions, 'q', value)
      : Urls.einsteinsuggestions;

    if (requestUrl === this.prevSearchUrl) {
      if (this.containerSuggestions.hasClass('desktop-search__inner--loading')) {
        this.containerSuggestions.removeClass('desktop-search__inner--loading');
      }
      return;
    }

    this.prevSearchUrl = requestUrl;
    $.get(requestUrl, (data) => {
      if (this.containerSuggestions.hasClass('desktop-search__inner--loading')) {
        this.containerSuggestions.removeClass('desktop-search__inner--loading');
      }
        this.containerSuggestions.html(data);
        
        // recent search phrases
        this.searchSuggestRecent = $('#search-suggest-recent');
        this.searchSuggestRecentList = $('#search-suggest-recent-list');
        // hide container if no localstorage
        if (localStorage.getItem('recent-search-phrases')) {
            this.searchSuggestRecent.show();
            this.fillRecentSearchPhrases();
        } else {
            this.searchSuggestRecent.hide();
        }
    });
  },
  
    // TODO:
    // => Limpiar variables al iniciar nueva busqueda
    // => Refactor, la funcion es gigante y se repiten varias lineas segun las condiciones
    handlePressedArrowKeys(e) {

        var arrowKey = e.keyCode;
        var desktopSearchResultsCol = $(this.containerSuggestions).find('.desktop-search__results > .desktop-search__results-col > div');
        var desktopSearchResults = $(this.containerSuggestions).find('.desktop-search__results > div');

        if (arrowKey === 38) { // up arrow
            if (desktopSearchResultsCol.length > 0) {
                if (this.selectedDivContainer) {
                    var desktopSearchResultsList = $(this.containerSuggestions).find(`#${this.selectedDivContainer.attr('id')} > ul > li`);
                    if (desktopSearchResultsList.index(this.selectedListItem) === 0) {
                        this.selectedListItem.removeClass('desktop-search__result--active-list-item');
                        this.nextDivContainer = desktopSearchResultsCol.eq(desktopSearchResultsCol.index(this.selectedDivContainer) - 1);
                        if (this.nextDivContainer.length > 0) {
                            this.selectedDivContainer = desktopSearchResultsCol.eq(desktopSearchResultsCol.index(this.selectedDivContainer) - 1);
                            this.selectedListItem = this.nextDivContainer.find('ul > li').eq(this.selectedDivContainer.find('ul > li').length - 1).addClass('desktop-search__result--active-list-item');
                        } else {
                            this.selectedDivContainer = desktopSearchResultsCol.eq(desktopSearchResultsCol.length-1);
                            this.selectedListItem = this.selectedDivContainer.find('ul > li').eq(this.selectedDivContainer.find('ul > li').length - 1).addClass('desktop-search__result--active-list-item');
                        }
                    } else {
                        this.selectedListItem.removeClass('desktop-search__result--active-list-item');
                        this.nextListItem = this.selectedListItem.prev();
                        if (this.nextListItem.length > 0) {
                            this.selectedListItem = this.nextListItem.addClass('desktop-search__result--active-list-item');
                        } else {
                            // cuando nextItem es cero por ahora reinicio vars
                            this.clearVarsForArrowKeysNavigation();
                        }
                    }
                } else {
                    this.selectedDivContainer = desktopSearchResultsCol.eq(desktopSearchResultsCol.length-1);
                    this.selectedListItem = this.selectedDivContainer.find('ul > li').eq(this.selectedDivContainer.find('ul > li').length - 1).addClass('desktop-search__result--active-list-item');
                }
            } else {
                if (this.selectedDivContainer) {
                    var desktopSearchResultsList = $(this.containerSuggestions).find(`#${this.selectedDivContainer.attr('id')} > ul > li`);
                    if (desktopSearchResultsList.index(this.selectedListItem) === 0) {
                        this.selectedListItem.removeClass('desktop-search__result--active-list-item');
                        this.nextDivContainer = desktopSearchResults.eq(desktopSearchResults.index(this.selectedDivContainer) - 1);
                        if (this.nextDivContainer.length > 0) {
                            this.selectedDivContainer = desktopSearchResults.eq(desktopSearchResults.index(this.selectedDivContainer) - 1);
                            this.selectedListItem = this.nextDivContainer.find('ul > li').eq(this.selectedDivContainer.find('ul > li').length - 1).addClass('desktop-search__result--active-list-item');
                        } else {
                            this.selectedDivContainer = desktopSearchResults.eq(desktopSearchResults.length - 1);
                            this.selectedListItem = this.selectedDivContainer.find('ul > li').eq(this.selectedDivContainer.find('ul > li').length - 1).addClass('desktop-search__result--active-list-item');
                        }
                    } else {
                        this.selectedListItem.removeClass('desktop-search__result--active-list-item');
                        this.nextListItem = this.selectedListItem.prev();
                        if (this.nextListItem.length > 0) {
                            this.selectedListItem = this.nextListItem.addClass('desktop-search__result--active-list-item');
                        } else {
                            // cuando nextItem es cero por ahora reinicio vars
                            this.clearVarsForArrowKeysNavigation();
                        }
                    }
                } else {
                    this.selectedDivContainer = desktopSearchResults.eq(desktopSearchResults.length - 1);
                    this.selectedListItem = this.selectedDivContainer.find('ul > li').eq(this.selectedDivContainer.find('ul > li').length - 1).addClass('desktop-search__result--active-list-item');
                }
            }
        } else if (arrowKey === 40) { // down arrow
            if (desktopSearchResultsCol.length > 0) {
                if (this.selectedDivContainer) {
                    var desktopSearchResultsList = $(this.containerSuggestions).find(`#${this.selectedDivContainer.attr('id')} > ul > li`);
                    if (desktopSearchResultsList.index(this.selectedListItem) === desktopSearchResultsList.length-1) {
                        this.selectedListItem.removeClass('desktop-search__result--active-list-item');
                        this.nextDivContainer = desktopSearchResultsCol.eq(desktopSearchResultsCol.index(this.selectedDivContainer) + 1);
                        if (this.nextDivContainer.length > 0) {
                            this.selectedDivContainer = desktopSearchResultsCol.eq(desktopSearchResultsCol.index(this.selectedDivContainer) + 1);
                            desktopSearchResultsCol.index(this.selectedDivContainer)
                            this.selectedListItem = this.nextDivContainer.find('ul > li').eq(0).addClass('desktop-search__result--active-list-item');
                        } else {
                            this.selectedDivContainer = desktopSearchResultsCol.eq(0);
                            this.selectedListItem = this.selectedDivContainer.find('ul > li').eq(0).addClass('desktop-search__result--active-list-item');
                        }
                    } else {
                        this.selectedListItem.removeClass('desktop-search__result--active-list-item');
                        this.nextListItem = this.selectedListItem.next();
                        if (this.nextListItem.length > 0) {
                            this.selectedListItem = this.nextListItem.addClass('desktop-search__result--active-list-item');
                        } else {
                            // cuando nextItem es cero por ahora reinicio vars
                            this.clearVarsForArrowKeysNavigation();
                        }
                    }
                } else {
                    this.selectedDivContainer = desktopSearchResultsCol.eq(0);
                    this.selectedListItem = this.selectedDivContainer.find('ul > li').eq(0).addClass('desktop-search__result--active-list-item');
                }
            } else {
                if (this.selectedDivContainer) {
                    var desktopSearchResultsList = $(this.containerSuggestions).find(`#${this.selectedDivContainer.attr('id')} > ul > li`);
                    if (desktopSearchResultsList.index(this.selectedListItem) === desktopSearchResultsList.length-1) {
                        this.selectedListItem.removeClass('desktop-search__result--active-list-item');
                        this.nextDivContainer = desktopSearchResults.eq(desktopSearchResults.index(this.selectedDivContainer) + 1);
                        if (this.nextDivContainer.length > 0) {
                            this.selectedDivContainer = desktopSearchResults.eq(desktopSearchResults.index(this.selectedDivContainer) + 1);
                            this.selectedListItem = this.nextDivContainer.find('ul > li').eq(0).addClass('desktop-search__result--active-list-item');
                        } else {
                            this.selectedDivContainer = desktopSearchResults.eq(0);
                            this.selectedListItem = this.selectedDivContainer.find('ul > li').eq(0).addClass('desktop-search__result--active-list-item');
                        }
                    } else {
                        this.selectedListItem.removeClass('desktop-search__result--active-list-item');
                        this.nextListItem = this.selectedListItem.next();
                        if (this.nextListItem.length > 0) {
                            this.selectedListItem = this.nextListItem.addClass('desktop-search__result--active-list-item');
                        } else {
                            // cuando nextItem es cero por ahora reinicio vars
                            this.clearVarsForArrowKeysNavigation();
                        }
                    }
                } else {
                    this.selectedDivContainer = desktopSearchResults.eq(0);
                    this.selectedListItem = this.selectedDivContainer.find('ul > li').eq(0).addClass('desktop-search__result--active-list-item');
                }
            }
        }
    },

    handleEnterSearch(e) {
        if (e.keyCode === 13 && this.selectedListItem) {
            this.selectedListItem.find('a')[0].click();
       }
    },

    clearVarsForArrowKeysNavigation() {
        this.selectedListItem  && this.selectedListItem.removeClass('desktop-search__result--active-list-item');
        this.selectedListItem = null;
        this.nextListItem = null;
        this.selectedDivContainer = null;
        this.nextDivContainer = null;
    },

    fillRecentSearchPhrases() {
        const storedSearches = localStorage.getItem('recent-search-phrases') ? JSON.parse(localStorage.getItem('recent-search-phrases')).reverse() : [];
        storedSearches.forEach(element => {
            let urlSearch = Urls.searchShow + '?q=' + element;
            this.searchSuggestRecentList.append(`
                <li>
                  <a href="${urlSearch}">
                    <span>
                      ${element}
                    </span>
                    <div class="hidden-md hidden-lg">  
                      <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.376 19.576a.523.523 0 0 0 .74-.008.523.523 0 0 0 .007-.739L10.39 9.097l8.565-.088a.535.535 0 0 0 .528-.528.51.51 0 0 0-.517-.517l-9.103.093a1.278 1.278 0 0 0-1.258 1.259l-.093 9.103a.51.51 0 0 0 .516.517.535.535 0 0 0 .528-.528l.088-8.565 9.732 9.733Z" fill="#1A1A1A"/></svg>
                    </div>
                  </a>
                </li>
            `)
        });
    }
};

module.exports = desktopSearch;
