'use strict';

var dialog = require('./dialog'),
    product = require('./pages/product'),
    util = require('./util'),
    selectbox = require('./selectbox'),
    page = require('./page'),
    _ = require('lodash'),
    liftingPdp = require('./pages/product/liftingPdp');


var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var quickview = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });
    },

    setup: function (qvUrl) {

        product.initializeEvents();
        if (document.querySelector('.js-lifting-pdp') !== null) {
            window.addEventListener("load", () => {
                liftingPdp.init();
            });
        }
        this.productLinkIndex = _(this.productLinks).findIndex(function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url;
        if (!this.exists()) {
            this.init();
        }
        url = makeUrl(options.url, options.source, options.productlistid);

        dialog.open({
            target: this.$container,
            url: url,
            options: {
                title: window.Resources.CART_EDIT_PRODUCT_MODAL_TITLE,
                draggable: false,
                autoOpen: false,
                responsive: true,
                modal: true,
                fluid: true,
                width: 900,
                dialogClass: 'ui-dialog-full edit-details-overlay',
                open: function () {
					$('html').addClass('prevent-scroll');
                    $('.pt_cart').css('overflow', 'hidden');
                    $(window).on('resize', function() {
                        $('#QuickViewDialog').dialog("option", "position", { my: 'center', at: 'center', of: window });
                    });
                    $('.ui-widget-overlay').bind('click', function(){   
                        $("#QuickViewDialog").dialog('close');
                        enabledScroll();
                    });
                    this.setup(url);
                    if (typeof options.callback === 'function') { options.callback(); }
                }.bind(this),
                close: function() {
					$('.pt_cart').css('overflow', 'auto');
                    $('html').removeClass('prevent-scroll'); 
                },
                position: {
                    my: 'center',
                    at: 'center',
                    of: window
                }
            }
        });
    },
    /**
     * @description show quick view dialog of PDP_default2
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    showPdpDefault2: function (options) {
        var url;
        if (!this.exists()) {
            this.init();
        }
        url = makeUrl(options.url, options.source, options.productlistid);
        dialog.open({
            target: this.$container,
            url: url,
            options: {
                title: window.Resources.CART_EDIT_PRODUCT_MODAL_TITLE,
                draggable: false,
                autoOpen: false,
                responsive: true,
                modal: true,
                fluid: true,
                width: 900,
                dialogClass: 'ui-dialog-full edit-details-overlay',
                modal: true,
                open: function () {
					$('html').addClass('prevent-scroll');
                    $('.pt_cart').css('overflow', 'hidden');
                    $(window).on('resize', function() {
                        $('#QuickViewDialog').dialog("option", "position", { my: 'center', at: 'center', of: window });
                    });
                    $('.ui-widget-overlay').bind('click', function(){   
                        $("#QuickViewDialog").dialog('close');
                        enabledScroll();
                    });
                    this.setup(url);
                    if (typeof options.callback === 'function') { options.callback(); }
                }.bind(this),
                close: function() {
					$('.pt_cart').css('overflow', 'auto');
					$('html').removeClass('prevent-scroll');
                },
                position: {
                    my: 'center',
                    at: 'center',
                    of: window
                }
            }
        });
    },
    loadCont: function (options) {
        var url;
        if (!this.exists()) {
            this.init();
        }
        url = makeUrl(options.url, options.source, options.productlistid);
        $.ajax({
            url: url,
            success: function (response) {
        		var plpDiv = $(response).find('.js-update-plp-quick-view');

            	if (plpDiv.length == 0) {
            		page.reload();
            		return;
				}

				if (options.element.children('.js-rollover-container').html() == '') {
		            var targetContainer = $(options.element).find('.js-rollover-container');

	                targetContainer.html(response);

	                var $targetElement = $(options.element).find('.content-details');
	                var targetElementHeight = $targetElement.height();
	                var offsetBottom = $targetElement.offset().top + targetElementHeight;
                    var containerOffsetBottom = $(options.element).offset().top + $(options.element).outerHeight();

		       	    $(targetContainer).find('.colors-plp').remove();

		       	    var $form = $(targetContainer).find('form');
	    	    	if ($form.length > 0) {
	    	    		var $options = $form.find('.js-product-option');
		 		        if ($options.length > 0 && $options.val().length == 0) {
		 		        	//error message
		 		        	$form.find('.add-to-cart').attr('disabled', 'disabled');
		 		        	$form.find('.add-to-cart').attr('title', $options.find(":selected").text());
		 		        	$options.addClass('error');
		 		        }
	    	    	}

	                // hideSwatches();
	            	product.initializeEvents(targetContainer);
                    if (document.querySelector('.js-lifting-pdp') !== null) {
                        window.addEventListener("load", () => {
                            liftingPdp.init();
                        });
                    }
				}

            },
            complete: function() {
                var targetContainer = $(options.element).find('.js-rollover-container');
                selectbox.init(targetContainer.find($('.js-option-select')));
            }
        });

    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = quickview;
