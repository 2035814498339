'use strict';

const ajax = require('./../../ajax');
const page = require('./../../page');
const util = require('./../../util');
const newLogin = require('./../../new-login');
const newRegister = require('./../../new-register');
const { returnPageName } = require('../pageInformation');
const account = require('../../pages/account');

const headerCustomer = {
  $el: null,

  $popup: null,

  $overlay: null,

  init() {
    this.initDOM();
    this.initEvents();
  },

  initDOM() {
    this.$el = $('.header-customer');
    this.$overlay = $('#overlay-content');
  },

  initEvents() {
    this.$el.off();

    // if (this.$el.hasClass('header-customer--authenticated')) {}

    if (util.isMobile()) {
      this.$el.on('click', () => {
        if (this.$el.hasClass('header-customer--authenticated')) {
          // page.redirect(window.Urls.accountShow);
          return;
        } else {
          newLogin.loadModal();
        }
      });
    } else {
      this.$el.on('mouseenter', this.handleOpen.bind(this));
      this.$el.on('mouseleave', this.handleClose.bind(this));
    }
  },

  setUser(userName) {
    const heading = `¡Hola, ${userName}!`;
    const subheading = 'Mi Cuenta';

    this.$el.addClass('header-customer--authenticated');
    this.$el.find('.header-customer__heading').text(heading);
    this.$el.find('.header-customer__subheading')
      .addClass('header-customer__subheading--loggedin')
      .text(subheading);

    this.initEvents();

    if (this.$popup) {
      this.$popup.remove();
      this.$popup = null;
    }
  },

  removeUser() {
    const heading = '¡Hola!';
    const subheading = 'Inicia Sesión o Regístrate';

    this.$el.removeClass('header-customer--authenticated');
    this.$el.find('.header-customer__heading').text(heading);
    this.$el.find('.header-customer__subheading')
      .removeClass('header-customer__subheading--loggedin')
      .text(subheading);

    this.initEvents();
  },

  showOverlay(e) {
    e.stopPropagation();

    this.$overlay.addClass('js-header-customer-overlay--show');
  },

  hideOverlay() {
    $('body').off();

    this.$overlay.removeClass('js-header-customer-overlay--show');
  },

  handleOpen(e) {
    const activeClass = 'header-customer--active';
    this.$el.addClass(activeClass);

    const dataSrc = this.$el.find('button').data('src');
    if (!dataSrc) {
      return;
    }

    if (!this.$popup) {
      const url = util.appendParamToURL(dataSrc, 'format', 'ajax');
      ajax.load({
        url,
        callback: response => {
          // console.log('data loaded');
          this.$popup = $(response);

          $('.header-customer__popup-placeholder').append(this.$popup);
          this.$popup.off('click', this.handlerClickPopup);
          this.$popup.on('click', this.handlerClickPopup);
          setTimeout(() => {
            if ($('.header-customer:hover').length >= 1) {
              this.$popup.addClass('header-popup--active');
              this.showOverlay(e);
            }
          }, 50);
        },
      });
    } else {
      this.$popup.addClass('header-popup--active');
      this.showOverlay(e);
    }
  },

  handleClose(e) {
    this.hideOverlay(e);
    
    if (this.$el && this.$el instanceof jQuery) {
      this.$el.removeClass('header-customer--active');
    }

    if (this.$popup && this.$popup instanceof jQuery) {
      this.$popup.removeClass('header-popup--active');
    }
  },

  handlerClickPopup(e) {
    const $el = $(e.target);
    const tagName = $el.prop('tagName');

    if (tagName === 'BUTTON' || tagName === 'A') {
      if ($el.data('action') === 'login') {
        newLogin.loadModal($el);
      }

      if (account.requestLogin($el)) {
        newLogin.loadModal($el, Resources.ACCOUNT_LOGIN_MODAL_SUBTITLE || '');
        e.preventDefault();
      }

      return;
    }
  },
};

module.exports = headerCustomer;
