'use strict'

const refinementFilters = {

    containerRefinements: null,
    /* refinements containers*/
    refinementDivContainer: null,
    nextDivContainer: null,
    desktopRefinementsResults: null,
    focuseableRefinements : null,
      
    /* result list items */
    selectedListItem: null,
    nextListItem: null,

    // max/min price 
    maxPrice: null,
    minPrice: null,

    init() {
        let self = this;
        $(document).ready(() => {
            self.initDomVariables();
            self.initEvents();
        });
    },

    initDomVariables() {
        this.containerRefinements = $('.secondary-refinement-wrapper');
        this.desktopRefinementsResults = $(this.containerRefinements).find('.refinement-content > div');
        this.focuseableRefinements = $('.refinement-content').find('div.refinement--navigable>:first-child, li');
        this.maxPrice = $('#maxPrice');
        this.minPrice = $('#minPrice');
      },

    initEvents() {
        let self = this;
        this.focuseableRefinements.on('keydown', (e) => self.handlePressedArrowKeys(e));
        this.focuseableRefinements.focus( (event) => self.handleFocusToggle(event)); 
        this.maxPrice.on({
            keypress: () => isNumber(),
            paste: () => onAction(),
            copy: () => onAction(),
            input: () => showApplyPriceRange(),
        });
        this.minPrice.on({
            keypress: () => isNumber(),
            paste: () => onAction(),
            copy: () => onAction(),
            input: () => showApplyPriceRange(),
        });
    },
    handleFocusToggle(e){
        if(e.target.nodeName.toUpperCase() === 'DIV'){
            this.refinementDivContainer = $(e.target.parentElement);
        }else{
            this.refinementDivContainer = $(e.target.closest('div'));
        }
        this.selectedListItem = $(e.target.parentElement);
        changeClassSelected(this.selectedListItem);
    },

    handlePressedArrowKeys(e) {
        $('.refinement--focus').removeClass('refinement--focus');
        this.pressKeyFunction(e)

    },
    pressKeyFunction(e){
        var arrowKey = e.keyCode;
        let refinementContainerType = this.refinementDivContainer?.attr('id');
        let activeElement = document.activeElement;
        let menuRefinementElementIsOpen = this.refinementDivContainer.find('.toggle')[0]?.classList.contains('ui-state-active');
        switch (arrowKey) {
            case 32: //space key case
                if(this.focuseableRefinements.index(activeElement) > -1){
                    e.preventDefault();
                    let openerContainer = null;
                    if(refinementContainerType === 'refinement-express-delivery' || refinementContainerType === 'refinement-sameday-delivery' 
                        || refinementContainerType === 'refinement-pickup-delivery'){
                        openerContainer = this.selectedListItem.find('input');
                    }else{
                        openerContainer = this.selectedListItem.find(':first-child').first().eq(0);
                    }
                    openerContainer.trigger('click');
                    changeClassSelected(this.selectedListItem);
                }
                break;
            case 27://escape key case
                if (this.refinementDivContainer) {
                    if(menuRefinementElementIsOpen){
                        let closerContainer = this.refinementDivContainer.find(':first-child').first().eq(0);
                        this.setSelectedListItem(this.refinementDivContainer);
                        closerContainer.trigger('click');
                    }
                }
                break;
            case 37: //left arrow case
                if (this.refinementDivContainer) {
                    let desktopRefinementsResultsList = $(this.containerRefinements).find(`#${this.refinementDivContainer.attr('id')} > ul > li`);
                    if(refinementContainerType === 'refinement-refinementColor' && menuRefinementElementIsOpen){
                        let actualRefinementIndex = desktopRefinementsResultsList.index(this.selectedListItem);
                        if(actualRefinementIndex >= 0){
                            if(actualRefinementIndex % 4 === 0){ //si es primer elemento de fila colores
                                if(actualRefinementIndex + 3 > desktopRefinementsResultsList.length-1){
                                    let previousColor = desktopRefinementsResultsList.last();
                                    if(previousColor.length > 0){
                                        this.setSelectedListItem(previousColor);
                                    }
                                }else{
                                    let previousColor = desktopRefinementsResultsList.eq(actualRefinementIndex + 3);
                                    if(previousColor.length > 0){
                                        this.setSelectedListItem(previousColor);
                                    }
                                }
                            }else{
                                let previousColor = this.selectedListItem.prev();
                                if(previousColor.length > 0){
                                    this.setSelectedListItem(previousColor);
                                }
                            }
                        }
                    } 
                }
                break;
            case 39: //rigth arrow case
                if (this.refinementDivContainer) {
                    let desktopRefinementsResultsList = $(this.containerRefinements).find(`#${this.refinementDivContainer.attr('id')} > ul > li`);
                    if(refinementContainerType === 'refinement-refinementColor' && menuRefinementElementIsOpen){
                        let actualRefinementIndex = desktopRefinementsResultsList.index(this.selectedListItem);
                        if(actualRefinementIndex >= 0){
                            if(actualRefinementIndex % 4 === 3 || actualRefinementIndex === desktopRefinementsResultsList.length -1){ //si es último elemento de fila colores
                                let nextColor = desktopRefinementsResultsList.eq(Math.trunc(actualRefinementIndex / 4) * 4);
                                if(nextColor.length > 0){
                                    this.setSelectedListItem(nextColor);
                                }
                            }else{
                                let nextColor = this.selectedListItem.next();
                                if(nextColor.length > 0){
                                    this.setSelectedListItem(nextColor);
                                }
                            }
                        }
                    }
                }
                break;
            case 38: //up arrow case
                e.preventDefault();
                if (this.refinementDivContainer) {
                    let listContainerSelector = '#'.concat(this.refinementDivContainer.attr('id'), ' > ul > li');
                    var desktopRefinementsResultsList = $(this.containerRefinements).find(listContainerSelector);
                    if(menuRefinementElementIsOpen){//Actual Filtro activo
                        let actualRefinementIndex = desktopRefinementsResultsList.index(this.selectedListItem);
                        if(actualRefinementIndex === -1){//Seleccionado el Título de filtro
                            this.selectPreviousDiv();
                        }else{
                            switch(refinementContainerType){
                                case 'refinement-refinementColor':
                                    if(actualRefinementIndex - 4 < 0){
                                        this.setSelectedListItem(this.refinementDivContainer)
                                    }else{
                                        let previousUlContainer = desktopRefinementsResultsList.eq(actualRefinementIndex - 4);//Se pasa directo arriba
                                        if (previousUlContainer.length > 0) {
                                            this.setSelectedListItem(previousUlContainer);
                                        }
                                    }
                                    break;
                                default:
                                    if(actualRefinementIndex === 0){
                                        this.setSelectedListItem(this.refinementDivContainer);
                                    }else{
                                        let previousUlContainer = desktopRefinementsResultsList.eq(actualRefinementIndex - 1);
                                        if (previousUlContainer.length > 0) {
                                            this.setSelectedListItem(previousUlContainer);
                                        }
                                    }
                                    break;
                            }
                        }
                    }else{
                        let actualSelectedIndex = desktopRefinementsResultsList.index(this.selectedListItem);
                        if(actualSelectedIndex === -1){
                            this.selectPreviousDiv();
                        }else if (actualSelectedIndex === 0) {
                            const actualDivContainerIndex = this.desktopRefinementsResults.index(this.refinementDivContainer);
                            this.nextDivContainer = this.desktopRefinementsResults.eq(actualDivContainerIndex - 1);
                            if (this.nextDivContainer.length > 0) {
                                this.refinementDivContainer = this.desktopRefinementsResults.eq(actualDivContainerIndex - 1);
                                this.setSelectedListItem(this.nextDivContainer.eq(this.refinementDivContainer.length - 1));
                            } else {
                                this.refinementDivContainer = this.desktopRefinementsResults.eq(this.desktopRefinementsResults.length - 1);
                                this.setSelectedListItem(this.refinementDivContainer.eq(this.refinementDivContainer.length - 1));
                            }
                        } else {
                            this.nextListItem = this.selectedListItem.prev();
                            if (this.nextListItem.length > 0) {
                                this.setSelectedListItem(this.nextListItem);
                            } else {
                                // cuando nextItem es cero por ahora reinicio vars
                                this.clearVarsForArrowKeysNavigation();
                            }
                        }
                    }
                } else {
                    this.refinementDivContainer = this.desktopRefinementsResults.eq(this.desktopRefinementsResults.length - 1);
                    this.setSelectedListItem(this.refinementDivContainer.eq(this.refinementDivContainer.length - 1));
                }
                break;
            case 40: //down arrow case
                e.preventDefault();
                if (this.refinementDivContainer) {
                    let listContainerSelector = '#'.concat(this.refinementDivContainer.attr('id'), ' > ul > li');
                    const desktopRefinementsResultsList = $(this.containerRefinements).find(listContainerSelector);
                    const actualDivContainerIndex = this.desktopRefinementsResults.index(this.refinementDivContainer);

                    if( menuRefinementElementIsOpen ){
                        let actualRefinementIndex = desktopRefinementsResultsList.index(this.selectedListItem);
                        if(actualRefinementIndex === -1){
                            this.setSelectedListItem(desktopRefinementsResultsList.first());
                        }else{
                            switch(refinementContainerType){
                                case 'refinement-refinementColor':
                                    if(actualRefinementIndex + 4 >= desktopRefinementsResultsList.length){
                                        this.nextDivItem = this.refinementDivContainer.next();//Se pasa al siguiente Filtro
                                        if(this.nextDivItem.length >0){
                                            this.refinementDivContainer = this.nextDivItem;
                                            this.setSelectedListItem(this.refinementDivContainer);
                                        }
                                    }else{
                                        this.nextUlContainer = desktopRefinementsResultsList.eq(actualRefinementIndex + 4);//Cambia directo color abajo
                                        if (this.nextUlContainer.length > 0) {
                                            this.setSelectedListItem(this.nextUlContainer);
                                        }
                                    }
                                    break;
                                default:
                                    if(actualRefinementIndex === desktopRefinementsResultsList.length - 1){
                                        this.nextDivContainer = this.desktopRefinementsResults.eq(actualDivContainerIndex + 1);
                                        if(this.nextDivContainer.length > 0){
                                            this.refinementDivContainer = this.nextDivContainer.eq(0);
                                            this.setSelectedListItem(this.refinementDivContainer);
                                        }
                                    }else{
                                        this.nextUlContainer = desktopRefinementsResultsList.eq(actualRefinementIndex + 1);
                                        if (this.nextUlContainer.length > 0) {
                                            this.setSelectedListItem(this.nextUlContainer);
                                        }
                                    }
                                    break;
                            }
                        }
                    }else{
                        if (desktopRefinementsResultsList.index(this.selectedListItem) === desktopRefinementsResultsList.length-1) {
                            this.nextDivContainer = this.desktopRefinementsResults.eq(actualDivContainerIndex + 1);
                            if (this.nextDivContainer.length > 0) {
                                this.refinementDivContainer = this.desktopRefinementsResults.eq(actualDivContainerIndex + 1);
                                this.setSelectedListItem(this.nextDivContainer.eq(0));
                            } else {
                                this.refinementDivContainer = this.desktopRefinementsResults.eq(0);
                                this.setSelectedListItem(this.refinementDivContainer.eq(0));
                            }
                        } else {
                            this.nextListItem = this.selectedListItem.next();
                            if (this.nextListItem.length > 0) {
                                this.setSelectedListItem(this.nextListItem);
                            } else {
                                // cuando nextItem es cero por ahora reinicio vars
                                this.clearVarsForArrowKeysNavigation();
                            }
                        }
                    }
                } else {
                    this.refinementDivContainer = this.desktopRefinementsResults.eq(0);
                    this.setSelectedListItem(this.refinementDivContainer.eq(0));
                }
            default:
                break;
        }
        if(this.selectedListItem?.css('display')?.toLowerCase() === 'none'){
            this.pressKeyFunction(e);
        }
    },
    setSelectedListItem(elementToSelect){
        this.selectedListItem = elementToSelect;
        this.selectedListItem.find(':first-child').first().focus();
    },
    selectPreviousDiv(){
        let previousDivContainer =  this.refinementDivContainer.prev();
        if(previousDivContainer.length > 0){
            this.refinementDivContainer = previousDivContainer;
            let previousMenuRefinementElementIsOpen = previousDivContainer.find('.toggle')[0]?.classList.contains('ui-state-active');
            if(previousMenuRefinementElementIsOpen){
                let desktopRefinementsResultsList = $(this.containerRefinements).find(`#${this.refinementDivContainer.attr('id')} > ul > li`);
                this.setSelectedListItem(desktopRefinementsResultsList.last());
            }else{
                this.setSelectedListItem(this.refinementDivContainer);
            }
        }
    },
    clearVarsForArrowKeysNavigation() {
        this.selectedListItem  && this.selectedListItem.find(':first-child').first().removeClass('refinement--focus');
        this.selectedListItem = null;
        this.nextListItem = null;
        this.refinementDivContainer = null;
        this.nextDivContainer = null;
    },
};

module.exports = refinementFilters;

function changeClassSelected(elementToSelect) {
    $('.refinement--focus').removeClass('refinement--focus');
    $(elementToSelect).find(':first-child').first().addClass('refinement--focus');
}

function isNumber() {
    var keycode = event.which;
    if (!(event.shiftKey == false && (keycode >= 48 && keycode <= 57))) {
        event.preventDefault();
    }
}

function showApplyPriceRange() {
    $('#input-price-error').html('');
    $('#minPrice').removeClass('input-price-invalid');
    $('#maxPrice').removeClass('input-price-invalid');
    if($('#minPrice').val().length > 0 || $('#maxPrice').val().length > 0) {
        $('#minPrice').val(formatCurrency($('#minPrice').val()));
        $('#maxPrice').val(formatCurrency($('#maxPrice').val()));
    }
}

function formatCurrency(currency) {
    if(currency.indexOf('.') !== -1) {
        currency = currency.toString().replace(/\./g, '');
    }
    return currency.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function onAction() {
    return false;
}