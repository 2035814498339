'use strict';

const { throttle } = require('lodash');
const dataLayer = require('./dataLayer.js');
const hamburgermenu = {
	init: () => {
		const menuHeader = (function() {
			let openedTerminalCat = null;
            const chkMenuTrigger = document.querySelector('#GTM_header_navbar');
			const navBar = document.querySelector('#js-navbarToggle');
            const menuLi = document.querySelector('#js-menu-li');
			const topLi = document.querySelectorAll('#js-menu-li div.subnav-content');
			const topLiA = document.querySelectorAll('a.js-hamburger-top-li-a');
			const LiTopElement = document.querySelectorAll('li.js-hamburger-top-element-li');
			const terminalA = document.querySelectorAll('a.js-hamburger-terminal-li-a');
			const subNavLi = document.querySelectorAll('ul.terminal > li');
			const back = document.querySelectorAll('.back');
			const closeMenu = document.querySelectorAll('.closed');
			const subnavDiv = document.querySelectorAll('div.subnav');
			const search = document.querySelector('#js-box-search-header');
            const loginUser = document.querySelector('.login-user');
            const jsClose =  document.querySelector('.js-close');

			return {
				eventsInit: function () {
					if (chkMenuTrigger.checked) {
						chkMenuTrigger.dispatchEvent(
							new MouseEvent('click', {
								bubbles: true,
								cancelable: true,
								view: window,
						}));
						this.adjustNavBar(false);
					}
					document.querySelector('.js-hidde-element-in-load').classList.remove('hidden');
					// document.querySelector('#js-box-search-header').classList.remove('hidden');
					if (this.isMobileScreen()) {
						this.disableHrefElements();
						this.hideIntermediateCategory();
						LiTopElement.forEach(element => {
							element.addEventListener('click', event => {
								element.querySelector('div').classList.remove('hidden');
								this.adjustNavBar(true);
							});
						});
						const loginImg = document.querySelector('.login-user-mob');
						if (loginImg) {
							loginImg.addEventListener('click', () => {
								if (!document.querySelector('#clickLogin')) {
									if (document.querySelector('.js-account-panel')) {
										document.querySelector('.js-account-panel').classList.toggle('hidden');
										if (!document.querySelector('.js-account-panel').classList.contains('hidden') && document.querySelector('#js-box-search-header').classList.contains('visible-search')) {
											document.querySelector('.js-show-search').click();
										}
									}
								}	
							});
						}
					} else {
						window.addEventListener('resize', throttle(this.positionMenuBelowHeader, 300));
                        
                        LiTopElement.forEach(categoryItemElement => {
                            categoryItemElement.addEventListener('mouseover', () => {
                                if (this.isCategoryExpanded(categoryItemElement)) return;
                                this.collapseAllCategories();
                                this.expandCategory(categoryItemElement);
                            });
                        });
					}
					
					chkMenuTrigger.addEventListener('click', event => {
						this.toggleMenuState(event);
						if (this.isMobileScreen()){
							// this.changeAttrrMenuHamburger();
							setTimeout(() => this.hideIntermediateCategory(), 200);
						}
					});

					closeMenu.forEach(closeButton => {
                        closeButton.addEventListener('click', event => {
							if (event.target.dataset.category) {
								if (event.target.dataset.category === 'foco search' && ($(event.target).hasClass('close-icon') ||
								$(event.target).hasClass('search-input') || $(event.target).text() === 'Cerrar') || 
								event.target.dataset.category === 'identificacion' || event.target.dataset.category === 'cart') {
										this.pushDatalayerHeaderMenu('header', 'click', event.target.dataset.category);
								}
							}
                            if (chkMenuTrigger.checked) {
                                chkMenuTrigger.dispatchEvent(
                                    new MouseEvent('click', {
                                        bubbles: true,
                                        cancelable: true,
                                        view: window,
                                }));
                                this.adjustNavBar(false);
                            }       
                        });
					});
					back.forEach(backButton => {
						backButton.addEventListener('click', event => {
                            if (event.target.dataset.category) {
                                window.dataLayer.push(
									dataLayer.eventInteractiveMenu(
										'menu',
										'click',
										event.target.dataset.category.split('/').join('_').toLowerCase()
									)
								);
                            }
							event.stopPropagation();
							this.processBackButtonEvent(backButton);
						});
					});
					subNavLi.forEach(intermediateCat => {
						let thisCat = intermediateCat;
						const terminalCategoriesList = thisCat.parentElement.querySelector('.list');
						const arrow = thisCat.parentElement.querySelector('.arrow-down');
						intermediateCat.addEventListener('click', event => {
                            if (event.target.dataset.category && event.target.dataset.type !== 'mobile') {
                                window.dataLayer.push(
									dataLayer.eventInteractiveMenu(
										'menu',
										'seleccionar',
										event.target.dataset.category.split('/').join('_').toLowerCase()
									)
								);
                            }
							event.stopPropagation();
							if (thisCat !== openedTerminalCat) {
								this.closeOpenedTerminaCat();
								openedTerminalCat = thisCat;
							} else {
								if (terminalCategoriesList.classList.contains('active')) {
									openedTerminalCat = null;
								} else {
									openedTerminalCat = thisCat;
								}
							}
							terminalCategoriesList.classList.toggle('active');
							terminalCategoriesList.classList.toggle('hiddn');
						});
						intermediateCat.addEventListener('click', event => {
							subNavLi.forEach(element => {
								const arrow = element.parentElement.querySelector('.arrow-down');
								arrow.classList.remove('arrow-up');
							});	
							if (terminalCategoriesList.classList.contains('active')) {
								arrow.classList.add('arrow-up');
							} else {
								arrow.classList.remove('arrow-up');
							}
						});
					});
					document.querySelectorAll('#js-menu-li > div > li').forEach(element => {
							element.addEventListener('mouseenter', () => {
									element.querySelector('div > div').scrollTop = 0;
							});
					});
					menuLi.addEventListener('click', (event)  => {
							if (event.target.dataset.category && event.target.dataset.type !== 'mobile') {
									window.dataLayer.push(
											dataLayer.eventInteractiveMenu(
													'menu',
													'seleccionar',
													event.target.dataset.category.split('/').join('_').toLowerCase()
											)
									);
							}
					});
                    this.removeBlockInteraction();
                    const dwsid = document.getElementById('dwsid').value;
                    localStorage.setItem('dwsid', dwsid);
				},
				adjustNavBar: function (adjust) {
					if (adjust) {
						menuLi.classList.add('onsubnav');
					} else {
						menuLi.classList.remove('onsubnav');
					}
				},
				hideIntermediateCategory: function () {
					subnavDiv.forEach( element => element.classList.add('hidden') );
				},
				processBackButtonEvent: function (backButton) {
					let subNavParent = backButton.closest('.subnav')
					subNavParent.classList.add('hidden');
					this.adjustNavBar(false);
				},
				clickOutsideMenuHandler: function (event) {
					const withinBoundaries = event.composedPath().includes(menuLi);
					if (!withinBoundaries) chkMenuTrigger.click();
				},
				toggleMenuState: function (event) {
                    this.collapseAllCategories();
					this.positionMenuBelowHeader();

					menuLi.classList.remove('navbarToggle-hidden');

					let tag = '';

					if (chkMenuTrigger.checked) {
					
						// disable scroll
						$('html, body').css({
							margin: 0,
							height: '100%',
							overflow: 'hidden',
						});
						
						// change close icon
						navBar.classList.add('open');

						$('#overlay-content').addClass('js-hamburger-menu-overlay--show');

						menuLi.scrollTop = 0;
						tag = 'abrir';

						if (window.screen.width <= 768) {
							if ($('#secondary')){
								$('#secondary').css('z-index', '100');
							}
						}

						setTimeout(() => {
							document.addEventListener('click', this.clickOutsideMenuHandler);
						}, 50);
					} else {

						// enable scroll removing attributes
						$('html, body').removeAttr('style');
						
						// change hamburger icon
						navBar.classList.remove('open');

						$('#overlay-content').removeClass('js-hamburger-menu-overlay--show');

						tag = 'cerrar';

						document.removeEventListener('click', this.clickOutsideMenuHandler);
					}

					if (event.target.id === 'GTM_header_navbar') {
						window.dataLayer.push(dataLayer.eventInteractiveMenu('menu', 'click', tag));
					} else {
						if (event.target.dataset.category) {
							window.dataLayer.push(dataLayer.eventInteractiveMenu('menu', 'seleccionar', event.target.dataset.category.split('/').join('_').toLowerCase()));
						}
					}
				},
				changeAttrrMenuHamburger: function() {
					if (search.classList.contains('visible-search')){
						search.classList.remove('visible-search');
						search.classList.add('hidden-search');
						loginUser.classList.remove('close-icon');
						document.querySelector('.login-box').classList.remove('hbr-open-search');
					}
				},
				disableHrefElements: function () {
					topLiA.forEach( link => link.setAttribute('data-type', 'mobile') );
					terminalA.forEach( link => link.setAttribute('data-type', 'mobile') );
					topLiA.forEach( link => link.setAttribute('href', 'javascript:void(0)') );
					terminalA.forEach( link => link.setAttribute('href', 'javascript:void(0)') );
					topLi.forEach( link => link.setAttribute('data-type', 'mobile') );
				},
				closeOpenedTerminaCat: function () {
					document.querySelectorAll('.list.active').forEach(cat => {
						cat.classList.remove('active');
						cat.classList.add('hiddn');
                    });
				},
				isMobileScreen: function () {
					return window.matchMedia('(max-width: 990px)').matches;
				},
				removeBlockInteraction: function () {
					document.querySelector('.hamburger').classList.remove('block-interaction');
					document.querySelector('header').classList.remove('in-progress');
                },
                positionMenuBelowHeader: function () {
                    const headerElement = document.querySelector('.hamburger');
                    const headerRectangle = headerElement.getBoundingClientRect();

                    menuLi.style.height = `calc(100vh - ${headerRectangle.bottom}px)`;

                    topLi.forEach(categoryContent => {
                        categoryContent.style.top = `${headerRectangle.bottom}px`;
                        categoryContent.parentElement.style.top = `${headerRectangle.bottom}px`;
                        categoryContent.style.height = `calc(100vh - ${headerRectangle.bottom}px)`;
                    });
                },
				pushDatalayerHeaderMenu: function(category, action, label) {
					window.dataLayer.push(
						dataLayer.eventInteractiveMenu(
							category,
							action,
							label
						)
					);
				},
                expandCategory: function (categoryItemElement) {
                    categoryItemElement.classList.add('active');
                    const categoryContentElement = categoryItemElement.querySelector('.subnav');
                    categoryContentElement.style.display = 'block';
                },
                collapseCategory: function (categoryItemElement) {
                    categoryItemElement.classList.remove('active');
                    const categoryContentElement = categoryItemElement.querySelector('.subnav');
                    categoryContentElement.style.display = 'none';
                },
                isCategoryExpanded: function (categoryItemElement) {
                    const categoryContentElement = categoryItemElement.querySelector('.subnav');
                    const categoryContentDisplayValue = window.getComputedStyle(categoryContentElement).getPropertyValue('display');
                    return categoryContentDisplayValue !== 'none';
                },
                collapseAllCategories: function () {
                    LiTopElement.forEach(categoryItemElement => {
                        this.collapseCategory(categoryItemElement);
                    });
                },
			};
		}());
		menuHeader.eventsInit();
    },
}
module.exports = hamburgermenu;
