'use strict';

var imagesLoaded = require('imagesloaded'),
	progress = require('./progress'),
	quickview = require('./quickview'),
	util = require('../js/util');

function initQuickViewButtons() {
	if (!util.isMobile()) {
		$('.box-product').off('mouseenter').on('mouseenter', function () {

			var rollover = $(this).children('.js-rollover-container');
			
			if (rollover.html() === '') {
				var slider = $(this).closest('.slick-list');
			    slider.addClass('remove-overflow');
				var $link = $(this).find('.thumb-link');
				progress.show($(this));
				var $that = $(this);
				if ($(this).closest('.flagged').length === 0) {
					quickview.loadCont({
						url: $link.attr('href'),
						source: 'plpquickview',
						element: $that,
						productlistid: $that.attr('id')
					});
                    progress.hide();
                    //$('.page-product').addClass('remove-overflow-2');
				}
				if ($(this).closest('.js-update-content').length !== 0) {
					$(this).closest('.js-update-content').removeClass('js-update-content').removeClass('flagged');
					progress.hide();
				}
			} else {
                /**
                 * this class is added to personalize carousels
                 */
                if($(this).closest('.permanent-add-to-cart-action')){
                    return;
                }
                rollover.removeClass('hidden');
			}
		}).off('mouseleave').on('mouseleave', function () {
            if($(this).closest('.permanent-add-to-cart-action')){
                return;
            }
            $(this).children('.js-rollover-container').addClass('hidden');
		});
	}
}

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
	// CNS-3289
    // var $boxProduct = $('.box-product');
    // $boxProduct.each(function() {
    //     util.truncateProductNames($(this));
    // });    

	initQuickViewButtons();

    gridViewToggle();

    warrantySelectQuickview();
    
    $('.swatch-list').on('mouseleave', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $thumb.data('current');

        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).children('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.product-image .thumb-link img').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

       
        
        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
}

function warrantySelectQuickview(){
    $(document).on('focus', '.warranty-list select', function () {
        $(this).parents('.box-product').addClass('flagged');
    });
    $(document).on('focus', '.select-size select', function () {
    	$(this).parents('.box-product').addClass('flagged');
    });
}

exports.init = function () {
    initializeEvents();
    var $tiles = $('.tiles-container .product-tile');
    if ($tiles.length === 0) { return; }
    imagesLoaded('.tiles-container').on('done', function () {
        $tiles.syncHeight()
            .each(function (idx) {
                $(this).data('idx', idx);
            });
    });
};
