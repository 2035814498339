'use strict';

const drawerTarjeta = {
    init: function(){
        const drawerActionButton = $('.drawer-tarjeta-cencosud__action-link').find('p');
        drawerActionButton.on('click', ()=>{
            $('.drawer-tarjeta-cencosud__form-container').addClass('opened');
            $('.drawer-tarjeta-cencosud__overlay').addClass('active');
            $('html').addClass('disabled-scroll');
            $('.js-close-drawer').on('click', ()=>{
                $('.js-close-drawer').off('click');
                
                $('.drawer-tarjeta-cencosud__form-container').removeClass('opened');
                $('.drawer-tarjeta-cencosud__overlay').removeClass('active');
                $('html').removeClass('disabled-scroll');
            })
        });
    }
}

module.exports = drawerTarjeta;