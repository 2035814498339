'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    variant = require('./variant'),
    delivery = require('./delivery'),
    regions = require('../../regions'),
    ajax =  require('../../ajax'),
    wishlist = require('../wishlist'),
    selectbox = require('../../selectbox'),
    installment = require('./installment');

	var SessionAttributes = window.SessionAttributes;

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
	productNav();
    tooltip.init();
    selectbox.init($("select"));
}
/**
 * @description checks if "Write comment" should be pressed after login.
 */
function ifWriteReviewShouldBePressed() {
	if($('input#_clickOnWrite').length > 0 && $('input#_clickOnWrite').attr('value') == 'true') {
		$('.js-write-review').click();
	}
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents(pdpMainOverride) {
    var $pdpMain = pdpMainOverride ? $(pdpMainOverride) : $('#pdpMain');
    
    addToCart.init($pdpMain);
    availability($pdpMain);
    delivery.init();
    wishlist.removeFromWishListHandler();
    regions.init();
    installment.init();
    variant.init();
    image($pdpMain);
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }

    $('.select-box').on('keypress', (e)=>{
        const keyCode = e.keyCode;
        if(keyCode === 32 || keyCode === 13){
            e.preventDefault();
            $(e.target).click();
            $(e.target).focus();
            const inputBox = $(e.target).find('input');
            const accesibilitylabel = $(e.target).find('.select-box_selected-label');
            const boxIsSelected = inputBox[0].checked;
            accesibilitylabel.attr('aria-label', boxIsSelected ? 'seleccionado' : 'sin seleccionar')
        }
    });
    
    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
    	var $disabledButton = $('.add-to-cart[disabled]');
    	if ($('.add-to-cart[disabled]').length > 0) {
    		$('.add-to-cart').attr('disabled', false);
    		$('.add-to-cart').attr('title', $('.add-to-cart').text() );
    		$('select.product-option').removeClass('error');
    	}
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
        selectbox.init($(this));
    });
    
    // product size
    $pdpMain.on('change', '.variation-select', function () {
    	if ($(this).val().length === 0) {
    		$('.add-to-cart').attr('disabled', true);
    	}
        selectbox.init($(this));
    });
    
    // product color
    $pdpMain.on('click', 'ul.swatches', function () {
    	if ($(this).find('.selected').length === 0) {
    		$('.add-to-cart').attr('disabled', true);
    	}
	});
	
    // we-call-you button (planes telefonicos)
    $pdpMain.on('click', '#we-call-you', function () {
		let c2c_url = Urls.holdtechC2C;
		let $dialog = $('<div></div>')
		.html('<iframe style="border: 0px; " src="' + c2c_url + '" width="100%" height="90%" scrolling="no" style="overflow:hidden;"></iframe>')
		.dialog({
			autoOpen: false,
			modal: true,
			height: 360,
			width: 340
		});
		$dialog.dialog('open');
    });

    function hideReviewComponentsIfUserNotLogged(){
    	
    	$('.bv_button_buttonFull').attr('style','display: none !important');
    	$('button.bv_stars_button_container').attr('style','cursor: default !important');
    	$('button.bv-write-review').attr('style','display: none !important');
    	$('.bv_avgRating_component_container').attr('style','display: block !important');
    	$('div.bv_main_container_line_break').attr('style','display: none !important');
    	$('.bv_main_container_row_flex div.bv_numReviews_component_container').attr('style','display: block !important');
        $('button#WAR').attr('style','display: block !important');
        // $('div.write-review-wrapper').attr('style', 'display: none !important');

    	$('div.js-rating-container-wrapper').show();
    }
    
    // Hide review div and evaluate if user is logged in and then show the review pdp summary
    $(document).ready(function() {
        var waitForWriteReview = function(callback) {
        		setTimeout(callback, 1500);
        };
        waitForWriteReview(hideReviewComponentsIfUserNotLogged);
	    $( document ).ajaxComplete(function() {
		    waitForWriteReview(hideReviewComponentsIfUserNotLogged);
	    });
        setTimeout(addClickEventOnRatingStars, 1500);
    });
    
    // Function that expands reading review section after successful review
    function expandReadingReviewsSecitonAftetWroteOne() {
    	$('button.bv-form-actions-submit').on('click',function(){
    		setTimeout(function(){
    			$('button.bv-mbox-close').on('click',function(){
    				if ($('#collapseComments').length > 0 && !$('#collapseComments').hasClass( 'active' )) {
    	    	    		$('button.bv-write-review').attr('style','display: none !important');
    	    	    		if ($('div.bv-content-placeholder').length > 0) {
    	    	        		$('div.bv-content-placeholder').find('a').attr('style','display: none !important');
    	    	        	}
    	    	    		$('.js-read-review-section').addClass('ui-state-active');
    	    	    		$('#collapseComments').addClass('active');
    	    	    	}
    			});
    		}, 2000);
    	});
    }
    //Event on click "Write review" link
    $('.js-write-review').on('click', function (e) {
    	setTimeout(expandReadingReviewsSecitonAftetWroteOne, 3500);
    	if($(this).attr('href') == 'javascript:void(0)') {
    		if ($('button#WAR').length > 0) {
    			$('button#WAR').click();
    		}
    	} else {
    		if ($('input#_clickOnWrite').length > 0) {
                $('input#_clickOnWrite').attr('value','true');
    		}
    		if ($('#collapseLogin').length > 0) {
    			if ($('#collapseLogin').css('display') == 'none') {
    				$('#clickLogin').click();
    		    }
    	    }
    	}
		return false;
    });
    
    //Events on clicking rating stars
    function addClickEventOnRatingStars () {
    	   $('button.bv_stars_button_container').on('click', function(){
    	    	if ($('#collapseComments').length > 0 && !$('#collapseComments').hasClass( 'active' )) {
    	    		$('button.bv-write-review').attr('style','display: none !important');
    	    		if ($('div.bv-content-placeholder').length > 0) {
    	        		$('div.bv-content-placeholder').find('a').attr('style','display: none !important');
    	        	}
    	    		$('.js-read-review-section').addClass('ui-state-active');
    	    		$('#collapseComments').addClass('active');
    	    		
    	    	}
    	    });
    }
    
    //Events on expand reading rating section 
    $('.reviews').on('click', function (e){
    	$('button.bv-write-review').attr('style','display: none !important');
    	if ($('div.bv-content-placeholder').length > 0) {
    		$('div.bv-content-placeholder').find('a').attr('style','display: none !important');
    	}
    	
    });
    
    
    $pdpMain.on('mouseenter', '.add-to-cart', function () {
        var $form = $(this).closest('form');
        var $options = $form.find('.js-product-option');
        if ($options.length > 0 && $options.val().length == 0 ) {
        	//error message
        	$('.add-to-cart').attr('disabled', 'disabled');
        	$('.add-to-cart').attr('title', $options.find(":selected").text());
        }
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('#product-content').on('click', '.js-size-chart', function(e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 'auto'
            }
        });
     });

    $pdpMain.on('click', '.js-accordion-header', function(e) {
        e.preventDefault();
        $(this).toggleClass('ui-state-active');
        $(this).next().toggleClass('active').slideToggle(300);
    });

    $('a.link-warranty').on('mouseenter touch', function (e) {
        e.preventDefault();
        var infoTooltip = $(this).parent().find('.info-tooltip');
        var infoTooltipTopPosition = 0;
        if (infoTooltip.parents('.plp-wrapper').length > 0) {
            infoTooltipTopPosition = -1 * (infoTooltip.outerHeight() + 10);
        }
        else {
            if (window.matchMedia("(max-width: 992px)").matches) {
                if (infoTooltip.parents('.edit-details-overlay').length > 0) {
                    infoTooltipTopPosition = -1 * (infoTooltip.outerHeight() + 5);
                }
                else if (infoTooltip.parents('.pdp-main').length > 0) {
                    infoTooltipTopPosition = -1 * (infoTooltip.outerHeight() -15);
                }
            }
            else {
                if (infoTooltip.parents('.plp-wrapper').length > 0) {
                    infoTooltipTopPosition = -1 * (infoTooltip.outerHeight() + 10);
                }
            }
        }
        infoTooltip.css('top', infoTooltipTopPosition);
        infoTooltip.show();
        return false;
    });

    $('a.link-warranty').on('mouseleave', function (e) {
        setTimeout(function() {      
            e.preventDefault(); 
            $('.info-tooltip').hide();
            return false;
        }, 100);
    });

    function handleClickShowDescription(e){
        if(e.keyCode === 32){
            e.preventDefault();
            showDescription(e);
        }
    }
    // Function show and hide Product Description
    function showDescription(e) {
        e.preventDefault();
        var description = document.querySelector('.description-table');
        description.classList.toggle('description-table--hide');
        var hideLinkShowDescription = document.querySelector('.read-me');
        hideLinkShowDescription.classList.toggle('hide');
        var showLinkCloseDescription = document.querySelector('.close-description');
        showLinkCloseDescription.classList.toggle('hide');
    }
     //Attempt to get the showAllDescription using document.getElementById
     var showAllDescription = document.querySelector("a.read-me");
 
     //If it isn't "undefined" and it isn't "null", then it exists.
     if (typeof(showAllDescription) != 'undefined' && showAllDescription != null){
         document.querySelector('a.read-me').onclick = function(e) {showDescription(e)};
         document.querySelector('a.close-description').onclick = function(e) {showDescription(e)};
         $('a.read-me').on('keypress', (e)=> handleClickShowDescription(e));
         $('a.close-description').on('keypress', (e)=> handleClickShowDescription(e));
     }

    const getButtonSpec = document.getElementById('pdp-spec-button-tab');
    const contentSpec = document.getElementById('pdp-spec-content-tab');
    const getButtonDesc = document.getElementById('pdp-desc-button-tab');
    const contentDesc = document.getElementById('pdp-desc-content-tab');
     
    function showProductSpec(){
        contentSpec.classList.toggle('pdp-product-info-bottom_spec-content-open');
        getButtonSpec.classList.toggle('pdp-product-info-bottom_spec-active');
    }
    function showProductDesc(){
        contentDesc.classList.toggle('pdp-product-info-bottom_desc-content-open');
        getButtonDesc.classList.toggle('pdp-product-info-bottom_desc-active');
    }
    if (typeof(getButtonSpec) != 'undefined' && getButtonSpec != null){
        getButtonSpec.addEventListener('click', showProductSpec);
    }
    if (typeof(getButtonDesc) != 'undefined' && getButtonDesc != null){
        getButtonDesc.addEventListener('click', showProductDesc);
    }

    dataLayer.push({'event': 'optimize.activate'});
}

//initializes sticky polyfill if required
function initializeSticky () {
    if (util.isIE11()) {
        if (typeof Stickyfill == 'undefined') {
            var stickyPolyfillURL = Urls.staticPath + '/lib/stickyfill.min.js';
            $.getScript(stickyPolyfillURL, function () {
                Stickyfill.add($('.sticky'));
            });
        } else {
            Stickyfill.add($('.sticky'));
        }
    }
}

function getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function getUrlParam(parameter, defaultValue) {
    let urlParameter = defaultValue;
    if (window.location.href.indexOf(parameter) > -1) {
        urlParameter = getUrlVars()[parameter];
    }
    return urlParameter;
}

document.addEventListener('DOMContentLoaded', function () {
    const valorParam = getUrlParam('bv', '');
    if (valorParam === 'openmodal') {
        setTimeout(function () {
            document.getElementById('WAR').click();
        }, 3000); //3 segundos
    }
});

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();

        initializeSticky();
        ifWriteReviewShouldBePressed();
    }
};


module.exports = product; 