'use strict';

var formPrepare = require('./formPrepare');


function checkForm() {
	var $form = $('.js-orderconfirmation-form form');
	formPrepare.init({
        continueSelector: $form.find('button'),
        formSelector: $form,
        validateOnKeyUp: true
    });
}
exports.init = function () {
	checkForm();
    $('.js-submit-orderconfirm-form').on('click', checkForm);
}
   
