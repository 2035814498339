'use strict';

var ajax = require('../../ajax'),
	formPrepare = require('./formPrepare'),
	progress = require('../../progress'),
	tooltip = require('../../tooltip'),
	util = require('../../util'),
	googlePlaces = require('../../components/googlePlaces'),
	googlePlacesPeru = require('../../components/googlePlacesPeru'),
	dialog = require('../../dialog'),
	page = require('../../page'),
	address = require('./address'),
	regions = require('../../regions'),
	clickcollect = require('./clickcollect'),
	programshipping = require('./programshipping'),
	validator = require('../../validator'),
	Promise = require('promise'),
	sippingTracking = require('./sippingTracking');

var shippingMethods;
var hasTriggeredProgramShipping = false;
/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary .js-summary');
    // indicate progress
    //progress.show($summary);

    // load the updated summary area
    $.get(Urls.summaryRefreshURL + '?tstamp=' + new Date().getTime(), function (data) {
        $summary.html(data);
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
        //progress.hide();
    })
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.js-address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        addressID: $form.find('input[name$="_addressID"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        address3: $form.find('input[name$="_address3"]').val(),
        countryCode: $form.find('input[name$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('select[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID, skipShippingCostCalculation) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }

    $('.js-shipping-delivery-restrictions-available').remove();

    var region = $('[name$="_addressFields_states_state"]').val();
	var comuna = $('[name$="_addressFields_city"]').val();

    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});

    if (skipShippingCostCalculation) {
    	url = util.appendParamToURL(url, 'skipCalculation', true);
    }
	if (region) {
		url = util.appendParamToURL(url, 'region', region);
	}
	if (comuna) {
		url = util.appendParamToURL(url, 'comuna', comuna);
	}

    ajax.getJson({
        url: url,
        async: false,
        callback: function (data) {
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
			}
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

            // TODO the for loop below isn't doing anything?
            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList(region, comuna) {
	var promise = new Promise(function (resolve, reject) {
        var $shippingMethodList = $('#shipping-method-list');
        if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }

        var url = getShippingMethodURL(Urls.shippingMethodsJSON);
    	url = util.appendParamToURL(url, 'methodType', $('.js-method-type-rendering-template').data('method-type'));
    	if (region) {
    		url = util.appendParamToURL(url, 'region', region);
    	}
    	if (comuna) {
    		url = util.appendParamToURL(url, 'comuna', comuna);
    	}

        ajax.getJson({
            url: url,
            callback: function (data) {
                if (!data) {
                    window.alert('Couldn\'t get list of applicable shipping methods.');
                    return false;
                }
                /*
                TODO: the cost might have changed, so need to check if the cost is different somehow, if not - don't replace the list
                if (shippingMethods && shippingMethods.toString() === data.toString()) {
                    // No need to update the UI.  The list has not changed.
                    return true;
                }
                */

                // We need to update the UI.  The list has changed.
                // Cache the array of returned shipping methods.
                shippingMethods = data;
                // indicate progress
                //progress.show($shippingMethodList);

                // load the shipping method form
                var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
                smlUrl = util.appendParamToURL(smlUrl, 'methodType', $('.js-method-type-rendering-template').data('method-type'));
                smlUrl = util.appendParamToURL(smlUrl, 'region', region);
                smlUrl = util.appendParamToURL(smlUrl, 'comuna', comuna);
                $shippingMethodList.load(smlUrl, function () {
                    $shippingMethodList.fadeIn('fast');
                    // rebind the radio buttons onclick function to a handler.
                    $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
                        selectShippingMethod($(this).val());
                    });

                    // update the summary
                    updateSummary();
                    //progress.hide();
                    tooltip.init();
                    //if nothing is selected in the shipping methods select the first one
                    if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                        $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                    }
                    initializeShippingMethods();
                    programshipping.initializeDeliveryCalendar();

                    //TODO: expand program shipping calendar is not visible

        			if (!hasTriggeredProgramShipping && $shippingMethodList.find('.input-radio[data-isprogram-shipping="true"]').data('isprogram-shipping') && $('.js-program-shipping-container').length == 0) {
        				$shippingMethodList.find('.input-radio[data-isprogram-shipping="true"]').trigger('click');
                    	hasTriggeredProgramShipping = true;
                    }

                    if ($('.js-shippingmethod-list .no-shipping-methods').length > 0) {
                        $('.js-shipping-address .js-submit-shipping').addClass('hidden');
                    }
                    resolve(data);
                });
            }
        });
	});
	return promise;
}

function loadShippingMethodRenderingTemplate(shippingMethodID, level, parentSelector, disableErrors) {
	if (!shippingMethodID || !level || !parentSelector) {
		return;
	}
	var disableButton = disableErrors != undefined ? disableErrors : true;
	var target = $(parentSelector).find('.js-shipping-method-form-level' + level);
	var url = util.appendParamsToUrl(Urls.showShippingMethodTemplate, {shippingMethodID: shippingMethodID, level: level}, true);
	var comuna = $('select[name$="_addressFields_city"]').val();
	if (comuna) {
		url = util.appendParamToURL(url, 'comuna', comuna);
	}

    //progress.show(target);

	ajax.load({
		url: url,
		callback: function (data) {
			target.html(data);
			//progress.hide();
			initializeEvents(true);
            programshipping.initializeDeliveryCalendar();

        	if ($('.js-address').length && !$('.js-select-addresses').is(':hidden') && $('.js-address :radio')) {
                $('.js-address :radio').each(function( index ) {
                    var $element = $(this);
                    if ($element.is(':checked')) {
						$element.trigger('change');
						var selectedAddress = $($element).data('address');
						if (!selectedAddress) {
							return;
						}
						var $form = $($element).closest('form');
						util.fillAddressFields(selectedAddress, $form);
                    }
                });
            }

            if ($('.js-shipping-form-fields').length > 0 && !$('.js-shipping-form-fields').hasClass('hidden')) {
            	if (!target.hasClass('js-shipping-method-form-level2')) {
            		if ($('.js-home-delivery-address').hasClass('hidden')) {
            			$('.js-home-delivery-address').removeClass('hidden');
            		}
            		initGTMEvents();
            	}
            	if (target.hasClass('js-shipping-method-form-level2')) {
            		window.dataLayer.push({
            			'event' : 'page-virtual',
            			'page': 'shipping/home-delivery/select-date',
            			'pagename': 'Buying Process - Type Delivery'
            		});
            	}
                validateFormFields();
            }

			if ($('.js-shipping-method-form-level2').find('.box-message-error').length > 0) {
				handleScheduledShippingFallback();
			}

            if (($(target).find('div.status-code-unavailable').length > 0 && disableButton)  && $('.productos div.status-code-available').length === 0) {
				$('.js-submit-shipping').attr('disabled', 'disabled');
            } else {
            	if ($('.js-shipping-form-fields').hasClass('hidden') && $('.productos div.product-wrapper select.js-select-delivert-date').length > 0) {
                    $('.js-submit-shipping').removeAttr('disabled');
            	}
			}

			sippingTracking.unavailableProducts('.js-no-inventory', 'no inventory', 'dad');
			sippingTracking.unavailableProducts('.js-no-capacity', 'no capacity', 'dad');
			address.init();
		}
	});
}

function loadShippingMethodRenderingTemplateWithRestrictions(data) {

	var disableButton = true;
	var target = $('.js-shipping-method-form-level2');

	progress.show(target);

	target.html(data);
	progress.hide();
	initializeEvents(true);
    showShippingMethods();

	if ($('.js-address').length && !$('.js-select-addresses').is(':hidden') && $('.js-address :radio')) {
		$('.js-address :radio').each(function (index) {
			var $element = $(this);
			if ($element.is(':checked')) {
				$element.trigger('change');
			}
		});
	}

	if ($('.js-shipping-form-fields').length > 0 && !$('.js-shipping-form-fields').hasClass('hidden')) {
		if (!target.hasClass('js-shipping-method-form-level2')) {
			if ($('.js-home-delivery-address').hasClass('hidden')) {
				$('.js-home-delivery-address').removeClass('hidden');
			}
			initGTMEvents();
		}
		/*
		if (target.hasClass('js-shipping-method-form-level2')) {
			window.dataLayer.push({
				'event': 'page-virtual',
				'page': 'shipping/home-delivery/select-date-restriction',
				'pagename': 'Buying Process - Type Delivery'
			});
		}
		*/
		validateFormFields();
	}

	if ($('.js-shipping-method-form-level2').find('.box-message-error').length > 0) {
		handleScheduledShippingFallback();
    }

	if (($(target).find('div.status-code-unavailable').length > 0 && disableButton) && $('.productos div.status-code-available').length === 0) {
		$('.js-submit-shipping').attr('disabled', 'disabled');
	} else {
		if ($('.js-shipping-form-fields').hasClass('hidden') && $('.productos div.product-wrapper select.js-select-delivert-date').length > 0) {
			$('.js-submit-shipping').removeAttr('disabled');
		}
	}

	sippingTracking.unavailableProducts('.js-no-inventory', 'no inventory', 'dad');
	sippingTracking.unavailableProducts('.js-no-capacity', 'no capacity', 'dad');
    address.init();
    transitionEffecWhenReturnCalculatorResult();
}

function transitionEffecWhenReturnCalculatorResult() {
    $('.js-shipping-form-fields').addClass('hidden').fadeOut('slow');
    $('.js-select-addresses').addClass('hidden').fadeOut('slow');
    $('.js-selected-address').addClass('hidden').fadeOut('slow');
    $('.js-shipping-form-fields').addClass('hidden').fadeOut('slow');
    $('.js-home-delivery-address').addClass('hidden').fadeOut('slow');
    $('.js-shippingmethod-list').removeClass('hidden').fadeIn('slow');

    setTimeout(function () {
        $('html, body').animate({
            scrollTop: $('#dwfrm_singleshipping_shippingAddress div.product-wrapper').first().offset().top
        }, 1000);
    }, 10);
}

/**
 * Show and hide fields filled by google addresses search
 * check and uncheck the save address checkbox
 * @returns
 */
function showHideManualAddress() {

    var $manualAddressMsg = $('.js-show-message');
    var $showManualAddress = $('.js-show-address');
    if ($(this).hasClass('js-show-google-search')) {
        clearAddressForm();
        $('.js-add-checkbox').find('input').trigger('click');
    }

    if ($(this).hasClass('js-show-address')) {
        $('.js-address-id-check').addClass('hidden');
    }
    $showManualAddress.toggleClass('hidden');
	//$manualAddressMsg.toggleClass('hidden');
	$('.js-form-hidden-field').toggleClass('hidden');
	$('.js-google-search-field').toggleClass('hidden');
	//If site is ParisPe, do not hide addToAddressBook checkbox.
	if(Resources.SITE_ID == "Paris"){
		$('.js-add-checkbox').toggleClass('hidden');
		$('[data-autocomplete=latitude]').val('');
		$('[data-autocomplete=longitude]').val('');
		$('[data-autocomplete=plusCode]').val(''); 
	}
	else if(Resources.SITE_ID == "ParisPe"){
		$('.js-add-checkbox').find('input').prop('checked', true);
	}

    if ($showManualAddress.hasClass('hidden')) {
		if(Resources.SITE_ID == "Paris"){
			$('.js-add-checkbox').find('input').prop('checked', false);
		}
    	$('.js-address-id-check input').removeClass('required error');

    }
    //clean latitude and longitude when address is filled manually

    validateFormFields();
}

/** Validate google address fields on checkout
 *  e.g  e.g Region , Comuna , Street name , Street Number , Latitude , Longitude
 *  if validation fail show  error msg otherwise hide error msg
 */
function validateGoogleAddrFields () {
    var error = false;
    $( '.js-google-validation' ).each(function( index ) {
		var element = $(this);
		/*Revisa todos los elementos con la clase js-google-validation
		Si alguno no tiene valor, entonces el formulario tiene error y muestra los campos */
        if (!element.find('input ,select').val()) {
			error = true;
			
			if(element.hasClass('required')){
				element.removeClass('hidden');
				//element.addClass('error');
				var formElement = element.find('input ,select');
				formElement.addClass('field-error');
				formElement.removeClass('field-valid');
				
				formElement.on('focus',function(){
					//formElement.removeClass('field-error');
					//formElement.removeClass('error');
					//element.removeClass('error');
				});
				
			}
            //return false;
		}
		else{
			if(element.hasClass('required')){
				//element.removeClass('hidden');
				element.find('input ,select').addClass('field-valid');
				element.find('input ,select').removeClass('field-error');
			}
		}
	});

	//Si algun campo requerido no es valido, entonces se muestra el formulario de ingreso manual
	if ($(".js-google-validation .required.field-valid").length != $(".js-google-validation .required").length) {
		$( '.js-google-validation.required' ).each(function( index ) {
			var element = $(this);
			element.removeClass('hidden');
		});
	}
	else{
		$('.input-search:before').css('content','');
		$('#searchTextField').addClass('field-valid');
		$('#searchTextField').addClass('search-field-valid');
	}

    if (!error) {
        $('.js-google-error').addClass('hidden');
        $('#searchTextField').removeClass('error');
        $('#searchTextField').addClass('valid');
    } else {
        $('.js-google-error').removeClass('hidden');
		$('#searchTextField').addClass('error');
		$('.js-google-search-field').toggleClass('hidden');
		$('.js-no-number-checkbox').toggleClass('hidden');
		//$('.js-show-message').toggleClass('hidden');
		$('.js-add-checkbox').toggleClass('hidden');
		$('.js-address-id-check').addClass('hidden');
		$('.js-show-address').addClass('hidden');
    }
    //checkForm();
	validateFormFields();
	$(".js-google-validation .required").not('.field-valid').first().focus();
	$(".js-google-validation .required").not('.field-valid').first().addClass('error');
}

function checkForm(){
    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector:'[id$="singleshipping_shippingAddress"]',
        enabledByDefault: true
    });
    formPrepare.validateForm({
		continueSelector: '[name$="collectonbehalf_continue"]',
		formSelector:'#collect-on-behalf-form'
	});

}

/**
 * On show Address Form
 * clear pre-populated address data
 * @returns
 */

function clearAddressForm(clearSearchField) {
    var cleanGoogleField = clearSearchField != undefined ? clearSearchField : true;
    var $form = $('.js-address');
    $form.find('input[name$="_address3"]').val('');
    $form.find('input[name$="_address1"]').val('');
    $form.find('input[name$="_address2"]').val('');
    $form.find('select[name$="_states_state"]').prop("selectedIndex", 0);
    $form.find('select[name$="_city"]').prop("selectedIndex", 0);
    $form.find('select[name$="_province"]').prop("selectedIndex", 0);
    $form.find('input[name$="_latitude"]').val('');
    $form.find('input[name$="_longitude"]').val('');
	$form.find('input[name$="_addressID"]').val('');
	$form.find('input[name$="_plusCode"]').val(''); 
    if (cleanGoogleField) {
    	$('#searchTextField').val('');
    }

    validateFormFields();


}


/**
 * On click on Add a New Address button
 * show the form with pre-populated address
 * @returns
 */

function showAddressForm() {

    $('.js-shipping-form').toggleClass('hidden');


//    if (!$('.js-show-message').hasClass('hidden')) {
//        showHideManualAddress();
//        return;
//    }
    if ($('.js-shipping-form').hasClass('hidden')) {
        //$('.js-add-address-new input[type="radio"]').prop('checked', false);

    } else {
        $('.js-selected-address input[type="radio"]').prop('checked', false);
        $('.js-add-address-new :radio').prop('checked', true);
        if (!$('.js-address-id-check').hasClass('hidden')) {
            $('.js-address-id-check input').removeClass('error');
		}
		/*
        if ($('.js-add-checkbox input').prop('checked') && !$('js-google-search-field').hasClass('hidden')) {
            $('.js-add-checkbox').find('input').trigger('click');
		}
		*/
    }


    clearAddressForm();
    if ($('.js-shipping-form').hasClass('hidden')) {
        $('[name$="shippingAddress_save"]').attr('disabled', 'disabled');
    }
}

/**
 * Delete Customer Address
 */

function deleteAddress($element) {
    var addressID =$element.find('input').val();
    var url = util.appendParamToURL(Urls.deleteAddress,'AddressID',addressID);
    url = util.appendParamToURL(url, 'format', 'ajax');
    $.ajax({
        type: 'POST',
        url: url,
        success: function (response) {
            if (response.status == "OK") {
                page.reload();
            }
        },
        failure: function () {
        	$(".js-error-msg-on-delete-address").show();
        	$(".header-for-address-list").hide();
        	$(".js-close-error-msg").on("click",function(){
        		$(this).parent().hide();
        		$(".header-for-address-list").show();
        	});
        }
    });

}

/**
 * Delete address from customer addresses book by addressID (address name)
 *
 * @returns
 */

function showDeleteAddrDialog($element) {
	dialog.openConfirmation(
		$('.js-address'),
		String.format(Resources.DELETE_ADDRESS),
		String.format(Resources.DELETE_ADDRESS_CONFIRM_MESSAGE),
		function () {
			deleteAddress($element);
	});
}

function selectSavedAddress() {
	if ($('.js-shippingmethod-list :radio')) {
        $('.js-shippingmethod-list :radio').each(function( index ) {
            var $element = $(this);
            if ($element.is(':checked')) {
                $element.trigger('click');
            }
        });
    }
	$('.js-address , select[name$="_addressFields_city"]').trigger('change');
}

function showShippingMethods() {
    programshipping.initializeDeliveryCalendar();
}

function saveShippingAddress() {

	var $form = $('.js-address');

	var data = $form.serialize();

	// add form action to data
	data += '&' + $form.find('button').attr('name') + '=';
	var url = util.appendParamToURL(Urls.saveShippingAddress,'format', 'ajax');
	ajax.load({
		type: 'POST',
		url: url,
		data: data,
		callback: function(response) {
			var responceObj = JSON.parse(response);
			if (responceObj.status == "OK") {
				$('.js-submit-shipping').attr('disabled', 'disabled');
            	var region = $('select[name$="_addressFields_states_state"]').val();
                var comuna = $('select[name$="_addressFields_city"]').val();
            	updateShippingMethodList(region, comuna).then(function (){
                    checkDeliveryRestrictions(true);
            	});
			} else {
				invalidateAddressFormElements();
			}
		}
	});
}

function invalidateAddressFormElements() {
	if ($('#searchTextField:visible').length > 0) {
		//Google form
		if ($('#searchTextField').val() == "") {
	        $('.js-google-error').removeClass('hidden');
	        $('#searchTextField').addClass('error');
		}
		if ($( "input[name*='addressID']:visible" ).val() == "") {
			$( "input[name*='addressID']:visible" ).addClass('error');
	        $( "input[name*='addressID']:visible" ).next('span').show();
		}
	} else {
		//Manual new address form
		var form = $('.js-address');
		form.valid();
	}
	progress.hide();
}

function checkDeliveryRestrictions(dontShowProgress) {
	if (dontShowProgress != true) {
		progress.show('.js-address-form');
	}

	if ($('.js-method-type-link[data-delivery-type="HOME"]').closest('li').hasClass('active')) {
		selectSavedAddress();
	}

	var region = $('select[name$="_addressFields_states_state"]').val();
	var comuna = $('select[name$="_addressFields_city"]').val();

	var url = Urls.checkDeliveryRestrictions;
	url = util.appendParamToURL(url, 'methodType', $('.js-method-type-rendering-template').data('method-type'));
	if (region) {
		url = util.appendParamToURL(url, 'region', region);
	}
	if (comuna) {
		url = util.appendParamToURL(url, 'comuna', comuna);
	}
	var storeID = $('.js-store-panel .store-name').data('storeid');

	if (storeID != 'undefined') {
		url = util.appendParamToURL(url, 'storeid', storeID);
	}

	ajax.load({
		url: url,
		callback: function (data) {
			// Check if the session is expired (cart is empty).
		    var isEmptyCart = $(data).find('#emptyCartError').text().trim();
		    if (isEmptyCart === 'true') {
                window.location.href = Urls.cartShow;
                return;
            }

		    //If we have chosen store from the list on "Click and Collect" and the response of ajax call is returning div with class - js-status-ok
			//Then we are sure there are no restrictions and we can proceed to the billing page
			if ($('.js-selected-store-address .store-name').data('storeid') > 0 && $(data).filter('.js-status-ok').length > 0) {
				//We do that because we need to unbind already existing click event on js-submit-store
				//after that we trigger click to proceed to next step Billing page (submit)
				$('.js-submit-store').unbind('click');
				$('.js-submit-store').trigger('click');
			} else if ($(data).filter('.deliverytable-new-iu-status-ok').length > 0) {
				loadShippingMethodRenderingTemplateWithRestrictions(data);
			} else if ($(data).filter('.store-pickup-slots-status-ok').length > 0) {
				$('.js-submit-store').unbind('click');
				$('.js-submit-store').on('click', function(e) {
					progress.show();
				});
                $('.js-store-pickup-slots-section').html(data);
                if (($('#dwfrm_singleshipping_shippingAddress div.product-row div.product-available-date').length === $('#dwfrm_singleshipping_shippingAddress div.product-row div.js-date-problem').length) || !$('#dwfrm_singleshipping_shippingAddress div.product-row div.product-available-date')) {
                    $('.js-submit-store').attr('disabled', 'disabled');
                    $('.js-new-3rd-party-section, .CA-message-3days').addClass('hidden');
                } else {
                    $('.js-submit-store').removeAttr('disabled');
                    $('.js-new-3rd-party-section, .CA-message-3days').removeClass('hidden');
                    $('.js-new-3rd-party-section').css('display', '');
                }
                autoScrollToRowTiendasProducts();
                if ($('.js-store-pickup-list').length && !$('.js-store-pickup-list').is(':hidden')) {
                	window.dataLayer.push({
                        'event' : 'page-virtual',
                        'page': 'shipping/collect-from-store/select-store',
                        'pagename': 'Buying Process - Type Delivery'
                    });
                } else if($('.js-store-panel').length){

					if($('.js-submit-store').length){
						window.dataLayer.push({
							'event' : 'page-virtual',
							'page': 'shipping/collect-from-store/select-store-confirmation',
							'pagename': 'Buying Process - Type Delivery'
						});
					}
				}
			} else {
				dialog.open({
		            html: data,
		            options: {
                        width: 840,
                        dialogClass: 'restricted-product-dialog',
		                open: function () {
                            $(document.body).css('overflow', 'hidden');
                            var $productsList = $(this).dialog().find('.mini-products-list');
                            var $productContainers = $productsList.find('.box-item-mc');
                            var $secondProduct = $productContainers.eq(1);
                            var minHeight = $productContainers.first().innerHeight();
                            $productsList.css('min-height', minHeight);
                            if ($secondProduct.length > 0) {
                                var maxHeight = minHeight + $secondProduct.innerHeight();
                                // fix the appearing scroll in some cases
                                maxHeight += 1;
                                $productsList.css('max-height', maxHeight);
                            }
		                	$('#js-restrictions-change-address').on('click', function () {
		                		dialog.close();
		                		return false;
		                	});
	                		$('#js-restrictions-switch-to-home').on('click', function (e) {
	                			e.preventDefault();
	                			$('.js-method-type-link[data-delivery-type="HOME"]').trigger('click');
	                			dialog.close();
		                		return false;
		                	});
                        }
		            }
				});
				progress.hide();
                $('.ui-dialog-titlebar-close').click(function () {
                	$(document.body).css('overflow', 'auto');
                	window.location.href = util.removeParamFromURL(window.location.href, 'deliveryrestriction');
                });
			}
			if (dontShowProgress != true) {
				progress.hide();
			}
		}
	});
}


function validateFormFields () {
	var isValid = true;

	if ($('.js-form-hidden-field').hasClass('hidden')) {
		$('.js-validate-form-field').each(function() {
			var element = $(this);
	        if (!element.val()) {
	            isValid = false;
	            return false;
	        }
		});
	} else {
		if ($('[name*="_address3"]').length > 0 && !$('[name*="_address3"]').valid()) {
			isValid = false;
		} else {
    		checkForm();
    		return;
		}
	}


	if ($('[name*="_address3"]').length > 0 && !$('[name*="_address3"]').valid()) {
		isValid = false;
	}

	if (!$('.js-address-id-check').hasClass('hidden') && !validator.regex.addressid.test($('.js-address-id-check input').val())) {
		isValid = false;
	}

	if (!isValid) {
		//$('[name$="shippingAddress_save"]').attr('disabled', 'disabled');
		return;
	}
	$('[name$="shippingAddress_save"]').removeAttr('disabled');

}

function initializeShippingMethods() {
    $('input.js-shipping-method-option').off('click');
    $('input.js-shipping-method-option').on('click', function () {
    	$('.js-shipping-method-form-level2').html("");
    	var shippingMethodID = $(this).data('shipping-method-id');
		selectShippingMethod(shippingMethodID);
		var parentSelector = '.js-shipping-method-option-container[data-shipping-method-id="' + shippingMethodID + '"]';
        loadShippingMethodRenderingTemplate(shippingMethodID, 2, parentSelector);
	});
}

function checkAddressName() {
	$('.js-address-id-check input').on('change', function() {
		if ($(this).valid()) {
			validateFormFields();
		} else {
			$('[name$="shippingAddress_save"]').attr('disabled', 'disabled');
		}
	});
}

function checkIfManual() {
	if ($('.js-method-type-link[data-delivery-type="HOME"]').closest('li').hasClass('active')) {
        var $form = $('.js-address');
        return $form.find('input[name$="_latitude"]').length > 0
        	&& $form.find('input[name$="_latitude"]').val().length == 0
	        && $form.find('input[name$="_longitude"]').val().length == 0
	        && $form.find('input[name$="_longitude"]').length > 0
	        && $form.find('select[id$="_city"]').val().length > 0
	        && $form.find('select[id$="_city"]').length > 0
	        && $form.find('select[id$="_state"]').val().length > 0
	        && $form.find('select[id$="_state"]').length > 0
	        && $form.find('input[name$="_address1"]').val().length > 0
	        && $form.find('input[name$="_address1"]').length > 0
	        && $form.find('input[name$="_address2"]').val().length > 0
	        && $form.find('input[name$="_address2"]').length > 0;
	}
	return false;
}

function initializeEvents(initShippingMethods) {

	var hasInitShippingMethods = initShippingMethods != undefined ? initShippingMethods : false;
	clickcollect.init();
	if(Resources.SITE_ID == "ParisPe"){
		regions.initializeDPDFiltering();
	}
	else{
		regions.initializeRegionsComunaFiltering();
	}

    //$('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);
	$(document).on('validate.google.addresses', validateGoogleAddrFields);
	if(SitePreferences.GMAPS_AUTOCOMPLETE_ENABLE){
		if(Resources.SITE_ID == "Paris"){
			googlePlaces.init();
		}
		else if(Resources.SITE_ID == "ParisPe"){
			googlePlacesPeru.init();
		}
	}
	else{
		showHideManualAddress();
	}
    //checkForm();

	$(document).on('validate.form.fields', validateFormFields);
	$(document).ready(function(){
		$(".ios").iosCheckbox();
		$(window).keydown(function(event){
			if(event.keyCode == 13) {
			  event.preventDefault();
			  return false;
			}
		  });
	});

    //when initializing populate form fields with address data from radio buttons
    if (!hasInitShippingMethods) {
    	initGTMEvents();
    	if ($('.js-address :radio')) {
            $('.js-address :radio').each(function( index ) {
                var $element = $(this);
                if ($element.is(':checked')) {
                    $element.trigger('change');
                }
            });
        }
	}


    //handle submit button action and proceed to next action (choosing shipping method)
    var $form = $('.js-address');
    $('.js-submit-shipping').off('click');
    $('.js-submit-shipping').on('click', function(e) {
	//$form.off('submit');
	//$form.on('submit', function(e) {
		e.preventDefault();
		if(!$('.js-address').valid()) {
			return false;
		}

        progress.show('.js-address-form');

        if ($('.js-address-id-check input').val().length < 2 ) {
			if(Resources.SITE_ID == "Paris"){
				var addressIDContent = validator.regex.addressid.test($('.js-comuna-select').val()) ? $('.js-comuna-select').val() : "Mi Dirección";
			}
			else if(Resources.SITE_ID == "ParisPe"){
				var addressIDContent = validator.regex.addressid.test($('.js-distrito-select').val()) ? $('.js-distrito-select').val() : "Mi Dirección";
			}
            $('.js-address-id-check input').val(addressIDContent);
        }

        if (!$('.js-shipping-form-fields').hasClass('hidden') || ($('.js-select-addresses').length > 0 && !$('.js-select-addresses').hasClass('hidden'))) {
			if(	SitePreferences.GMAPS_VALIDATION_WITH_MAP && 
				($(".js-google-search-field").hasClass('hidden') || 
				$(".js-shipping-form").hasClass('hidden')) &&
				$('.CA-fonocompra-box').length == 0){

				if(!$('.js-address-form').hasClass('addressVerified')){
					//Validate address with GMAPS

					validateAddressWithGoogle(false);
					document.getElementById("dwfrm_singleshipping_shippingAddress_addressFields_address1").addEventListener("change", validateAddressWithGoogle);
					document.getElementById("dwfrm_singleshipping_shippingAddress_addressFields_address2").addEventListener("change", validateAddressWithGoogle);
					document.getElementById("dwfrm_singleshipping_shippingAddress_addressFields_city").addEventListener("change", validateAddressWithGoogle);
					document.getElementById("dwfrm_singleshipping_shippingAddress_addressFields_states_state").addEventListener("change", validateAddressWithGoogle);

					return false;
				}
				else{
					//continue
				}
			}
			$('#GoogleMapsPlaceholder').hide();
			$(".gmaps-ca-msg").addClass("hidden");
			$('#GoogleMapsPlaceholder').empty();
        	saveShippingAddress();
        	return false;
        }

        //TODO if program shipping, do an ajax call to create the order, check if error and if not - redirect to the billing page
        if ($('.js-program-shipping-container').length > 0) {

        	var $form = $('.js-address');
        	var data = $form.serialize();
        	data += '&' + $('.js-submit-shipping').attr('name') + '=';
        	var url = util.appendParamToURL($form.attr('action'),'format', 'ajax');
        	$.ajax({
        		type: 'POST',
        		url: url,
        		data: data,
        		async: true,
        		success: function (response) {
        			if ($(response).hasClass('js-dates-rejected')) {
        				showShippingAddressErrorInline($(response));
                        progress.hide();
                        showShippingMethods();
        			} else {
        				page.redirect(Urls.billingStart);
        			}
        		}
        	});

        	return false;
        } else {
			page.redirect(Urls.billingStart);
		}
    });

    var $apartment = $form.find('input[name*="_address3"]');
    $apartment.on('change',function() {
    	validateFormFields();
	});

	//handle click on no street number checkbox
	$('.js-no-number-checkbox :checkbox').on('click', function() {
		$('input.streetnumber').val($('input.streetnumber').val() == '0' ? '' : '0');
		$('input.streetnumber').prop('readonly', !$(this).attr('checked'));
		!$(this).attr('checked') ? $('.js-no-number-checkbox').prev().removeClass('error') &&  $('.js-no-number-checkbox').prev().find('input').removeClass('field-error'): $('.js-no-number-checkbox').prev().addClass('error') && $('.js-no-number-checkbox').prev().find('input').addClass('field-error');
    });

   //handle save address checkbox action for show and hide address name field
    $('.js-add-checkbox :checkbox').on('click', function() {
        checkAddressName();
        $('.js-address-id-check').toggleClass('hidden');

        validateFormFields();
    });
    checkAddressName();
   //handle submit button action and proceed to next action (choosing shipping method)
    $('.js-submit-store').on('click', function(e) {
        checkForm();
        if ($('.store-pickup-slots-section div.row-tiendas').first().find('div.product-data div.product-row div.product-available-date').length === 0) {
            e.preventDefault();
            checkDeliveryRestrictions();
        }
    	//TODO: check something else before submitting the store address
    });

    if ($('.js-shipping-delivery-restrictions-available').length > 0) {
        checkDeliveryRestrictions();
    }

    //handle add new address link
    $('.js-add-new-address-btn').on('click', function(e){
        e.preventDefault();
		$('#GoogleMapsPlaceholder').hide();
		$(".gmaps-ca-msg").addClass("hidden");
		$('#GoogleMapsPlaceholder').empty();
		$('.js-address-form').removeClass('addressVerified')
		document.getElementById("dwfrm_singleshipping_shippingAddress_addressFields_address1").removeEventListener("change", validateAddressWithGoogle);
		document.getElementById("dwfrm_singleshipping_shippingAddress_addressFields_address2").removeEventListener("change", validateAddressWithGoogle);
		document.getElementById("dwfrm_singleshipping_shippingAddress_addressFields_city").removeEventListener("change", validateAddressWithGoogle);
		document.getElementById("dwfrm_singleshipping_shippingAddress_addressFields_states_state").removeEventListener("change", validateAddressWithGoogle);
        showAddressForm();
    });

    //delete address
    $('.js-delete-address').on('click', function(e) {
        e.preventDefault();
        if (!SitePreferences.ENABLE_NEW_HOME_DELIVERY_UI) {
            showDeleteAddrDialog($(this));
        } else {
            // New Home Delivery UI
            var isSelected = $(this).hasClass('expanded');

            $('.js-delete-address').removeClass('expanded');
            var container = $(this).parent().next('div.js-show-address-delete-confimation-container');
            var overlay = $('div.js-show-address-delete-confimation').html();
            var index = $('.js-show-address-delete-confimation-container').index(container);

            function selectAddressByIndex(value) {
                return value !== index;
            }

            var deleteAddressContainer = $('.js-show-address-delete-confimation-container').filter(selectAddressByIndex);
            container.empty().append(overlay);
            container.slideToggle();
            container.prev('.select-single-address').toggleClass('selected-address-for-deletion')

            deleteAddressContainer.each(function(index, element){
                if ($(element).css('display') !== 'none') {
                    $(element).slideToggle();
                }

                $(element).prev('.select-single-address').removeClass('selected-address-for-deletion');
            })

            if (!isSelected) {
                $(this).addClass('expanded');
            }

            var singleAddress = $('this').parent('.select-single-address');

            $('.js-cancel-delete-address').on('click', function(e) {
                $('.js-show-address-delete-confimation-container').hide();
                $('.select-single-address.selected-address-for-deletion').removeClass('selected-address-for-deletion');
            });

            $('.js-confirm-delete-address').on('click', function(e) {
                $(this).parent().prev().find('a.js-delete-address').show();
                $(this).parent().hide();
                deleteAddress($(this).parents('.js-show-address-delete-confimation-container').prev());
                singleAddress.css('background-color', '#fffff');
            });
        }
    });

    //handle Add Address Manually button
    $('.js-show-address, .js-show-google-search').on('click', showHideManualAddress);
    $('.js-shipping-method-select').off('click');
    $('.js-shipping-method-select').click(function (e) {
    	e.preventDefault();
    	var $this = $(this);
    	var methodType = $this.data('delivery-type');
    	var shippingMethodID = $this.data('shipping-method-id');
        var activeMethodType = $('.js-shipping-method-select').data('delivery-type');
        var $parent = $this.parent();

        //dont allow actions on the currently selected method
        if ($parent.hasClass('active'))
            return false;

    	if (shippingMethodID) {
    		var parentSelector = '.js-delivery-method-types';

    		selectShippingMethod(shippingMethodID, $(this).hasClass('js-method-type-link'));
    		loadShippingMethodRenderingTemplate(shippingMethodID, 1, parentSelector, false);
    		//return false;
        }
    	hasTriggeredProgramShipping = false;
        //add the highlight classes
        $parent.addClass('active').siblings().removeClass('active');
    	return false;
    });

    if (hasInitShippingMethods) {
    	validateFormFields();
    	initializeShippingMethods();
	}

    if ($('.js-collect-on-behalf-panel').length > 0) {
    	$('.js-collect-on-behalf-panel').hide();
	}
	
	setTimeout(function() { $('#searchTextField').focus(); }, 20);
}

function validateAddressWithGoogleCallBack(results,status, address, zeroResults){
	if (status === google.maps.places.PlacesServiceStatus.OK) {
		console.log(results[0].formatted_address);
		console.log(address.replace("avenida","av."));

		/* Automatically set lat, lng with the suggested place, if it's the same place or not */
		var latitude = results[0].geometry.location.lat();
		var longitude = results[0].geometry.location.lng();
		var plusCode = (results[0].hasOwnProperty('plus_code')) ? results[0].plus_code.global_code : '';
		$('.js-latitude').find('input').val(latitude);
		$('.js-select-addresses :radio:checked').length > 0 && ($('.js-select-addresses :radio:checked').data('address').latitude = latitude);
		$('.js-longitude').find('input').val(longitude);
		$('.js-select-addresses :radio:checked').length > 0 && ($('.js-select-addresses :radio:checked').data('address').longitude = longitude);
		$('.js-plusCode').find('input').val(plusCode);
		$('.js-select-addresses :radio:checked').length > 0 && ($('.js-select-addresses :radio:checked').data('address').plusCode = plusCode);
		$('.js-address-form').addClass('addressVerified');

		if((results[0].formatted_address.toLowerCase() == address || (address.includes("avenida") && address.replace("avenida","av.") == results[0].formatted_address.toLowerCase())) && !zeroResults){
			/* if it's the same place, save shipping and continue */

			saveShippingAddress();
		}
		else{
			/* else display the map*/
			var styles = {
				default: null,
				hide: [
					{
					  "featureType": "administrative.land_parcel",
					  "elementType": "labels",
					  "stylers": [
						{
						  "visibility": "off"
						}
					  ]
					},
					{
					  "featureType": "poi",
					  "elementType": "labels.text",
					  "stylers": [
						{
						  "visibility": "off"
						}
					  ]
					},
					{
					  "featureType": "poi.business",
					  "stylers": [
						{
						  "visibility": "off"
						}
					  ]
					},
					{
					  "featureType": "poi.park",
					  "elementType": "labels.text",
					  "stylers": [
						{
						  "visibility": "off"
						}
					  ]
					},
					{
					  "featureType": "road.arterial",
					  "elementType": "labels",
					  "stylers": [
						{
						  "visibility": "on"
						}
					  ]
					},
					{
					  "featureType": "road.highway",
					  "elementType": "labels",
					  "stylers": [
						{
						  "visibility": "on"
						}
					  ]
					},
					{
					  "featureType": "road.local",
					  "stylers": [
						{
						  "visibility": "on"
						}
					  ]
					},
					{
					  "featureType": "road.local",
					  "elementType": "labels",
					  "stylers": [
						{
						  "visibility": "on"
						}
					  ]
					}
				  ]
			  };
			var nearestPlace = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
			var map = new google.maps.Map(document.getElementById('GoogleMapsPlaceholder'), {center: nearestPlace, zoom: 17});
			map.setOptions({styles: styles['hide']});
			var marker = new google.maps.Marker({
				position: nearestPlace,
				map: map,
				draggable:true,
				title:"Ubica tu dirección"
			});
			marker.addListener('drag', updateLatLngWhenDrag);
			marker.addListener('dragend', updateLatLngWhenDrag);
			var contentString = Resources.GMAPS_INFOWINDOW_TEXT;

			var infowindow = new google.maps.InfoWindow({
				content: contentString
			  });
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open(map,marker);
			});
			google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
				infowindow.open(map, marker);
			  });
			progress.hide('.js-address-form');
			$("#GoogleMapsPlaceholder").show();
			$(".gmaps-ca-msg").removeClass("hidden");

			//scroll to GoogleMapsPlaceHolder
			var offset = $(".gmaps-ca-msg").offset();
			var scrollto = offset.top - 10; // fixed_top_bar_height = 50px
			$('html, body').animate({scrollTop:scrollto}, 0);

			$(".js-submit-span-shipping").html(Resources.GMAPS_CONFIRMBUTTON);
		}
	}
	else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS){
		validateAddressWithGoogle(true);
	}
	else{
		progress.hide('.js-address-form');
		$("#GoogleMapsPlaceholder").hide();
		$(".gmaps-ca-msg").addClass("hidden");
	}
}

function updateLatLngWhenDrag(event){
	$('.js-latitude').find('input').val(event.latLng.lat());
	$('.js-select-addresses :radio:checked').length > 0 && ($('.js-select-addresses :radio:checked').data('address').latitude = event.latLng.lat());
	$('.js-longitude').find('input').val(event.latLng.lng());
	$('.js-select-addresses :radio:checked').length > 0 && ($('.js-select-addresses :radio:checked').data('address').longitude = event.latLng.lng());
	$('.js-address-form').addClass('addressVerified');
}

function validateAddressWithGoogle(zeroResults){
	if (zeroResults === undefined) {
		zeroResults = false;
	}
	var streetName = $(".streetnamecontent").val();
	var streetNumber = $(".streetnumber").val();
	var country = Resources.SITE_ID == "Paris" ? "Chile" : (Resources.SITE_ID == "ParisPe" ? "Peru" : "Chile");
	if(Resources.SITE_ID === "Paris"){
		var comuna = $(".comuna-select").val().charAt(0).toUpperCase()+$(".comuna-select").val().substring(1).toLowerCase();
		var region = $(".js-region-select option:selected").text();
		var address = zeroResults ? (comuna+", "+region+", "+country) : (streetName+" "+streetNumber+", "+comuna+", "+region+", "+country);
	}
	else if(Resources.SITE_ID === "ParisPe"){
		var distrito = $(".distrito-select").val().charAt(0).toUpperCase()+$(".distrito-select").val().substring(1).toLowerCase();
		var provincia = $(".js-provincia-select").val().charAt(0).toUpperCase()+$(".js-provincia-select").val().substring(1).toLowerCase();
		var departamento = $(".js-departamento-select option:selected").text();
		var address = zeroResults ? (distrito+", "+provincia+", "+departamento+", "+country) : (streetName+" "+streetNumber+", "+distrito+", "+provincia+", "+departamento+", "+country);
	}
	if (typeof(window.google) !== 'undefined') {
		var service = new google.maps.places.PlacesService($("#GoogleMapsPlaceholder").get(0));
	}
	var request = {
		query: address,
		fields: ['formatted_address', 'place_id', 'geometry', 'plus_code'],
	  };
	service.findPlaceFromQuery(request, function(results, status){
		validateAddressWithGoogleCallBack(results, status, address.toLowerCase(), zeroResults);
	});
}

function showShippingAddressErrorInline(html) {
	$('.js-program-shipping-inline-error-section').html(html);
	$('.js-program-shipping-inline-error-section').show();

	handleScheduledShippingFallback();
}

function showShippingAddressError(html) {
	dialog.open({
        html: html,
        options: {
            width: 400
        }
    });
	handleScheduledShippingFallback();
}

function handleScheduledShippingFallback() {

	if (SitePreferences.SCHEDULED_SHIPPING_FALLBACK_ENABLED) {
		$('.js-shipping-method-option-section.fallback').show();
		$('.js-shipping-method-option-section.fallback .js-shipping-method-option-container').show();
	}
}

function initGTMEvents () {
	if ($('.js-checkout-primary-content').length) {

		var selectAddress = {
				'event' : 'page-virtual',
				'page': 'shipping/home-delivery/select-address',
				'pagename': 'Buying Process - Type Delivery'
			};
		var addAddressManually = {
				'event' : 'page-virtual',
				'page': 'shipping/home-delivery/add-address-manually',
				'pagename': 'Buying Process - Type Delivery'
			};
		var addAddress = {
				'event' : 'page-virtual',
				'page': 'shipping/home-delivery/add-address',
				'pagename': 'Buying Process - Type Delivery'
			};

	    if ($('.js-choose-shippingmethod').length && $('.js-shippingmethod-list').hasClass('hidden')) {
	        var deliveryType = $('.js-delivery-method-type.active').find('.js-shipping-method-select').data('delivery-type');
	        if (deliveryType != undefined && deliveryType == 'HOME') {
	        	$(document).on('change', '.js-address .js-select-addresses :radio', function () {
	        		window.dataLayer.push(selectAddress);
	        	});

	        	if ($('.js-select-addresses').length == 0) {
	        		if ($('.js-google-search-field').hasClass('hidden')) {
	        			window.dataLayer.push(addAddressManually);
	        		} else if (!$('.js-google-search-field').hasClass('hidden')) {
	        			window.dataLayer.push(addAddress);
	        		}
	        	}

	        	$('.js-add-new-address-btn, .js-show-google-search').on('click', function () {
	        		if ($('.js-shipping-form').hasClass('hidden') || $(this).hasClass('js-show-google-search')) {
		        		window.dataLayer.push(addAddress);
	        		}
	        	});

	        	$('.js-show-address').on('click',function(){
	        		if ($('.js-form-hidden-field').hasClass('hidden')) {
	        			window.dataLayer.push(addAddressManually);
	        		}

	        	});
	        } else if (deliveryType == "CLICK_COLLECT") {



	        }
	    }
    }
}

// function removeProductsWithRestrictions() {
// 	$(document.body).on('click', '#js-restrictions-remove-from-cart[data-uid-remove]', function () {
//         var productsToRemove = $(this).attr('data-uid-remove');
//         $.ajax({
//         	url: util.ajaxUrl(Urls.removeProductsWithRestrictions),
//         	type: 'POST',
//         	data: {
//         		productsToRemove: productsToRemove
//         	},
//         	beforeSend: function () {
//                 dialog.close();
//         		progress.show();
//         	},
//         	success: function (response) {
//                 if (response.success) {
//                 	if (response.quantityProductsOk > 0) {
//                 		updateSummary();
//                 		removeProductsWithRestrictionsFromUI(JSON.parse(productsToRemove));
//                 		showShippingMethods();
//                 	} else {
//                 		window.location.href = response.urlRedirect;
//                 	}
//                 } else {
//                     window.location.href = '/';
//                 }
//         	},
//         	error: function () {
//         		window.location.href = '/';
//             }
//         });
// 	});
// }

// function removeProductsWithRestrictionsFromUI(productsToRemove) {
//     $('.product-wrapper[data-product]').each(function () {
//     	if (productsToRemove[$(this).data('product')]) {
//     		$(this).remove();
//     	}
//     });
//     if ($('.status-code-unavailable .product-wrapper[data-product]').length === 0) {
//         $('.status-code-unavailable').remove();
//     }
//     programshipping.initializeDeliveryCalendar();
// }

function autoScrollToRowTiendasProducts() {
    setTimeout(function () {
        $('html, body').animate({
            scrollTop: $('#dwfrm_singleshipping_shippingAddress div.row-tiendas').first().offset().top
        }, 1000);
    }, 10);
}

exports.init = function () {
    initializeEvents();
    validateFormFields();
    if ($('.js-dates-rejected').length > 0) {
    	showShippingAddressError($('.js-dates-rejected'));
    }
    if ($('.js-comuna-code-missing').length > 0) {
    	showShippingAddressError($('.js-comuna-code-missing'));
    }
    hasTriggeredProgramShipping = false;
};

exports.updateShippingMethodList = updateShippingMethodList;
exports.validateFormFields = validateFormFields;
exports.checkDeliveryRestrictions = checkDeliveryRestrictions;
