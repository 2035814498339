'use strict';

const dialog = require('./dialog');
const util = require('./util');
const page = require('./page');
const progress = require('./progress');

const newRegister = {
  formValidation: null,

  loadModal($target) {
    const self = this;
    dialog.open({
      url: $target.data('src'),
      options: {
        title: window.Resources.ACCOUNT_REGISTER_MODAL_TITLE,
        draggable: false,
        autoOpen: false,
        responsive: true,
        modal: true,
        fluid: true,
        width: 395,
        dialogClass: 'ui-dialog-full ui-dialog-register-customer',
      },
      callback() {
        const $form = $('.js-register-modal-form');
        const formValidation = $form.validateForm();
        
        formValidation.onErrorUpdated = function() {
          const $error = formValidation.$errors;
          delete $error.dni;
          if ((!$error.dni) && this.hasErrors()) {
            this.$submitButton.prop('disabled', 'disabled');
          } else {
            this.$submitButton.removeAttr('disabled');
          }
        };
        
        $form.on('submit', (e) => {
          e.preventDefault();

          formValidation.validate().then(valid => {
            if (valid) {
              self.submit($(this), formValidation.$fields, $form);
            }
          });
        });

        $form.find('a[data-action="login"]').on('click', function(e) {
          require('./new-login').loadModal($(this));
        });
      }
    });
  },

  initToogleSelection() {
    const $toggleButton = $('#toggle-selection');
    const $rutContainer = $('#rut-container');
    const $dniContainer = $('#dni-container');

    const targetToShow = $toggleButton.data('target');

    if (targetToShow === 'rut-container') {
      $rutContainer.hide();
      $dniContainer.show();
    }
    
    if (targetToShow === 'dni-container') {
      $dniContainer.hide();
      $rutContainer.show();
    }
    
    $toggleButton.on('click', e => {
      e.preventDefault();

      const targetToShow = $toggleButton.data('target');

      this.formValidation.updateErrorState(false);

      if (targetToShow === 'rut-container') {
        $rutContainer.show();
        $dniContainer.hide();
        $toggleButton.text('Quiero comprar con DNI');
        $toggleButton.data('target', 'dni-container');
      }
      
      if (targetToShow === 'dni-container') {
        $dniContainer.show();
        $rutContainer.hide();
        $toggleButton.text('Quiero comprar con RUT');
        $toggleButton.data('target', 'rut-container');
      }
    });
  },

  submit(fields, $form, idTypeSelection) {
    window.headerCustomer.setUser('New Customer name');
    const $submitLogin = $($form).find('[name$="dwfrm_profile_confirm"]');
    let data = $form.serialize();  
    data += '&identityOption=' + 'RUT';//idTypeSelection.toLocaleUpperCase();
    data += '&' + $submitLogin.attr('name') + '=';
    const url = util.appendParamToURL($form.attr('action'),'format', 'ajax');
    const rut = btoa($('#rut').val());

    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        dataType: 'json',
        success: function (resp) {
            if (resp.data.errorCode === 200) {
              var gaObject = new Object();
              gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
              gaObject['event-interactive-category'] = window.Resources.GTM_REGISTER;
              gaObject['event-interactive-accion'] = window.Resources.GTM_REGISTER_OK;
              gaObject['event-interactive-tag'] = rut;
              window.dataLayer.push(gaObject);
                resolve(resp.data);
            } else {
                reject(resp.data);
            }
        },
        failure: function (e) {
          reject(e);
        }
      });
    });

  },

  init() {
    const $form = window.registerFormId ? $(`#${window.registerFormId}`) : $('.new-register__inner form');
    
    if ($form.length === 0) {
        return;
    }
    
    this.formValidation = $form.validateForm();
    const self = this;

    self.initToogleSelection()

    this.formValidation.onErrorUpdated = function() {
      const $errors = this.$errors;
      const $toggleButton = $('#toggle-selection');
      const targetToShow = $toggleButton.data('target');
      const errorsCount = this.hasErrors();

      if (errorsCount) {
        this.$submitButton.prop('disabled', 'disabled');

        if($errors['dwfrm_profile_customer_email'] && $errors['dwfrm_profile_customer_email'].find(item => item.rule === 'uniqueEmail')) {
          var gaObject = new Object();
          gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
          gaObject['event-interactive-category'] = window.Resources.GTM_REGISTER;
          gaObject['event-interactive-accion'] = window.Resources.GTM_RESPONSE;
          gaObject['event-interactive-tag'] = window.Resources.GTM_WRONG_EMAIL;
          window.dataLayer.push(gaObject);
        }

        if($errors['rut'] && $errors['rut'].find(item => item.rule === 'uniqueRut')) {
          var gaObject = new Object();
          gaObject.event = window.Resources.GTM_EVENT_INTERACTIVE;
          gaObject['event-interactive-category'] = window.Resources.GTM_REGISTER;
          gaObject['event-interactive-accion'] = window.Resources.GTM_RESPONSE;
          gaObject['event-interactive-tag'] = window.Resources.GTM_WRONG_RUT;
          window.dataLayer.push(gaObject);
        }
      }

      if (errorsCount === 1) {
        if (targetToShow === 'dni-container' && !!$errors.dni) {
          this.$submitButton.removeAttr('disabled');
        }

        if (targetToShow === 'rut-container' && !!$errors.rut) {
          this.$submitButton.removeAttr('disabled');
        }
      } else if (errorsCount === 0) {
        this.$submitButton.removeAttr('disabled');
      }
    };

    $('.dwfrm_profile_customer_sendNotifications_checkbox').on('change', () => {
      if ($('#dwfrm_profile_customer_sendNotifications').length) {
        if ($('#dwfrm_profile_customer_sendNotifications')[0].checked) {
          $('#dwfrm_profile_customer_sendNotifications_label').addClass('checkbox-label__label--checked');
        } else {
          $('#dwfrm_profile_customer_sendNotifications_label').removeClass('checkbox-label__label--checked');
        }
      }
    });

    $('.dwfrm_profile_customer_conditions_checkbox').on('change', () => {
      if ($('#dwfrm_profile_customer_conditions').length) {
        if ($('#dwfrm_profile_customer_conditions')[0].checked) {
          $('#dwfrm_profile_customer_conditions_label').addClass('checkbox-label__label--checked');
          $('#dwfrm_profile_customer_conditions_error').css('display', 'none');
          $('#dwfrm_profile_customer_conditions_checkbox').removeClass('error');
        } else {
          $('#dwfrm_profile_customer_conditions_label').removeClass('checkbox-label__label--checked');
          $('#dwfrm_profile_customer_conditions_error').css('display','');
          $('#dwfrm_profile_customer_conditions_checkbox').addClass('error');
        }
      }
    });
    
    $form.on('submit', (e) => {
      e.preventDefault();

      this.formValidation.$submitButton.empty().addClass('btn--loading');
      this.formValidation.$submitButton.append($('<div>'));
      this.formValidation.$submitButton.append($('<div>'));
      this.formValidation.$submitButton.append($('<div>'));

      this.formValidation.validate().then(valid => {
        const errorsCount = this.formValidation.hasErrors();
        const errors = this.formValidation.$errors;
        const $toggleButton = $('#toggle-selection');
        const targetToShow = $toggleButton.data('target');
        const idTypeSelection = targetToShow == 'dni-container' ? 'rut' : 'dni';

        if ($('#dwfrm_profile_customer_conditions').length && $('#dwfrm_profile_customer_conditions')[0].checked === false) {
          $('#dwfrm_profile_customer_conditions_label').removeClass('checkbox-label__label--checked');
          $('#dwfrm_profile_customer_conditions_error').css('display','');
          $('#dwfrm_profile_customer_conditions_checkbox').addClass('error');
    
          if($('#dwfrm_profile_confirm').length) {
            this.formValidation.$submitButton.empty().removeClass('btn--loading').text('Crear cuenta');
          }
          return;
        }
  
        if (errorsCount > 1) {
          return;
        } else {
          if (errorsCount === 1) {
            if (idTypeSelection === 'dni' && errors.hasOwnProperty('dni')) {
              this.formValidation.$submitButton.empty().removeClass('btn--loading').text('Crear cuenta');
              return;
            }
    
            if (idTypeSelection === 'rut' && errors.hasOwnProperty('rut')) {
              this.formValidation.$submitButton.empty().removeClass('btn--loading').text('Crear cuenta');
              return;
            }
          }
        }

        self.submit(this.formValidation.$fields, $form, idTypeSelection)
          .then(data => {
            window.location.href = data.redirectUrl;
          })
          .catch(e => {
            console.error(e);
            window.location.href = window.location.href;
          })
          .finally(() => {
            this.formValidation.$submitButton.empty().removeClass('btn--loading').text('Crear cuenta');
          });
      });
    });

    $form.find('a[data-action="login"]').on('click', function(e) {
      e.preventDefault();
      progress.show();

      const homeUrl = window.Urls.home;
      let url = util.appendParamToURL(homeUrl, 'showform', 'true');
      page.redirect(url);
    });
    const rutInput = $('#rut');
    $(rutInput).on('keydown', function(e) {
      if (e.keyCode === 32) {
        e.preventDefault();
      }
    });
  },
};

module.exports = newRegister;
