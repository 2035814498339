'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    factura = require('./factura'),
    giftcard = require('../../giftcard'),
    util = require('../../util'),
    dialog = require('../../dialog'),
    validator = require('../../validator'),
    creditCardType = require('../../components/credit-card-type'),
    progress = require('../../progress'),
    page = require('../../page'),
    _ = require('lodash'),
    formatRut = require('./../../components/formatRut');
var personalDetails = require('./personalDetails');
var isSavedCard = false;

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
var isCencosudCard;
var isCreditCard;
function setCCFields(data) {
    var $creditCard;
    var cardHolder = data.holder;
    var cardHolderNames = cardHolder.split(' ');

    var isCencosudCardPaymentMethodSelected =
        $('#is-CENCOSUD_CARD').is(':checked');
    var isCreditCardPaymentMethodSelected = $('#is-CREDIT_CARD').is(':checked');
    var isCencosudCardTCompPaymentMethodSelected = $(
        '#is-CENCOSUD_CARD_TCOMP'
    ).is(':checked');
    var isCreditCardTCompPaymentMethodSelected = $('#is-CREDIT_CARD_TCOMP').is(
        ':checked'
    );
    var isCencosudCardXCashPaymentMethodSelected = $(
        '#is-CENCOSUD_CARD_XCASH'
    ).is(':checked');

    if (isCencosudCardPaymentMethodSelected) {
        $creditCard = $('[data-method="CENCOSUD_CARD"]');
        $creditCard
            .find('input[name$="cencosudCard_firstName"]')
            .val(cardHolderNames[0])
            .trigger('change');
        $creditCard
            .find('input[name$="cencosudCard_lastName"]')
            .val(cardHolderNames[1])
            .trigger('change');
        $creditCard
            .find('input[name*="_cencosudCard_number"]')
            .val(data.maskedNumber)
            .trigger('change');
        if (data.expirationMonth.length === 1) {
            $creditCard
                .find('[name$="_month"]')
                .val('0' + data.expirationMonth)
                .trigger('change');
        } else {
            $creditCard
                .find('[name$="_month"]')
                .val(data.expirationMonth)
                .trigger('change');
        }
        $creditCard
            .find('[name$="_year"]')
            .val(data.expirationYear)
            .trigger('change');
        $creditCard.find('input[name*="_cvn"]').val('').focus();
        $creditCard
            .find('input[name$="_selectedCardID"]')
            .val(data.selectedCardID);
        $creditCard
            .find('input[name$="_paymentToken"]')
            .val(data.creditCardToken);
        $("[name*='billing_save']:visible").prop('disabled', false);
    } else if (isCreditCardPaymentMethodSelected) {
        $creditCard = $('[data-method="CREDIT_CARD"]');
        $creditCard
            .find('input[name$="creditCard_firstName"]')
            .val(cardHolderNames[0])
            .trigger('change');
        $creditCard
            .find('input[name$="creditCard_lastName"]')
            .val(cardHolderNames[1])
            .trigger('change');
        $creditCard
            .find('input[name*="_creditCard_number"]')
            .val(data.maskedNumber)
            .trigger('change');
        if (data.expirationMonth.length === 1) {
            $creditCard
                .find('[name$="_month"]')
                .val('0' + data.expirationMonth)
                .trigger('change');
        } else {
            $creditCard
                .find('[name$="_month"]')
                .val(data.expirationMonth)
                .trigger('change');
        }
        $creditCard
            .find('[name$="_year"]')
            .val(data.expirationYear)
            .trigger('change');
        $creditCard.find('input[name*="_cvn"]').val('').focus();
        $creditCard
            .find('input[name$="_selectedCardID"]')
            .val(data.selectedCardID);
        $creditCard
            .find('input[name$="_paymentToken"]')
            .val(data.creditCardToken);
        $("[name*='billing_save']:visible").prop('disabled', false);
    } else if (isCencosudCardTCompPaymentMethodSelected) {
        $creditCard = $('[data-method="CENCOSUD_CARD_TCOMP"]');
        $creditCard
            .find('input[name$="cencosudCardTComp_firstName"]')
            .val(cardHolderNames[0])
            .trigger('change');
        $creditCard
            .find('input[name$="cencosudCardTComp_lastName"]')
            .val(cardHolderNames[1])
            .trigger('change');
        $creditCard
            .find('input[name*="_cencosudCardTComp_number"]')
            .val(data.maskedNumber)
            .trigger('change');
        if (data.expirationMonth.length === 1) {
            $creditCard
                .find('[name$="_month"]')
                .val('0' + data.expirationMonth)
                .trigger('change');
        } else {
            $creditCard
                .find('[name$="_month"]')
                .val(data.expirationMonth)
                .trigger('change');
        }
        $creditCard
            .find('[name$="_year"]')
            .val(data.expirationYear)
            .trigger('change');
        $creditCard.find('input[name*="_cvn"]').addClass('required');
        $creditCard.find('input[name*="_cvn"]').val('').focus();
        $creditCard
            .find('input[name$="_selectedCardID"]')
            .val(data.selectedCardID);
        $("[name*='billing_save']:visible").prop('disabled', false);
    } else if (isCreditCardTCompPaymentMethodSelected) {
        $creditCard = $('[data-method="CREDIT_CARD_TCOMP"]');
        $creditCard
            .find('input[name$="creditCardTComp_firstName"]')
            .val(cardHolderNames[0])
            .trigger('change');
        $creditCard
            .find('input[name$="creditCardTComp_lastName"]')
            .val(cardHolderNames[1])
            .trigger('change');
        $creditCard
            .find('input[name*="_creditCardTComp_number"]')
            .val(data.maskedNumber)
            .trigger('change');
        if (data.expirationMonth.length === 1) {
            $creditCard
                .find('[name$="_month"]')
                .val('0' + data.expirationMonth)
                .trigger('change');
        } else {
            $creditCard
                .find('[name$="_month"]')
                .val(data.expirationMonth)
                .trigger('change');
        }
        $creditCard
            .find('[name$="_year"]')
            .val(data.expirationYear)
            .trigger('change');
        $creditCard.find('input[name*="_cvn"]').addClass('required');
        $creditCard.find('input[name*="_cvn"]').val('').focus();
        $creditCard
            .find('input[name$="_selectedCardID"]')
            .val(data.selectedCardID);
        $("[name*='billing_save']:visible").prop('disabled', false);
    } else if (isCencosudCardXCashPaymentMethodSelected) {
        $creditCard = $('[data-method="CENCOSUD_CARD_XCASH"]');
        $creditCard
            .find('input[name$="cencosudCardXCash_firstName"]')
            .val(cardHolderNames[0])
            .trigger('change');
        $creditCard
            .find('input[name$="cencosudCardXCash_lastName"]')
            .val(cardHolderNames[1])
            .trigger('change');
        $creditCard
            .find('input[name*="_cencosudCardXCash_number"]')
            .val(data.maskedNumber)
            .trigger('change');
        if (data.expirationMonth.length === 1) {
            $creditCard
                .find('[name$="_month"]')
                .val('0' + data.expirationMonth)
                .trigger('change');
        } else {
            $creditCard
                .find('[name$="_month"]')
                .val(data.expirationMonth)
                .trigger('change');
        }
        $creditCard
            .find('[name$="_year"]')
            .val(data.expirationYear)
            .trigger('change');

        $creditCard.find('input[name*="_cvn"]').addClass('required');
        $creditCard.find('input[name*="_cvn"]').val('').focus();
        $creditCard
            .find('input[name$="_selectedCardID"]')
            .val(data.selectedCardID);
        $("[name*='billing_save']:visible").prop('disabled', false);
    }
    if (!checkIfTermsAndConditionsIsChecked()) {
        disablePayButton();
    }
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details change to POST
    var dataObj = { creditCardUUID: cardID };
    $.ajax({
        type: 'POST',
        url: Urls.billingSelectCC,
        data: dataObj,
        success: function (response, status, xhr) {
            if (!response) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }

            setCCFields(response);
        },
        failure: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
        },
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded');

    var $selectedPaymentMethod = $paymentMethods.filter(
        '[data-method="' + paymentMethodID + '"]'
    );
    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    $selectedPaymentMethod.addClass('payment-method-expanded');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value="' + paymentMethodID + '"]').prop('checked', 'checked');

    formPrepare.validateForm({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]',
        enabledByDefault: true,
    });
    inputsFormEventValidation(paymentMethodID);
}

/**
 * @function
 * @description register focusout to event listener for extra validation form
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 * @returns void
 */
function inputsFormEventValidation(_paymentMethodID) {
    if ($('.CA-fonocompra-box').length == 0) {
        var paymentMethodID = _paymentMethodID
            ? _paymentMethodID
            : $('.box-form-payment-radio.active-state')
                  .find('input[type=radio]')
                  .first()
                  .attr('value');
    } else {
        var paymentMethodID = _paymentMethodID
            ? _paymentMethodID
            : $('.js-nav-tab.active').find('a').attr('href').substring(1);
    }
    $('.checkout-billing')
        .find('input:visible')
        .each(function (index, value) {
            $(value).focusout(function () {
                if (!checkIfTermsAndConditionsIsChecked()) {
                    $('#' + paymentMethodID)
                        .find('button[name*=billing_save]')
                        .prop('disabled', true);
                }
            });
        });
}

/**
 * @function
 * @description disable pay button for all pay methods
 * @returns void
 */
function disablePayButton() {
    if ($('.CA-fonocompra-box').length == 0) {
        var method = $('.box-form-payment-radio.active-state')
            .find('input[type=radio]')
            .first()
            .attr('value');
    } else {
        var method = $('.js-nav-tab.active')
            .find('a')
            .attr('href')
            .substring(1);
    }
    $('#' + method)
        .find('button[name*=billing_save]')
        .prop('disabled', true);
}

/**
 * @function
 * @description enable pay button for all pay methods
 * @returns void
 */
function enablePayButton() {
    if ($('.CA-fonocompra-box').length == 0) {
        var method = $('.box-form-payment-radio.active-state')
            .find('input[type=radio]')
            .first()
            .attr('value');
    } else {
        var method = $('.js-nav-tab.active')
            .find('a')
            .attr('href')
            .substring(1);
    }
    $('#' + method)
        .find('button[name*=billing_save]')
        .prop('disabled', false);
}

/**
 * @function
 * @description return true/false if checkbox was check
 * @returns boolean
 */
function checkIfTermsAndConditionsIsChecked() {
    if ($('.CA-fonocompra-box').length == 0) {
        var method = $('.box-form-payment-radio.active-state')
            .find('input[type=radio]')
            .first()
            .attr('value');
    } else {
        var method = $('.js-nav-tab.active')
            .find('a')
            .attr('href')
            .substring(1);
    }
    var termsAndContiditonsCheck = $('#' + method).find('.js-terms-and-cond');
    return $(termsAndContiditonsCheck).is(':checked');
}

function displayPaymentDetails(selectedPaymentMethod) {
    var savedCards = 0;

    if (selectedPaymentMethod === 'CENCOSUD_CARD') {
        savedCards = $('#savedCencosudCards').children();
    } else if (selectedPaymentMethod === 'CREDIT_CARD') {
        savedCards = $('#savedCreditCards').children();
    } else if (selectedPaymentMethod === 'CENCOSUD_CARD_TCOMP') {
        savedCards = $('#savedCencosudCardsTComp').children();
    } else if (selectedPaymentMethod === 'CREDIT_CARD_TCOMP') {
        savedCards = $('#savedCreditCardsTComp').children();
    } else if (selectedPaymentMethod === 'CENCOSUD_CARD_XCASH') {
        savedCards = $('#savedCencosudCardsXCash').children();
    }

    if (savedCards.length <= 0) {
        $('.new-card').hide();
        $('.saved-card-fields').show();
        $('.payment-method .buttons, .payment-method .checkout').show();
        $('.non-saved-card-fields').show();
        $('.js-card-number-field').attr('type', 'tel');
    } else {
        $('.new-card').show();
        $('.saved-card-fields').hide();
        $('.payment-method .buttons, .payment-method .checkout').hide();
        $('.non-saved-card-fields').hide();
    }

    if (
        selectedPaymentMethod === 'WEBPAY' ||
        selectedPaymentMethod === 'XCash' ||
        selectedPaymentMethod === 'ALIGNET_CREDIT_DEBIT' ||
        selectedPaymentMethod === 'ALIGNET_CENCOSUD_CARD' ||
        selectedPaymentMethod === 'MERCADOPAGO' ||
        selectedPaymentMethod === 'MERCADOPAGO_CENCOSUD'
    ) {
        $('.payment-method .buttons').show();
        formatRut.initFormatRutInstance('#js-catcerrada-rut-container');
    }
}

function cc_format(value) {
    var i, len;
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || '';
    var parts = [];
    for (i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
        return parts.join(' ');
    } else {
        return value;
    }
}

function highlightCard() {
    $('.js-card-number').on('change keyup', function () {
        var types = creditCardType(
            $(this).val().replace(new RegExp('[^0-9]', 'g'), '')
        );
        var cardType = types.length > 0 ? types[0].type : '';
        $('.js-card-images img').addClass('disabled');
        $('.js-card-images img[data-type="' + cardType + '"]').removeClass(
            'disabled'
        );
        if (isCencosudCard) {
            $('.js-credit-card-icon img').addClass('disabled');
        } else if (isCreditCard) {
            $('.js-cencosud-card-icon img').addClass('disabled');
        }

        if (cardType === 'VISA' || cardType === 'MASTERCARD') {
            $('input[name*="_cvn"]').attr('maxlength', 3);
        } else if (cardType === 'AE') {
            $('input[name*="_cvn"]').attr('maxlength', 4);
        }
    });
    if ($('.CA-fonocompra-box').length == 0) {
        $('.js-card-number').on('keypress change', function () {
            $(this).val(function (index, value) {
                return cc_format(value);
            });
        });
    }
}

function highlightCardFonocompra() {
    if ($('.js-nav-tab').length > 0) {
        $('.js-nav-tab').each(function (index) {
            var $element = $(this);

            if ($element.hasClass('active')) {
                var ID = $element.find('a').attr('href').replace('#', '');
                var cardNumber = $('#' + ID).find('.js-card-number');

                if (cardNumber.length > 0 && cardNumber.val().length > 0) {
                    cardNumber.trigger('change');
                }
            }
        });
    }
}

function validateAllInputField() {
    $('.checkout-billing')
        .find('input:visible')
        .each(function (index, value) {
            if ($(value).val() == '') {
                $(value).addClass('error');
                $(value).closest('span.error').show();
            }
        });
}

function onLeaveMergedCreditCardNumber() {
    var cardNumber = $(this).val();
    var dataObj = { CardNumber: cardNumber };
    var data = dataObj;
    if (cardNumber.length > 0) {
        $.ajax({
            type: 'POST',
            url: Urls.checkCardType,
            data: dataObj,
            success: function (response, status, xhr) {
                isCencosudCard = response.IsCencosudCard;
                isCreditCard = response.IsCreditCard;
                if (isCencosudCard) {
                    //Show msg and price for Cencosud Card
                    $('.js-message-giftcard-ready').show();
                    $('.js-cencosud-total-price').show();
                    $('.js-creditcard-total-price').hide();
                } else if (isCreditCard) {
                    //Show price for Credit Card and hide msg for Cencosud
                    $('.js-message-giftcard-ready').hide();
                    $('.js-cencosud-total-price').hide();
                    $('.js-creditcard-total-price').show();
                }
                $('.js-card-number').keyup();
            },
            failure: function () {
                return false;
            },
        });
    }
}

// Stock Alerts
function showFeedbackUpdateQty() {
    var showModalBg = document.getElementById('alertStockQuantityBilling');
    if (showModalBg) {
        showModalBg.classList.remove('hidden');
        showModalBg.classList.add('fade-in');
        setTimeout(showMessageUpdateQty(), 400);
    }
}

function showMessageUpdateQty() {
    var showModalMessage = document.querySelector('.feedback-success');
    if (showModalMessage) {
        showModalMessage.classList.remove('hide-message');
        showModalMessage.classList.add('slide-down');
        setTimeout(function () {
            hideFeedBackUpdateQty();
            showModalMessage.classList.remove('slide-down');
        }, 4500);
    }
}

function hideFeedBackUpdateQty() {
    var hideModalMessage = document.querySelector('.feedback-success');
    if (hideModalMessage) {
        hideModalMessage.classList.remove('slide-down');
        hideModalMessage.classList.add('slide-up');
        setTimeout(function () {
            hideModalUpdateQty();
            hideModalMessage.classList.remove('slide-up');
            hideModalMessage.classList.add('hide-message');
        }, 400);
    }
}

function hideModalUpdateQty() {
    var hideModalContent = document.querySelector('.modal-content-bg');
    if (hideModalContent) {
        hideModalContent.classList.remove('fade-in');
        hideModalContent.classList.add('fade-out');
        setTimeout(function () {
            hideModalContent.classList.add('hidden');
            hideModalContent.classList.remove('fade-out');
        }, 400);
    }
}

/**
 * @function
 * @description show alert after add a coupon or after a throwed error
 * @param {Boolean} type if true is a success alert, if false is an error alert
 * @param {String} title the title for the alert
 * @param {String} message the message for the alert
 * @returns void
 */
function showCouponAlert(type, title, message) {
    const showAlertBg = document.getElementById('alertCoupon');
    const alertIcon = document.getElementById('alert-icon');
    const alertTitle = showAlertBg.getElementsByClassName('title')[0];
    const alertMsg = showAlertBg.getElementsByClassName('message')[0];
    alertIcon.classList.remove(...alertIcon.classList);
    type
        ? alertIcon.classList.add('success-alert')
        : alertIcon.classList.add('barra-alert');
    alertTitle.innerHTML = title;
    alertMsg.innerHTML = message;
    showAlertBg.classList.remove('hidden');
    setTimeout(function () {
        showAlertBg.classList.add('hidden');
    }, 4000);
}

/**
 * @function
 * @description hide coupon alert
 * @returns void
 */
function hideCouponAlert() {
    const showAlertBg = document.getElementById('alertCoupon');
    showAlertBg.classList.add('hidden');
}

/**
 * @function
 * @description remove coupon via ajax
 * @param {Object} e the input event
 * @returns void
 */
function removeCoupon(e) {
    progress.show();
    const couponCode = e.target.name;
    const url = util.appendParamsToUrl(Urls.removeCoupon, {
        couponCode,
        format: 'ajax',
    });
    $.getJSON(url, function (data) {
        if (data.success) {
            if (window.location.search) {
                window.location.assign(
                    `${Urls.billing}${window.location.search}`
                );
            } else {
                window.location.assign(Urls.billing);
            }
        }
    });
}

/**
 * @function
 * @description load modal for delete coupon
 * @param {Object} event the input event
 * @returns void
 */
function loadModal(event) {
    const modalOptions = {
        modal: true,
        draggable: false,
        dialogClass: 'confirmation-dialog billing',
        title: String.format(Resources.DELETE_COUPON_MODAL_TITLE),
        show: { effect: 'drop', direction: 'up', duration: 300 },
        hide: { effect: 'drop', direction: 'up', duration: 300 },
        open: function () {
            $('html').css('overflow', 'hidden');
        },
        close: function () {
            $('html').css('overflow', 'auto');
        },
        buttons: [
            {
                text: Resources.DELETE_COUPON_MODAL_CANCEL,
                class: 'btn btn-secondary cancel',
                click: function () {
                    $(this).dialog('close');
                },
            },
            {
                text: Resources.DELETE_COUPON_MODAL_DELETE,
                class: 'btn btn-primary delete',
                click: function () {
                    removeCoupon(event);
                },
            },
        ],
    };

    dialog.open({
        target: $('#confirmation-dialog'),
        html: `<span>${String.format(
            Resources.DELETE_COUPON_MODAL_MSG
        )}</span>`,
        options: modalOptions,
    });
}

/**
 * Validate and style Terms & Conditions checkbox section.
 *
 * @param {boolean} [showErrorMessage=true] - Mainly used to hide the error message and style when opening the payment method tab for the first time.
 * @returns {void}
 */
function validateTermsAndCons(showErrorMessage = true) {
    const activePaymentMethod = $('.box-form-payment-radio.active-state');
    const checkboxInput = activePaymentMethod.find('input.js-terms-and-cond');
    const errorMessage = activePaymentMethod.find('.js-terms-and-cons-error');
    const termsAndConsLabel = activePaymentMethod.find('.terms-and-cons-label');

    if (checkboxInput.is(':checked')) {
        enablePayButton();
        termsAndConsLabel.addClass('terms-and-cons-label--checked');
        errorMessage.hide();
        checkboxInput.removeClass('error');
        checkboxInput.attr('aria-invalid', false);
    } else {
        disablePayButton();
        termsAndConsLabel.removeClass('terms-and-cons-label--checked');
        if (showErrorMessage) {
            errorMessage.show();
            checkboxInput.addClass('error');
            checkboxInput.attr('aria-invalid', true);
        }
    }
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
function initializeEvents() {
    if ($('.js-program-timeout').length > 0) {
        dialog.open({
            html: $('.js-program-timeout'),
            options: {
                width: 800,
                open: function () {
                    $('#js-program-error-use-standard-method').on(
                        'click',
                        function () {
                            $('#js-revert-to-standard-method').val('true');
                            $('[name="dwfrm_billing_save"]').trigger('click');
                            dialog.close();
                            return false;
                        }
                    );
                },
            },
        });
    }

    var $checkoutForm = $('.checkout-billing');
    var $addGiftCert = $('#add-giftcert');
    var $giftCertCode = $('input[name$="_giftCertCode"]');
    var $couponError = $('.coupon-error');
    var $addCoupon = $('#apply-coupon-cart');
    var $removeCoupon = $('.js-remove-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $closeAlert = $('.close-alert');
    var $selectPaymentMethod = $('.js-payment-method-options');
    var selectedPaymentMethod = $('.js-payment-method-options')
        .find('li.active')
        .find('input')
        .val();
    var $loadYears = $('.js-years').find('.js-set-years');

    displayPaymentDetails(selectedPaymentMethod);

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]',
        enabledByDefault: true,
    });

    factura.initFormPrepare();

    var mergedCreditCards = $('.js-cards-number-merged');
    if (mergedCreditCards.length > 0) {
        // Cencosud and Credit card tabs are merged
        mergedCreditCards.on('focusout', onLeaveMergedCreditCardNumber);
    }
    //here
    $('.js-factura-header-link').on('click', function () {
        $('.js-factura-container').toggleClass('in');
        $(this).toggleClass('collapsed');
        $(document).trigger('factura-form-show');
        if ($('.CA-fonocompra-box').length == 0) {
            var offset = $(this).offset();
            var scrollto = offset.top - 10; // fixed_top_bar_height = 50px
            $('html, body').animate({ scrollTop: scrollto }, 0);
            setTimeout(function () {
                $('.js-factura-container').find('input').first().focus();
            }, 20);
        }
        return false;
    });

    $('.js-novios-code-validation-header-link').on('click', function () {
        $('.js-novios-code-validation-container').toggleClass('in');
        $('.specialprogramcodefield').toggleClass('required');
        $(this).toggleClass('collapsed');
        if ($(this).hasClass('collapsed')) {
            $('.specialprogramcodefield').val('');
        }
        formPrepare.init({
            formSelector: 'form.js-form-novios-code-validation',
            continueSelector: '.js-form-novios-code-validation-button',
            enabledByDefault: true,
        });
        if ($('.CA-fonocompra-box').length == 0) {
            var offset = $(this).offset();
            var scrollto = offset.top - 10; // fixed_top_bar_height = 50px
            $('html, body').animate({ scrollTop: scrollto }, 0);
            setTimeout(function () {
                $('.js-novios-code-validation-container')
                    .find('input')
                    .first()
                    .trigger('focus');
            }, 20);
        }
        return false;
    });

    giftcard.initGiftCardFormPrepare();

    $('.js-giftcard-header-link').on('click', function () {
        $('.js-giftcard-container').toggleClass('in');
        $(this).toggleClass('collapsed');
        $(document).trigger('giftcard-form-show');
        if ($('.CA-fonocompra-box').length == 0) {
            var offset = $(this).offset();
            var scrollto = offset.top - 10; // fixed_top_bar_height = 50px
            $('html, body').animate({ scrollTop: scrollto }, 0);
            setTimeout(function () {
                $('.js-giftcard-container')
                    .find('input')
                    .first()
                    .trigger('focus');
            }, 20);
        }
        return false;
    });

    // default payment method to 'CREDIT_CARD'
    let defaultPaymentMethod =
        Resources.SITE_ID === 'Paris'
            ? 'CREDIT_CARD'
            : Resources.SITE_ID === 'ParisPe'
            ? 'ALIGNET_CENCOSUD_CARD'
            : 'CREDIT_CARD';
    if ($('.CA-fonocompra-box').length > 0 && Resources.SITE_ID === 'Paris') {
        updatePaymentMethod(
            selectedPaymentMethod ? selectedPaymentMethod : defaultPaymentMethod
        );
    }
    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        if ($('.CA-fonocompra-box').length == 0) {
            $('.tab-content').addClass('hidden');
            $('.box-form-payment-radio').removeClass('active-state');
        }
        $(this)
            .closest('.js-payment-method-options')
            .find('.js-nav-tab.active')
            .removeClass('active');

        if ($('.CA-fonocompra-box').length == 0) {
            $(this).closest('.box-form-payment-radio').addClass('active-state');

            $('.tab-content')
                .filter('[data-method="' + $(this).val() + '"]')
                .removeClass('hidden');
        }
        $(this).closest('.js-nav-tab').addClass('active');

        highlightCardFonocompra();

        selectedPaymentMethod = $(this).val();
        displayPaymentDetails(selectedPaymentMethod);
        updatePaymentMethod($(this).val());

        if ($('.CA-fonocompra-box').length == 0) {
            var cardNumberInput = $(this)
                .closest('.box-form-payment-radio')
                .find('.js-card-number,.js-card-number-field')
                .first();
            if (cardNumberInput.length > 0) {
                setTimeout(function () {
                    cardNumberInput.focus();
                }, 20);
            }
            if (
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCreditCards').length > 0 &&
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCreditCards')
                    .children().length > 0
            ) {
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCreditCards')
                    .children()
                    .last()
                    .trigger('click');
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('.cvncontent')
                    .first()
                    .focus();
            }
            if (
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCards').length > 0 &&
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCards')
                    .children().length > 0
            ) {
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCards')
                    .children()
                    .last()
                    .trigger('click');
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('.cvncontent')
                    .first()
                    .focus();
            }
            if (
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCreditCardsTComp').length > 0 &&
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCreditCardsTComp')
                    .children().length > 0
            ) {
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCreditCardsTComp')
                    .children()
                    .last()
                    .trigger('click');
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('.cvncontent')
                    .first()
                    .focus();
            }
            if (
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCardsTComp').length > 0 &&
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCardsTComp')
                    .children().length > 0
            ) {
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCardsTComp')
                    .children()
                    .last()
                    .trigger('click');
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('.cvncontent')
                    .first()
                    .focus();
            }
            if (
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCardsXCash').length > 0 &&
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCardsXCash')
                    .children().length > 0
            ) {
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('#savedCencosudCardsXCash')
                    .children()
                    .last()
                    .trigger('click');
                $(this)
                    .closest('.box-form-payment-radio')
                    .find('.cvncontent')
                    .first()
                    .focus();
            }
            /*
			else{
				var paymentButton = $(this).closest('.box-form-payment-radio').find('.btn-primary');
				setTimeout(function() {
					paymentButton.focus();
				}, 20);
			}
			*/
            if ($('.CA-fonocompra-box').length == 0) {
                var offset = $(this)
                    .closest('.box-form-payment-radio')
                    .offset();
                var scrollto = offset.top - 10; // fixed_top_bar_height = 50px
                $('html, body').animate({ scrollTop: scrollto }, 0);
            }
        }
    });

    highlightCard();
    highlightCardFonocompra();

    if ($('js-nav-tab').length > 0) {
        $('.js-nav-tab').each(function (index) {
            var $element = $(this);
            if ($element.hasClass('active')) {
                $element.trigger('click');
            }
        });
    }

    // select credit card from list
    $('.saved-card').on('click', function () {
        isSavedCard = true;
        $('.js-card-number-field').attr('type', 'input');
        $('.saved-card-fields').show();
        $('div.buttons, div.checkout').show();
        $('.new-card').show();
        $('.non-saved-card-fields').hide();
        var cardUUID = $(this).find($('.cardUUID')).html();
        if (!cardUUID) {
            return;
        }
        populateCreditCardForm(cardUUID);

        //highlighting selected card
        $('.saved-card.active').removeClass('active');
        $(this).addClass('active');

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
        inputsFormEventValidation(null);

        var selectedCardType = $(this).data('cardtype');
        if (selectedCardType === 'Visa' || selectedCardType === 'MasterCard') {
            $(this)
                .closest('.box-form-payment-radio')
                .find('input[name*="_cvn"]')
                .attr('maxlength', 3);
        } else {
            $(this)
                .closest('.box-form-payment-radio')
                .find('input[name*="_cvn"]')
                .attr('maxlength', 4);
        }
    });

    $('.new-card').on('click', function () {
        isSavedCard = false;
        $('.js-card-number-field').attr('type', 'tel');
        $('.saved-card-fields').show();
        $('div.buttons, div.checkout').show();
        $('.non-saved-card-fields').show();
        $('input[name$="creditCard_selectedCardID"]').val('');
        $('input[name$="cencosudCard_selectedCardID"]').val('');
        $('input[name$="creditCardTComp_selectedCardID"]').val('');
        $('input[name$="cencosudCardTComp_selectedCardID"]').val('');
        $('input[name$="cencosudCardXCash_selectedCardID"]').val('');
        $('input[name$="creditCard_paymentToken"]').val('');
        $('input[name$="cencosudCard_paymentToken"]').val('');
        $('.js-set-month')
            .val($('.js-set-month > .select-option:first').val())
            .trigger('change');
        $('.js-set-years')
            .val($('.js-set-years > .select-option:first').val())
            .trigger('change');
        $(this)
            .closest('.box-form-payment-radio')
            .find('input[name*="_cvn"]')
            .attr('maxlength', 4);

        $('.saved-card.active').removeClass('active');

        formPrepare.init({
            formSelector: 'form[id$="billing"]',
            continueSelector: '[name$="billing_save"]',
            enabledByDefault: true,
        });

        highlightCard();
        inputsFormEventValidation(null);
        if (
            $(this)
                .closest('.box-form-payment-radio')
                .find('#savedCencosudCards').length > 0
        )
            $('input[name*="cencosudCard_number"]').focus() &&
                $('input[name*="cencosudCard_number"]').val('');
        if (
            $(this).closest('.box-form-payment-radio').find('#savedCreditCards')
                .length > 0
        )
            $('input[name*="creditCard_number"]').focus() &&
                $('input[name*="creditCard_number"]').val('');
        if (
            $(this)
                .closest('.box-form-payment-radio')
                .find('#savedCencosudCardsTComp').length > 0
        )
            $('input[name*="cencosudCardTComp_number"]').focus() &&
                $('input[name*="cencosudCardTComp_number"]').val('');
        if (
            $(this)
                .closest('.box-form-payment-radio')
                .find('#savedCreditCardsTComp').length > 0
        )
            $('input[name*="creditCardTComp_number"]').focus() &&
                $('input[name*="creditCardTComp_number"]').val('');
        if (
            $(this)
                .closest('.box-form-payment-radio')
                .find('#savedCencosudCardsXCash').length > 0
        )
            $('input[name*="cencosudCardXCash_number"]').focus() &&
                $('input[name*="cencosudCardXCash_number"]').val('');
    });

    $('#check-giftcert').on('click', function (e) {
        e.preventDefault();
        var $balance = $('.balance');
        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            var error = $balance.find('span.error');
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance
                    .html(Resources.GIFT_CERT_INVALID)
                    .removeClass('success')
                    .addClass('error');
                return;
            }
            $balance
                .html(
                    Resources.GIFT_CERT_BALANCE +
                        ' ' +
                        data.giftCertificate.balance
                )
                .removeClass('error')
                .addClass('success');
        });
    });

    $addGiftCert.on('click', function (e) {
        e.preventDefault();
        var code = $giftCertCode.val(),
            $error = $checkoutForm.find('.giftcert-error');
        if (code.length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {
            giftCertCode: code,
            format: 'ajax',
        });
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message
                    .split('<')
                    .join('&lt;')
                    .split('>')
                    .join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            } else {
                window.location.assign(Urls.billing);
            }
        });
    });

    $addCoupon.on('click', function (e) {
        e.preventDefault();
        progress.show();

        const code = $couponCode.val();
        if (code.length === 0) {
            progress.hide();
            const title = Resources.ALERT_TITLE_FAIL_SERVER;
            const msg = Resources.ALERT_MESSAGE_FAIL_SERVER;
            showCouponAlert(false, title, msg);
            return;
        }

        const url = util.appendParamsToUrl(Urls.addCoupon, {
            couponCode: code,
            format: 'ajax',
        });

        $.getJSON(url, function (data) {
            let fail = false;
            let title = '';
            let msg = '';
            if (!data) {
                title = Resources.ALERT_TITLE_FAIL_SERVER;
                msg = Resources.ALERT_MESSAGE_FAIL_SERVER;
                fail = true;
            } else if (!data.success) {
                if (data.status === 'NO_ACTIVE_PROMOTION') {
                    title = Resources.ALERT_TITLE_FAIL_AVAILABILITY;
                    msg = Resources.ALERT_MESSAGE_FAIL_AVAILABILITY;
                } else {
                    title = Resources.ALERT_TITLE_FAIL_SERVER;
                    msg = Resources.ALERT_MESSAGE_FAIL_SERVER;
                }
                fail = true;
            }
            if (fail) {
                progress.hide();
                showCouponAlert(false, title, msg);
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing);
            }
            title = Resources.ALERT_TITLE_SUCCESS;
            msg = Resources.ALERT_MESSAGE_SUCCESS;
            showCouponAlert(true, title, msg);
            if (window.location.search) {
                window.location.assign(
                    `${Urls.billing}${window.location.search}`
                );
            } else {
                window.location.assign(Urls.billing);
            }
        });
    });

    // trigger events on keydown
    $couponCode.on('keydown', function (e) {
        if ($couponCode.val().length - 1) {
            $addCoupon.addClass('active');
        } else {
            $addCoupon.removeClass('active');
        }
        $couponError.addClass('hide');
        $couponError.html('');

        // Trigger enter
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });

    $removeCoupon.on('click', function (e) {
        e.preventDefault();
        loadModal(e);
    });

    $closeAlert.on('click', function () {
        hideCouponAlert();
    });

    $giftCertCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

    //show only 11 years in dropdown for credit/cencosudcard
    $loadYears.each(function () {
        var year = new Date().getFullYear();

        $('.js-set-years option').each(function (i) {
            if ($(this).val() < year || $(this).val() >= year + 11) {
                $(this).remove();
            }
        });
    });

    $('body').keypress(function (e) {
        var key = e.which;
        if (key == 13) {
            e.preventDefault();
        }
    });

    $('input[id*="_login_username"]').keypress(function (e) {
        var key = e.which;
        if (key == 13) {
            $('form[id$="_login"]').submit();
        }
    });
    $('input[id*="_login_password"]').keypress(function (e) {
        var key = e.which;
        if (key == 13) {
            $('form[id$="_login"]').submit();
        }
    });

    $('input[name*="_saveCard"]').on('change', function () {
        if ($(this).is(':checked')) {
            $(this).attr('value', 'true');
        } else {
            $(this).attr('value', 'false');
        }
    });
    $('button[name$="_billing_save"]').on('click', function (e) {
        e.preventDefault();
        disablePayButton();
        if (checkIfTermsAndConditionsIsChecked()) {
            validator.validateCardExpirationDate();
            var _self = $(this);
            var isWebpayPaymentMethodSelected = $('#is-WEBPAY').is(':checked');
            var isCatCerradaPaymentMethodSelected =
                $('#is-XCash').is(':checked');
            var isMercadoPagoPaymentMethodSelected =
                $('#is-MERCADOPAGO').is(':checked');
            var isMercadoPagoCencosudPaymentMethodSelected = $(
                '#is-MERCADOPAGO_CENCOSUD'
            ).is(':checked');
            var isAlignetCencosudCard = $('#is-ALIGNET_CENCOSUD_CARD').is(
                ':checked'
            );
            var isAlignetCreditDebit = $('#is-ALIGNET_CREDIT_DEBIT').is(
                ':checked'
            );

            var isCencosudCardPaymentMethodSelected =
                $('#is-CENCOSUD_CARD').is(':checked');
            var isCreditCardPaymentMethodSelected =
                $('#is-CREDIT_CARD').is(':checked');
            var isCencosudCardTCompPaymentMethodSelected = $(
                '#is-CENCOSUD_CARD_TCOMP'
            ).is(':checked');
            var isCreditCardTCompPaymentMethodSelected = $(
                '#is-CREDIT_CARD_TCOMP'
            ).is(':checked');
            var isCencosudCardXCashPaymentMethodSelected = $(
                '#is-CENCOSUD_CARD_XCASH'
            ).is(':checked');

            if (
                !isWebpayPaymentMethodSelected &&
                $('.use-webpay').length <= 0 &&
                !isCatCerradaPaymentMethodSelected &&
                !isMercadoPagoPaymentMethodSelected &&
                !isMercadoPagoCencosudPaymentMethodSelected &&
                !isAlignetCencosudCard &&
                !isAlignetCreditDebit
            ) {
                progress.show();
                if (isSavedCard) {
                    $checkoutForm.valid()
                        ? $checkoutForm.submit()
                        : progress.hide() || enablePayButton();
                } else {
                    var cvn;
                    var cardNumber;
                    var isCardToSave;
                    var cardExpirationDate;
                    var installments;
                    if (isCencosudCardPaymentMethodSelected) {
                        cardNumber = $(
                            'input[name*="_cencosudCard_number"]:not(.dummy-pwd)'
                        ).val();
                        var cencosudExpirationMonth = $(
                            'select[name*="_cencosudCard_expiration_month"]'
                        ).val();
                        var cencosudExpirationYear = $(
                            'select[name*="_cencosudCard_expiration_year"]'
                        ).val();
                        isCardToSave = $(
                            'input[name*="_cencosudCard_saveCard"]'
                        ).is(':checked');
                        installments = $(
                            'select[name*="_cencosudCard_installments"]'
                        ).val();
                        cvn = $('input[name*="_cencosudCard_cvn"]').val();

                        if (cencosudExpirationMonth.length === 1) {
                            cencosudExpirationMonth =
                                '0' + cencosudExpirationMonth;
                        }
                        cardExpirationDate =
                            cencosudExpirationMonth +
                            '-' +
                            cencosudExpirationYear;
                    } else if (isCreditCardPaymentMethodSelected) {
                        cardNumber = $(
                            'input[name*="_creditCard_number"]:not(.dummy-pwd)'
                        ).val();
                        var creditExpirationMonth = $(
                            'select[name*="_creditCard_expiration_month"]'
                        ).val();
                        var creditExpirationYear = $(
                            'select[name*="_creditCard_expiration_year"]'
                        ).val();
                        isCardToSave = $(
                            'input[name*="_creditCard_saveCard"]'
                        ).is(':checked');
                        installments = $(
                            'select[name*="_creditCard_installments"]'
                        ).val();
                        cvn = $('input[name*="_creditCard_cvn"]').val();

                        if (creditExpirationMonth.length === 1) {
                            creditExpirationMonth = '0' + creditExpirationMonth;
                        }
                        cardExpirationDate =
                            creditExpirationMonth + '-' + creditExpirationYear;
                    } else if (isCencosudCardTCompPaymentMethodSelected) {
                        cardNumber = $(
                            'input[name*="_cencosudCardTComp_number"]:not(.dummy-pwd)'
                        ).val();
                        var cencosudExpirationMonth = $(
                            'select[name*="_cencosudCardTComp_expiration_month"]'
                        ).val();
                        var cencosudExpirationYear = $(
                            'select[name*="_cencosudCardTComp_expiration_year"]'
                        ).val();
                        isCardToSave = $(
                            'input[name*="_cencosudCardTComp_saveCard"]'
                        ).is(':checked');
                        installments = $(
                            'select[name*="_cencosudCardTComp_installments"]'
                        ).val();
                        cvn = $('input[name*="_cencosudCardTComp_cvn"]').val();

                        if (cencosudExpirationMonth.length === 1) {
                            cencosudExpirationMonth =
                                '0' + cencosudExpirationMonth;
                        }
                        cardExpirationDate =
                            cencosudExpirationMonth +
                            '-' +
                            cencosudExpirationYear;
                    } else if (isCreditCardTCompPaymentMethodSelected) {
                        cardNumber = $(
                            'input[name*="_creditCardTComp_number"]:not(.dummy-pwd)'
                        ).val();
                        var creditExpirationMonth = $(
                            'select[name*="_creditCardTComp_expiration_month"]'
                        ).val();
                        var creditExpirationYear = $(
                            'select[name*="_creditCardTComp_expiration_year"]'
                        ).val();
                        isCardToSave = $(
                            'input[name*="_creditCardTComp_saveCard"]'
                        ).is(':checked');
                        installments = $(
                            'select[name*="_creditCardTComp_installments"]'
                        ).val();
                        cvn = $('input[name*="_creditCardTComp_cvn"]').val();

                        if (creditExpirationMonth.length === 1) {
                            creditExpirationMonth = '0' + creditExpirationMonth;
                        }
                        cardExpirationDate =
                            creditExpirationMonth + '-' + creditExpirationYear;
                    } else if (isCencosudCardXCashPaymentMethodSelected) {
                        cardNumber = $(
                            'input[name*="_cencosudCardXCash_number"]:not(.dummy-pwd)'
                        ).val();
                        var cencosudExpirationMonth = $(
                            'select[name*="_cencosudCardXCash_expiration_month"]'
                        ).val();
                        var cencosudExpirationYear = $(
                            'select[name*="_cencosudCardXCash_expiration_year"]'
                        ).val();
                        isCardToSave = $(
                            'input[name*="_cencosudCardXCash_saveCard"]'
                        ).is(':checked');
                        installments = $(
                            'select[name*="_cencosudCardXCash_installments"]'
                        ).val();
                        cvn = $('input[name*="_cencosudCardXCash_cvn"]').val();

                        if (cencosudExpirationMonth.length === 1) {
                            cencosudExpirationMonth =
                                '0' + cencosudExpirationMonth;
                        }
                        cardExpirationDate =
                            cencosudExpirationMonth +
                            '-' +
                            cencosudExpirationYear;
                    }
                    ajax.load({
                        url: Urls.handleCardSelection,
                        type: 'POST',
                        data: {
                            cardNumber: cardNumber,
                            isCencosudCardPaymentMethodSelected:
                                isCencosudCardPaymentMethodSelected,
                            isCreditCardPaymentMethodSelected:
                                isCreditCardPaymentMethodSelected,
                            isCencosudCardTCompPaymentMethodSelected:
                                isCencosudCardTCompPaymentMethodSelected,
                            isCreditCardTCompPaymentMethodSelected:
                                isCreditCardTCompPaymentMethodSelected,
                            isCencosudCardXCashPaymentMethodSelected:
                                isCencosudCardXCashPaymentMethodSelected,
                            cardExpirationDate: cardExpirationDate,
                            isCardToSave: isCardToSave,
                            installments: installments,
                            cvn: cvn,
                        },
                        callback: function (response) {
                            var isError = $(response)
                                .find('#binValidationError')
                                .text()
                                .trim();
                            var isEmptyCart = $(response)
                                .find('#emptyCartError')
                                .text()
                                .trim();
                            var noCBSTokenization =
                                $(response).find('#noCBSTokenization').length ==
                                1
                                    ? true
                                    : false;
                            if (noCBSTokenization && isError != 'true') {
                                $checkoutForm.valid()
                                    ? $checkoutForm.submit()
                                    : progress.hide() || enablePayButton();
                            } else {
                                if (isError === 'true') {
                                    enablePayButton();
                                    $('.error-container').show();
                                    var $validator = _self
                                        .closest('form')
                                        .validate();
                                    var errors = {};
                                    errors[
                                        _self
                                            .closest('.payment-method')
                                            .find('.js-card-number')
                                            .attr('name')
                                    ] = Resources.INVALID_CREDIT_CARD_NUMBER;
                                    $validator.showErrors(errors);
                                    validator.validateCardExpirationDate();
                                    validateAllInputField();
                                    progress.hide();
                                } else if (isEmptyCart === 'true') {
                                    window.location.href = Urls.cartShow;
                                } else {
                                    $('.box-payment-user').append($(response));
                                    $('#silentPost').submit();
                                }
                            }
                        },
                    });
                }
            } else {
                progress.show();
                $checkoutForm.valid()
                    ? $checkoutForm.submit()
                    : progress.hide() || enablePayButton();
            }
        }
    });
    $(document).ready(function () {
        if (
            $('.js-edit-facture').length &&
            $('.js-comuna-name-backup').length &&
            $('.js-comuna-name-backup').text().length
        ) {
            var regionSelect = $('.js-facturaform').find('.js-region-select');
            if (regionSelect) {
                regionSelect.trigger('change');
                $('.js-facturaform')
                    .find('.js-comuna-select')
                    .val($('.js-comuna-name-backup').text());
                $('.js-facturaform')
                    .find('.js-comuna-select')
                    .trigger('change');
            }
        }

        if ($('.js-pop-up-animation').length > 0) {
            $('.js-pop-up-animation').show();
        }

        if ($('.js-load-content-asset').length > 0) {
            $('.js-load-content-asset').click(function (e) {
                e.preventDefault();
                dialog.open({
                    url: $(e.target).attr('href'),
                });
            });
        }

        if ($('.js-terms-and-cond').length > 0) {
            $('.box-form-payment-radio').change(
                validateTermsAndCons.bind(undefined, false)
            );
            $('.js-terms-and-cond').change(validateTermsAndCons);
        }
    });

    if (Resources.SITE_ID === 'ParisPe') {
        $checkoutForm.submit(function (e) {
            e.preventDefault();
            progress.show();
            if (PF.Cards.isValidForm()) {
                var formData =
                    $checkoutForm.serialize() + '&dwfrm_billing_savePe=Pagar';
                jQuery.ajax({
                    method: 'post',
                    url: Urls.billingsubmit,
                    cache: false,
                    data: formData,
                    success: function (data) {
                        if (data.success === true) {
                            progress.show();
                            console.log('billingsubmit success');
                            PF.Cards.configuration.data.operation.operationNumber =
                                data.operationNumber;
                            PF.Cards.configuration.data.signature =
                                data.signature;
                            PF.Cards.configuration.data.operation.signature =
                                data.signature;
                            PF.Cards.configuration.feature.reserved[0].value =
                                data.orderNo;
                            console.log(
                                JSON.stringify({
                                    operationNumber: data.operationNumber,
                                    signature: data.signature,
                                    orderNo: data.orderNo,
                                })
                            );
                            console.log(JSON.stringify(initConfigObjectTC));
                            console.log(JSON.stringify(initConfigObject));
                            PF.Pay.execute();
                        } else {
                            console.log('billingsubmit failure');
                            progress.hide();
                            console.log(JSON.stringify(data));
                            window.location.href = data.redirectUrl
                                ? data.redirectUrl
                                : Urls.cartShow;
                        }
                    },
                });
            } else {
                progress.hide();
                console.log('invalid form');
            }
        });
    }

    //When clicked ouside the pop up, it hides
    $(document).mouseup(function (e) {
        var container = $('.js-pop-up-animation');

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.hide();
        }
    });

    //Adding dataLayer
    if ($('.js-payment-method-options').length) {
        window.dataLayer.push({
            event: 'page-virtual',
            page: 'billing/payment',
            pagename: 'Buying Process - Billing Selector',
        });
    }

    //CNS-3393
    //Dialog on some class
    if (
        $('.js-info-response-payment').length > 0 &&
        $('.js-info-response-payment').is(':hidden')
    ) {
        dialog.open({
            position: { my: 'top', at: 'center', of: window },
            html: $('.js-info-response-payment').html(),
            options: {
                width: 900,
                draggable: false,
                dialogClass: 'ui-dialog-auto-height ui-dialog-error-payment',
                open: function () {
                    $('.js-close-dialog-payresponse:button').on(
                        'click',
                        function () {
                            dialog.close();
                        }
                    );
                },
            },
        });

        if ($('.ui-dialog-titlebar-close').length) {
            $('.ui-dialog-titlebar-close').attr('aria-label', 'Cerrar');
            $('.ui-dialog').focus();
        }
    }

    // Method for Message Container for out of stock, or stock less than selected quantity
    $(document).ready(function () {
        setTimeout(showFeedbackUpdateQty(), 2000);
    });
    // END Method for Message Container for out of stock, or stock less than selected quantity

    $(window).click(function () {
        hideFeedBackUpdateQty();
    });

    // if ($('#js-wallet-finalize-purchase').length) {
    //     $('#js-wallet-finalize-purchase').click(function () {
    //         console.log('kdkdkd', $(this).data('href'));
    //         $(this).prop('disabled', true);
    //         // window.location.href = $(this).data('href');
    //     });
    // }
}

exports.init = function () {
    initializeEvents();
};

// Method for Message Container for out of stock, or stock less than selected quantity
