'use strict';

/**
 * copied from https://github.com/darkskyapp/string-hash
 */
function hashFn(str) {
    var hash = 5381,
        i = str.length;

    while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i);
    }
    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
    * integers. Since we want the results to be always positive, convert the
    * signed int to an unsigned by doing an unsigned bitshift. */
    return hash >>> 0;
}

/**
 * Create rating based on hash ranging from 2-5
 * @param pid
 */
function getRating(pid) {
    return hashFn(pid.toString()) % 30 / 10 + 2;
}

/**
 * Dictionary with Icons
 * checked: fa fa-star
 * unchecked: fa fa-star
 * half: fa fa-star-half-o
 */
var ICON_TYPE = {
    iChecked: '<i class="fa fa-star"></i>',
    iUnChecked: '<i class="fa fa-star-o"></i>',
    iHalf: '<i class="fa fa-star-half-o"></i>',
    spanChecked: '<span class="fa fa-star checked"></span>',
    spanUnChecked: '<span class="fa fa-star unchecked"></span>',
    spanHalf: '<span class="fa fa-star-half-o checked"></span>'
}

/**
 * Execute rating based on params
 * @param isPLP
 * @param element
 * @param rating
 * @param baseRating
 */
function executeRating(isPLP, element, rating, baseRating, reviewCount) {
    var starsCount = 0;
    for (var i = 0; i < baseRating; i++) {
        element.append(isPLP ? ICON_TYPE.spanChecked : ICON_TYPE.iChecked)
        starsCount++;
    }
    if (rating > baseRating) {
        element.append(isPLP ? ICON_TYPE.spanHalf : ICON_TYPE.iUnChecked);
        starsCount++;
    }
    if (starsCount < 5) {
        for (var j = 0; j < 5 - starsCount; j++) {
            element.append(isPLP ? ICON_TYPE.spanUnChecked : ICON_TYPE.iUnChecked);
        }
    }

    if (reviewCount) {
        reviewCount = parseInt(reviewCount, 10);
        element.append("<span> (" + reviewCount +')</span>');
    }
}

module.exports = {
    init: function () {
        $('.product-review').each(function (index, review) {
            var pid = $(review).data('pid');
            if (!pid) {
                return;
            }
            // rating range from 2 - 5
            var rating = getRating(pid);
            var baseRating = Math.floor(rating);
            var element = $('.rating', review);
            executeRating(false, element, rating, baseRating, null);
        });

        // Rating for POD in PLP
        $('input[name="averageRatingForPOD"]').each(function (index, element) {
            var rating = $(element).val(); // test Math.random() * (5 - 1) + 1; 
            var reviewCount = $(element).data('review-count');
            var baseRating = Math.floor(rating); 
            var element = $(element).next();
            executeRating(true, element, rating, baseRating, reviewCount);
        });
    },

    initProductReview: function () {
        $('.product-review').each(function (index, review) {
            var pid = $(review).data('pid');
            if (!pid) {
                return;
            }
            // rating range from 2 - 5
            var rating = getRating(pid);
            var baseRating = Math.floor(rating);
            var element = $('.rating', review);
            executeRating(false, element, rating, baseRating, null);
        });
    },

    initPodRating: function () {
        // Rating for POD in PLP
        $('input[name="averageRatingForPOD"]').each(function (index, element) {
            var rating = $(element).val(); // test Math.random() * (5 - 1) + 1; 
            var reviewCount = $(element).data('review-count');
            var baseRating = Math.floor(rating); 
            var element = $(element).next();
            executeRating(true, element, rating, baseRating, reviewCount);
        });
    }
};
