'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    personalDetails = require('./personalDetails'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
	dialog = require('../../dialog'),
    validator = require('../../validator'),
    storelocator = require('../storelocator'),
	sippingTracking = require('./sippingTracking'),
	shipping = require('./shipping');

var componentForm = {
        street_number: 'short_name',  // street number
        route: 'long_name',   // street name
        locality: 'long_name'   // comuna
}

function loadClickCollectStoresList(region, regionName, rmZone) {
	progress.show();
	var regName = regionName ? regionName : '';
	ajax.load({
	    url: window.Urls.lookupStoreCollectStores,
		data: { region:region , regionName : regName, rmZone: (rmZone ? rmZone : '')},
		callback: function (data) {
			$(".js-store-pickup-list").html(data);
			progress.hide();
			initializeStoreClickCollectList();
			$('.js-location-address').addClass('hidden');
			$('.user-location').show();
			/*
			var rmZone = $('#dwfrm_storelocator_state option:selected').data("rm-zone");
			if(rmZone){
				if(rmZone == "todas"){
					$('.js-store-search-result-item').removeClass("hidden");
				}
				else{
					$('.js-store-search-result-item').each(function(x){
						var storeZone = $(this).data("rm-zone");
						$(this).data("rm-zone") != rmZone ? $(this).addClass("hidden") : $(this).removeClass("hidden")
					});
				}
			}
			*/
			$("#dwfrm_storelocator_state").change(function(){
				$("#control_dwfrm_storelocator_state").val('1');
			});
			if ($('.js-store-pickup-list').length && !$('.js-store-pickup-list').is(':hidden') && $("#control_dwfrm_storelocator_state").val()!='1' ) {
		       window.dataLayer.push({
					'event' : 'page-virtual',
					'page': 'shipping/collect-from-store/select-store',
					'pagename': 'Buying Process - Type Delivery'
				});
			}
		}
	});
}

function loadAllClickCollectStoresList() {
    var $target = $('.js-store-pickup-list');
    progress.show($target);
	var params = {};
		params["getall"] = true;
	
	var url = util.appendParamsToUrl(Urls.lookupStoreCollectStores, params);
	var options = {
		url : url,
		type : 'POST',
		target : $target,
		callback: function () {
			initializeStoreClickCollectList();
			storelocator.displayAddress();
			progress.hide();
		}
	}
	ajax.load(options);
    
    // IE hack:
    if (util.isIE() != false) {
        progress.hide();
    }
}

function initializeStoreClickCollectList() {

	$('.js-no-found-geolocation').hide();
	$('.js-not-found-store-geolocation').show();


	if ($('.js-select-store-link').length) {
		$('.js-select-store-link').on('click', function (e) {

			e.preventDefault();
			
			progress.show();
			
			var $form = $(this).closest('form');
			var $element = $(this);
			
			var selectedAddress = $($element).data('address');        
			if (!selectedAddress) { 
				return; 
			}
			
			util.fillAddressFields(selectedAddress, $form);
			
			// re-validate the form
			$form.validate().form();
			
			$('.js-store-pickup-slots-section').html('');
			$('.js-selected-store-address').html($(this).closest('.js-store-search-result-item').find('.js-store-address').html());
			$('.js-selected-store-address').find('.js-store-map').remove();
			$('.js-store-panel').show();
			$('.js-delivery-options').show();
			$('.js-store-pickup-list').hide();
			$('.js-store-address-search-panel').hide();
			$('.js-store-address-search-panel').attr('style','display:none !important');
			// var url = util.appendParamsToUrl(Urls.includePickUpSlots, { storeId : $('.js-selected-store-address').find('.store-name').data('storeid') });
			
			shipping.checkDeliveryRestrictions();
			
			return false;
		});
	}

	if ($('.js-select-store-link-new-ui-v2').length) {
		$('.js-select-store-link-new-ui-v2').on('click', function (e) {
			e.preventDefault();
	
			const storeId = $(this).data('storeid');
			const storeImg = $(this).data('storeimg');
			const storeList = window.storeList;
	
			if (storeList) {
				const selectedDated = {};
				const store = storeList.find(function (store) {
					return store.ID == storeId;
				});
	
				// print header store info
				const $headerStoreInfo = $('#container-select-store');
				$headerStoreInfo.find('#store-img').attr('src', storeImg);
				$headerStoreInfo.find('#store-name').text(store.name);
				$headerStoreInfo.find('#store-address1').text(store.address1);
				$headerStoreInfo.find('#store-address2').text(store.address2.concat(', '));
				$headerStoreInfo.find('#store-city').text(store.city.concat(', '));
				$headerStoreInfo.find('#store-regionName').text(store.regionName);
	
				// clean main store container
				const $pickupSlotSection = $('.js-store-pickup-slots-section');
				$pickupSlotSection.empty();
	
				const $confirmMsg = $('#confirm-message-collect').clone();
				const $thirdCollectLink = $('#third-collect').clone();

				// print available products
				for (let product of store.products.available) {
					let $productSuccessContainer = $('#storepickupslot-success').clone();
					$productSuccessContainer.find('#storepickupslot-success-img').attr({
						'src': product.img.url,
						'srcset': product.img.srcset,
						'alt': product.img.alt,
						'title': product.img.title
					});
					$productSuccessContainer.find('#storepickupslot-success-name').text(product.name);
					$productSuccessContainer.find('#storepickupslot-success-sku').text(product.sku);
					$productSuccessContainer.find('#storepickupslot-success-quantity').text(product.quantity);
					$productSuccessContainer.find('#storepickupslot-success-datePickup').text(product.datePickup.label);
					if (product.lastUnities) $productSuccessContainer.find('#storepickupslot-success-lastUnities').removeClass('hidden');
					$productSuccessContainer.removeClass('hidden');
					$pickupSlotSection.append($productSuccessContainer);
	
					selectedDated[product.sku] = {
						dispatchDate: product.datePickup.billing,
						arrivalDate: product.datePickup.billing
					};
				}

				$pickupSlotSection.append($confirmMsg);
				$confirmMsg.removeClass('hidden');
	
				$pickupSlotSection.append($thirdCollectLink);
				$thirdCollectLink.removeClass('hidden');
				collectThirdConfirmLinkEventInit();
	
				if (store.products.unavailable.length) {
					// print unavailable products
					const $errorMsg = $('#storepickupslot-error-msg');
					$pickupSlotSection.append($errorMsg);
					for (let product of store.products.unavailable) {
						let $productErrorContainer = $('#storepickupslot-error').clone();
						$productErrorContainer.find('#storepickupslot-error-img').attr({
							'src': product.img.url,
							'srcset': product.img.srcset,
							'alt': product.img.alt,
							'title': product.img.title
						});
						$productErrorContainer.find('#storepickupslot-error-name').text(product.name);
						$productErrorContainer.find('#storepickupslot-error-sku').text(product.sku);
						$productErrorContainer.find('#storepickupslot-error-quantity').text(product.quantity);
						$productErrorContainer.removeClass('hidden');
						$pickupSlotSection.append($productErrorContainer);
					}
					if (store.products.unavailable.length === 1) {
						$errorMsg.find('#storepickupslot-error-msg-detail').removeClass('hidden');
					} else {
						$errorMsg.find('#storepickupslot-error-msg-detail-plural').removeClass('hidden');
					}
					$errorMsg.removeClass('hidden');
				}
	
				// this set inputs hidden
				$('input[name="dwfrm_singleshipping_shippingAddress_addressFields_addressID"]').val(store.ID);
				$('input[name="dwfrm_singleshipping_shippingAddress_addressFields_address1"]').val(store.address1);
				$('input[name="dwfrm_singleshipping_shippingAddress_addressFields_address2"]').val(store.address2);
				$('input[name="dwfrm_singleshipping_shippingAddress_addressFields_city"]').val(store.city);
				$('input[name="dwfrm_singleshipping_shippingAddress_addressFields_states_state"]').val(store.regionID);
				$('input[name="dwfrm_singleshipping_shippingAddress_addressFields_province"]').val(store.province);
				$('input[name="dwfrm_singleshipping_shippingAddress_addressFields_latitude"]').val(store.latitude);
				$('input[name="dwfrm_singleshipping_shippingAddress_addressFields_longitude"]').val(store.longitude);
	
				// this show or hide view in function to event
				$('.js-store-address-search-panel').addClass('hide-location');
				$('.js-delivery-options').show();
				$('.js-submit-store').removeAttr('disabled');
				$('.js-submit-store').unbind('click');
				$('.js-line-item-selected-dates').val(JSON.stringify(selectedDated));
				$('.js-store-panel').fadeIn();
				$('.store-pickup-list').addClass('hide');
			}
			window.dataLayer.push({
				'event' : 'page-virtual',
				'page': 'shipping/collect-from-store/select-store-confirmation',
				'pagename': 'Buying Process - Type Delivery'
			});
		});
	}
	
	setTimeout(function(){
		$('.js-select-home-delivery').off('click');
		$('.js-select-home-delivery').on('click', function(e) {
			$('a.js-shipping-method-select').first().click();
		});
	}, 2500);
	
	sippingTracking.unavailableClickAndCollectMethod('.js-remove-from-minicart-data', 'no method', 'ret')

	$('.js-clear-selected-store').off('click');
	$('.js-clear-selected-store').on('click', function (e) {
		$('.js-store-address-search-panel').removeClass('hide-location');
		$('.store-pickup-list').removeClass('hide')
		e.preventDefault();
		
		$('.js-store-address-field').each(function (index, elem) {
			$(elem).val('');
		});
		
        var region = $('.js-click-collect-section').data('default-region');
		var regionName = $('.js-click-collect-section').data('default-region-name');
        if (region) {
        	$('#dwfrm_storelocator_state').val(region);
    		loadClickCollectStoresList(region, regionName);
        }
		$('.js-clear-3rd-party-collection-details').trigger('click');

        
        $('.js-store-panel').hide();
        //$('.js-store-panel').html('');
        $('.js-store-address-search-panel').show();
        $('.js-store-pickup-list').show();
        $('.js-delivery-options').hide();
//        window.dataLayer.push({
//    		'event' : 'page-virtual',
//    		'page': 'checkout/shipping/remove-store',
//    		'pagename': 'Buying Process - Type Delivery'
//    	});
		if(Resources.SITE_ID == "ParisPe"){
			loadAllClickCollectStoresList();
			$('.js-store-address-search-panel').hide();
			$(".js-store-pickup-list").css('overflow','hidden');
		} 
		autoScrollToStoreAddressSearchPanel();
        return false;
	});
	
	if ($('.js-store-search-result-item').find('div.js-status-unavailable').length > 0) {
		$('.js-store-search-result-item').find('div.js-status-unavailable').parents('div.js-store-search-result-item').addClass('status-unavailable-wrapper');
	}

}

function getSelectedStoreJSON(currentElement) {
    
    const storeJSONEncode = $(currentElement).closest(".js-pickupstore-container").find(".js-pickupstore-data").val();
    const storeJSONDecode = decodeURIComponent(storeJSONEncode);
    const storeJSON = $.parseJSON(storeJSONDecode);
    
    return storeJSON;
}

function loadPickupStore(container, json) {
    if (json.ID) {
        container.find(".js-pickupstore-id").val(json.ID);
        container.find(".js-pickupstore-county").val(json.county);
    
        container.find(".js-pickupstore-name").text(json.name).val(json.name);
        container.find(".js-pickupstore-address1").text(json.address1).val(json.address1);
        container.find(".js-pickupstore-city").text(json.city).val(json.city);
        container.find(".js-pickupstore-postal-code").text(json.postalCode).val(json.postalCode);
        container.find(".js-pickupstore-phone").text(json.phone).val(json.phone);
        container.find(".js-pickupstore-distance").text(json.distance).val(json.distance);
        formPrepare.init({
            continueSelector: '[name*="shippingAddress_save"]',
            formSelector:'[id$="singleshipping_shippingAddress"]',
            enabledByDefault: true
        });
    }
}

function getLocation() {
    var $target = $('.js-store-pickup-list');
    progress.show($target);
	function showPosition(position) {
		$('.js-no-geolocation').hide();
		$('.js-no-found-geolocation').hide();
		$('.js-not-found-store-geolocation').show();
        $('.js-location-latitude').val(position.coords.latitude);
        $('.js-location-longitude').val(position.coords.longitude);
    	
    	
    	var lat = [$('.js-location-latitude').attr('name')];
    	var lon = [$('.js-location-longitude').attr('name')];
    
    	var params = {};
    		params[lat] = $('.js-location-latitude').val();
    		params[lon] =  $('.js-location-longitude').val();
    		params["searchbyposition"] = true;
    	
    	var url = util.appendParamsToUrl(Urls.lookupStoreCollectStores, params);
    	var options = {
    		url : url,
    		type : 'POST',
    		target : $target,
    		callback: function () {
    			initializeStoreClickCollectList();
    			storelocator.displayAddress();
    			progress.hide();
    		}
    	}
    	ajax.load(options);
        
    }
    
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, geoError);
    } else { 
        // show the error MSG
    }
    
    // IE hack:
    if (util.isIE() != false) {
        progress.hide();
    }
}

function geoError(err) {
	if ($('.js-location-address').length != 0 && !$('.js-location-address').hasClass('hidden')) {
		$('.js-location-address').addClass('hidden');
	}
	if ($('.js-store-pickup-list .container-store-result')) {
		$('.js-store-pickup-list .container-store-result').html('');
	}
	$('.js-no-geolocation').show();
	progress.hide();
}

function collectThirdConfirmLinkEventInit() {
	$('.js-add-3rd-party-collection-details, .js-edit-3rd-party-collection-details').off('click');
	$('.js-add-3rd-party-collection-details, .js-edit-3rd-party-collection-details').on('click', function (e) {
		e.preventDefault();

		progress.show($('.js-click-collect-section'));

		var url = $(this).attr('href');
		var options = {
			url: url,
			type: 'GET',
			async: false,
			target: $('div.js-collect-on-behalf-form'),
			callback: function () {
				$('.js-collect-on-behalf-panel').hide();
				$('.js-new-3rd-party-section').show();
				progress.hide();
				personalDetails.selectIdentityInput($('input[id*="collectonbehalf_identityOption"]:checked').val());

				$(document).on('change', 'input[id*="collectonbehalf_identityOption"]', function (event) {
					personalDetails.selectIdentityInput($(this).val());
					formPrepare.updateRequiredFields({
						continueSelector: '[name$="collectonbehalf_continue"]',
						formSelector: '#collect-on-behalf-form'
					});
				});

				if($('#icon-plus-collect-third').hasClass('js-active')) {
					$('#icon-less-collect-third').removeClass('hidden').addClass('js-active');
					$('#icon-plus-collect-third').addClass('hidden').removeClass('js-active');
				} else if ($('#icon-less-collect-third').hasClass('js-active')) {
					$('#icon-plus-collect-third').removeClass('hidden').addClass('js-active');
					$('#icon-less-collect-third').addClass('hidden').removeClass('js-active');
				}

				formPrepare.init({
					continueSelector: '[name$="collectonbehalf_continue"]',
					formSelector: '#collect-on-behalf-form'
				});

				$("input[id*='collectonbehalf_rut']").change(function () {
					formPrepare.validateForm({
						continueSelector: '[name$="collectonbehalf_continue"]',
						formSelector: '#collect-on-behalf-form'
					});
				});

				$('#collect-on-behalf-form').find('button.js-collect-on-behalf-submit').on('click', function (e) {
					e.preventDefault();

					var empty = true;
					if ($('#dwfrm_collectonbehalf_identityOption_RUT').is(':checked')) {
						if ($('#dwfrm_collectonbehalf_rut').val() === '') {
							$('#dwfrm_collectonbehalf_rut').focus();
							empty = false;
							return empty;
						}
					}
					if ($('#dwfrm_collectonbehalf_identityOption_DNI').is(':checked')) {
						if ($('#dwfrm_collectonbehalf_dni').val() === '') {
							$('#dwfrm_collectonbehalf_dni').focus();
							empty = false;
							return empty;
						}
					}
					if ($('#dwfrm_collectonbehalf_firstname').val() === '') {
						$('#dwfrm_collectonbehalf_firstname').focus();
						empty = false;
						return empty;
					}
					if ($('#dwfrm_collectonbehalf_lastname').val() === '') {
						$('#dwfrm_collectonbehalf_lastname').focus();
						empty = false;
						return empty;
					}
					if (!empty) return;

					progress.show($('.js-click-collect-section'));

					var $thirdPartyDetails = $('div#collect-on-behalf-form');
					var data = "";
					$('div#collect-on-behalf-form').find('input').each(function (index, value) {
						if ($(value).attr("type") == "radio" && $(value).prop("checked") == false) {
							return
						}
						data += $(value).attr('name') + "=" + $(value).val() + "&";
					});
					data = data.substring(0, data.length - 1);
					// add form action to data
					data += '&' + $('#collect-on-behalf-form').find('button.js-collect-on-behalf-submit').attr('name') + '=';
					var url = util.appendParamToURL($thirdPartyDetails.data('action'), 'format', 'ajax');
					$.ajax({
						type: 'POST',
						url: url,
						data: data,
						success: function (response) {
							$('.js-collect-on-behalf-panel').show();
							$('.js-new-3rd-party-section').hide();
							$('.js-collect-on-behalf-panel').html($(response).find('.js-collect-on-behalf-panel').html());
							initializeSearchStoreEvents();
							progress.hide();
						}
					});

					return false;
				});
			}
		};

		ajax.load(options);

		$(this).toggleClass('opened').siblings('.js-collect-on-behalf-form').slideToggle("slow");

		return false;
	});
}

function initializeSearchStoreEvents() {
	
	$('.js-check-location').off('click');
	$('.js-check-location').on('click', function(e) {
		getLocation();
	});
    
    
	$('#js-searchstores').off('click');
	$('#js-searchstores').on('click', function (e) {
        e.preventDefault();
        var region = $('#dwfrm_storelocator_state').val();
		var regionName = $('#dwfrm_storelocator_state option:selected').text();
		var rmZone = $('#dwfrm_storelocator_state option:selected').data("rm-zone");
        loadClickCollectStoresList(region, regionName, rmZone);
        validator.init();
    });
	
	$('#dwfrm_storelocator_state').off('click');
	$('#dwfrm_storelocator_state').on('change', function (e) {
		$('.js-store-pickup-slots-section').empty();
		$('.js-store-panel').hide();
		e.preventDefault();
		$('.js-no-geolocation').hide();
        var region = $('#dwfrm_storelocator_state').val();
		var regionName = $('#dwfrm_storelocator_state option:selected').text();
		var rmZone = $('#dwfrm_storelocator_state option:selected').data("rm-zone");
		loadClickCollectStoresList(region, regionName, rmZone);
		validator.init();		
	});
	
	$('.js-clear-3rd-party-collection-details').off('click');
	$('.js-clear-3rd-party-collection-details').on('click', function (e) {
		e.preventDefault();
		
		progress.show($('.js-click-collect-section'));
		
		var url = $(this).attr('href');
		$.ajax({
            url: url,
            success: function (response) {
            	$('.js-new-3rd-party-section').show();
            	$('.js-collect-on-behalf-panel').hide();
				$('div.js-collect-on-behalf-form').hide();
				$('.js-add-3rd-party-collection-details').removeClass('opened');
            	initializeSearchStoreEvents();
            	progress.hide();
            }
		});
		
		return false;
	});
	
	collectThirdConfirmLinkEventInit();
}

function initializeEvents() {	
	initializeSearchStoreEvents();
}

function autoScrollToStoreAddressSearchPanel() {
	setTimeout(function () {
		$('html, body').animate({
			scrollTop: $('.js-store-address-search-panel').offset().top
		}, 1000);
	}, 500);
}

exports.init = function () {
	if (!$('#dwfrm_singleshipping_shippingAddress div.product-row div.product-available-date').length) {
		$('.js-store-address-search-panel').show();		
	}
    initializeEvents();    
    //TODO: check if we have the store panel open, in this case - don't do a search
    var region = $('#dwfrm_storelocator_state').val();
	var regionName = $('#dwfrm_storelocator_state option:selected').text();
	var rmZone = $('#dwfrm_storelocator_state option:selected').data("rm-zone");
    if (region) {
    	if ($('.js-store-panel .js-selected-store-address .store-name').length == 0) {
    		loadClickCollectStoresList(region, regionName, rmZone);
    	}
	}
	if(Resources.SITE_ID == "ParisPe"){
		if($('.js-click-collect-section').length != 0){
			loadAllClickCollectStoresList();
			$('.js-store-address-search-panel').hide();
			$(".js-store-pickup-list").css('overflow','hidden');
		}
	} 
    initializeStoreClickCollectList();
};
