'use strict';

const ProductsMarking = require('./../../utils/ProductsMarking');
const image = require('./../../pages/product/image');

const sliderMagazine = {
  mainSelector: '.slider-magazine',

  element: null,

  init() {
    $(() => {
      this.element = $(this.mainSelector);

      if (this.element.length > 0) {
        this.element.each((i, el) => {
          el = $(el);
          if (!el.hasClass('slick-initialized')) {
            const config = this.getOptions(el);
            const listMarkingEnabled = el.data('listMarking') || 0;
            const listMarkingDescription = el.data('listMarkingDescription');
            const listMarkingSlot = el.data('listMarkingSlot');

            const Marking = listMarkingEnabled ? new ProductsMarking() : null;

            if (listMarkingEnabled) {
              const listName = listMarkingSlot !== null && listMarkingSlot !== undefined ? `${listMarkingDescription} - ${listMarkingSlot}` : `${listMarkingDescription}`;
              Marking.defineListName(listName);
            }

            el.on('init', (e, slick) => {
              if (!!Marking) {
                Marking.handleChangeSlide(slick);
              }
            });

            el.slick(config);

            el.on('afterChange', (e, slick, currentSlide) => {
              if (!!Marking) {
                Marking.handleChangeSlide(slick, currentSlide);
              }

              image.lazyLoad();
            });
          }
        });
      }
    });
  },

  getOptions(el) {
    const options = {
      lazyLoad: 'ondemand',
      dots: true,
      slidesToShow: this.getSlidesToShow(el, 'lg'),
      slidesToScroll: this.getSlidesToScroll(el, 'lg'),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: this.getSlidesToShow(el, 'md'),
            slidesToScroll: this.getSlidesToScroll(el, 'md'),
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: this.getSlidesToShow(el, 'sm'),
            slidesToScroll: this.getSlidesToScroll(el, 'sm'),
            arrows: false,
            dots: true,
          },
        },
      ],
    };

    if (el.hasClass('slider-magazine--autoplay')) {
      options.autoplay = true;
      options.pauseOnDotsHover = true;
      options.autoplaySpeed = 5000;
    }

    return options;
  },

  getSlidesToShow(el,sizeId) {
    const dataPerPage = el.data('perPage');
    let perPage = 1;

    if (!dataPerPage) {
      return perPage;
    }

    dataPerPage.split('|').forEach(breakpoint => {
      const breakpointData = breakpoint.split(':');
      const breakpointId = breakpointData[0];
      const breakpointPerPage = breakpointData[1];

      if (breakpointId === sizeId) {
        perPage = Number(breakpointPerPage);
      }
    });

    return perPage;
  },

  getSlidesToScroll(el, sizeId) {
    const dataToScroll = el.data('toScroll');
    let toScroll = 1;

    if (!dataToScroll) {
      return toScroll;
    }

    dataToScroll.split('|').forEach(breakpoint => {
      const breakpointData = breakpoint.split(':');
      const breakpointId = breakpointData[0];
      const breakpointToScroll = breakpointData[1];

      if (breakpointId === sizeId) {
        toScroll = Number(breakpointToScroll);
      }
    });

    return toScroll;
  },
};

module.exports = sliderMagazine;
