var actionResults = {
  element: null,
  quickFilters: null,

  elementTopPosition: 0,

  init: function() {
    this.element = $('.pagination-top');
    this.quickFilters = $('.quick-filters');

    if (window.matchMedia('(max-width: 768px)').matches && this.element.length) {
      this.elementTopPosition = this.element.offset().top;
      $(window).on('scroll', this.handleScroll.bind(this));
    }
  },

  restart: function() {
    $(window).off('scroll');
    this.init();
  },

  handleScroll() {
    var scrollTop = $(window).scrollTop();
    // var elHeight = this.element.height();
    var elHeight = $('.action-results').height();

    const nextElemenet = this.element.next().length
      ? this.element.next()
      : this.element.parent().next().length
        ? this.element.parent().next()
        : this.element.parent().parent().next().length
          ? this.element.parent().parent().next()
          : this.element.parent().parent().parent().next();

    
    if (scrollTop >= this.elementTopPosition) {
      this.element.addClass('pagination-top--fixed');
      this.quickFilters.removeClass('quick-filters--hidden');
      nextElemenet.css('margin-top', elHeight);
    } else {
      this.element.removeClass('pagination-top--fixed');
      this.quickFilters.addClass('quick-filters--hidden');
      nextElemenet.css('margin-top', 'inherit');
    }
  },
};

module.exports = actionResults;
