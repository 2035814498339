'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    shipping = require('./shipping'),
    factura = require('./factura'),
    giftcard = require('./giftcard'),
    specialProgram = require('./specialProgram'),
    personalDetails = require('./personalDetails'),
    personalDetailsGuest = require('./personalDetailsGuest'),
    stepper = require('./stepper'),
    regions = require('../../regions');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    //JD: Init regions and comunas
    // regions.initializeRegionsComunasSmartCustomer();
    regions.initPersonalDetailRegionsComunas();
    address.init();
    if ($('.checkout-shipping').length > 0) {
        shipping.init();
    } else if ($('.box-checkout-personal-information').length > 0 || $("#AccountDetails").length > 0) {
        // TODO: JD: Al parecer "personalDetails" queda deprecado, queda comentado en caso de...
    	// personalDetails.init();
        personalDetailsGuest.init();
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
    } else {
        factura.init();
        giftcard.init();
        billing.init();
        specialProgram.init();
    }
    
    //if on the order review page and there are products that are not available diable the submit order button
    if ($('.order-summary-footer').length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }

    // Get guest info
    if (document.location.pathname.includes('personaldetails')) {
        $('.btn-unregistered-user-continue').on('click', function () {
            var firstName = '';
            var lastName = '';
            var email = '';
            var phone = '';
            var userId = '';
            var rut = '';

            userId = $('#dwfrm_personaldetails_rut').val();
            userId = userId.replace(/\./g, '');
            userId = userId.replace('-', '');

            if ($('#dwfrm_personaldetails_firstName').hasClass('valid')){
                firstName = btoa($('#dwfrm_personaldetails_firstName').val());
            }
            if ($('#dwfrm_personaldetails_lastName').hasClass('valid')) {
                lastName = btoa($('#dwfrm_personaldetails_lastName').val());
            }
            if ($('#dwfrm_personaldetails_email').hasClass('valid')) {
                email = btoa($('#dwfrm_personaldetails_email').val().toLowerCase());
            }
            if ($('#dwfrm_personaldetails_phoneCode').hasClass('valid')) {
                phone = btoa($('#dwfrm_personaldetails_phoneCode').val());
            }
            if ($('#dwfrm_personaldetails_rut').hasClass('valid')) {
                rut = btoa(userId);
            }

            if (firstName.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0 && userId.length > 0) {
                dataLayer.push({
                    'event' : 'pushGuestRegister',
                    'ud1' : firstName,
                    'ud2' : lastName,
                    'ud3' : email,
                    'ud4' : rut,
                    'ud5' : rut,
                    'ud6' : 'guest'
                });
            }
        });
    }

    stepper.init();
};
