'use strict';

var image = require('./product/image');
var sliders = require('./../sliders');
localStorage.removeItem("slot");

function initStickyNav() {
    var menuHeight = 100;

    $(".GTM-menuclick").on('click', function(e){
        e.preventDefault();
        var slotID = $(this).data('slotid');
        var $idTo = $("#world-" + slotID);
        $('html,body').animate({
            scrollTop: $idTo.offset().top - menuHeight
        }, 500);
        initContentLazyLoad('.content-lazy.loaderCircle');
    });
    $("a.GTM-menuclick").first().addClass('active');

    if ($('#sticky--').length > 0) {
        $(window).on('scroll', function () {
            var stickyNavTop = $('#sticky--').offset().top;
            var scrollTop = $(window).scrollTop();
            if (scrollTop > stickyNavTop) {
                $('#menuid').addClass('menu-sticky-new');
            } else {
                $('#menuid').removeClass('menu-sticky-new');
            }
            var sections = $("section > div[class*=content-lazy").not("#world-7");

            if (!sections.length) {
                sections = $("section > div[class*=word-content");
            }

            sections.each(function () {
                if (scrollTop >= ($(this).position().top - menuHeight - 10) && scrollTop <= ($(this).position().top + $(this).height())) {
                    //console.log($(this).data('slotid'));
                    //console.log($("a.GTM-menuclick").not($("a.GTM-menuclick[data-slotid='"+$(this).data('slotid')+"']")));
                    $("a.GTM-menuclick").not($("a.GTM-menuclick[data-slotid='"+$(this).data('slotid')+"']")).removeClass('active');
                    sections.removeClass('active');
                    $(this).addClass('active');
                    $("a.GTM-menuclick[data-slotid='"+$(this).data('slotid')+"']").addClass("active");
                }
            });
            //$('#ul-menu').scrollLeft($("a.GTM-menuclick.active").position().left)
            if ($('a.GTM-menuclick.active').length) {
                var myScrollPos = $('a.GTM-menuclick.active').offset().left + $('a.GTM-menuclick.active').outerWidth(true)/2 + $('#ul-menu').scrollLeft() - $('#ul-menu').width()/2;
                $('#ul-menu').scrollLeft(myScrollPos);
            }
        });
    }
};
    
function initContentLazyLoad(selector){
	$(selector).lazy({
        chainable: false,
        scrollDirection: 'vertical',
        effect: 'fadeIn',
        bind: 'event',
        visibleOnly: true,
        threshold: 0,
		afterLoad: function(element) {
            element.removeClass("loaderCircle");
            image.lazyLoad();

            if(element.attr("id") === "world-7"){
                initStickyNav();
            }

            window.sliderMagazine.init();
            window.modalIframe.init();
            sliders.initializeSliders();
		}
	});
}
exports.init = function () {
    if (typeof(window.pageType) == 'undefined') {
        window.dataLayer.push({
            'userID': userIdDataLayer,
            'page': window.location.pathname, 
            'pagename' : window.location.pathname,
        });
    } else {
        window.dataLayer.push({
            'userID': userIdDataLayer,
            'page': window.location.pathname, 
            'pagename' : window.pageType.type,
        });
    }


    $('#homepage-slider')
        // responsive slides
        .on('jcarousel:create jcarousel:reload', function () {
            var element = $(this),
                width = element.innerWidth();
            element.jcarousel('items').css('width', width + 'px');
        })
        .jcarousel({
            wrap: 'circular'
        })
        .jcarouselAutoscroll({
            interval: 5000
        });
    $('#homepage-slider .jcarousel-control')
        .on('jcarouselpagination:active', 'a', function () {
            $(this).addClass('active');
        })
        .on('jcarouselpagination:inactive', 'a', function () {
            $(this).removeClass('active');
        })
        .jcarouselPagination({
            item: function (page) {
                return '<a href="#' + page + '">' + page + '</a>';
            }
        });

    $('#vertical-carousel')
        .jcarousel({
            vertical: true
        })
        .jcarouselAutoscroll({
            interval: 5000
        });
    $('#vertical-carousel .jcarousel-prev')
        .on('jcarouselcontrol:active', function () {
            $(this).removeClass('inactive');
        })
        .on('jcarouselcontrol:inactive', function () {
            $(this).addClass('inactive');
        })
        .jcarouselControl({
            target: '-=1'
        });

    $('#vertical-carousel .jcarousel-next')
        .on('jcarouselcontrol:active', function () {
            $(this).removeClass('inactive');
        })
        .on('jcarouselcontrol:inactive', function () {
            $(this).addClass('inactive');
        })
        .jcarouselControl({
            target: '+=1'
		});
	
    initContentLazyLoad('.content-lazy');

    addEventListener('load', function(){
        window.dataLayer.push({
            'event': 'page-virtual',
        });
    }, false);
};

exports.initStickyNav = initStickyNav;
