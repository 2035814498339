'use strict';
 
var ajax = require('./ajax'),
    util = require('./util'),
    formPrepare = require('./pages/checkout/formPrepare');
/**
 * @function
 * @description Load details to a given gift certificate
 * @param {String} id The ID of the gift certificate
 * @param {Function} callback A function to called
 */
exports.checkBalance = function (id, callback) {
    // load gift certificate details
    var url = util.appendParamToURL(Urls.giftCardCheckBalance, 'giftCertificateID', id);

    ajax.getJson({
        url: url,
        callback: callback
    });
};

exports.initGiftCardFormPrepare = function () {
	formPrepare.init({
		continueSelector: '[name$="_giftcard_apply"]',
		formSelector: '[id$="_giftcard"]',
		enabledByDefault: true
	});
	
	$(document).bind("giftcard-form-show", function(e, status){
		formPrepare.validateForm({
			continueSelector: '[name$="_giftcard_apply"]',
			formSelector: '[id$="_giftcard"]',
			enabledByDefault: true
		});
	});
};
