/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict'; 

const { resolve, reject } = require('promise');
var	dialog = require('./dialog'),
    minicart = require('./minicart'),
    productguide = require('./product-guide.js'),
    catalogproductguide = require('./catalog-product-guide.js'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    login = require('./login'),
    sliders = require('./sliders'),
    mobileMeganav = require('./mobile-meganav'),
    orderconfirmation = require('./pages/checkout/orderconfirmation'),
    image = require('./pages/product/image'),
    delivery = require('./pages/product/delivery'),
    liftingPdp = require('./pages/product/liftingPdp'),
    productTile = require('./product-tile'),
    variant = require('./pages/product/variant'),
    libroReclamaciones = require('./libroreclamaciones.js'),
    smartCustomer = require('./smartCustomer'),
    drawerTarjeta = require('./drawerTarjeta'),
    // bannerSmartCustomer = require('./bannerSmartCustomer'),
    newRegister = require('./new-register'),
    newLogin = require('./new-login'),
    createPassword = require('./create-password'),
    mainMenu = require('./main-menu'),
    hamburgerMenu = require('./hamburgermenu'),
    account = require('./pages/account'),
    smartbanner = require('./smartbanner');

/**
 * import global UI components
 * */
window.mobileSearch = require('./components/mobile-search-fixed');
window.desktopSearch = require('./components/desktop-search');
window.refinementFilters = require('./components/refinement-filters');
window.navigationDrawerMobile = require('./components/navigation-drawer-mobile');
window.headerCustomer = require('./components/header/header-customer'),
window.sliderAdjustable = require('./components/commons/slider-adjustable');
window.sliderMagazine = require('./components/commons/slider-magazine');
window.buttonTopTop = require('./components/commons/button-to-top');
window.quickFilters = require('./components/commons/quick-filters');
window.storefront = require('./pages/storefront');
window.countdown = require('./components/commons/countdown');
window.modalIframe = require('./components/commons/modal-iframe');
window.collapse = require('./components/commons/collapse');
window.uiSelect = require('./components/commons/ui-select');
window.uiTextfield = require('./components/commons/ui-textfield');
window.uiNotification = require('./components/commons/ui-notification');
window.tabs = require('./components/tabs');

/**
 * Custom Plugins for jQuery
 * 
 **/
$.fn.selectUiValue = function(value) {
    const $el = $(this);

    if ($el.prop('tagName') !== 'SELECT') {
        return;
    }

    if (!$el.parents('.select-ui').length) {
        return;
    }
    
    return window.uiSelect.setValue($el, value);
};

$.fn.validateForm = function() {
    this.$form = $(this);

    this.$fields = {};

    this.$errors = {};

    this.$submitButton = null;

	this.onErrorUpdated = null;

    if (this.$form.prop('tagName') !== 'FORM') {
        return null;
    }

    this.validations = {
        required: {
            validation: (value) => !!value,
            message: 'Campo requerido',
        },
        formatNameSurname: {
            validation(field) {
                const re = /^[a-zA-Z ]+$/;
                return re.test(field);
            },
            message: 'Por favor, ingresa tu dato válido. Solo se permiten letras.',
        },
        min: {
            validation: (value, ruleValue) => value.length >= ruleValue,
            message: 'El valor debe tener un minimo ${s1} caracteres',
        },
        max: {
            validation: (value, ruleValue) => value.length <= ruleValue,
            message: 'El valor debe tener un maximo ${s1} caracteres'
        },
        email: {
            validation(email) {
                const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            message: 'Por favor, ingresa un correo válido.',
        },
        between: {
            validation: (value, ruleValue) => {
                return value.length >= ruleValue[0] && value.length <= ruleValue[1];
            },
            message: 'Revisa que tu contraseña tenga entre ${s1} y ${s2} caracteres',
        },
        minCapitalCount: {
            validation: (value) => {
                const re = /([A-Z]{1})/;
                return re.test(value);
            },
            message: 'El campo debe contener al menos 1 mayúscula',
        },
        minLowerCount: {
            validation: (value) => {
                const re = /([a-z]{1})/;
                return re.test(value);
            },
            message: 'El campo debe contener al menos 1 minuscula',
        },
        minNumberCount: {
            validation: (value) => {
                const re = /([0-9]{1})/;
                return re.test(value);
            },
            message: 'El campo debe contener al menos 1 número',
        },
        rut: {
            validation: (rut) => util.helperRut.rutValidate(rut),
            message: 'Por favor, ingresa un RUT válido.',
        },
        uniqueEmail: {
            request: null,
            validation(email) {
                if (this.request) {
                    this.request.abort();
                }
                return new Promise((resolve) => {
                    this.request = $.ajax({
                        type: 'POST',
                        url: window.Urls.checkEmailIsRegistered,
                        method: 'get',
                        data: { email },
                        success: function (response) {
                            if (response.code === 409) {
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                        },
                        failure: function () {
                            resolve(false);
                        }
                    });
                });
            },
            message: 'El correo que ingresaste ya tiene una cuenta asociada.',
        },
        uniqueRut: {
            request: null,
            validation(rut) {
                if (this.request) {
                    this.request.abort();
                }
                return new Promise((resolve) => {
                    const newRut = util.helperRut.rutClean(rut);
                    this.request = $.ajax({
                        type: 'POST',
                        url: window.Urls.checkRutIsRegistered,
                        method: 'get',
                        data: { rut: newRut },
                        success: function (response) {
                            if (response.code === 409) {
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                        },
                        failure: function () {
                            resolve(false);
                        }
                    });
                });
            },
            message: 'El RUT que ingresaste ya tiene una cuenta asociada.',
        }
    };

    function parseRules(stringRules) {
        if (typeof stringRules !== 'string') {
            return {};
        }

        return stringRules.split('|').reduce((objectRules, stringRule) => {
            const ruleData = stringRule.split(':');
            const ruleName = ruleData[0];
            const ruleValue = ruleData.length > 1
                ? ruleData[1]
                : ruleName === 'required' || 'email'
                    ? true
                    : null;

            const arrRuleValue = typeof ruleValue === 'string' ? ruleValue.split(',') : [];
            objectRules[ruleName] = arrRuleValue.length > 1 ? arrRuleValue : ruleValue;
            return objectRules;
        }, {});
    }

    const getAsyncValidation = (ruleName, ruleValue, value) => {
        if (this.validations.hasOwnProperty(ruleName)) {
            
            return new Promise(resolve => {
                const func = this.validations[ruleName].validation(value, ruleValue);
                const message = this.validations[ruleName].message;
                
                Promise.all([func])
                    .then(([result]) => {
                        resolve({
                            passed: result,
                            rule: ruleName,
                            message,
                        });
                    });
            });
        } else {
            return Promise.resolve({
                passed: true,
                rule: ruleName,
                message: '',
            });
        }
    }

    function getFieldErrors(value, rules) {
        return new Promise(resolve => {
            if ((typeof rules !== 'object') || (!value && !rules.hasOwnProperty('required'))) {
                return resolve([]);
            }

            const rulesResult = Object.keys(rules).map(ruleKey => {
                const ruleName = ruleKey;
                const ruleValue = rules[ruleKey];
                return getAsyncValidation(ruleName, ruleValue, value);
            });

            Promise.all(rulesResult).then((results) => {
                const errors = [];
                results.forEach(result => {
                    if (!result.passed) {
                        delete result.passed;
                        const message = result.message;
                        const ruleValue = rules[result.rule];

                        errors.push({
                            ...result,
                            message: message.replaceS(ruleValue),
                        });
                    }
                });
                resolve(errors);
            });
        });
    }

    this.updateErrorState = function(refreshInputs = false) {
        this.setFields();
        this.getErrors(refreshInputs).then(errors => {
            this.$errors = errors;

            if (this.onErrorUpdated) {
                this.onErrorUpdated(errors);
            }
        });
    }

    this.hasErrors = function() {
        return Object.values(this.$errors).length;
    }

    this.setFields = function() {
        this.$submitButton = this.$form.find('button[type="submit"]');

        this.$form.find('input:not([type="radio"], [type="checkbox"], [type="hidden"]), select').each((i, field) => {
            const $field = $(field);
            const dataField = {
                value: $field.val(),
                name: $field.prop('name'),
                rawRules: $field.data('rules'),
                rules: parseRules($field.data('rules')),
                $el: $field,
            };
            this.$fields[dataField.name] = dataField;
        });
    }

    this.initForm = function() {
        const updateAllInputsOnErrorRefresh = false;
        Object.keys(this.$fields).forEach(fieldName => {
            const fieldData = this.$fields[fieldName];
            const $field = fieldData.$el;
            const tagName = $field.prop('tagName');
                if (tagName === 'SELECT') {
                    if (!fieldData.name === 'dwfrm_personaldetails_region' || !fieldData.name === 'dwfrm_personaldetails_comuna') {
                        window.uiSelect.initSelect($field);
                    }
                    $field.on('change', () => {
                        this.$fields[fieldName].value = $field.val();

                        getFieldErrors($field.val(), fieldData.rules).then(fieldErrors => {
                            if (fieldErrors.length) {
                                this.$errors[fieldData.name] = fieldErrors;
                                window.uiSelect.addError($field, fieldErrors[0].message);
                            } else {
                                delete this.$errors[fieldData.name];
                                window.uiSelect.check($field);
                            }
                            this.updateErrorState(updateAllInputsOnErrorRefresh);
                        });
                    });
                }
                
                if (tagName === 'INPUT') {
                    if ($field.prop('type') !== 'text' &&
                        $field.prop('type') !== 'password' &&
                        $field.prop('type') !== 'number') {
                        return;
                    }

                    window.uiTextfield.initInput($field);

                    if (fieldData.rules.hasOwnProperty('rut')) {
                        $field.on('blur', () => {
                            $field.val(util.helperRut.rutFormat($field.val()));
                        });
                    }

                    $field.on('keyup', () => {
                        this.$fields[fieldName].value = $field.val();

                        getFieldErrors($field.val(), fieldData.rules).then(fieldErrors => {
                            if (!$field.data('verbose')) {
                                if (fieldErrors.length) {
                                    this.$errors[fieldData.name] = fieldErrors;
                                    window.uiTextfield.addError($field, fieldErrors[0].message);
                                } else {
                                    delete this.$errors[fieldData.name];
                                    window.uiTextfield.check($field);
                                }
                            } else {
                                window.uiTextfield.setErrorVerbose($field, fieldErrors);
                            }
                            this.updateErrorState(updateAllInputsOnErrorRefresh);
                        });
                    });
                }
        });
    }

    this.resetFieldErrors = function() {
        Object.keys(this.$fields).forEach(fieldName => {
            const fieldData = this.$fields[fieldName];
            const $field = fieldData['$el'];
            const tagName = $field.prop('tagName');

            if (tagName === 'SELECT') {
                window.uiSelect.removeError($field);
            } else {
                window.uiTextfield.removeError($field);
            }
        });
    }

    this.resetForm = function() {
        Object.keys(this.$fields).forEach(fieldName => {
            const fieldData = this.$fields[fieldName];
            const $field = fieldData['$el'];
            const tagName = $field.prop('tagName');

            if (tagName === 'SELECT') {
                window.uiSelect.setValue($field, '');
                window.uiSelect.removeError($field);
                window.uiSelect.uncheck($field);
            } else {
                window.uiTextfield.setValue($field, null);
                window.uiTextfield.removeError($field);
                window.uiTextfield.uncheck($field);
            }
        });
    }

    this.getErrors = function(refresInputs) {
        return new Promise(resolve => {
            const errors = {};
            const promises = [];

            Object.values(this.$fields).forEach((fieldData) => {
                const value = fieldData.$el.val();

                const fieldErrors = getFieldErrors(value, fieldData.rules).then(fieldErrors => {
                    if (fieldErrors.length) {
                        errors[fieldData.name] = fieldErrors;

                        if (refresInputs || !!value) {
                            const tagName = fieldData.$el.prop('tagName');
                            if (tagName === 'SELECT') {
                                window.uiSelect.addError(fieldData.$el, fieldErrors[0].message);
                            } else {
                                if (fieldData.$el.data('verbose')) {
                                    window.uiTextfield.setErrorVerbose(fieldData.$el, fieldErrors);
                                } else {
                                    window.uiTextfield.addError(fieldData.$el, fieldErrors[0].message);
                                }
                            }
                        }
                    } else {
                        if (refresInputs || !!value) {
                            const tagName = fieldData.$el.prop('tagName');
                            if (tagName === 'SELECT') {
                                window.uiSelect.check(fieldData.$el);
                            } else {
                                if (fieldData.$el.data('verbose')) {
                                    window.uiTextfield.setErrorVerbose(fieldData.$el, fieldErrors);
                                } else {
                                    window.uiTextfield.check(fieldData.$el);
                                }
                            }
                        }
                    }
                });
                
                promises.push(fieldErrors);
            }, []);

            Promise.all(promises).then(() => {
                resolve(errors);
            });
        });
    }

    this.validate = function() {
        this.setFields();
        this.resetFieldErrors();
        return new Promise(resolve => {
            var refreshInputs = true;
            this.getErrors(refreshInputs).then(errors => {
                this.$errors = errors;

                if (this.onErrorUpdated) {
                    this.onErrorUpdated(errors);
                }

                resolve(!this.hasErrors());
            });
        });
    }

    this.updateErrorState();
    this.initForm();
    return this;
};

// it should be imorted only in PLP page
window.actionResults = require('./action-results');
window.sellerDetails = require('./components/seller-details');

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    // main menu toggle
    $('.menu-toggle').on('click', function () {
        $('#wrapper').toggleClass('menu-active');
    });
    $('.menu-category li .menu-item-toggle').on('click', function (e) {
        e.preventDefault();
        var $parentLi = $(e.target).closest('li');
        $parentLi.siblings('li').removeClass('active').find('.menu-item-toggle').removeClass('fa-chevron-up active').addClass('fa-chevron-right');
        $parentLi.toggleClass('active');
        $(e.target).toggleClass('fa-chevron-right fa-chevron-up active');
    });
    $('.user-account').on('click', function (e) {
        e.preventDefault();
        $(this).parent('.user-info').toggleClass('active');
    });
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
}

function showSuccessMsg() {
    const search = window.location.search.substring(1); 
    let params = {};

    try {
      params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
    } catch(e) {
      return;
    }

    if (params.hasOwnProperty('succcessPaswordReset') && params.succcessPaswordReset === 'true') {
        const url = new URL(window.location);
        url.searchParams.delete('succcessPaswordReset');
        window.history.pushState({}, '', url);
        window.uiNotification('success', Resources.ACCOUNT_PASSWORD_RECOVERY_SUCCESS_MSG_TITLE, Resources.ACCOUNT_PASSWORD_RECOVERY_SUCCESS_MSG_TEXT);
    }
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    pdp: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: window.storefront,
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator')
};

var app = {
    init: function () {
        if (!navigator.cookieEnabled) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }

        initStickyAddToCartPDP();

        initializeDom();
        initializeEvents();
        // init specific global components
        tooltip.init();
        minicart.init(true);
        validator.init();
        rating.initProductReview();
        productguide.init();
        catalogproductguide.init();
        searchplaceholder.init();
        login.init();
        libroReclamaciones.init();
        account.initMyCredits();
        smartbanner.init();
        if (document.querySelector('.js-lifting-pdp') !== null) {
            window.addEventListener("load", () => {
				liftingPdp.init();
            });
        }
        if (document.querySelector('.hamburger') !== null) {
            if (document.querySelector('.header-mockup-header-bar') !== null) {
                mainMenu.initializeMainMenu({
                    // TODO: Make these flags accessible through Business Manager with Custom Preferences
                    showOverlay: true,
                    closeOnClickOutside: true,
                    gesturesOnMobile: true,
                    keyboardInteraction: true,
                    tagging: true,
                    startOpen: false
                });
            } else {
                hamburgerMenu.init();
            }
        }
        if($('.onecolumn').length) {
            delivery.bundlePDP();
        }

        if ($('#dwfrm_personaldetails_continue').length && $('#dwfrm_profile_customer_conditions').length && $('#personal-details-form').length) {
            $('.dwfrm_profile_customer_conditions_checkbox').on('change', () => {
                if ($('#dwfrm_profile_customer_conditions').length) {
                  if ($('#dwfrm_profile_customer_conditions')[0].checked) {
                    $('#dwfrm_profile_customer_conditions_label').addClass('checkbox-label__label--checked');
                    $('#dwfrm_profile_customer_conditions_error').css('display', 'none');
                    $('#dwfrm_profile_customer_conditions_checkbox').removeClass('error');
                    
                  } else {
                    $('#dwfrm_profile_customer_conditions_label').removeClass('checkbox-label__label--checked');
                    $('#dwfrm_profile_customer_conditions_error').css('display','');
                    $('#dwfrm_profile_customer_conditions_checkbox').addClass('error');
                }
                }
            });

            $('#personal-details-form').on('submit', (e) => {
                if ($('#dwfrm_profile_customer_conditions').length && $('#dwfrm_profile_customer_conditions')[0].checked === false) {
                    e.preventDefault();
                    $('#dwfrm_profile_customer_conditions_label').removeClass('checkbox-label__label--checked');
                    $('#dwfrm_profile_customer_conditions_error').css('display','');
                    $('#dwfrm_profile_customer_conditions_checkbox').addClass('error');
                }
            });
        }
         
        // execute page specific initializations
        $.extend(page, window.pageContext);
        const namespace = page.ns;
        if (namespace && pages[namespace] && pages[namespace].init) {
            pages[namespace].init();
        }
        sliders.initializeSliders();
		sliders.initializeEinSliders();
        sliders.initializeColorCarousels(); 
        sliders.initialSwatchesMobSwipe();
        sliders.initialSliderProdEvent();
        sliders.initialQtySliders();
        mobileMeganav.initializeMobileMeganav();
        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
        if ($('.pt_order-confirmation').length > 0) {
            orderconfirmation.init();
        }
		showSuccessMsg();
		image.lazyLoad();
		productTile.init();

        // TODO: [PRS-1449] Solución parche para evitar múltiples inicializaciones en la PDP,
        // queda pendiente filtrar todas las inicializaciones de una forma más eficiente.
        if (namespace !== 'product') {
            variant.init();
        }

        smartCustomer.init();
        drawerTarjeta.init();
        // bannerSmartCustomer.init();
        newRegister.init();
        newLogin.checkToOpen();
        
        // it should be initialiazed only in PLP products
        if (namespace === 'search') {
            window.quickFilters.init();
            window.actionResults.init();
        }

        // it should be initialiazed only in confirmation view for guest user
        createPassword.init();
        window.sellerDetails.init();
        
        /**
         * initialize global UI components
         * */

        window.desktopSearch.init();
        window.refinementFilters.init();
        window.mobileSearch.init();
        window.navigationDrawerMobile.init();
        window.headerCustomer.init();
        window.sliderAdjustable.init()
        window.sliderMagazine.init();
        window.buttonTopTop.init();
        window.storefront.initStickyNav();
        window.countdown.init();
        window.modalIframe.init();
        window.collapse.init();
        window.tabs.init();
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };

    String.prototype.replaceS = function(arr) {
        let string = this.toString();

        if (!Array.isArray(arr)) {
            return string.replace(`\${s1}`, arr);
        } else {
            return arr.reduce((string, item, i) => string.replace(`\${s${i + 1}}`, item), string);
        }
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});

function initStickyAddToCartPDP() {
    var element = document.getElementsByClassName("box-boton");

    if (!element.length) {
        return;
    }

    var element = element[0];

    window.onscroll = function() {
        if(window.innerWidth > 767) {
            if (($('.box-boton').offset().top + $('.box-boton').height()) - ($(window).scrollTop() + $(window).height()) > 12) {
                element.classList.add('box-boton--sticky');
            } else {
                element.classList.remove('box-boton--sticky');
            }
        }
    };
}