'use strict';

const buttonToTop = {
  /*
   * documentation http://api.jquery.com/animate/
   */
  easing: 'swing',

  duration: 500,

  button: null,

  init() {
    $(document).ready(() => {
      this.button = $('.button-to-top');
      const top = $(document).scrollTop();
      
      if (top > 0) {
        this.button.show();
      } else {
        this.button.hide()
      }

      this.button.each((i, el) => {
        $(el).click(this.handleClick.bind(this));
      });

      $(document).bind('scroll', this.handleScroll.bind(this));
    });
  },

  handleScroll(e) {
    const top = $(document).scrollTop();

    if (top > 0) {
      this.button.fadeIn('fast');
    } else {
      this.button.fadeOut('fast')
    }
  },

  handleClick(e) {
    const body = $('html, body');

    body
      .stop()
      .animate({
        scrollTop: 0,
      }, this.duration, this.easing);
  },
};

module.exports = buttonToTop;
